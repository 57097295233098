/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { WooCommerceAccountLink } from '../model/wooCommerceAccountLink';
import { WooCommerceCreateCouponView } from '../model/wooCommerceCreateCouponView';
import { WooCommerceSettingsView } from '../model/wooCommerceSettingsView';
import { WooCommerceTransactionView } from '../model/wooCommerceTransactionView';
import { WooCommerceVendorLink } from '../model/wooCommerceVendorLink';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WooCommerceService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWooCommerceAddVendorsPost(body?: Array<WooCommerceVendorLink>, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWooCommerceAddVendorsPost(body?: Array<WooCommerceVendorLink>, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWooCommerceAddVendorsPost(body?: Array<WooCommerceVendorLink>, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWooCommerceAddVendorsPost(body?: Array<WooCommerceVendorLink>, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/WooCommerce/AddVendors`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWooCommerceConfigureWebhooksPost(scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWooCommerceConfigureWebhooksPost(scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWooCommerceConfigureWebhooksPost(scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWooCommerceConfigureWebhooksPost(scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/WooCommerce/ConfigureWebhooks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWooCommerceCreateCouponPost(body?: WooCommerceCreateCouponView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWooCommerceCreateCouponPost(body?: WooCommerceCreateCouponView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWooCommerceCreateCouponPost(body?: WooCommerceCreateCouponView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWooCommerceCreateCouponPost(body?: WooCommerceCreateCouponView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/WooCommerce/CreateCoupon`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWooCommerceGetOrdersCustomerIdGet(customerId: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWooCommerceGetOrdersCustomerIdGet(customerId: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWooCommerceGetOrdersCustomerIdGet(customerId: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWooCommerceGetOrdersCustomerIdGet(customerId: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiWooCommerceGetOrdersCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/WooCommerce/GetOrders/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWooCommerceLinkCustomerPost(body?: WooCommerceAccountLink, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWooCommerceLinkCustomerPost(body?: WooCommerceAccountLink, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWooCommerceLinkCustomerPost(body?: WooCommerceAccountLink, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWooCommerceLinkCustomerPost(body?: WooCommerceAccountLink, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/WooCommerce/LinkCustomer`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWooCommerceOnRefundPost(body?: WooCommerceTransactionView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWooCommerceOnRefundPost(body?: WooCommerceTransactionView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWooCommerceOnRefundPost(body?: WooCommerceTransactionView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWooCommerceOnRefundPost(body?: WooCommerceTransactionView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/WooCommerce/OnRefund`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWooCommerceOnTransactionSchemeIdPost(schemeId: string, body?: WooCommerceTransactionView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWooCommerceOnTransactionSchemeIdPost(schemeId: string, body?: WooCommerceTransactionView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWooCommerceOnTransactionSchemeIdPost(schemeId: string, body?: WooCommerceTransactionView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWooCommerceOnTransactionSchemeIdPost(schemeId: string, body?: WooCommerceTransactionView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiWooCommerceOnTransactionSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/WooCommerce/OnTransaction/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWooCommerceSetupPost(body?: WooCommerceSettingsView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWooCommerceSetupPost(body?: WooCommerceSettingsView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWooCommerceSetupPost(body?: WooCommerceSettingsView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWooCommerceSetupPost(body?: WooCommerceSettingsView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/WooCommerce/Setup`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWooCommerceVendorsGet(scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWooCommerceVendorsGet(scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWooCommerceVendorsGet(scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWooCommerceVendorsGet(scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/WooCommerce/Vendors`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
