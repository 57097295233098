<mat-card>
    <mat-card-header>

        <mat-card-title>
            <mat-icon color="accent" class="mr-2">sms</mat-icon>
            <span>SMS</span>
        </mat-card-title>

        <mat-card-subtitle>
            Select an existing SMS or create a new one
        </mat-card-subtitle>

        <button mat-icon-button class="top-right-action" (click)="removeSMS.emit()" matTooltip="Remove">
            <mat-icon>cancel</mat-icon>
        </button>

    </mat-card-header>

    <mat-card-content>
        <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

        <div class="row align-items-center" *ngIf="!awaitingGet">
            <div class="col-12 col-lg-7">
                <mat-form-field class="w-100">
                    <mat-label>SMS</mat-label>
                    <mat-select name="giftCard" #tagForm="ngModel" [(ngModel)]="selectedSMSId"
                                (ngModelChange)="onSMSChange()" [disabled]="smsList.length == 0" required>

                        <mat-option>
                            <ngx-mat-select-search name="filter" #filter="ngModel" [(ngModel)]="smsFilter"
                                                   (ngModelChange)="onFilterSMS()" placeholderLabel="Search"
                                                   noEntriesFoundLabel="No results found">
                                <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option [value]="smsForm.id" *ngFor="let smsForm of filteredSMS">
                            {{smsForm.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="tagForm?.errors?.required">Required</mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 col-lg-1 text-center">
                <h5 class="mb-4">Or</h5>
            </div>

            <div class="col-12 col-lg-4 text-center text-lg-left">
                <button mat-flat-button color="primary" class="mb-4" (click)="onNewSMS()">Create a new SMS
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>


