/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Outlet } from './outlet';

export interface Address { 
    id?: string;
    addressLine1?: string;
    addressLine2?: string;
    town?: string;
    postCode?: string;
    state?: string;
    countryId?: string;
    outlets?: Array<Outlet>;
}