import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DeleteDialogData {
  message: string;
  confirmValue?: string;
  warning?: string;
}

@Component({
  selector: 'loyale-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})

export class ConfirmDeleteDialogComponent implements OnInit {
  confirmInput = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteDialogData) { }

  ngOnInit() {
    if (!this.data.confirmValue) {
      this.data.confirmValue = 'DELETE';
    }
  }
}
