import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionSubtypeIcon'
})
export class TransactionSubtypeIconPipe implements PipeTransform {

  transform(value: any, split: boolean, send: boolean): string {
    if (value == 0 && split) {
      return 'call_split';
    } else if ((value == 2 && split) || (value == 2 && send)) {
      return 'call_made';
    } else if ((value == 1 && split) || (value == 1 && send)) {
      return 'call_received';
    }
  }

}
