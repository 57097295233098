import { Pipe, PipeTransform } from '@angular/core';

export enum ProductType {
  'Other' = 0,
  'Gift Card',
  'Coupon',
  'Sale',
  'TopUp',
  'Subscription'
}

@Pipe({
  name: 'productType'
})
export class ProductTypePipe implements PipeTransform {

  transform(value: any): string {
    return ProductType[value];
  }

}
