/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { FeedbackAnswer } from './feedbackAnswer';
import { FeedbackLinked } from './feedbackLinked';
import { FeedbackQuestion } from './feedbackQuestion';

export interface FeedbackResponse { 
    id?: string;
    feedbackQuestion?: FeedbackQuestion;
    feedbackQuestionId?: string;
    feedbackAnswer?: FeedbackAnswer;
    feedbackAnswerId?: string;
    customer?: Customer;
    customerId?: string;
    customResponse?: string;
    schemeId?: string;
    feedbackLinked?: FeedbackLinked;
    feedbackLinkedId?: string;
    createdBy?: string;
    updatedBy?: string;
    createdDate?: Date;
    updatedDate?: Date;
}