/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InvoiceCharge } from './invoiceCharge';
import { InvoiceType } from './invoiceType';
import { PaymentsLog } from './paymentsLog';
import { SchemeTerm } from './schemeTerm';
import { TqRecord } from './tqRecord';

export interface InvoiceListView { 
    schemeTerm?: SchemeTerm;
    paymentsLog?: Array<PaymentsLog>;
    id?: string;
    schemeId?: string;
    createdDate?: Date;
    updatedDate?: Date;
    schemeTermId?: string;
    invoiceNumber?: number;
    transactionReference?: string;
    total?: number;
    totalWithVat?: number;
    totalUsd?: number;
    totalWithVatUsd?: number;
    invoicePdfLink?: string;
    isPaid?: boolean;
    invoiceType?: InvoiceType;
    planChargesJson?: string;
    additionalChargesJson?: string;
    packChargesJson?: string;
    friendlyTpResponse?: string;
    tpTransactionQuery?: TqRecord;
    invoiceCharges?: Array<InvoiceCharge>;
}