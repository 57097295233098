<ng-container *ngIf="sessionService.schemeOffline">
    <button mat-button (click)="onPutOnline()" class="ic_top-nav-status">
        <mat-icon style="opacity:0.5;">toggle_off</mat-icon>
        <span class="ml-1">Offline</span>
    </button>
</ng-container>

<ng-container *ngIf="!sessionService.schemeOffline">
    <button mat-button (click)="onPutOffline()" class="ic_top-nav-status">
        <mat-icon color="accent">toggle_on</mat-icon>
        <span class="ml-1">Online</span>
    </button>
</ng-container>
