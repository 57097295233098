<div class="d-flex">
  <h1 mat-dialog-title>Add Prize</h1>
  <div class="flex-fill"></div>
  <mat-form-field  class="ml-3">
    <mat-label>Filter</mat-label>
    <input matInput name="filter" [(ngModel)]="compFilter" (ngModelChange)="onDebounceFilter()"
      [disabled]="awaitingGet" />
  </mat-form-field>
</div>
<div mat-dialog-content>
  <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

  <ng-container *ngIf="!awaitingGet">
    <div class="table-wrapper">
      <table mat-table [dataSource]="rewards" class="w-100">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let reward">
            <a mat-button [routerLink]="['/prizes/details', reward.id]" target="_blank">
              {{reward.name }}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef> Quantity&nbsp;&nbsp;</th>
          <td mat-cell *matCellDef="let reward">{{reward.quantity}}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef>Created On</th>
          <td mat-cell *matCellDef="let reward">
            {{reward.createdDate | amFromUtc | amLocal | amDateFormat:'DD/MM/YYYY'}}</td>
        </ng-container>

        <ng-container matColumnDef="add">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let reward" class="text-right">
            <button mat-button (click)="onAddReward(reward.id)">Add</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <div class="text-center p-3" *ngIf="rewards.data.length == 0">
      <h5>{{'No results found' | translate}}</h5>
    </div>

    <div class="d-flex align-items-center mt-3">
      <small class="page-count ml-3">{{rewards.data.length}} / {{totalCount}}</small>
      <div class="flex-fill"></div>
      <loyale-paginator [page]="page" [totalPages]="totalPages" (changePage)="onUpdateView($event)">
      </loyale-paginator>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions>
  <div class="flex-fill"></div>
  <button mat-flat-button [mat-dialog-close]="refreshForChanges" [disabled]="awaitingPost">Close</button>
</div>
