/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Transaction } from './transaction';

export interface TransactionItem { 
    id?: string;
    itemId?: string;
    quantity?: number;
    unitPrice?: string;
    description?: string;
    groupId?: string;
    transaction?: Transaction;
    transactionId?: string;
    discounts?: Array<number>;
    totalPrice?: number;
}