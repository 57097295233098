/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { EnabledView } from '../model/enabledView';
import { MailchimpSetupInsertView } from '../model/mailchimpSetupInsertView';
import { Unsubscribe } from '../model/unsubscribe';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MailchimpService {

    protected basePath = 'https://api.staging.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Gets audience info available on mailchimp API.
     * 
     * @param scheme 
     * @param schemeIntegrationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpAudienceSchemeIntegrationIdGet(scheme: string, schemeIntegrationId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpAudienceSchemeIntegrationIdGet(scheme: string, schemeIntegrationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpAudienceSchemeIntegrationIdGet(scheme: string, schemeIntegrationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpAudienceSchemeIntegrationIdGet(scheme: string, schemeIntegrationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiMailchimpAudienceSchemeIntegrationIdGet.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiMailchimpAudienceSchemeIntegrationIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Mailchimp/Audience/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if the integration is enabled
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiMailchimpEnabledSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Mailchimp/Enabled/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change mailchimp integration enabled status
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: EnabledView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: EnabledView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: EnabledView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: EnabledView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiMailchimpEnabledSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Mailchimp/Enabled/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get merge fields available from customer.
     * 
     * @param scheme 
     * @param schemeIntegrationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpMergeFieldsSchemeIntegrationIdGet(scheme: string, schemeIntegrationId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpMergeFieldsSchemeIntegrationIdGet(scheme: string, schemeIntegrationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpMergeFieldsSchemeIntegrationIdGet(scheme: string, schemeIntegrationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpMergeFieldsSchemeIntegrationIdGet(scheme: string, schemeIntegrationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiMailchimpMergeFieldsSchemeIntegrationIdGet.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiMailchimpMergeFieldsSchemeIntegrationIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Mailchimp/MergeFields/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unsubscribe user from loyale UI/Client.
     * 
     * @param schemeId 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpOnUnsubscribePostForm(schemeId: string, customerId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpOnUnsubscribePostForm(schemeId: string, customerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpOnUnsubscribePostForm(schemeId: string, customerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpOnUnsubscribePostForm(schemeId: string, customerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiMailchimpOnUnsubscribePost.');
        }


        let headers = this.defaultHeaders;
        if (schemeId !== undefined && schemeId !== null) {
            headers = headers.set('schemeId', String(schemeId));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (customerId !== undefined) {
            formParams = formParams.append('customerId', <any>customerId) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Mailchimp/OnUnsubscribe`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Handle unsubscription webhook fired from mailchimp.
     * 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpOnUnsubscribeSchemeIdPost(schemeId: string, body?: Unsubscribe, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpOnUnsubscribeSchemeIdPost(schemeId: string, body?: Unsubscribe, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpOnUnsubscribeSchemeIdPost(schemeId: string, body?: Unsubscribe, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpOnUnsubscribeSchemeIdPost(schemeId: string, body?: Unsubscribe, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiMailchimpOnUnsubscribeSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Mailchimp/OnUnsubscribe/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get mailchimp configuration settings
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiMailchimpSettingsSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Mailchimp/Settings/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update mailchimp configuration settings
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: MailchimpSetupInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: MailchimpSetupInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: MailchimpSetupInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: MailchimpSetupInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiMailchimpSettingsSchemeIntegrationIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Mailchimp/Settings/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Setup mailchimp for a scheme using the ApiKey, list details, and selected merge fields.
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpSetupPost(body?: MailchimpSetupInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpSetupPost(body?: MailchimpSetupInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpSetupPost(body?: MailchimpSetupInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpSetupPost(body?: MailchimpSetupInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Mailchimp/Setup`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Setup mailchimp for a scheme using the ApiKey, list details, and selected merge fields.
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpSetupSchemeIntegrationIdPost(schemeIntegrationId: string, body?: MailchimpSetupInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpSetupSchemeIntegrationIdPost(schemeIntegrationId: string, body?: MailchimpSetupInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpSetupSchemeIntegrationIdPost(schemeIntegrationId: string, body?: MailchimpSetupInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpSetupSchemeIntegrationIdPost(schemeIntegrationId: string, body?: MailchimpSetupInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiMailchimpSetupSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Mailchimp/Setup/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Starts customer synchronization with mailchimp Marketing API
     * 
     * @param scheme 
     * @param schemeIntegrationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpSyncSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpSyncSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpSyncSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpSyncSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiMailchimpSyncSchemeIntegrationIdPost.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiMailchimpSyncSchemeIntegrationIdPost.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Mailchimp/Sync/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Starts customer synchronization with mailchimp Marketing API
     * 
     * @param scheme 
     * @param schemeIntegrationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMailchimpUpdateAllCustomersSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMailchimpUpdateAllCustomersSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMailchimpUpdateAllCustomersSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMailchimpUpdateAllCustomersSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiMailchimpUpdateAllCustomersSchemeIntegrationIdPost.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiMailchimpUpdateAllCustomersSchemeIntegrationIdPost.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Mailchimp/UpdateAllCustomers/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
