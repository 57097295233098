export enum QuestionType {
  'Multiple Choice',
  'Emoji Rating',
  'Scale Rating',
  'Free Text'
}

export enum QuestionTypeIcon {
  'radio_button_unchecked',
  'sentiment_very_satisfied',
  'star_border',
  'notes'
}
