import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainActionInterface } from '../main-action.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActionType } from '../../stepper/action.enum';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActionConnectorInterface } from '../../stepper/actionConnector.interface';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailInsertView, SmsService } from '../../../swagger';
import { CommonService } from '../../../services/common.service';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'loyale-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit, MainActionInterface {
  awaitingPost = false;
  awaitingGet = false;

  sms: any;

  smsForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl('', [Validators.required, Validators.maxLength(160)]),
    isMarketingMaterial: new UntypedFormControl(false, [])
  });

  smsCopy: any = null;

  @Input() smsId: string = null;

  public readonly dataUpdate: BehaviorSubject<ActionConnectorInterface> = new BehaviorSubject<ActionConnectorInterface>({
    actionType: ActionType.SMS,
    actionData: null,
    formValid: null
  });

  @Output() emailData: EventEmitter<any> = new EventEmitter(this.smsForm.value);
  @Output() entityChanges: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private translate: TranslateService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private smsService: SmsService,
    public commonService: CommonService,
    public sessionService: SessionService) { }

  ngOnInit() {
    if (this.smsId) {
      this.getSMS();
    }

    this.smsForm.valueChanges.subscribe(changes => {
      this.dataUpdate.next(
        {
          actionType: ActionType.SMS,
          actionData: this.smsForm.value,
          formValid: this.smsForm.valid
        }
      );

      if (this.smsForm.touched) {
        this.emailData.next(changes);
      }

      this.sms = { ...this.sms, ...this.smsForm.value };
      this.entityChanges.next(JSON.stringify(this.smsCopy) !== JSON.stringify(this.sms));

    });
  }

  getSMS() {
    this.awaitingGet = true;
    this.smsService.apiSmsSmsSmsIdGet(this.smsId, this.sessionService.schemeId).subscribe(res => {
      this.awaitingGet = false;
      this.sms = res;
      this.smsCopy = res;
      this.smsForm.patchValue({ ...res });
    }, err => {
      this.awaitingPost = false;
      this.commonService.errorHandler(err);
    });
  }

  public createNew(): Observable<any> {
    if (this.smsForm.valid) {
      this.awaitingPost = true;

      return this.smsService.apiSmsSmsPost(this.smsForm.value, this.sessionService.schemeId).pipe(
        tap(res => {
          this.awaitingPost = false;

          this.dataUpdate.next(
            {
              actionType: ActionType.SMS,
              actionData: res,
              formValid: this.smsForm.valid
            }
          );
        })
      );
    } else {
      return null;
    }
  }

  public update(): Observable<any> {
    if (this.smsForm.valid) {
      this.awaitingPost = true;
      return this.smsService.apiSmsSmsPut(this.sms, this.sessionService.schemeId).pipe(
        tap(res => {
          this.awaitingPost = false;

          this.dataUpdate.next(
            {
              actionType: ActionType.SMS,
              actionData: res,
              formValid: this.smsForm.valid
            }
          );

          this.sms = res;
          this.smsCopy = res;

          this.smsForm.patchValue({ ...res });
        })
      );
    } else {
      return null;
    }
  }

  public getData(): ActionConnectorInterface {
    this.smsForm.markAllAsTouched();

    return {
      actionType: ActionType.SMS,
      actionData: this.smsForm.value as EmailInsertView,
      formValid: this.smsForm.valid
    };
  }

}
