import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'loyale-setup-card-cta',
  templateUrl: './setup-card-cta.component.html',
  styleUrls: ['./setup-card-cta.component.scss']
})
export class SetupCardCtaComponent implements OnInit {
  @Input() fromDashboard = false;

  constructor(public sessionService: SessionService) { }

  ngOnInit(): void {
  }

}
