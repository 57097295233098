import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DemographicsService } from '../../swagger';
import { SessionService } from '../../services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewTargetDemographicsDialogComponent } from './new-target-demographics-dialog/new-target-demographics-dialog';

@Component({
  selector: 'loyale-target-demographics-selector',
  templateUrl: './target-demographics-selector.component.html',
  styleUrls: ['./target-demographics-selector.component.scss']
})
export class TargetDemographicsSelectorComponent implements OnInit {
  awaitingGet = false;
  demographics = [];
  filteredDemographics = [];
  @Input() selectedDemographicId = null;
  @Output() outputDemographicId = new EventEmitter<any>();

  demographicFilter = null;

  constructor(private demographicsService: DemographicsService, public sessionService: SessionService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getDemographics();
  }

  getDemographics() {
    this.awaitingGet = true;
    this.demographicsService.apiDemographicsGet(this.sessionService.schemeId).subscribe(res => {
      this.awaitingGet = false;
      this.demographics = res;
      this.demographics.unshift({ id: '00000000-0000-0000-0000-000000000000', name: 'All Customers' });
      this.filteredDemographics = this.demographics;
    })
  }

  onNewDemographic() {
    const dialogRef = this.dialog.open(NewTargetDemographicsDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getDemographics();
        this.selectedDemographicId = res.id;
        this.onDemographicChange();
      }
    })
  }

  onDemographicChange() {
    this.outputDemographicId.emit(this.selectedDemographicId);
  }

  onFilterDemographics() {
    if (!this.demographics) {
      return;
    }

    let filter = this.demographicFilter;
    if (!filter) {
      this.filteredDemographics = this.demographics;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredDemographics = this.demographics.filter(demographic => demographic.name.toLowerCase().indexOf(filter) > -1);
  }
}
