<mat-toolbar color="warn" class="light-warning"
  *ngIf="sessionService?.schemeHealth?.billingDetailsMissing || sessionService?.schemeHealth?.paymentMethodMissing">
  <mat-icon class="mr-2">warning</mat-icon>
  <span class="d-none d-md-flex">Billing Issues</span>
  <div class="flex-fill"></div>
  <span class="small">Missing Billing Details or Payment Method</span>
  <a [routerLink]="['/settings/subscription/billing']" mat-flat-button class="ml-2"
    *ngIf="sessionService.agentIsOwner">Resolve</a>
</mat-toolbar>

<mat-toolbar color="warn" *ngIf="sessionService?.schemeHealth?.pendingInvoice">
  <mat-icon class="mr-2">report</mat-icon>
  <span class="d-none d-md-flex">Payment Issues</span>
  <div class="flex-fill"></div>
  <span class="small">Unpaid Invoice</span>
  <a [routerLink]="['/settings/subscription/invoices']" mat-flat-button class="ml-2"
    *ngIf="sessionService.agentIsOwner">Resolve</a>
</mat-toolbar>
