import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';
import { AllowPageExitService } from '../services/allow-page-exit.service';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';

export interface CanComponentDeactivate {
  confirm(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ViewsGuard implements CanActivate, CanDeactivate<CanComponentDeactivate> {
  constructor(private sessionService: SessionService, private router: Router, private allowPageExitService: AllowPageExitService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const tokenValid = this.sessionService.tokenValid();

    if (tokenValid) {
      return true;
    } else {
      sessionStorage.setItem('destination', state.url);

      if (state.url.includes('hmac') && state.url.includes('shop')) {
        let hmac = state.root.queryParams['hmac'];
        let host = state.root.queryParams['host'];
        let shop = state.root.queryParams['shop'];
        let timestamp = state.root.queryParams['timestamp'];

        let redirectUrl = environment.setupUrl + '?hmac=' + hmac + '&host=' + host + '&shop=' + shop + '&timestamp=' + timestamp;
        window.location.href = redirectUrl;
      } else {
        this.router.navigate(['/auth'], { queryParamsHandling: 'merge' });
      }

      return false;
    }
  }

  canDeactivate(): Observable<boolean> {
    if (this.allowPageExitService.showConfirmRedirectDialog) {
      this.allowPageExitService.showDialog();
      return this.allowPageExitService.allowRedirect;
    } else {
      return of(true);
    }
  }

}
