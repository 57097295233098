<div class="theme-wrapper" [ngClass]="themeService.theme">
  <ng-progress [spinner]="false"></ng-progress>

  <ng-container *ngIf="sessionService.tokenValid() && sessionService.schemeId; else unauthorized">
    <div class="d-flex flex-column vh-100">
      <!-- load demo cta after scheme status is found in storage -->
      <ng-container *ngIf="sessionService.schemeStatus">
        <loyale-demo-cta></loyale-demo-cta>
      </ng-container>

      <!-- load demo cta after scheme status is found in storage -->
      <ng-container *ngIf="sessionService.schemeStatus">
        <loyale-status-cta></loyale-status-cta>
      </ng-container>

      <!-- load setup cta after scheme setup is found in storage -->
      <ng-container *ngIf="sessionService.schemeSetup">
        <loyale-setup-cta></loyale-setup-cta>
      </ng-container>

      <!-- load health cta after scheme health is found in storage -->
      <ng-container *ngIf="sessionService.schemeHealth">
        <!-- only show health cta if shceme is not a demo -->
        <loyale-health-cta *ngIf="sessionService.schemeStatus != 5"></loyale-health-cta>
      </ng-container>

      <mat-sidenav-container autosize>
        <mat-sidenav class="app-sidenav" #appSidenav [mode]="sidenavMode" [opened]="sidenavOpen"
          [disableClose]="mobileMode ? false : true">
          <loyale-sidenav></loyale-sidenav>
        </mat-sidenav>

        <mat-sidenav-content class="app-content" (scroll)="onAppContentScroll($event)">
          <div class="toggle-wrapper" *ngIf="mobileMode">
            <button mat-mini-fab color="primary" (click)="sidenav.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
          </div>

          <router-outlet></router-outlet>

          <div class="w-100 p-3"></div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </ng-container>

  <ng-template #unauthorized>
    <div class="auth-wrapper">
      <div class="tags-background"></div>

      <router-outlet></router-outlet>

      <div class="position-fixed" style="bottom: 0.5rem; left: 0.5rem;">
        <small class="version auth" (click)="onSuperClick()">{{env.version}} <span
            *ngIf="!env.production">{{env.name}}</span></small>
      </div>
    </div>
  </ng-template>

  <tour-step-template>
    <ng-template let-step="step">
      <mat-card (click)="$event.stopPropagation()" class="tour-dialog" [style.width]="step.stepDimensions?.width"
        [style.min-width]="step.stepDimensions?.minWidth" [style.max-width]="step.stepDimensions?.maxWidth"
        [style.height]="step.stepDimensions?.height" [style.min-height]="step.stepDimensions?.minHeight"
        [style.max-height]="step.stepDimensions?.maxHeight">
        <mat-card-header class="w-100 d-flex align-items-center justify-content-between">

          <mat-card-title class="mb-0">
            <div>{{step.title}}</div>

          </mat-card-title>
          <button mat-icon-button (click)="tourService.end()" class="close">
            <mat-icon>close</mat-icon>
          </button>

        </mat-card-header>

        <mat-card-content [innerHTML]="step.content">

        </mat-card-content>

        <div class="d-flex">
          <button mat-button [disabled]="!tourService.hasPrev(step)" (click)="tourService.prev()">
            <mat-icon>chevron_left</mat-icon>
            {{step.prevBtnTitle}}
          </button>

          <div class="flex-fill"></div>

          <button *ngIf="tourService.hasNext(step) && !step.nextOnAnchorClick" (click)="tourService.next()" mat-button>
            {{step.nextBtnTitle}}
            <mat-icon iconPositionEnd>chevron_right</mat-icon>
          </button>
          <button mat-button (click)="tourService.end()" *ngIf="!tourService.hasNext(step)">
            {{step.endBtnTitle}}
          </button>
        </div>
      </mat-card>

    </ng-template>
  </tour-step-template>
</div>
