import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenderPipe } from './gender.pipe';
import { TransactionTypePipe } from './transaction-type.pipe';
import { TransactionPolarityPipe } from './transaction-polarity.pipe';
import { TransactionTypeIconPipe } from './transaction-type-icon.pipe';
import { TransactionSubtypeIconPipe } from './transaction-subtype-icon.pipe';
import { PaddingPipe } from './padding.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { SubstringPipe } from './substring.pipe';
import { ReplacePipe } from './replace.pipe';
import { AlertStatusPipe } from './alert-status.pipe';
import { MessageStatusPipe } from './message-status.pipe';
import { LotteryStatusPipe } from './lottery-status.pipe';
import { ReceiveByPipe } from './receive-by.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { RuleOperatorPipe } from './rule-operator.pipe';
import { SubscriptionErrorsPipe } from './subscription-errors.pipe';
import { SchemeStatusPipe } from './scheme-status.pipe';
import { InvoiceTypePipe } from './invoice-type.pipe';
import { WebhookTopicPipe } from './webhook-topic.pipe';
import { AutomationActionPipe } from './automation-action.pipe';
import { TrustPaymentTypePipe } from './trust-payment-type.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { IntegrationPipe } from './integration.pipe';
import { StripeRefundReasonPipe } from './stripe-refund-reason.pipe';
import { QuestionTypePipe } from './question-type.pipe';
import { PaymentStatusPipe } from './payment-status.pipe';
import { PayoutStatusPipe } from './payout-status.pipe';
import { DestinationTypePipe } from './destination-type.pipe';
import { ProductTypePipe } from './product-type.pipe';

@NgModule({
  declarations: [
    GenderPipe,
    TransactionTypePipe,
    TransactionPolarityPipe,
    TransactionTypeIconPipe,
    TransactionSubtypeIconPipe,
    PaddingPipe,
    DateAgoPipe,
    ReplacePipe,
    SubstringPipe,
    AlertStatusPipe,
    MessageStatusPipe,
    LotteryStatusPipe,
    ReceiveByPipe,
    SafeHtmlPipe,
    RuleOperatorPipe,
    SubscriptionErrorsPipe,
    SchemeStatusPipe,
    InvoiceTypePipe,
    WebhookTopicPipe,
    AutomationActionPipe,
    TrustPaymentTypePipe,
    ShortNumberPipe,
    IntegrationPipe,
    StripeRefundReasonPipe,
    QuestionTypePipe,
    PaymentStatusPipe,
    PayoutStatusPipe,
    DestinationTypePipe,
    ProductTypePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GenderPipe,
    TransactionTypePipe,
    TransactionPolarityPipe,
    TransactionTypeIconPipe,
    TransactionSubtypeIconPipe,
    PaddingPipe,
    DateAgoPipe,
    ReplacePipe,
    SubstringPipe,
    AlertStatusPipe,
    MessageStatusPipe,
    LotteryStatusPipe,
    ReceiveByPipe,
    SafeHtmlPipe,
    RuleOperatorPipe,
    SubscriptionErrorsPipe,
    AutomationActionPipe,
    WebhookTopicPipe,
    TrustPaymentTypePipe,
    ShortNumberPipe,
    IntegrationPipe,
    StripeRefundReasonPipe,
    QuestionTypePipe,
    PaymentStatusPipe,
    PayoutStatusPipe,
    DestinationTypePipe,
    ProductTypePipe
  ],
  providers: [
    AutomationActionPipe,
  ]
})
export class PipesModule { }
