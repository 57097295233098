import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loyale-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent implements OnInit {
  @Input() placeholder = 'results';
  @Input() note = null;

  constructor() { }

  ngOnInit() {
  }

}
