import { AlertResultView } from 'src/app/swagger';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'loyale-preview-post',
  templateUrl: './preview-post.component.html',
  styleUrls: ['./preview-post.component.scss']
})
export class PreviewPostComponent implements OnInit {
  @Input() alert: AlertResultView = null;

  dummyDate = moment();

  constructor() { }

  ngOnInit() {
  }

}
