import { Component, Input, OnInit } from '@angular/core';
import { CustomerResultView } from 'src/app/swagger';

@Component({
  selector: 'loyale-micro-customer',
  templateUrl: './micro-customer.component.html',
  styleUrls: ['./micro-customer.component.scss']
})
export class MicroCustomerComponent implements OnInit {
  @Input() customer: CustomerResultView = <CustomerResultView>{};
  @Input() disabled = false;

  constructor() { }

  ngOnInit() {
  }

}
