import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'loyale-readonly-field',
  templateUrl: './readonly-field.component.html',
  styleUrls: ['./readonly-field.component.scss']
})
export class ReadonlyFieldComponent implements OnInit {
  @Input() label = '';
  @Input() value = '';
  @Input() copy = true;
  @Input() textarea = false;
  @Input() textareaRows = 1;

  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
  }

}
