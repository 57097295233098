import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RedirectConfirmDialogComponent } from '../shared/redirect-confirm/redirect-confirm.component';
import { BehaviorSubject, Observer, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AllowPageExitService {
  message = 'Confirming the exit from the page? All not saved data will be lost';
  confirmButtonLabel = 'Confirm';
  cancelButtonLabel = 'Cancel';
  showConfirmRedirectDialog = false;
  allowRedirect: Subject<any> = new Subject();

  constructor(private dialog: MatDialog) {}

  showDialog() {
    const dialogRef = this.dialog.open(RedirectConfirmDialogComponent,
      {
        data: {
          message: this.message,
          confirmButtonLabel: this.confirmButtonLabel,
          cancelButtonLabel: this.cancelButtonLabel
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.allowRedirect.next(true);
      } else {
        this.allowRedirect.next(false);
      }
    });
  }

}
