<h1 mat-dialog-title>New Points Modifier</h1>
<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>Type</mat-label>
          <mat-select name="type" #type="ngModel" [(ngModel)]="pointsModifier.transactionType" required>
            <mat-optgroup label="Positive">
              <mat-option [value]="0">Purchase</mat-option>
              <mat-option [value]="1">Positive Adjustment</mat-option>
              <mat-option [value]="5">Top Up</mat-option>
            </mat-optgroup>
            <mat-optgroup label="Negative">
              <mat-option [value]="3">Redeem</mat-option>
              <mat-option [value]="2">Negative Adjustment</mat-option>
              <mat-option [value]="4">Refund</mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error *ngIf="type?.errors?.required">Required</mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>Value</mat-label>
          <input matInput name="value" #value="ngModel" [(ngModel)]="pointsModifier.value" required>
          <mat-error *ngIf="value?.errors?.required">Required</mat-error>
        </mat-form-field>

        <mat-form-field class="w-100" *ngIf="!allOutlets">
          <mat-label>Outlet</mat-label>
          <mat-select name="outlet" #outlet="ngModel" [(ngModel)]="pointsModifier.outletId"
            [disabled]="nestedOutlets.length == 0" required>
            <mat-optgroup *ngFor="let nest of nestedOutlets" [label]="nest.franchiseName">
              <mat-option *ngFor="let outlet of nest.outlets" [value]="outlet.id">
                {{outlet.name}}
              </mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error *ngIf="outlet?.errors?.required">Required</mat-error>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>Description</mat-label>
          <input matInput name="description" #description="ngModel" [(ngModel)]="pointsModifier.description">
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-6">

      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="flex-fill"></div>
    <button mat-flat-button [mat-dialog-close]>Close</button>
    <button mat-flat-button color="primary" [disabled]="awaitingPost">Save</button>
  </div>
</form>
