/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TotalPerOutlet } from './totalPerOutlet';
import { TotalValues } from './totalValues';

export interface StatementReport { 
    id?: string;
    barCode?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    mobileNumber?: string;
    dob?: string;
    gender?: string;
    marketingSub?: boolean;
    levels?: string;
    balancePoints?: number;
    schemeId?: string;
    balanceCurrency?: number;
    totalPurchase?: TotalValues;
    totalTopUp?: TotalValues;
    totalPositiveAdjustment?: TotalValues;
    totalRedeem?: TotalValues;
    totalNegativeAdjustment?: TotalValues;
    totalEarnedFromOutlet?: Array<TotalPerOutlet>;
    totalSpentAtOutlet?: Array<TotalPerOutlet>;
}