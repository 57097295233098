export enum ActionType {
  PUSH_NOTIFICATION = 0,
  POST = 1,
  EMAIL = 2,
  COUPON = 3,
  PRODUCT = 4,
  MODIFIER = 5,
  MULTIPLIER = 6,
  GIFT_CARD = 8,
  FEEDBACK_FORM = 9,
  TAG = 10,
  SMS = 11
}

export enum ActionRedirectURL {
  'push-notifications' = 0,
  'alerts' = 1,
  'emails' = 2,
  'coupons' = 3,
  // PRODUCT = 4,
  // MODIFIER = 5,
  // MULTIPLIER = 6,
  'gift-cards' = 8,
  'feedbacks' = 9,
  // TAG = 10,
  'sms' = 11,
}
