import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trustPaymentType'
})

export class TrustPaymentTypePipe implements PipeTransform {
  //https://help.trustpayments.com/hc/en-us/articles/4403576725265-Visa-for-Webservices-API
  visaOptions = ['DELTA', 'ELECTRON', 'PURCHASING', 'VISA', 'VPAY']

  //https://help.trustpayments.com/hc/en-us/articles/4403576654481-Mastercard-for-Webservices-API
  mastercardOptions = ['MAESTRO', 'MASTERCARD', 'MASTERCARDDEBIT']

  transform(value: any): string {
    let icon = '../../assets/payments/generic.svg';

    if (this.visaOptions.includes(value)) {
      icon = '../../assets/payments/visa.svg';
    } else if (this.mastercardOptions.includes(value)) {
      icon = '../../assets/payments/mastercard.svg';
    }

    return icon;
  }

}
