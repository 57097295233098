/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DiscountAllocation } from './discountAllocation';
import { PriceSet } from './priceSet';

export interface LineItem { 
    id?: any;
    variantId?: number;
    title?: string;
    quantity?: number;
    sku?: string;
    variantTitle?: string;
    vendor?: string;
    fulfillmentService?: string;
    productId?: number;
    requiresShipping?: boolean;
    taxable?: boolean;
    giftCard?: boolean;
    name?: string;
    variantInventoryManagement?: string;
    properties?: Array<any>;
    productExists?: boolean;
    fulfillableQuantity?: number;
    grams?: number;
    price?: string;
    totalDiscount?: string;
    fulfillmentStatus?: any;
    priceSet?: PriceSet;
    totalDiscountSet?: PriceSet;
    discountAllocations?: Array<DiscountAllocation>;
    duties?: Array<any>;
    adminGraphqlApiId?: string;
    taxLines?: Array<any>;
}