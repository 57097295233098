/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type RefundReason = 0 | 1 | 2 | 3;

export const RefundReason = {
    NUMBER_0: 0 as RefundReason,
    NUMBER_1: 1 as RefundReason,
    NUMBER_2: 2 as RefundReason,
    NUMBER_3: 3 as RefundReason
};