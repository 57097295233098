/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Admin } from './admin';
import { Role } from './role';
import { Scheme } from './scheme';

export interface AdminRole { 
    id?: string;
    role?: Role;
    roleId?: string;
    admin?: Admin;
    adminId?: string;
    scheme?: Scheme;
    schemeId?: string;
}