/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DiscountType } from './discountType';

export interface CouponInsertView { 
    name: string;
    description: string;
    valid?: boolean;
    initialNumberOfUses: number;
    from?: Date;
    to?: Date;
    imageUrl?: string;
    isDynamic?: boolean;
    outletId?: Array<string>;
    externalReference?: string;
    platform?: string;
    maxUses?: number;
    discountType?: DiscountType;
    discountAmount?: number;
    terms?: string;
    notify?: boolean;
    notificationTime?: Date;
    daysBeforeExpire?: number;
    pushNotificationId?: string;
    validAfter?: number;
    validFor?: number;
}