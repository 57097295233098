<div class="position-relative" [formGroup]="textForm">
  <mat-form-field (click)="onEditorClick()">
    <mat-label>{{label}}</mat-label>
    <textarea #input matInput [rows]="rows" name="text" formControlName="text"
      (ngModelChange)="outputValue.emit(textForm.get('text').value)" [maxLength]="maxLength"></textarea>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    <mat-error *ngIf="textForm.get('text').hasError('required')">Required</mat-error>
    <mat-error *ngIf="textForm.get('text').hasError('maxlength')">Maximum length {{maxLength}}</mat-error>
  </mat-form-field>

  <div class="options">
    <div class="insert-emoji" *ngIf="options.emoji">
      <button mat-icon-button type="button" matTooltip="Insert emoji" (click)="showEmojiPicker = !showEmojiPicker"
        [disabled]="disabled">
        <mat-icon>insert_emoticon</mat-icon>
      </button>
      <emoji-mart [autoFocus]="true" [isNative]="true" [showPreview]="false"
        (emojiClick)="onInsertEmoji($event); outputValue.emit( this.textForm.get('text').value)"
        *ngIf="showEmojiPicker"></emoji-mart>
    </div>
    <div class="insert-referrals" *ngIf="options.referrals">
      <button mat-icon-button type="button" matTooltip="Insert placeholder" [matMenuTriggerFor]="menu"
        [disabled]="disabled">
        <mat-icon>settings_ethernet</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngFor="let tag of placeHolders" mat-menu-item type="button"
          (click)="addTagToMessage(tag)">{{tag}}</button>
      </mat-menu>
    </div>
  </div>
</div>
