import { Directive, Input, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicAndRuleComponent } from '../shared/dynamic-and-rule/dynamic-and-rule.component';
import { DynamicOrRuleComponent } from '../shared/dynamic-or-rule/dynamic-or-rule.component';

// import { Field } from './field';

export interface Field {
  name?: string;
  properties?: any[];
  value?: any;
  group?: number;
  type: string;
}

const componentMapper = {
  and: DynamicAndRuleComponent,
  or: DynamicOrRuleComponent
};

@Directive({
  selector: '[loyaleSharedDynamicField]'
})
export class DynamicFieldSharedDirective {
  @Input() field: Field;
  @Input() group: UntypedFormGroup;
  componentRef: any;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(componentMapper[this.field.type]);
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
  }
}
