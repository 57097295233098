import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loyale-shared-dynamic-or-rule',
  templateUrl: './dynamic-or-rule.component.html',
  styleUrls: ['./dynamic-or-rule.component.scss']
})
export class DynamicOrRuleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
