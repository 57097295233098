import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagService } from '../../../swagger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../services/session.service';
import { CommonService } from '../../../services/common.service';
import { NewTagDialogComponent } from '../../action-selector-dialog/new-tag-dialog/new-tag-dialog.component';

@Component({
  selector: 'loyale-action-tag-selector',
  templateUrl: './action-tag-selector.component.html',
  styleUrls: ['./action-tag-selector.component.scss']
})
export class ActionTagSelectorComponent implements OnInit {

  awaitingGet = false;
  tags: any = [];

  filteredTags = [];

  @Input() selectedTagId = null;
  @Output() outputTagId = new EventEmitter<any>();
  @Output() removeTag = new EventEmitter<any>();

  tagFilter = null;

  constructor(public commonService: CommonService, private tagService: TagService, private dialog: MatDialog, private snackBar: MatSnackBar, public sessionService: SessionService) { }

  ngOnInit() {
    this.getTags();
  }

  getTags() {
    this.awaitingGet = true;

    this.tagService.apiTagGet(this.sessionService.schemeId, '', 'name', null, null).subscribe(res => {
      this.tags = res;
      this.filteredTags = this.tags;
      this.awaitingGet = false;
    }, err => {
      this.commonService.errorHandler(err);
      this.awaitingGet = false;
    });
  }

  onNewTag() {
    const dialogRef = this.dialog.open(NewTagDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getTags();
        this.selectedTagId = res.id;
        this.onTagChange();
      }
    });
  }

  onTagChange() {
    this.outputTagId.emit(this.selectedTagId);
  }

  onFilterTag() {
    if (!this.tags) {
      return;
    }

    let filter = this.tagFilter;
    if (!filter) {
      this.filteredTags = this.tags;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredTags = this.tags.filter(tag => tag.name.toLowerCase().indexOf(filter) > -1);
  }

}
