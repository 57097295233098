import { Pipe, PipeTransform } from '@angular/core';

export enum PayoutStatus {
  'Paid' = 0,
  'Pending',
  'In Transit',
  'Canceled',
  'Failed'
}

@Pipe({
  name: 'payoutStatus'
})
export class PayoutStatusPipe implements PipeTransform {

  transform(value: any): string {
    return PayoutStatus[value];
  }

}
