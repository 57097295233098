/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SchemePolicyGrowthStatsResultView } from '../model/schemePolicyGrowthStatsResultView';
import { SchemePolicyInsertView } from '../model/schemePolicyInsertView';
import { SchemePolicyPercentageStatsResultView } from '../model/schemePolicyPercentageStatsResultView';
import { SchemePolicyPutView } from '../model/schemePolicyPutView';
import { SchemePolicyResultView } from '../model/schemePolicyResultView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SchemePolicyService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete a Scheme Policy
     * 
     * @param id 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSchemePolicyDelete(id?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSchemePolicyDelete(id?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSchemePolicyDelete(id?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSchemePolicyDelete(id?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/SchemePolicy`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Scheme Policies in Scheme
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSchemePolicyGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SchemePolicyResultView>>;
    public apiSchemePolicyGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SchemePolicyResultView>>>;
    public apiSchemePolicyGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SchemePolicyResultView>>>;
    public apiSchemePolicyGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SchemePolicyResultView>>('get',`${this.basePath}/api/SchemePolicy`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Scheme Policy
     * 
     * @param id 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSchemePolicyIdGet(id: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SchemePolicyResultView>;
    public apiSchemePolicyIdGet(id: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SchemePolicyResultView>>;
    public apiSchemePolicyIdGet(id: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SchemePolicyResultView>>;
    public apiSchemePolicyIdGet(id: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSchemePolicyIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SchemePolicyResultView>('get',`${this.basePath}/api/SchemePolicy/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Scheme Policy
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSchemePolicyPost(body?: SchemePolicyInsertView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SchemePolicyResultView>;
    public apiSchemePolicyPost(body?: SchemePolicyInsertView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SchemePolicyResultView>>;
    public apiSchemePolicyPost(body?: SchemePolicyInsertView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SchemePolicyResultView>>;
    public apiSchemePolicyPost(body?: SchemePolicyInsertView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SchemePolicyResultView>('post',`${this.basePath}/api/SchemePolicy`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Scheme Policy
     * 
     * @param policyId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSchemePolicyPublishPolicyIdPost(policyId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SchemePolicyResultView>;
    public apiSchemePolicyPublishPolicyIdPost(policyId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SchemePolicyResultView>>;
    public apiSchemePolicyPublishPolicyIdPost(policyId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SchemePolicyResultView>>;
    public apiSchemePolicyPublishPolicyIdPost(policyId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (policyId === null || policyId === undefined) {
            throw new Error('Required parameter policyId was null or undefined when calling apiSchemePolicyPublishPolicyIdPost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SchemePolicyResultView>('post',`${this.basePath}/api/SchemePolicy/Publish/${encodeURIComponent(String(policyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify a Scheme Policy
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSchemePolicyPut(body?: SchemePolicyPutView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SchemePolicyResultView>;
    public apiSchemePolicyPut(body?: SchemePolicyPutView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SchemePolicyResultView>>;
    public apiSchemePolicyPut(body?: SchemePolicyPutView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SchemePolicyResultView>>;
    public apiSchemePolicyPut(body?: SchemePolicyPutView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SchemePolicyResultView>('put',`${this.basePath}/api/SchemePolicy`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the number of customers which accepted/rejected the given Scheme Policy over a given period of time
     * 
     * @param schemePolicyId 
     * @param tzOffset 
     * @param from 
     * @param to 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSchemePolicyStatsChoicesChartSchemePolicyIdTzOffsetGet(schemePolicyId: string, tzOffset: number, from?: Date, to?: Date, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SchemePolicyGrowthStatsResultView>;
    public apiSchemePolicyStatsChoicesChartSchemePolicyIdTzOffsetGet(schemePolicyId: string, tzOffset: number, from?: Date, to?: Date, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SchemePolicyGrowthStatsResultView>>;
    public apiSchemePolicyStatsChoicesChartSchemePolicyIdTzOffsetGet(schemePolicyId: string, tzOffset: number, from?: Date, to?: Date, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SchemePolicyGrowthStatsResultView>>;
    public apiSchemePolicyStatsChoicesChartSchemePolicyIdTzOffsetGet(schemePolicyId: string, tzOffset: number, from?: Date, to?: Date, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemePolicyId === null || schemePolicyId === undefined) {
            throw new Error('Required parameter schemePolicyId was null or undefined when calling apiSchemePolicyStatsChoicesChartSchemePolicyIdTzOffsetGet.');
        }

        if (tzOffset === null || tzOffset === undefined) {
            throw new Error('Required parameter tzOffset was null or undefined when calling apiSchemePolicyStatsChoicesChartSchemePolicyIdTzOffsetGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from.toISOString());
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to.toISOString());
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SchemePolicyGrowthStatsResultView>('get',`${this.basePath}/api/SchemePolicy/Stats/ChoicesChart/${encodeURIComponent(String(schemePolicyId))}/${encodeURIComponent(String(tzOffset))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the number of customers which accepted/rejected the given Scheme Policy and the percentage of these over the scheme
     * 
     * @param schemePolicyId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSchemePolicyStatsPercentageSchemePolicyIdGet(schemePolicyId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SchemePolicyPercentageStatsResultView>;
    public apiSchemePolicyStatsPercentageSchemePolicyIdGet(schemePolicyId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SchemePolicyPercentageStatsResultView>>;
    public apiSchemePolicyStatsPercentageSchemePolicyIdGet(schemePolicyId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SchemePolicyPercentageStatsResultView>>;
    public apiSchemePolicyStatsPercentageSchemePolicyIdGet(schemePolicyId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemePolicyId === null || schemePolicyId === undefined) {
            throw new Error('Required parameter schemePolicyId was null or undefined when calling apiSchemePolicyStatsPercentageSchemePolicyIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SchemePolicyPercentageStatsResultView>('get',`${this.basePath}/api/SchemePolicy/Stats/Percentage/${encodeURIComponent(String(schemePolicyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
