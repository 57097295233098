/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PaymentLine { 
    id?: number;
    accountId?: number;
    currencyCode?: string;
    gratuityAmount?: number;
    amount?: number;
    staffId?: number;
    staffName?: string;
    date?: number;
    active?: boolean;
    paymentMethod?: string;
    paymentMethodDescription?: string;
    deviceId?: number;
    deviceName?: string;
}