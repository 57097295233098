import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'loyale-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
  @Input() redirectRoute = '/route/new';
  @Input() featureName = 'Feature';
  @Input() title = 'Title';
  @Input() description = 'Description.';
  @Input() points = [{ title: 'Point title', subtitle: 'Point subtitle.' }]
  @Input() imageUrl = 'https://s3.eu-west-1.amazonaws.com/loyale.assets/feature/emails.svg';

  @Input() isAvailable = false;
  @Input() isEnabled = false;
  @Input() isEmpty = false;

  @Output() onEnable = new EventEmitter<any>();

  constructor(public sessionService: SessionService) { }

  ngOnInit(): void {
  }

}
