/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PlanAndSchemeAvailability } from './planAndSchemeAvailability';

export interface SchemeFeaturesResultView { 
    points?: PlanAndSchemeAvailability;
    referrals?: PlanAndSchemeAvailability;
    coupons?: PlanAndSchemeAvailability;
    pointsExpiry?: PlanAndSchemeAvailability;
    lotteries?: PlanAndSchemeAvailability;
    promoCodes?: PlanAndSchemeAvailability;
    messages?: PlanAndSchemeAvailability;
    giftCards?: PlanAndSchemeAvailability;
    pushNotifications?: PlanAndSchemeAvailability;
    emails?: PlanAndSchemeAvailability;
    posts?: PlanAndSchemeAvailability;
    feedbackForms?: PlanAndSchemeAvailability;
    products?: PlanAndSchemeAvailability;
    sms?: PlanAndSchemeAvailability;
    tags?: PlanAndSchemeAvailability;
    send?: PlanAndSchemeAvailability;
    split?: PlanAndSchemeAvailability;
    payments?: PlanAndSchemeAvailability;
}