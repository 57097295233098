/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PunchCardActionType } from './punchCardActionType';

export interface PunchCardResultView { 
    id?: string;
    name?: string;
    description?: string;
    numberOfRewards?: number;
    totalNumberOfPunches?: number;
    action?: PunchCardActionType;
    redeemItem?: string;
    spendItem?: string;
    updatedDate?: Date;
    createdDate?: Date;
    schemeId?: string;
    validFrom?: Date;
    validUntil?: Date;
    isDynamic?: boolean;
    dynamicDays?: number;
    isAutoRenew?: boolean;
}