/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { Lottery } from './lottery';
import { LotteryReward } from './lotteryReward';
import { Transaction } from './transaction';

export interface LotteryTicket { 
    id?: string;
    customer?: Customer;
    customerId?: string;
    lottery?: Lottery;
    lotteryId?: string;
    claimed?: boolean;
    reward?: LotteryReward;
    rewardId?: string;
    transaction?: Transaction;
    transactionId?: string;
    ticketNumber?: string;
    isWinner?: boolean;
    createdDate?: Date;
    updatedDate?: Date;
}