<form [formGroup]="eventForm" (ngSubmit)="onSubmit(eventForm)">
    <div class="row">
        <div class="col-12">
            <p>Select a predefined event, and build rules on top of it.</p>
        </div>

        <div class="col-12 col-lg-6">
            <mat-form-field class="w-100">
                <mat-label>Event</mat-label>
                <mat-select [disabled]="events.length == 0"
                            formControlName="event"
                            (ngModelChange)="onEventChange($event)">
                    <mat-option *ngFor="let event of events" [value]="event">{{event.name}}
                        - {{event.description}}</mat-option>
                </mat-select>
                <mat-error *ngIf="eventForm.get('event')?.errors?.required">Required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <ng-container>
        <div class="rules">
            <h5>Rules</h5>
            <loyale-shared-dynamic-form #dynamicForm></loyale-shared-dynamic-form>
        </div>

        <div class="d-flex justify-content-start">
            <button type="button" mat-flat-button color="accent" (click)="addAndRule()"
                    [disabled]="ruleProperties.length == 0">AND
            </button>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <button type="button" mat-flat-button color="accent" (click)="addOrRule()"
                    [disabled]="ruleProperties.length == 0">OR
            </button>
        </div>
    </ng-container>

    <div class="d-flex">
        <div class="flex-fill"></div>
        <button mat-flat-button color="primary" [disabled]="awaitingPost || eventForm.get('event').value == null">Set
            Trigger
        </button>
    </div>
</form>
