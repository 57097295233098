<span class="micro-item" *ngIf="transaction">
  <button mat-button [matMenuTriggerFor]="previewTransaction" class="toggle">
    {{transaction.externalRefId | substring : 's' : 20 : true}}
    <mat-icon class="open">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #previewTransaction="matMenu">
    <ng-template matMenuContent>
      <div mat-menu-item class="pointer-events-none">
        <mat-icon>today</mat-icon>
        <span>{{transaction.transactionDate | amFromUtc | amLocal | amDateFormat:'DD/MM/YYYY HH:mm'}}</span>
      </div>
      <div mat-menu-item class="pointer-events-none">
        <mat-icon>unknown_2</mat-icon>
        <span>{{transaction.transactionType | transactionType }}</span>
      </div>
      <a mat-menu-item [routerLink]="['/transactions/details', transaction.id]" *ngIf="!disabled">
        <mat-icon>launch</mat-icon>
        <span>Open</span>
      </a>
    </ng-template>
  </mat-menu>
</span>
