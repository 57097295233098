import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { QuestionType, QuestionTypeIcon } from '../../views/feedbacks/feedbacks.enum';

@Component({
  selector: 'loyale-question-selector',
  templateUrl: './question-selector.component.html',
  styleUrls: ['./question-selector.component.scss']
})
export class QuestionSelectorComponent implements OnInit, AfterViewInit {
  selectorIcon = 'radio_button_unchecked';
  addIconColor = '';
  deleteIconColor = '';
  copyIconColor = '';

  selectorValue = 'Multiple Choice';
  showEmojiPicker = false;
  emojiValue = '';
  scaleEmojiNumber = 0;

  optionList = ['', ''];
  optionValue: string[] = ['', ''];
  emojiList: { value: string, label: string, openEmoji: boolean }[] = [{ value: '', label: '', openEmoji: false }];
  scaleEmojiList: { value: string, label: string }[] = [{ value: this.emojiValue, label: '' }];
  requiredFlag = false;

  labelEnable = false;

  questionData = {
    type: null,
    value: null,
    required: false,
    answers: [],
  };

  questionFormGroup: UntypedFormGroup = this.formBuilder.group({
    question: ['', Validators.required],
    typeSelector: ['', Validators.required],
    required: [false],
    answers: [''],
  });

  @Input()
  public set markAsTouched(touched: boolean) {
    if (touched === true) {
      this.questionFormGroup.markAllAsTouched();
    }
  }

  @Input() questionInputData: any | undefined;
  @Input() editViewMode = false;

  @ViewChild('input', { static: true }) input: ElementRef;
  @Output() questionFormData = new EventEmitter();
  @Output() updateQuestionFormDate = new EventEmitter();
  @Output() deleteQuestion = new EventEmitter();
  @Output() duplicateQuestion = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    if (this.questionInputData !== null) {
      this.parseDataFromInput(this.questionInputData);
    }
  }

  ngAfterViewInit() {

    this.questionFormGroup.valueChanges.subscribe(data => {
      this.getQuestionData();

      this.questionFormData.emit(this.questionData);

    });
  }

  parseDataFromInput(questionData: any) {
    this.questionFormGroup.controls.required.setValue(questionData.required);
    this.selectorIcon = QuestionTypeIcon[questionData.type];
    this.questionFormGroup.controls.question.setValue(questionData.value);
    this.questionFormGroup.controls.typeSelector.setValue(QuestionType[questionData.type]);
    this.selectorValue = QuestionType[questionData.type];

    if (QuestionType[questionData.type] === 'Multiple Choice') {
      this.optionList = [];
      this.optionValue = [];
      questionData.feedbackAnswers.forEach(element => {
        this.optionList.push(element.value);
        this.optionValue.push(element.value);
      });
    }

    if (QuestionType[questionData.type] === 'Emoji Rating') {
      this.emojiList = [];
      questionData.feedbackAnswers.forEach(element => {
        this.emojiList.push({ value: element.value, label: element.label, openEmoji: false });
      });
      this.emojiList.forEach(element => {
          if (element.label) {
            this.labelEnable = true;
          }
        }
      );
    }

    if (QuestionType[questionData.type] === 'Scale Rating') {
      this.scaleEmojiNumber = this.questionInputData.feedbackAnswers.length - 1;
      this.emojiValue = this.questionInputData.feedbackAnswers[0].value;
      this.scaleEmojiList = this.questionInputData.feedbackAnswers;

      this.scaleEmojiList.forEach(element => {
        if (element.label) {
          this.labelEnable = true;
        }
      });
    }

    if (QuestionType[questionData.type] === 'Free Text') {
      this.scaleEmojiNumber = this.questionInputData.feedbackAnswers.length - 1;
      this.emojiValue = this.questionInputData.feedbackAnswers[0].value;
    }

  }

  onInsertEmoji(event) {
    if (this.emojiValue == null) {
      this.emojiValue = '';
    }

    this.emojiValue = event.emoji.native;
    this.showEmojiPicker = false;
    this.triggerAnswerChange();
  }

  onInsertEmojiToList(event, index) {
    if (this.emojiValue == null) {
      this.emojiValue = '';
    }
    this.emojiList[index].value = event.emoji.native;
    this.emojiList[index].openEmoji = false;
    this.triggerAnswerChange();
  }

  openEmojiMart(index: number): void {
    this.emojiList[index].openEmoji = true;
  }

  addOption() {
    if (this.selectorValue === 'Multiple Choice') {
      this.optionList.push('');
      this.optionValue.push('');
      this.triggerAnswerChange();
    }

    if (this.selectorValue === 'Emoji Rating') {
      if (this.emojiList.length < 5) {
        this.emojiList.push({ value: '', label: null, openEmoji: false });
        this.triggerAnswerChange();
      }

    }

  }

  changeAnswer(answer: string, index: number): void {

    this.optionList[index] = answer;
    this.triggerAnswerChange();
  }

  getQuestionData(): void {

    switch (this.questionFormGroup.value['typeSelector']) {
      case 'Multiple Choice':
        let multipleChoice = [];

        for (let i = 0; i < this.optionList.length; i++) {
          multipleChoice.push({
            order: i,
            value: `${this.optionList[i]}`,
            label: null
          });
        }

        this.questionData = {
          type: QuestionType[this.questionFormGroup.value['typeSelector']],
          value: this.questionFormGroup.value['question'],
          required: this.questionFormGroup.value['required'],
          answers: multipleChoice,
        };
        break;
      case 'Emoji Rating':
        let emojiRating = [];

        for (let i = 0; i < this.emojiList.length; i++) {

          emojiRating.push({
            order: i,
            value: `${this.emojiList[i].value}`,
            label: this.labelEnable ? `${this.emojiList[i].label}` : null
          });
        }

        this.questionData = {
          type: QuestionType[this.questionFormGroup.value['typeSelector']],
          value: this.questionFormGroup.value['question'],
          required: this.questionFormGroup.value['required'],
          answers: emojiRating,
        };

        break;

      case 'Scale Rating':
        let scaleAnswer = [];

        for (let i = 0; i < this.scaleEmojiList.length; i++) {
          scaleAnswer.push({
            order: i,
            value: `${this.emojiValue.repeat(i + 1)}`,
            label: this.labelEnable ? this.scaleEmojiList[i].label : null
          });
        }

        this.questionData = {
          type: QuestionType[this.questionFormGroup.value['typeSelector']],
          value: this.questionFormGroup.value['question'],
          required: this.questionFormGroup.value['required'],
          answers: scaleAnswer,
        };

        break;

      case 'Free Text':
        this.questionData = {
          type: QuestionType[this.questionFormGroup.value['typeSelector']],
          value: this.questionFormGroup.value['question'],
          required: this.questionFormGroup.value['required'],
          answers: [{
            order: 0,
            value: '',
            label: null
          }],
        };
        break;
    }
  }

  triggerAnswerChange() {
    this.questionFormGroup.controls.answers.setValue(['1']);
  }

  changeScaleRating() {
    // this.scaleEmojiList = [];
    const length = this.scaleEmojiList.length;
    if (this.scaleEmojiNumber + 1 > this.scaleEmojiList.length) {

      for (let i = length; i <= this.scaleEmojiNumber; i++) {
        this.scaleEmojiList.push({ value: this.emojiValue.repeat(i), label: '' });
      }

    }

    if (this.scaleEmojiNumber < this.scaleEmojiList.length) {
      for (let i = length; i > this.scaleEmojiNumber + 1; i--) {
        this.scaleEmojiList.pop();
      }
    }

    this.triggerAnswerChange();
  }

  deleteEmojiRatingOption(index: number): void {
    this.emojiList.splice(index, 1);
    this.triggerAnswerChange();
  }

  deleteOption(index: number): void {
    this.optionList.splice(index, 1);
    this.optionValue.splice(index, 1);
    this.triggerAnswerChange();
  }

  emitDeleteQuestionForm() {
    this.deleteQuestion.emit(true);
  }

  emitDuplicateQuestionForm() {
    this.triggerAnswerChange();
    this.duplicateQuestion.emit(this.questionData);
  }

  fillEmojis() {
    const prefillList = ['😩', '😕', '😐', '😀', '😊'];
    this.emojiList = [
      { value: '😩', label: '', openEmoji: false },
      { value: '😕', label: '', openEmoji: false },
      { value: '😐', label: '', openEmoji: false },
      { value: '😀', label: '', openEmoji: false },
      { value: '😊', label: '', openEmoji: false }];

    // this.emojiList.forEach((emojiElement, index) => {
    //   emojiElement.value = prefillList[index];
    // });
  }
}
