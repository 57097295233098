/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { Scheme } from './scheme';

export interface AdditionalCustomerFields { 
    customerId?: string;
    customer?: Customer;
    createdBy?: string;
    updatedBy?: string;
    id?: string;
    schemeId?: string;
    scheme?: Scheme;
    name?: string;
    key?: string;
    value?: string;
    internal?: boolean;
    createdDate?: Date;
    updatedDate?: Date;
}