/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdditionalCustomerFieldsResultView } from './additionalCustomerFieldsResultView';
import { Gender } from './gender';

export interface CustomerResultViewPos { 
    id?: string;
    email?: string;
    firstName?: string;
    readonly name?: string;
    lastName?: string;
    dob?: Date;
    gender?: Gender;
    areaCode?: string;
    mobileNumber?: string;
    addressLine1?: string;
    addressLine2?: string;
    town?: string;
    state?: string;
    postCode?: string;
    country?: string;
    marketingSub?: boolean;
    barCode?: string;
    createdBy?: string;
    updatedBy?: string;
    createdDate?: Date;
    updatedDate?: Date;
    lastConnectedDate?: Date;
    lastTransactionDate?: Date;
    additionalCustomerFields?: Array<AdditionalCustomerFieldsResultView>;
    profileImageUrl?: string;
    onHold?: boolean;
    emailVerified?: boolean;
    mobileNumberVerified?: boolean;
    externalRefId?: string;
    isPartial?: boolean;
    isGuest?: boolean;
}