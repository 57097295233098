<div>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Advanced Scheduling
      </mat-card-title>

      <mat-slide-toggle color="accent" class="top-right-action" (change)="changeActionAvailability()"
        [checked]="showChips"></mat-slide-toggle>
    </mat-card-header>
    <mat-card-content *ngIf="showChips">
      <h6>Add Actions</h6>
      <mat-chip-list multiple="true">
        <ng-container
          *ngIf="sessionService.schemeFeatures.posts.isAvailable && sessionService.schemeFeatures.posts.isEnabled">
          <mat-chip class="action-chip" color="accent" [selected]="hasPost" (click)="onToggleAction(1)"
            *ngIf="actionType!==actionTypeEnum.POST">
            <mat-icon class="mr-1">sticky_note_2</mat-icon>
            Post
          </mat-chip>
        </ng-container>
        <ng-container
          *ngIf="sessionService.schemeFeatures.emails.isAvailable && sessionService.schemeFeatures.emails.isEnabled">
          <mat-chip class="action-chip" color="accent" [selected]="hasEmail" (click)="onToggleAction(2)"
            *ngIf="actionType!==actionTypeEnum.EMAIL">
            <mat-icon class="mr-1">email</mat-icon>
            Email
          </mat-chip>
        </ng-container>
        <ng-container
          *ngIf="sessionService.schemeFeatures.coupons.isAvailable && sessionService.schemeFeatures.coupons.isEnabled">
          <mat-chip class="action-chip" color="accent" [selected]="hasCoupon" (click)="onToggleAction(3)"
            *ngIf="actionType!==actionTypeEnum.COUPON">
            <mat-icon class="mr-1">local_play</mat-icon>
            Coupon
          </mat-chip>
        </ng-container>
        <ng-container
          *ngIf="sessionService.schemeFeatures.giftCards.isAvailable && sessionService.schemeFeatures.giftCards.isEnabled">
          <mat-chip class="action-chip" color="accent" [selected]="hasGiftCard" (click)="onToggleAction(8)"
            *ngIf="actionType!==actionTypeEnum.GIFT_CARD">
            <mat-icon class="mr-1">card_membership</mat-icon>
            Gift Card
          </mat-chip>
        </ng-container>
        <ng-container
          *ngIf="sessionService.schemeFeatures.products.isAvailable && sessionService.schemeFeatures.products.isEnabled">
          <mat-chip class="action-chip" color="accent" [selected]="hasProduct" (click)="onToggleAction(4)"
            *ngIf="actionType!==actionTypeEnum.PRODUCT">
            <mat-icon class="mr-1">shopping_bag</mat-icon>
            Product
          </mat-chip>
        </ng-container>
        <mat-chip class="action-chip" color="accent" [selected]="hasPointsModifier" (click)="onToggleAction(5)"
          *ngIf="actionType!==actionTypeEnum.MODIFIER">
          <mat-icon class="mr-1">iso</mat-icon>
          Points Modifier
        </mat-chip>
        <mat-chip class="action-chip" color="accent" [selected]="hasPointsMultiplier" (click)="onToggleAction(6)"
          *ngIf="actionType!==actionTypeEnum.MULTIPLIER">
          <mat-icon class="mr-1">emergency</mat-icon>
          Points Multiplier
        </mat-chip>
        <ng-container
          *ngIf="sessionService.schemeFeatures.feedbackForms.isAvailable && sessionService.schemeFeatures.feedbackForms.isEnabled">
          <mat-chip class="action-chip" color="accent" [selected]="hasFeedbackForm" (click)="onToggleAction(9)"
            *ngIf="actionType!==actionTypeEnum.FEEDBACK_FORM">
            <mat-icon class="mr-1">sms_failed</mat-icon>
            Feedback Form
          </mat-chip>
        </ng-container>
        <mat-chip class="action-chip" color="accent" [selected]="hasTag" (click)="onToggleAction(10)"
          *ngIf="actionType!==actionTypeEnum.TAG">
          <mat-icon class="mr-1">label</mat-icon>
          <span matLine>{{'Tags' | translate}}</span>
        </mat-chip>
        <ng-container
          *ngIf="sessionService.schemeFeatures.sms.isAvailable && sessionService.schemeFeatures.sms.isEnabled">
          <mat-chip class="action-chip" color="accent" [selected]="hasSMS" (click)="onToggleAction(11)"
            *ngIf="actionType!==actionTypeEnum.SMS">
            <mat-icon class="mr-1">sms</mat-icon>
            <span matLine>{{'SMS' | translate}}</span>
          </mat-chip>
        </ng-container>
        <ng-container
          *ngIf="sessionService.schemeFeatures.pushNotifications.isAvailable && sessionService.schemeFeatures.pushNotifications.isEnabled">
          <mat-chip class="action-chip" color="accent" [selected]="hasPushNotification" (click)="onToggleAction(0)"
            *ngIf="actionType!==actionTypeEnum.PUSH_NOTIFICATION">
            <mat-icon class="mr-1">notifications</mat-icon>
            Push Notification
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </mat-card-content>
  </mat-card>
</div>

<div>
  <loyale-action-push-notification-selector *ngIf="hasPushNotification == true"
    (outputPushNotificationId)="onActionChange(0, $event)" (removePushNotification)="onToggleAction(0)">
  </loyale-action-push-notification-selector>

  <loyale-action-alert-selector *ngIf="hasPost == true" (outputAlertId)="onActionChange(1, $event)"
    (removeAlert)="onToggleAction(1)">
  </loyale-action-alert-selector>

  <loyale-action-email-selector *ngIf="hasEmail == true" (outputEmailId)="onActionChange(2, $event)"
    (removeEmail)="onToggleAction(2)">
  </loyale-action-email-selector>

  <loyale-action-coupon-selector *ngIf="hasCoupon == true" (outputCouponId)="onActionChange(3, $event)"
    (removeCoupon)="onToggleAction(3)">
  </loyale-action-coupon-selector>

  <loyale-action-giftcard-selector *ngIf="hasGiftCard == true" (outputGiftCardId)="onActionChange(8, $event)"
    (removeGiftCard)="onToggleAction(8)">
  </loyale-action-giftcard-selector>

  <loyale-action-product-selector *ngIf="hasProduct == true" (outputProductId)="onActionChange(4, $event)"
    (removeProduct)="onToggleAction(4)">
  </loyale-action-product-selector>

  <loyale-action-modifier-selector *ngIf="hasPointsModifier == true" (outputModifierId)="onActionChange(5, $event)"
    (removeModifier)="onToggleAction(5)">
  </loyale-action-modifier-selector>

  <loyale-action-multiplier-selector *ngIf="hasPointsMultiplier == true"
    (outputMultiplierId)="onActionChange(6, $event)" (removeMultiplier)="onToggleAction(6)">
  </loyale-action-multiplier-selector>

  <loyale-action-feedback-selector *ngIf="hasFeedbackForm == true" (outputFeedbackFormId)="onActionChange(9, $event)"
    (removeFeedbackForm)="onToggleAction(9)">
  </loyale-action-feedback-selector>

  <loyale-action-tag-selector *ngIf="hasTag == true" (outputTagId)="onActionChange(10, $event)"
    (removeTag)="onToggleAction(10)">

  </loyale-action-tag-selector>

  <loyale-action-sms-selector *ngIf="hasSMS == true" (outputSMSId)="onActionChange(11, $event)"
    (removeSMS)="onToggleAction(11)">
  </loyale-action-sms-selector>
</div>
