import { Stack, useFocusIdx } from 'easy-email-editor';
import { AttributesPanelWrapper, ColorPickerField, TextAreaField, TextField, RichTextField, FontFamily } from 'easy-email-extensions';
import React from 'react';
import { Collapse } from 'antd';

export function Panel() {
  const { focusIdx } = useFocusIdx();
  return (
    <AttributesPanelWrapper>
      <Collapse defaultActiveKey={['1']}>
        <Collapse.Panel key="1" header="Unsubscribe settings">
          <Stack vertical>

            <TextField
              label="Unsubscribe Url"
              name={`${focusIdx}.data.value.unsubscribeUrl`}
            />
            <ColorPickerField
              label="Background color"
              name={`${focusIdx}.attributes.background-color`}
            />
            <ColorPickerField
              label="Text color"
              name={`${focusIdx}.attributes.text-color`}
            />
            <ColorPickerField
              label="Button text color"
              name={`${focusIdx}.attributes.button-text-color`}
            />

            <FontFamily name={`${focusIdx}.attributes.font-family`}></FontFamily>
          </Stack>
        </Collapse.Panel>
      </Collapse>


    </AttributesPanelWrapper>
  );

}


// {/*<TextAreaField*/}
// {/*  label="Unsubscribe Text"*/}
// {/*  name={`${focusIdx}.data.value.unsubscribeText`}*/}
// {/*/>*/}
