import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartService } from 'src/app/swagger';
import { SessionService } from 'src/app/services/session.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'loyale-ageing-chart',
  templateUrl: './ageing-chart.component.html',
  styleUrls: ['./ageing-chart.component.scss']
})
export class AgeingChartComponent implements OnInit, OnChanges {
  chart: any[] = [];
  colorList = [];
  report = { range: [], pointValue: [], monetaryValue: [] };

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = '';
  legendTitle: string = '';

  colorScheme = {
    domain: ['#7b61e5', '#b3a1ff']
  };

  constructor(private chartService: ChartService, private sessionService: SessionService, public commonService: CommonService) { }

  ngOnInit() {
    this.getChart();
  }

  //since this does not have inputs, we can call getchart on init
  ngOnChanges(changes: SimpleChanges) {
    this.getChart();
  }

  getChart() {
    this.chartService.apiChartAgeingGet(this.sessionService.schemeId).subscribe(res => {
      this.report = res;

      this.chart = [];

      this.report.range.forEach((date, index) => {
        this.chart.push({
          name: date.toString(),
          series: [
            {
              name: 'Point Value',
              value: this.report.pointValue[index],
            },
            {
              name: 'Monetary Value',
              value: this.report.monetaryValue[index],
            }
          ],
        });
      });
    }, err => {
      this.commonService.errorHandler(err);
    });
  }
}
