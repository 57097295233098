import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loyale-card-plan-prefix',
  templateUrl: './card-plan-prefix.component.html',
  styleUrls: ['./card-plan-prefix.component.scss']
})
export class CardPlanPrefixComponent implements OnInit {
  @Input() planName = 'Free';

  constructor() { }

  ngOnInit(): void {
  }

}
