import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { AlertService } from 'src/app/swagger';

@Component({
  selector: 'loyale-new-alert-dialog',
  templateUrl: './new-alert-dialog.component.html',
  styleUrls: ['./new-alert-dialog.component.scss']
})
export class NewAlertDialogComponent implements OnInit {
  @ViewChild('alertComponent') alertComponent;

  awaitingPost = false;

  constructor(
    private translate: TranslateService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<NewAlertDialogComponent>,
    public sessionService: SessionService,
    private snackBar: MatSnackBar,
    public commonService: CommonService) { }

  ngOnInit() {
  }

  onSubmit() {
    if (this.alertComponent.alertForm.valid) {
      this.awaitingPost = true;

      this.alertComponent.createNew().subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });
    }
  }

}
