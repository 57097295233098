import { Pipe, PipeTransform } from '@angular/core';

enum Topic {
  'Customer Join Scheme' = 0,
  'Customer Account Update',
  'Transaction Create',
  'Customer Leave Scheme',
  'Additional Customer Fields Create',
  'Additional Customer Fields Update',
  'Coupon Create',
  'Coupon Use',
  'Customer Account Activate',
  'Customer Account Suspend',
  'Transfer Of Points',
  'Customer Account Delete'
}

@Pipe({
  name: 'webhookTopic'
})
export class WebhookTopicPipe implements PipeTransform {

  transform(value: any): string {
    return Topic[value];
  }

}
