/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LevelInsertView { 
    tierId: string;
    name: string;
    pointAllocationPerCurrency: number;
    monetaryThreshold: number;
    joinByQRCode?: boolean;
    externalRef?: string;
    schemeId?: string;
    percentageDiscount: number;
    color?: string;
    visitsThreshold?: number;
}