/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Gender } from './gender';

export interface WalletCustomerInsertView { 
    firstName: string;
    lastName: string;
    dob?: Date;
    email: string;
    password: string;
    areaCode?: string;
    mobileNumber: string;
    gender?: Gender;
    marketingSub?: boolean;
    profileImageUrl?: string;
    addressLine1?: string;
    addressLine2?: string;
    town?: string;
    state?: string;
    postCode?: string;
    country?: string;
}