/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Link } from './link';

export interface Unsubscribe { 
    campaignId?: string;
    emailAddress?: string;
    emailId?: string;
    links?: Array<Link>;
    listId?: string;
    reason?: string;
    timestamp?: string;
}