/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdditionalCustomerFieldsPosResultView } from './additionalCustomerFieldsPosResultView';
import { LevelResultView } from './levelResultView';

export interface PosCustomerWithAdditionalFieldsResultView { 
    id?: string;
    email?: string;
    barCode?: string;
    firstName?: string;
    lastName?: string;
    dob?: Date;
    gender?: string;
    mobileNumber?: string;
    marketingSub?: boolean;
    pointsAvailable?: number;
    cashRedeemable?: number;
    currency?: string;
    levels?: Array<LevelResultView>;
    additionalFields?: Array<AdditionalCustomerFieldsPosResultView>;
    updatedDate?: Date;
    createdDate?: Date;
    externalRefId?: string;
}