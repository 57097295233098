import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'loyale-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Input() label = 'Time';
  @Input() appearance = 'standard';
  @Input() fullWidth = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() clearable = false;
  @Input() time = null;
  @Input() returnUtc = true;
  @Input() stepMinute = 1;
  @Output() onChange = new EventEmitter<any>();

  @Input()
  public set markAsTouched(touched: boolean) {
    if (touched === true) {
      this.timeForm.markAllAsTouched();
    }
  }

  timeForm: UntypedFormGroup = new UntypedFormGroup({
    timeControl: new UntypedFormControl('', [this.required ? Validators.required : Validators.nullValidator])
  });

  constructor() { }

  ngOnInit() {
    console.log(this.time);
    //if this is not a 1 minute step, and no input time, set minute to 0
    //so minute increments will start from 0
    if (this.stepMinute != 1 && !this.time) {
      this.time = moment().minutes(0).format('YYYY-MM-DDTHH:mm:ssZ');
    }

    if (this.time !== null) {
      this.timeForm.patchValue({ timeControl: this.time });
    }
  }

  onClear() {
    this.time = null;
    this.timeForm.patchValue({ timeControl: null });
    this.onChange.emit(this.time);
  }

  onTimeChange() {
    if (this.timeForm.get('timeControl').value != null) {
      if (this.returnUtc) {
        this.onChange.emit(this.timeForm.get('timeControl').value.second(0).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));
      } else {
        this.onChange.emit(this.timeForm.get('timeControl').value.second(0).format('YYYY-MM-DDTHH:mm:ssZ'));
      }
    }
  }
}
