import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from 'src/app/services/session.service';
import { AppInfoDialogComponent } from '../dialogs/app-info-dialog/app-info-dialog.component';

@Component({
  selector: 'loyale-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {

  constructor(public sessionService: SessionService, private dialog: MatDialog) { }

  ngOnInit() {
  }

  onAppInfo() {
    this.dialog.open(AppInfoDialogComponent);
  }
}
