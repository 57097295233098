import { Pipe, PipeTransform } from '@angular/core';

enum Type {
  'Multiple Choice' = 0,
  'Emoji Rating',
  'Scale Rating',
  'Free Text'
}

@Pipe({
  name: 'questionType'
})
export class QuestionTypePipe implements PipeTransform {

  transform(value: any): string {
    return Type[value];
  }

}
