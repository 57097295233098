import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActionType } from '../action.enum';
import {
  ScheduledTaskActions, ScheduledTaskInsertView, ScheduledTaskService,
  TimeTriggerInsertView, TimeTriggerService
} from '../../../swagger';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';
import { SessionService } from '../../../services/session.service';
import { AutomationActionPipe } from '../../../pipes/automation-action.pipe';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActionConnectorInterface } from '../actionConnector.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'loyale-send-now',
  templateUrl: './send-now.component.html',
  styleUrls: ['./send-now.component.scss']
})
export class SendNowComponent implements OnInit, OnDestroy {
  @Input() actionType: ActionType;
  @Input() actionDataObject: ActionConnectorInterface;

  awaitingPost;
  actionTypeEnum = ActionType;

  type = 0;
  runIndefinitely = true;

  scheduledTask: ScheduledTaskInsertView = <ScheduledTaskInsertView> {};

  sendForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
  });

  @Output() scheduledTaskEvent: EventEmitter<ScheduledTaskInsertView> = new EventEmitter<ScheduledTaskInsertView>();
  private destroy$ = new Subject<void>();

  constructor(private snackBar: MatSnackBar,
              private translate: TranslateService,
              public commonService: CommonService,
              private router: Router,
              private scheduledTaskService: ScheduledTaskService,
              public sessionService: SessionService,
              private timeTriggerService: TimeTriggerService,
              private automationActionPipe: AutomationActionPipe) { }

  ngOnInit() {
    this.scheduledTask.taskActions = [];
    this.scheduledTask.isEnabled = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTriggerChange(triggerId) {
    this.scheduledTask.triggerId = triggerId;
    this.snackBar.open('Trigger set');
  }

  changeTaskActions(actionsList: Array<ScheduledTaskActions>) {
    this.scheduledTask.taskActions = actionsList;
  }

  onTargetChange(demographicId) {
    this.scheduledTask.demographicId = demographicId;
    this.snackBar.open('Target set');
  }

  onActionChange(actionType, actionId) {
    const action: ScheduledTaskActions = { id: actionId, type: actionType };

    const index = this.scheduledTask.taskActions.findIndex(a => a.type === actionType);

    if (index === -1) {
      this.scheduledTask.taskActions.push(action);
    } else {
      this.scheduledTask.taskActions[index] = action;
    }

    this.snackBar.open(this.automationActionPipe.transform(actionType) + ' Action set');
  }

  createAndSendActionNow(actionData?: ActionConnectorInterface) {
    this.sendForm.markAllAsTouched();
    if (this.sendForm.valid) {
      this.awaitingPost = true;
      const dateTime = moment(new Date()).add(5, 'm').format();

      const date = dateTime.split('T')[0].split('-');
      const day = date[2];
      const month = date[1];

      const time = dateTime.split('T')[1].replace('T', '').replace('Z', '').split(':');
      const hour = time[0];
      const minute = time[1];

      const triggerTime: TimeTriggerInsertView = <TimeTriggerInsertView> {};
      triggerTime.cron = minute + ' ' + hour + ' ' + day + ' ' + month + ' *';

      this.onActionChange(actionData.actionType, actionData.actionData.id);

      this.timeTriggerService.apiTimeTriggerPost(this.sessionService.schemeId, triggerTime).subscribe(res => {
        this.scheduledTask.triggerId = res.id;
        this.scheduledTaskEvent.next(this.scheduledTask);
        this.snackBar.open(this.translate.instant('Saved'), '', {
          duration: 3000
        });
        this.awaitingPost = false;
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });

    }
  }

}
