import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { AlertsLinkedService, AlertService, LotteryService, LotteryTicketService, LotteryRewardService, LotteryPickWinnerInsertView } from 'src/app/swagger';
import { LinkAlertComponent } from '../../../views/alerts/link-alert/link-alert.component';

export interface DialogData {
  lotteryId: string;
  ticketId: string;
}

@Component({
  selector: 'loyale-link-prize-dialog',
  templateUrl: './link-prize-dialog.component.html',
  styleUrls: ['./link-prize-dialog.component.scss']
})
export class LinkPrizeDialogComponent implements OnInit {
  awaitingGet = false;
  awaitingPost = false;
  page = 1;
  totalPages = null;
  totalCount = null;

  compFilter = null;

  displayedColumns: string[] = ['name', 'quantity', 'createdDate', 'add'];
  rewards: any = [];

  debounceCheck: Subject<string> = new Subject<string>();

  refreshForChanges = false;

  constructor(private linkedAlertsService: AlertsLinkedService, private lotteryService: LotteryService, private lotteryRewardService: LotteryRewardService, private translate: TranslateService, private dialogRef: MatDialogRef<LinkAlertComponent>, public commonService: CommonService, @Inject(MAT_DIALOG_DATA) public data: DialogData, public sessionService: SessionService, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar, private dialog: MatDialog, private alertService: AlertService) {
    this.debounceCheck.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(dc => {
        this.onUpdateView('1');
      });
  }

  ngOnInit() {
    this.getLotteryRewards();
  }

  getLotteryRewards() {
    this.awaitingGet = true;
    let filters = 'lotteryId==' + this.data.lotteryId + ',quantity!=0';

    if (this.compFilter != null) {
      filters += 'name@=*' + this.compFilter.trim();
    }

    this.lotteryRewardService.apiLotteryRewardGet(filters, '-createdDate', this.page, 10, this.sessionService.schemeId, 'response').subscribe(res => {
      this.rewards = new MatTableDataSource<any>(res.body);
      this.totalPages = +res.headers.get('x-total-pages');
      this.totalCount = +res.headers.get('x-total-count');
      this.awaitingGet = false;
    }, err => {
      this.commonService.errorHandler(err);
      this.awaitingGet = false;
    });
  }

  onAddReward(rewardId) {
    let link: LotteryPickWinnerInsertView = <LotteryPickWinnerInsertView>{};
    link.rewardId = rewardId;

    this.awaitingPost = true;
    this.lotteryService.apiLotteryLotteryIdPickWinnerTicketIdPost(this.data.lotteryId, this.data.ticketId, link).subscribe(_res => {
      this.awaitingPost = false;
      this.snackBar.open(this.translate.instant('Added'));
      this.refreshForChanges = true;
      this.dialogRef.close(true);
    }, err => {
      this.awaitingPost = false;
      this.commonService.errorHandler(err);
    })
  }

  onDebounceFilter() {
    this.debounceCheck.next(this.compFilter);
  }

  onUpdateView(page?: string) {
    if (page != undefined) {
      this.page = +page;
    }

    this.getLotteryRewards();
  }
}
