import { Component, OnInit, ViewChild } from '@angular/core';
import { SmsMessageInsertView, SmsService } from '../../../swagger';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../services/session.service';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'loyale-new-sms-dialog',
  templateUrl: './new-sms-dialog.component.html',
  styleUrls: ['./new-sms-dialog.component.scss']
})
export class NewSmsDialogComponent implements OnInit {
  @ViewChild('smsComponent') smsComponent;

  awaitingPost = false;
  sms: SmsMessageInsertView = <SmsMessageInsertView> {};

  constructor(
    private translate: TranslateService,
    private smsService: SmsService,
    private dialog: MatDialog,
    public sessionService: SessionService,
    public commonService: CommonService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<NewSmsDialogComponent>) { }

  ngOnInit() {
  }

  createSms() {
    if (this.smsComponent.smsForm.valid) {
      this.awaitingPost = true;

      this.smsComponent.createNew().subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });
    }

  }

}
