<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon color="accent" class="mr-2">label</mat-icon>
            <span>Tag</span>
        </mat-card-title>

        <mat-card-subtitle>
            Select an existing tag or create a new one
        </mat-card-subtitle>


        <button mat-icon-button class="top-right-action" matTooltip="Remove" (click)="removeTag.emit()">
            <mat-icon>cancel</mat-icon>
        </button>
    </mat-card-header>

    <mat-card-content>
        <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

        <div class="row align-items-center" *ngIf="!awaitingGet">
            <div class="col-12 col-lg-7">
                <mat-form-field class="w-100">
                    <mat-label>Tags</mat-label>
                    <mat-select name="giftCard" #tagForm="ngModel" [(ngModel)]="selectedTagId"
                                (ngModelChange)="onTagChange()" [disabled]="tags.length == 0" required>

                        <mat-option>
                            <ngx-mat-select-search name="filter" #filter="ngModel" [(ngModel)]="tagFilter"
                                                   (ngModelChange)="onFilterTag()" placeholderLabel="Filter"
                                                   noEntriesFoundLabel="No results found">
                                <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option [value]="tagForm.id" *ngFor="let tagForm of filteredTags">
                            {{tagForm.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="tagForm?.errors?.required">Required</mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 col-lg-1 text-center">
                <h5 class="mb-4">Or</h5>
            </div>

            <div class="col-12 col-lg-4 text-center text-lg-left">
                <button mat-flat-button color="primary" class="mb-4" (click)="onNewTag()">Create a new tag
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>


