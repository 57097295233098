import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[complex]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ComplexDirective, multi: true }]
})
export class ComplexDirective implements Validator {
  @Input('complex') complex;

  constructor() {
  }

  validate(c: AbstractControl) {
    let isValid = this.complexityCheck(c.value);

    const validationState = !isValid ? { complex: true } : null;
    return validationState;
  }

  complexityCheck(value) {
    var uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lowercase = "abcdefghijklmnopqrstuvwxyz";
    var digits = "0123456789";
    var special = "!@#$%^&*()_-+=[]{};:\\/|?,.<>~";

    var ucaseFlag = this.includes(value, uppercase);
    var lcaseFlag = this.includes(value, lowercase);
    var digitsFlag = this.includes(value, digits);
    var specialFlag = this.includes(value, special);

    if (ucaseFlag && lcaseFlag && digitsFlag && specialFlag) {
      return true;
    } else {
      return false;
    }
  }

  includes(string, allowedChars) {
    if (string != null || string != undefined) {
      for (let i = 0; i < string.length; i++) {
        var char = string.charAt(i);
        if (allowedChars.indexOf(char) >= 0) {
          return true;
        }
      }

      return false;
    }
  }
}
