<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon color="accent" class="mr-2">donut_large</mat-icon>
            <span>Demographics</span>
        </mat-card-title>
        <mat-card-subtitle>Select an existing demographic or create a new one</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

        <div class="row align-items-center" *ngIf="!awaitingGet">
            <div class="col-12 col-lg-7">
                <mat-form-field class="w-100">
                    <mat-label>Demographics</mat-label>
                    <mat-select name="demographic" #demographic="ngModel" [(ngModel)]="selectedDemographicId"
                                (ngModelChange)="onDemographicChange()" [disabled]="demographics.length == 0" required>

                        <mat-option>
                            <ngx-mat-select-search name="filter" #filter="ngModel" [(ngModel)]="demographicFilter"
                                                   (ngModelChange)="onFilterDemographics()" placeholderLabel="Filter"
                                                   noEntriesFoundLabel="No results found">
                                <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option [value]="demographic.id" *ngFor="let demographic of filteredDemographics">
                            {{demographic.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="demographic?.errors?.required">Required</mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 col-lg-1 text-center">
                <h5 class="mb-4">Or</h5>
            </div>

            <div class="col-12 col-lg-4 text-center text-lg-left">
                <button mat-flat-button color="primary" class="mb-4" (click)="onNewDemographic()">Create a new
                    demographic</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
