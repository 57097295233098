/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LotteryRewardResultView } from './lotteryRewardResultView';
import { LotteryStatus } from './lotteryStatus';

export interface LotteryResultView { 
    id?: string;
    name?: string;
    description?: string;
    dateFrom?: Date;
    dateTo?: Date;
    drawDate?: Date;
    status?: LotteryStatus;
    autoDraw?: boolean;
    purchaseCountPerTicket?: number;
    purchaseValuePerTicket?: number;
    maxTicketsPerCustomer?: number;
    createdBy?: string;
    updatedBy?: string;
    rewards?: Array<LotteryRewardResultView>;
}