/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TqRecord { 
    transactionstartedtimestamp?: string;
    sitereference?: string;
    tpInterface?: string;
    livestatus?: string;
    threedversion?: string;
    dccenabled?: string;
    settleduedate?: string;
    errorcode?: string;
    baseamount?: string;
    subscriptionnumber?: string;
    tid?: string;
    issuer?: string;
    securityresponsepostcode?: string;
    transactionreference?: string;
    merchantname?: string;
    paymenttypedescription?: string;
    eci?: string;
    accounttypedescription?: string;
    fraudrating?: string;
    acquirerresponsecode?: string;
    requesttypedescription?: string;
    expirydate?: string;
    securityresponsesecuritycode?: string;
    currencyiso3a?: string;
    maskedpan?: string;
    settlebaseamount?: string;
    errormessage?: string;
    issuercountryiso2a?: string;
    merchantcountryiso2a?: string;
    enrolled?: string;
    securityresponseaddress?: string;
    operatorname?: string;
    settlestatus?: string;
    parenttransactionreference?: string;
    xid?: string;
    settledtimestamp?: string;
    status?: string;
    cavv?: string;
    updatereason?: string;
    splitfinalnumber?: string;
    authcode?: string;
}