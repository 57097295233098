import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmailService } from '../../../swagger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewEmailDialogComponent } from '../../action-selector-dialog/new-email-dialog/new-email-dialog.component';

@Component({
  selector: 'loyale-action-email-selector',
  templateUrl: './action-email-selector.component.html',
  styleUrls: ['./action-email-selector.component.scss']
})
export class ActionEmailSelectorComponent implements OnInit {
  awaitingGet = false;
  emails = [];
  filteredEmails = [];
  @Input() selectedEmailId = null;
  @Output() outputEmailId = new EventEmitter<any>();
  @Output() removeEmail = new EventEmitter<any>();

  emailFilter = null;

  constructor(private emailService: EmailService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getEmails();
  }

  getEmails() {
    this.awaitingGet = true;
    this.emailService.apiEmailGet(null, '-createdDate').subscribe(res => {
      this.awaitingGet = false;
      this.emails = res;
      this.filteredEmails = this.emails;
    });
  }

  onNewEmail() {
    const dialogRef = this.dialog.open(NewEmailDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getEmails();
        this.selectedEmailId = res.id;
        this.onEmailChange();
      }
    });
  }

  onEmailChange() {
    this.outputEmailId.emit(this.selectedEmailId);
  }

  onFilterEmails() {
    if (!this.emails) {
      return;
    }

    let filter = this.emailFilter;
    if (!filter) {
      this.filteredEmails = this.emails;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredEmails = this.emails.filter(email => email.name.toLowerCase().indexOf(filter) > -1);
  }

}
