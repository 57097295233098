/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ReceiveBy } from './receiveBy';

export interface ProductInsertView { 
    name: string;
    description: string;
    pointsValue: number;
    imageUrl?: string;
    expiryDate?: Date;
    quantity: number;
    receiveBy: ReceiveBy;
    pickupNote?: string;
    categoriesIds?: Array<string>;
    collectionId?: string;
    couponId?: string;
}