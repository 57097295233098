import { PromiseService } from 'src/app/services/promise.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/services/common.service';
import { NestedLevels, SessionService } from 'src/app/services/session.service';
import { LevelService } from 'src/app/swagger';

export interface DialogData {
  currentLevelId: string;
}

@Component({
  selector: 'loyale-level-transfer-dialog',
  templateUrl: './level-transfer-dialog.component.html',
  styleUrls: ['./level-transfer-dialog.component.scss']
})
export class LevelTransferDialogComponent implements OnInit {
  selectedLevelId = null;
  nestedLevels: NestedLevels[] = []

  constructor(
    public dialogRef: MatDialogRef<LevelTransferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private levelService: LevelService, private promiseService: PromiseService) { }

  ngOnInit(): void {
    this.promiseService.getNestedLevels().then(res => {
      this.nestedLevels = res;
    });
  }

  onTransfer(): void {
    this.levelService.apiLevelTransferCustomersPost({
      fromLevelId: this.data.currentLevelId,
      toLevelId: this.selectedLevelId
    }).subscribe(_res => {
      this.snackBar.open('Transfer in process...');
      this.dialogRef.close();
    }, err => {
      this.commonService.errorHandler(err);
    });
  }
}
