import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActionType } from '../action.enum';
import { ScheduledTaskActions, ScheduledTaskInsertView, ScheduledTaskService, TimeTriggerService } from '../../../swagger';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';
import { SessionService } from '../../../services/session.service';
import { AutomationActionPipe } from '../../../pipes/automation-action.pipe';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActionConnectorInterface } from '../actionConnector.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'loyale-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit, OnDestroy {
  @Input() actionType: ActionType;
  @Input() actionDataObject: ActionConnectorInterface;

  awaitingPost;
  actionTypeEnum = ActionType;

  type = 0;
  runIndefinitely = true;

  scheduledTask: ScheduledTaskInsertView = <ScheduledTaskInsertView> {};

  scheduleForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    runCheck: new UntypedFormControl(true, []),
    dateFromDummy: new UntypedFormControl(null, []),
    dateToDummy: new UntypedFormControl(null, []),
  });

  @Output() scheduledTaskEvent: EventEmitter<ScheduledTaskInsertView> = new EventEmitter<ScheduledTaskInsertView>();

  private destroy$ = new Subject<void>();

  constructor(private snackBar: MatSnackBar,
              private translate: TranslateService,
              public commonService: CommonService,
              private router: Router,
              private scheduledTaskService: ScheduledTaskService,
              public sessionService: SessionService,
              private timeTriggerService: TimeTriggerService,
              private automationActionPipe: AutomationActionPipe) { }

  ngOnInit() {
    this.scheduledTask.taskActions = [];
    this.scheduledTask.isEnabled = true;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  scheduleAction(actionData?: any) {
    this.scheduleForm.markAllAsTouched();

    if (this.scheduleForm.valid && this.scheduledTask.triggerId) {
      this.onActionChange(this.actionDataObject.actionType, actionData.actionData.id);
      this.scheduledTaskEvent.next(this.scheduledTask);
    }
    if (!this.scheduledTask.triggerId) {
      this.snackBar.open('Set the trigger rule');
    }
  }

  onActionChange(actionType, actionId) {
    const action: ScheduledTaskActions = { id: actionId, type: actionType };

    const index = this.scheduledTask.taskActions.findIndex(a => a.type === actionType);

    if (index === -1) {
      this.scheduledTask.taskActions.push(action);
    } else {
      this.scheduledTask.taskActions[index] = action;
    }

    this.snackBar.open(this.automationActionPipe.transform(actionType) + ' Action set');
  }

  onTriggerChange(triggerId) {
    this.scheduledTask.triggerId = triggerId;
    this.snackBar.open('Trigger set');
  }

  onTargetChange(demographicId) {
    this.scheduledTask.demographicId = demographicId;
    this.snackBar.open('Target set');
  }

  changeTaskActions(actionsList: Array<ScheduledTaskActions>) {
    this.scheduledTask.taskActions = actionsList;
  }

  changeValidationStatus() {
    if (!this.scheduleForm.get('runCheck').value) {
      this.scheduleForm.get('dateFromDummy').setValidators([Validators.required]);
      this.scheduleForm.get('dateToDummy').setValidators([Validators.required]);
      this.scheduleForm.get('dateFromDummy').updateValueAndValidity();
      this.scheduleForm.get('dateToDummy').updateValueAndValidity();
    } else {
      this.scheduleForm.get('dateFromDummy').clearValidators();
      this.scheduleForm.get('dateFromDummy').clearValidators();
      this.scheduleForm.get('dateFromDummy').updateValueAndValidity();
      this.scheduleForm.get('dateToDummy').updateValueAndValidity();
    }
  }

}
