/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { Level } from './level';
import { Scheme } from './scheme';

export interface CustomerLevel { 
    id?: string;
    customerId?: string;
    customer?: Customer;
    schemeId?: string;
    scheme?: Scheme;
    levelId?: string;
    level?: Level;
    dateJoined?: Date;
    dateLeft?: Date;
    createdBy?: string;
    updatedBy?: string;
    progress?: number;
    createdDate?: Date;
    updatedDate?: Date;
}