/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { Feedback } from './feedback';
import { FeedbackResponse } from './feedbackResponse';
import { FeedbackStatus } from './feedbackStatus';

export interface FeedbackLinked { 
    id?: string;
    feedback?: Feedback;
    feedBackId?: string;
    customer?: Customer;
    customerId?: string;
    submitted?: boolean;
    schemeId?: string;
    feedbackResponses?: Array<FeedbackResponse>;
    submittedDate?: Date;
    createdBy?: string;
    updatedBy?: string;
    status?: FeedbackStatus;
    createdDate?: Date;
    updatedDate?: Date;
}