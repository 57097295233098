import { Pipe, PipeTransform } from '@angular/core';

enum Integration {
  'Point of Sale' = 0,
  'Web Store',
  'Marketing'
}

@Pipe({
  name: 'integration'
})
export class IntegrationPipe implements PipeTransform {

  transform(value: any): any {
    return Integration[value];
  }

}
