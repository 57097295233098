/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Demographics } from './demographics';
import { EventTriggerResultView } from './eventTriggerResultView';
import { ScheduledTaskActions } from './scheduledTaskActions';
import { TimeTriggerResultView } from './timeTriggerResultView';
import { TriggerType } from './triggerType';

export interface ScheduledTaskResultView { 
    id?: string;
    type?: TriggerType;
    name?: string;
    eventTrigger?: EventTriggerResultView;
    timeTrigger?: TimeTriggerResultView;
    demographic?: Demographics;
    demographicId?: string;
    dateFrom?: Date;
    dateTo?: Date;
    taskActions?: Array<ScheduledTaskActions>;
    updatedDate?: Date;
    createdDate?: Date;
    createdBy?: string;
    updatedBy?: string;
    isEnabled?: boolean;
    isRunning?: boolean;
    percentageRun?: number;
    runningExecutionCount?: number;
}