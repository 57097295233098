<ng-container>
  <div class="row">
    <div class="col-12 col-lg-6">
      <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

      <ng-container *ngIf="!awaitingGet">
        <mat-card>
          <mat-card-content>
            <form [formGroup]="alertForm">
              <mat-form-field class="w-100">
                <mat-label>Title</mat-label>
                <input matInput formControlName="title">
                <mat-error *ngIf="alertForm.get('title').errors?.required">Required</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Sub Title</mat-label>
                <input matInput formControlName="subTitle">
                <mat-error *ngIf="alertForm.get('subTitle').errors?.required">Required</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Message</mat-label>
                <textarea rows="5" matInput formControlName="text"></textarea>
                <mat-error *ngIf="alertForm.get('text').errors?.required">Required</mat-error>
              </mat-form-field>

              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Contains Marketing Material</span>
                    <mat-icon class=""
                      matTooltip="If this contains markeing material, it will never be distributed to customers that opted out of the marketing communication">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="marketingMaterial" formControlName="isMarketingMaterial">
                    <span *ngIf="alertForm.get('isMarketingMaterial').value">Yes</span>
                    <span *ngIf="!alertForm.get('isMarketingMaterial').value">No</span>
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <loyale-image label="Thumbnail Image" tooltip="200 x 200 pixels" [required]="true" [width]="200"
                    [inputUrl]="alertForm.get('thumbnailUrl').value"
                    (outputUrl)="alertForm.patchValue({thumbnailUrl: $event})"></loyale-image>
                  <input type="hidden" name="thumb" formControlName="thumbnailUrl" required>
                  <mat-error *ngIf="alertForm.touched && alertForm.get('thumbnailUrl')?.errors?.required">
                    Required
                  </mat-error>
                </div>
              </div>

              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <loyale-image label="Main Image" tooltip="1080 x 610 pixels" [required]="true" [width]="1080"
                    [ratio]="16 / 9" [inputUrl]="alertForm.get('imageUrl').value"
                    (outputUrl)="alertForm.patchValue({imageUrl: $event})"></loyale-image>
                  <input type="hidden" name="image" formControlName="imageUrl" required>
                  <mat-error *ngIf="alertForm.touched && alertForm.get('imageUrl')?.errors?.required">
                    Required
                  </mat-error>
                </div>
              </div>
            </form>
          </mat-card-content>

        </mat-card>

      </ng-container>
    </div>

    <div class="col-12 col-lg-6">
      <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

      <loyale-preview-post [alert]="alert" *ngIf="!awaitingGet"></loyale-preview-post>
    </div>
  </div>
</ng-container>
