/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StripePayoutsInterval } from './stripePayoutsInterval';
import { StripeWeeklyAnchor } from './stripeWeeklyAnchor';

export interface StripeAccountSettingsOptions { 
    statementDescriptor?: string;
    primaryColor?: string;
    secondaryColor?: string;
    webCheckoutSuccessUrl?: string;
    webCheckoutCancelUrl?: string;
    stripePayoutsInterval?: StripePayoutsInterval;
    stripeWeeklyAnchor?: StripeWeeklyAnchor;
    stripeMonthlyAnchor?: number;
}