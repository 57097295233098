import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

interface RedirectConfirm {
  message: string;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
}

@Component({
  selector: 'loyale-redirect-confirm',
  templateUrl: './redirect-confirm.component.html',
  styleUrls: ['./redirect-confirm.component.scss']
})
export class RedirectConfirmDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: RedirectConfirm, private dialogRef: MatDialogRef<RedirectConfirmDialogComponent>) { }

  ngOnInit(): void {
  }

}
