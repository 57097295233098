import { Component, OnInit, Input } from '@angular/core';
import { OutletResultView } from 'src/app/swagger';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'loyale-mini-outlet',
  templateUrl: './mini-outlet.component.html',
  styleUrls: ['./mini-outlet.component.scss']
})
export class MiniOutletComponent implements OnInit {
  @Input() outlet: OutletResultView = <OutletResultView>{}
  @Input() disabled = false;

  constructor(public sessionService: SessionService) { }

  ngOnInit() {
  }

}
