/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MailchimpSetupInsertView { 
    schemeIntegrationName: string;
    apiKey: string;
    name: string;
    fromEmail: string;
    fromName: string;
    language: string;
    subject: string;
    address1: string;
    company: string;
    country: string;
    city: string;
    state: string;
    zip: string;
    emailTypeOption: boolean;
    marketingPermissions: boolean;
    permissionReminder: string;
    selectedMergeFields: { [key: string]: string; };
    replaceExistingAudience?: boolean;
}