<h1 mat-dialog-title>Errors</h1>
<div mat-dialog-content>
  <ng-container *ngFor="let error of data.errors">
    <p>{{error}}</p>
  </ng-container>
</div>
<div mat-dialog-actions>
  <div class="flex-fill"></div>
  <button mat-button [mat-dialog-close]>Close</button>
</div>
