import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'loyale-demo-cta',
  templateUrl: './demo-cta.component.html',
  styleUrls: ['./demo-cta.component.scss']
})
export class DemoCtaComponent implements OnInit {
  environment = environment

  constructor(public sessionService: SessionService) { }

  ngOnInit(): void {
  }

}
