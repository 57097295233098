import { AfterViewInit, Component, ContentChild, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ScheduledTaskInsertView, ScheduledTaskService, TimeTriggerService } from '../../../swagger';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';
import { SessionService } from '../../../services/session.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutomationActionPipe } from '../../../pipes/automation-action.pipe';
import { ActionType, ActionRedirectURL } from '../action.enum';
import { ActionConnectorInterface } from '../actionConnector.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainActionInterface } from '../../main-entities/main-action.interface';

@Component({
  selector: 'loyale-shared-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('schedule') schedule;
  @ViewChild('sendNow') sendNow;

  @Input() actionType: ActionType;
  @Input() firstStepTitle: string | undefined;
  @Input() secondStepTitle: string | undefined;

  @Input() nextStepButtonTitle = 'Next step';

  @ContentChild('content')
  content: ElementRef | MainActionInterface;

  awaitingPost = false;
  isLinear = true;

  scheduledTask: ScheduledTaskInsertView = <ScheduledTaskInsertView> {};

  stepSecondStatus = '';
  stepperSelectedIndex = 0;

  choiceButtonEnable = false;

  actionDataObject: ActionConnectorInterface;
  actionRedirectURL = ActionRedirectURL;

  firstFormGroup = this.formBuilder.group({
    firstCtrl: [null, Validators.required],
  });

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    public commonService: CommonService,
    private router: Router,
    private scheduledTaskService: ScheduledTaskService,
    public sessionService: SessionService,
    private snackBar: MatSnackBar,
    private timeTriggerService: TimeTriggerService,
    private automationActionPipe: AutomationActionPipe,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.scheduledTask.taskActions = [];
    this.scheduledTask.isEnabled = true;
  }

  ngAfterViewInit() {
    // @ts-ignore
    this.content.dataUpdate.subscribe(res => {
      if (res.formValid) {
        this.firstFormGroup.patchValue({ firstCtrl: res.formValid });
      } else {
        this.firstFormGroup.patchValue({ firstCtrl: null });
      }

    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectionChange(event) {
    // @ts-ignore
    this.actionDataObject = this.content.getData();
    this.choiceButtonEnable = this.actionDataObject.formValid;
    this.stepperSelectedIndex = event.selectedIndex;
  }

  nextStep() {
    // @ts-ignore
    this.actionDataObject = this.content.getData();

    // check entity validity and if all good, move to the next step
    if (this.actionDataObject.formValid) {
      this.stepperSelectedIndex = 1;
      // old safety on case if user somehow go to step without passing all validation check
      this.choiceButtonEnable = true;
    }
  }

  setSecondStage(stageStatus: string) {
    this.stepSecondStatus = stageStatus;
  }

  save() {
    // @ts-ignore
    this.content.createNew().subscribe(res => {
      this.awaitingPost = true;
      this.router.navigate(['/' + this.actionRedirectURL[this.actionType] + '/details/' + res.id]);
    }, err => {
      this.awaitingPost = false;
      this.commonService.errorHandler(err);
    });
  }

  onTriggerChange(triggerId) {
    this.scheduledTask.triggerId = triggerId;
    this.snackBar.open('Trigger set');
  }

  onTargetChange(demographicId) {
    this.scheduledTask.demographicId = demographicId;
    this.snackBar.open('Target set');
  }

  send() {
    this.sendNow.sendForm.markAllAsTouched();
    if (this.sendNow.sendForm.valid) {
      // @ts-ignore
      this.content.createNew().subscribe(res => {
        this.awaitingPost = true;
        this.actionDataObject = { actionData: res, actionType: this.actionType, formValid: true };
        this.sendNow.createAndSendActionNow(this.actionDataObject);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });
    }

  }

  scheduleAction() {
    this.schedule.scheduleForm.markAllAsTouched();

    if (!this.scheduledTask.triggerId) {
      this.snackBar.open('Set the trigger rule');
    }
    
    if (this.schedule.scheduleForm.valid) {
      // @ts-ignore
      this.content.createNew().subscribe(res => {
        this.awaitingPost = true;
        this.actionDataObject = { actionData: res, actionType: this.actionType, formValid: true };
        this.schedule.scheduleAction(this.actionDataObject);

        // this.sendNow.createAndSendActionNow(this.actionDataObject);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });
    }
  }

  sendScheduleTaskPost(scheduledTask: ScheduledTaskInsertView) {
    this.awaitingPost = true;
    this.scheduledTaskService.apiScheduledTaskPost(this.sessionService.schemeId, scheduledTask).pipe(takeUntil(this.destroy$)
    ).subscribe(scheduleTaskResult => {
      this.awaitingPost = false;
      this.router.navigate(['/automations/details/' + scheduleTaskResult.id]);
    }, err => {
      this.awaitingPost = false;
      this.commonService.errorHandler(err);
    });
  }
}
