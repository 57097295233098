<mat-form-field class="w-100">
  <mat-label>Outlet</mat-label>
  <mat-select name="outlet" #outlet="ngModel" [(ngModel)]="outlets" [required]="required" [multiple]="multiple"
    [disabled]="nestedOutlets.length == 0" (ngModelChange)="onOutletChange()">

    <mat-option>
      <ngx-mat-select-search name="filter" #filter="ngModel" [(ngModel)]="outletFilter"
        (ngModelChange)="onFilterOutlets()" placeholderLabel="Filter" noEntriesFoundLabel="No results found">
        <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
      </ngx-mat-select-search>
    </mat-option>

    <mat-optgroup *ngFor="let nest of filteredNestedOutlets" [label]="nest.franchiseName">
      <mat-option *ngFor="let outlet of nest.outlets" [value]="outlet.id">
        {{outlet.name}}
      </mat-option>
    </mat-optgroup>
  </mat-select>
  <mat-error *ngIf="outlet?.errors?.required">Required</mat-error>
</mat-form-field>
