import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PushNotificationService } from '../../../swagger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewPushNotificationDialogComponent } from '../../action-selector-dialog/new-push-notification-dialog/new-push-notification-dialog.component';

@Component({
  selector: 'loyale-action-push-notification-selector',
  templateUrl: './action-push-notification-selector.component.html',
  styleUrls: ['./action-push-notification-selector.component.scss']
})
export class ActionPushNotificationSelectorComponent implements OnInit {

  awaitingGet = false;
  pushNotifications = [];
  filteredPushNotifications = [];
  @Input() selectedPushNotificationId = null;
  @Output() outputPushNotificationId = new EventEmitter<any>();
  @Output() removePushNotification = new EventEmitter<any>();

  pushNotificationFilter = null;

  constructor(private pushNotificationsService: PushNotificationService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getPushNotifications();
  }

  getPushNotifications() {
    this.awaitingGet = true;
    this.pushNotificationsService.apiPushNotificationGet(null, '-createdDate').subscribe(res => {
      this.awaitingGet = false;
      this.pushNotifications = res;
      this.filteredPushNotifications = this.pushNotifications;
    });
  }

  onNewPushNotification() {
    const dialogRef = this.dialog.open(NewPushNotificationDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getPushNotifications();
        this.selectedPushNotificationId = res.id;
        this.onPushNotificationChange();
      }
    });
  }

  onPushNotificationChange() {
    this.outputPushNotificationId.emit(this.selectedPushNotificationId);
  }

  onFilterPushNotifications() {
    if (!this.pushNotifications) {
      return;
    }

    let filter = this.pushNotificationFilter;
    if (!filter) {
      this.filteredPushNotifications = this.pushNotifications;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredPushNotifications = this.pushNotifications.filter(pushNotification => pushNotification.title.toLowerCase().indexOf(filter) > -1);
  }

}
