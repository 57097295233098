import { Injectable, Optional, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration, BASE_PATH } from '../swagger';

@Injectable({
  providedIn: 'root'
})
export class Upload2Service {
  protected basePath = environment.baseApiUrl;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient,
    @Optional()
    @Inject(BASE_PATH)
    basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  public uploadFile(file?: Blob) {
    const formData = new FormData();
    formData.append('file', file);

    const options = {};

    return this.httpClient.post<any>(`${this.basePath}/api/Upload`, formData, options);
  }
}
