/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ReceiveBy } from './receiveBy';
import { Scheme } from './scheme';

export interface Product { 
    id?: string;
    name?: string;
    description?: string;
    pointsValue?: number;
    imageUrl?: string;
    expiryDate?: Date;
    quantity?: number;
    receiveBy?: ReceiveBy;
    pickupNote?: string;
    schemeId?: string;
    scheme?: Scheme;
    categories?: Array<string>;
    collectionId?: string;
    couponId?: string;
    createdBy?: string;
    updatedBy?: string;
    createdDate?: Date;
    updatedDate?: Date;
}