/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BasicCustomerModel } from './basicCustomerModel';
import { SchemeStripeLinkResultView } from './schemeStripeLinkResultView';
import { StripePaymentAttemptResultView } from './stripePaymentAttemptResultView';
import { StripePaymentStatus } from './stripePaymentStatus';
import { StripeProductType } from './stripeProductType';
import { TransactionResultView } from './transactionResultView';

export interface StripePaymentResultViewById { 
    refundAmount?: number;
    id?: string;
    amount?: number;
    loyaleFeeAmount?: number;
    processingFeeAmount?: number;
    customerId?: string;
    customer?: BasicCustomerModel;
    transactionId?: string;
    transaction?: TransactionResultView;
    lastFailureMessage?: string;
    paymentIntentId?: string;
    currency?: string;
    status?: StripePaymentStatus;
    productType?: StripeProductType;
    createdBy?: string;
    updatedBy?: string;
    createdDate?: Date;
    updatedDate?: Date;
    schemeStripeLinkId?: string;
    orderId?: string;
    guest?: string;
    schemeStripeLink?: SchemeStripeLinkResultView;
    stripePaymentAttempts?: Array<StripePaymentAttemptResultView>;
}