/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LSDiscount } from './lSDiscount';
import { LSItem } from './lSItem';

export interface SaleLine { 
    saleLineID?: string;
    unitQuantity?: string;
    unitPrice?: string;
    itemID?: string;
    item?: LSItem;
    discount?: LSDiscount;
}