<mat-form-field [ngClass]="{'w-100': fullWidth}" [formGroup]="timeForm">
  <mat-label>{{label}}</mat-label>
  <input matInput [owlDateTime]="picker" name="dr" (ngModelChange)="onTimeChange()" [required]="required"
    [disabled]="disabled" [owlDateTimeTrigger]="picker" readonly formControlName="timeControl">
  <button type="button" mat-icon-button matSuffix (click)="onClear()" *ngIf="clearable && time != null">
    <mat-icon>cancel</mat-icon>
  </button>
  <button type="button" mat-icon-button matPrefix [owlDateTimeTrigger]="picker">
    <mat-icon>schedule</mat-icon>
  </button>
  <mat-error *ngIf="timeForm.get('timeControl')?.errors?.required">Required</mat-error>
  <owl-date-time #picker pickerType="timer" [stepMinute]="stepMinute" hour12Timer="true"></owl-date-time>
</mat-form-field>
