import { Component, OnInit, ViewChild } from '@angular/core';
import 'quill-emoji';
import 'quill-mention';
import { EmailService } from 'src/app/swagger';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from 'src/app/services/session.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'loyale-new-email-dialog',
  templateUrl: './new-email-dialog.component.html',
  styleUrls: ['./new-email-dialog.component.scss']
})
export class NewEmailDialogComponent implements OnInit {
  @ViewChild('emailComponent') emailComponent;

  awaitingPost = false;

  constructor(private translate: TranslateService, private emailService: EmailService, private dialog: MatDialog, public sessionService: SessionService, public commonService: CommonService, private snackBar: MatSnackBar, private dialogRef: MatDialogRef<NewEmailDialogComponent>) { }

  ngOnInit() {
  }

  onSubmit() {
    if (this.emailComponent.emailForm.valid) {
      this.awaitingPost = true;

      this.emailComponent.createNew().subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });
    }
  }
}
