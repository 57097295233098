import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService, CouponService, FeedbackService, PushNotificationInsertView, PushNotificationService } from 'src/app/swagger';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'loyale-new-push-notification-dialog',
  templateUrl: './new-push-notification-dialog.component.html',
  styleUrls: ['./new-push-notification-dialog.component.scss']
})
export class NewPushNotificationDialogComponent implements OnInit {
  @ViewChild('pushNotificationComponent') pushNotificationComponent;

  awaitingPost = false;

  constructor(private translate: TranslateService, private feedbackService: FeedbackService, public commonService: CommonService, private couponService: CouponService, private dialogRef: MatDialogRef<NewPushNotificationDialogComponent>, private sessionService: SessionService, private snackBar: MatSnackBar, private alertService: AlertService, private pushNotificationService: PushNotificationService) { }

  ngOnInit() {
  }

  onSubmit() {
    this.pushNotificationComponent.changeEvent();

    if (this.pushNotificationComponent.pushNotificationForm.valid) {
      this.awaitingPost = true;

      this.pushNotificationComponent.createNew().subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });
    }
  }

}
