/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Enabled } from '../model/enabled';
import { ShireburnSettingsView } from '../model/shireburnSettingsView';
import { ShireburnSetupRequestView } from '../model/shireburnSetupRequestView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ShireburnService {

    protected basePath = 'https://api.staging.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Checks if the integration is enabled
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShireburnEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShireburnEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShireburnEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShireburnEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShireburnEnabledSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shireburn/Enabled/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Integration enabled status
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShireburnEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShireburnEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShireburnEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShireburnEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShireburnEnabledSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shireburn/Enabled/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param customerId 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param dateFrom 
     * @param dateTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShireburnImportCustomerPastTransactionsSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, dateFrom?: Date, dateTo?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShireburnImportCustomerPastTransactionsSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, dateFrom?: Date, dateTo?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShireburnImportCustomerPastTransactionsSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, dateFrom?: Date, dateTo?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShireburnImportCustomerPastTransactionsSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, dateFrom?: Date, dateTo?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiShireburnImportCustomerPastTransactionsSchemeIntegrationIdCustomerIdGet.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShireburnImportCustomerPastTransactionsSchemeIntegrationIdCustomerIdGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('dateFrom', <any>dateFrom.toISOString());
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('dateTo', <any>dateTo.toISOString());
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shireburn/ImportCustomerPastTransactions/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(customerId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Retail setup Settings
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShireburnSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShireburnSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShireburnSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShireburnSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShireburnSettingsSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shireburn/Settings/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Retail setup settings
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShireburnSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShireburnSettingsView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShireburnSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShireburnSettingsView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShireburnSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShireburnSettingsView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShireburnSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShireburnSettingsView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShireburnSettingsSchemeIntegrationIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Shireburn/Settings/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a New Shireburn SchemIntegration 
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShireburnSetupPost(body?: ShireburnSetupRequestView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShireburnSetupPost(body?: ShireburnSetupRequestView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShireburnSetupPost(body?: ShireburnSetupRequestView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShireburnSetupPost(body?: ShireburnSetupRequestView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shireburn/Setup`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync&#x27;s a customer with shireburn
     * 
     * @param customerId 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShireburnSyncCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiShireburnSyncCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiShireburnSyncCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiShireburnSyncCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiShireburnSyncCustomerSchemeIntegrationIdCustomerIdGet.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShireburnSyncCustomerSchemeIntegrationIdCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/Shireburn/SyncCustomer/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync&#x27;s all scheme customers with shireburn
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShireburnSyncSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShireburnSyncSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShireburnSyncSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShireburnSyncSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShireburnSyncSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shireburn/Sync/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync&#x27;s all scheme customers with shireburn
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShireburnUpdateAllCustomersSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShireburnUpdateAllCustomersSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShireburnUpdateAllCustomersSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShireburnUpdateAllCustomersSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShireburnUpdateAllCustomersSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shireburn/UpdateAllCustomers/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync&#x27;s a customer with shireburn
     * 
     * @param customerId 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShireburnUpdateCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiShireburnUpdateCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiShireburnUpdateCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiShireburnUpdateCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiShireburnUpdateCustomerSchemeIntegrationIdCustomerIdGet.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShireburnUpdateCustomerSchemeIntegrationIdCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/Shireburn/UpdateCustomer/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
