import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loyale-micro-gift-card',
  templateUrl: './micro-gift-card.component.html',
  styleUrls: ['./micro-gift-card.component.scss']
})
export class MicroGiftCardComponent implements OnInit {
  outlet: any;

  constructor() { }

  ngOnInit() {
  }

}
