import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GiftCardService } from '../../../swagger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../services/session.service';
import { NewGiftCardDialogComponent } from '../../action-selector-dialog/new-giftcard-dialog/new-giftcard-dialog.component';

@Component({
  selector: 'loyale-action-giftcard-selector',
  templateUrl: './action-giftcard-selector.component.html',
  styleUrls: ['./action-giftcard-selector.component.scss']
})
export class ActionGiftcardSelectorComponent implements OnInit {

  awaitingGet = false;
  giftCards = [];
  filteredGiftCards = [];
  @Input() selectedGiftCardId = null;
  @Output() outputGiftCardId = new EventEmitter<any>();
  @Output() removeGiftCard = new EventEmitter<any>();

  giftCardFilter = null;

  constructor(private giftCardService: GiftCardService, private dialog: MatDialog, private snackBar: MatSnackBar, public sessionService: SessionService) { }

  ngOnInit() {
    this.getGiftCards();
  }

  getGiftCards() {
    this.awaitingGet = true;
    this.giftCardService.apiGiftCardGet(this.sessionService.schemeId, null, '-createdDate').subscribe(res => {
      this.awaitingGet = false;
      this.giftCards = res;
      this.filteredGiftCards = this.giftCards;
    });
  }

  onNewGiftCard() {
    const dialogRef = this.dialog.open(NewGiftCardDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getGiftCards();
        this.selectedGiftCardId = res.id;
        this.onGiftCardChange();
      }
    });
  }

  onGiftCardChange() {
    this.outputGiftCardId.emit(this.selectedGiftCardId);
  }

  onFilterGiftCards() {
    if (!this.giftCards) {
      return;
    }

    let filter = this.giftCardFilter;
    if (!filter) {
      this.filteredGiftCards = this.giftCards;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredGiftCards = this.giftCards.filter(giftCard => giftCard.name.toLowerCase().indexOf(filter) > -1);
  }

}
