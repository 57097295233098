/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdditionalCustomerFields } from './additionalCustomerFields';
import { AlertLinked } from './alertLinked';
import { CouponsLinked } from './couponsLinked';
import { CustomerDevices } from './customerDevices';
import { CustomerLevel } from './customerLevel';
import { CustomerPassbooks } from './customerPassbooks';
import { CustomerScheme } from './customerScheme';
import { CustomerStatusLog } from './customerStatusLog';
import { FeedbackLinked } from './feedbackLinked';
import { Gender } from './gender';
import { PointBalance } from './pointBalance';
import { TagLinked } from './tagLinked';

export interface Customer { 
    readonly name?: string;
    addressLine1?: string;
    addressLine2?: string;
    town?: string;
    state?: string;
    postCode?: string;
    country?: string;
    dob?: string;
    gender: Gender;
    areaCode: string;
    mobileNumber: string;
    barCode?: string;
    lastTransactionDate?: Date;
    isPartial?: boolean;
    isGuest?: boolean;
    onHold?: boolean;
    profileImageUrl?: string;
    customerLevels?: Array<CustomerLevel>;
    alertsLinked?: Array<AlertLinked>;
    tagsLinked?: Array<TagLinked>;
    pointBalances?: Array<PointBalance>;
    couponsLinked?: Array<CouponsLinked>;
    feedbacksLinked?: Array<FeedbackLinked>;
    customerStatusLogs?: Array<CustomerStatusLog>;
    additionalCustomerFields?: Array<AdditionalCustomerFields>;
    customerPassbooks?: Array<CustomerPassbooks>;
    customerDevices?: Array<CustomerDevices>;
    customerSchemes?: Array<CustomerScheme>;
    isChild?: boolean;
    deletedBy?: string;
    deletedDate?: Date;
    hasWalletAccount?: boolean;
    firstName: string;
    lastName: string;
    lastConnectedDate?: Date;
    createdDate?: Date;
    updatedDate?: Date;
    signUpPlatform?: string;
    deleted?: boolean;
    externalRefId?: string;
    createdBy?: string;
    updatedBy?: string;
    recoveryCode?: string;
    lastPasswordChangeDate?: Date;
    marketingSub?: boolean;
    id?: string;
    userName?: string;
    normalizedUserName?: string;
    email?: string;
    normalizedEmail?: string;
    emailConfirmed?: boolean;
    passwordHash?: string;
    securityStamp?: string;
    concurrencyStamp?: string;
    phoneNumber?: string;
    phoneNumberConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    lockoutEnd?: Date;
    lockoutEnabled?: boolean;
    accessFailedCount?: number;
}