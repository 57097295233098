import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertInsertView, AlertResultView, AlertService } from '../../../swagger';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActionConnectorInterface } from '../../stepper/actionConnector.interface';
import { ActionType } from '../../stepper/action.enum';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../../services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../services/common.service';
import { MainActionInterface } from '../main-action.interface';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'loyale-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, MainActionInterface {
  awaitingPost = false;
  awaitingGet = false;

  alert = null;
  alertCopy = null;

  @Input() alertId = null;
  displayAlertName = null;

  alertForm: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl('', [Validators.required]),
    subTitle: new UntypedFormControl('', Validators.required),
    text: new UntypedFormControl('', Validators.required),
    isMarketingMaterial: new UntypedFormControl(false, []),
    imageUrl: new UntypedFormControl(null, Validators.required),
    thumbnailUrl: new UntypedFormControl(null, Validators.required),

  });

  @Output() entityChanges: EventEmitter<boolean> = new EventEmitter(false);
  @Output() alertData: EventEmitter<any> = new EventEmitter(this.alertForm.value);

  @Output() changeFormData: EventEmitter<any> = new EventEmitter();

  public readonly dataUpdate: BehaviorSubject<ActionConnectorInterface> = new BehaviorSubject<ActionConnectorInterface>({
    actionType: ActionType.POST,
    actionData: this.alertForm.value as AlertInsertView,
    formValid: this.alertForm.valid
  });

  editorOptions = {
    emoji: true
  };

  constructor(private translate: TranslateService, public sessionService: SessionService, private snackBar: MatSnackBar, private route: ActivatedRoute, public commonService: CommonService, private dialog: MatDialog, private router: Router, private alertService: AlertService) {
  }

  ngOnInit() {

    if (this.alertId) {
      this.getAlert();
    }

    this.alertForm.valueChanges.subscribe(changes => {
      this.dataUpdate.next(
        {
          actionType: ActionType.POST,
          actionData: this.alertForm.value,
          formValid: this.alertForm.valid
        }
      );

      if (this.alertForm.touched) {
        this.alertData.next(changes);
      }

      this.alert = { ...this.alert, ...this.alertForm.value };
      this.entityChanges.next(JSON.stringify(this.alertCopy) !== JSON.stringify(this.alert));

    });
  }

  public getData(): ActionConnectorInterface {
    this.alertForm.markAllAsTouched();

    return {
      actionType: ActionType.POST,
      actionData: this.alertForm.value as AlertInsertView,
      formValid: this.alertForm.valid
    };
  }

  changeEvent(form: any) {
    if (form.valid) {
      this.dataUpdate.next({
        actionType: ActionType.POST,
        actionData: this.alertForm.value as AlertInsertView,
        formValid: this.alertForm.valid
      });
    }
  }

  createNew(): Observable<any> {
    if (this.alertForm.valid) {
      this.awaitingPost = true;
      return this.alertService.apiAlertPost(this.sessionService.schemeId, this.alert).pipe(tap(res => {
        this.awaitingPost = false;

        this.dataUpdate.next(
          {
            actionType: ActionType.POST,
            actionData: res,
            formValid: this.alertForm.valid
          }
        );
      }));
    } else {
      return undefined;
    }

  }

  update(): Observable<any> {
    if (this.alertForm.valid) {
      this.awaitingPost = true;

      return this.alertService.apiAlertPut(this.alert, this.sessionService.schemeId).pipe(
        tap(res => {
          this.awaitingPost = false;

          this.dataUpdate.next(
            {
              actionType: ActionType.POST,
              actionData: res,
              formValid: this.alertForm.valid
            }
          );

          this.alert = res;
          this.alertCopy = res;

          this.alertForm.patchValue({ ...res });

        })
      );
    } else {
      return null;
    }
  }

  getAlert() {
    this.awaitingGet = true;
    this.alertService.apiAlertAlertIdGet(this.alertId).subscribe(res => {
      this.awaitingGet = false;
      this.alert = res;
      this.alertCopy = res;
      this.alertForm.patchValue({ ...res });
    }, err => {
      this.awaitingGet = false;
      this.commonService.errorHandler(err);
    });
  }

}
