<h1 mat-dialog-title>New SMS</h1>
<loyale-sms #smsComponent mat-dialog-content>
</loyale-sms>

<div mat-dialog-actions>
    <div class="flex-fill"></div>
    <button mat-flat-button [mat-dialog-close]>Close</button>
    <button mat-flat-button color="primary" [disabled]="awaitingPost" (click)="createSms()">Save</button>
</div>

