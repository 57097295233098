/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FranchiseResultView } from './franchiseResultView';
import { Rounding } from './rounding';
import { SchemeStatus } from './schemeStatus';

export interface CustomerSchemeResultView { 
    id?: string;
    name?: string;
    currency?: string;
    description?: string;
    website?: string;
    color?: string;
    imageUrl?: string;
    pointRedemptionPerCurrency?: number;
    updatedDate?: Date;
    createdDate?: Date;
    inLoyale?: boolean;
    hidden?: boolean;
    rounding?: Rounding;
    billingStatus?: SchemeStatus;
    franchises?: Array<FranchiseResultView>;
    accountId?: number;
}