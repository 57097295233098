/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ParameterCompareType } from './parameterCompareType';
import { ParameterListRowResultView } from './parameterListRowResultView';

export interface BlueprintPropertyResultView { 
    id?: string;
    name?: string;
    compareType?: ParameterCompareType;
    listValues?: Array<ParameterListRowResultView>;
}