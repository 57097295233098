import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loyale-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() mode = 'indeterminate';
  @Input() color = 'accent';
  @Input() diameter = 100;
  @Input() align = 'center';

  constructor() { }

  ngOnInit() {
  }

}
