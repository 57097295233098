import { Component, ContentChild, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActionType } from '../action.enum';
import { ScheduledTaskInsertView, ScheduledTaskService } from '../../../swagger';
import { ActionConnectorInterface } from '../actionConnector.interface';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';
import { SessionService } from '../../../services/session.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';

export interface ScheduleDialogData {
  actionDataObject: ActionConnectorInterface;
}

@Component({
  selector: 'loyale-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.scss']
})
export class ScheduleDialogComponent implements OnInit, OnDestroy {
  @ViewChild('schedule') schedule;
  @ViewChild('sendNow') sendNow;

  @Input() actionType: ActionType;
  @Input() firstStepTitle: string | undefined;
  @Input() secondStepTitle: string | undefined;

  @Input() nextStepButtonTitle = 'Next step';

  @ContentChild('content')
  content: ElementRef | undefined;

  awaitingPost = false;

  scheduledTask: ScheduledTaskInsertView = <ScheduledTaskInsertView> {};

  scheduleStatus = '';

  choiceButtonEnable = false;

  actionDataObject: ActionConnectorInterface;

  private destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    public commonService: CommonService,
    private router: Router,
    private scheduledTaskService: ScheduledTaskService,
    public sessionService: SessionService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<ScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ScheduleDialogData) { }

  ngOnInit() {
    this.scheduledTask.taskActions = [];
    this.scheduledTask.isEnabled = true;

    if (this.dialogData) {
      this.actionType = this.dialogData.actionDataObject.actionType;
      this.actionDataObject = this.dialogData.actionDataObject;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setSecondStage(stageStatus: string) {
    this.scheduleStatus = stageStatus;
  }

  onTriggerChange(triggerId) {
    this.scheduledTask.triggerId = triggerId;
    this.snackBar.open('Trigger set');
  }

  onTargetChange(demographicId) {
    this.scheduledTask.demographicId = demographicId;
    this.snackBar.open('Target set');
  }

  send() {
    this.sendNow.sendForm.markAllAsTouched();
    if (this.sendNow.sendForm.valid) {
      this.sendNow.createAndSendActionNow(this.actionDataObject);
    }
  }

  scheduleAction() {
    this.schedule.scheduleForm.markAllAsTouched();
    if (this.schedule.scheduleForm.valid) {
      this.schedule.scheduleAction(this.actionDataObject);
    }
  }

  sendScheduleTaskPost(scheduledTask: ScheduledTaskInsertView) {
    console.log('sendScheduleTaskPost');
    this.awaitingPost = true;
    this.scheduledTaskService.apiScheduledTaskPost(this.sessionService.schemeId, scheduledTask).pipe(takeUntil(this.destroy$)
    ).subscribe(scheduleTaskResult => {
      this.awaitingPost = false;
      this.dialogRef.close();
      this.router.navigate(['/automations/details/' + scheduleTaskResult.id]);
    }, err => {
      this.awaitingPost = false;
      this.commonService.errorHandler(err);
    });
  }

}
