import { Pipe, PipeTransform } from '@angular/core';

enum Action {
  'Push Notification' = 0,
  'Post',
  'Email',
  'Coupon',
  'Product',
  'Points Modifier',
  'Points Multiplier',
  'Punch Card',
  'Gift Card',
  'Feedback Form',
  'Tag',
  'SMS'
}

@Pipe({
  name: 'automationAction'
})
export class AutomationActionPipe implements PipeTransform {

  transform(value: any): any {
    return Action[value];
  }

}
