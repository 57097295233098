/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Scheme } from './scheme';

export interface SchemeStripeLink { 
    id?: string;
    stripeAccountId?: string;
    scheme?: Scheme;
    schemeId?: string;
    onboardingComplete?: boolean;
    requirementsComplete?: boolean;
    webCheckoutSuccessUrl?: string;
    webCheckoutCancelUrl?: string;
    createdBy?: string;
    updatedBy?: string;
    createdDate?: Date;
    updatedDate?: Date;
}