export function getTemplateData(): any {

  return templateData;
}

export function templateData(headerUrl: string, footerUrl: string): any {
  return {
    'type': 'page',
    'data': {
      'value': {
        'breakpoint': '480px',
        'font-size': '16px',
        'font-weight': '400',
        'line-height': '1.7',
        'responsive': true,
        'font-family': 'Arial',
        'text-color': '#000000'
      }
    },
    'attributes': {
      'background-color': '#F4F4F4',
      'width': '600px',
      'css-class': 'mjml-body',

    },
    'children': [
      {
        'type': 'section',
        'data': {
          'value': {
            'noWrap': false
          }
        },
        'attributes': {
          'padding': '0px 0px 0px 0px',
          'border': 'none',
          'direction': 'ltr',
          'text-align': 'center',
          'background-repeat': 'repeat',
          'background-size': 'auto',
          'background-position': 'top center',
          'background-color': '#ffffff'
        },
        'children': [
          {
            'type': 'column',
            'data': {
              'value': {}
            },
            'attributes': {
              'border': 'none',
              'vertical-align': 'top',
              'padding': '0px 0px 0px 0px'
            },
            'children': [
              {
                'type': 'image',
                'data': {
                  'value': {}
                },
                'attributes': {
                  'align': 'center',
                  'height': 'auto',
                  'src': headerUrl,
                  'target': '_blank',
                  'width': '600px',
                  'padding': '0px 0px 0px 0px'
                },
                'children': []
              }
            ]
          }
        ]
      },
      {
        'type': 'advanced_section',
        'data': {
          'value': {
            'noWrap': false
          }
        },
        'attributes': {
          'padding': '0px 0px 0px 0px',
          'background-repeat': 'repeat',
          'background-size': 'auto',
          'background-position': 'top center',
          'border': 'none',
          'direction': 'ltr',
          'text-align': 'center'
        },
        'children': [
          {
            'type': 'advanced_column',
            'attributes': {
              'width': [
                '25%',
                '25%',
                '25%',
                '25%'
              ]
            },
            'data': {
              'value': {}
            },
            'children': [

            ]
          }
        ]
      },
      {
        'type': 'section',
        'data': {
          'value': {
            'noWrap': false
          }
        },
        'attributes': {
          'background-repeat': 'repeat',
          'background-size': 'auto',
          'background-position': 'top center',
          'border': 'none',
          'direction': 'ltr',
          'text-align': 'center',
          'background-color': '#ffffff',
          'padding': '0px 0px 0px 0px'
        },
        'children': [
          {
            'type': 'column',
            'data': {
              'value': {}
            },
            'attributes': {
              'border': 'none',
              'vertical-align': 'top',
              'padding': '0px 0px 0px 0px'
            },
            'children': [
              {
                'type': 'image',
                'data': {
                  'value': {}
                },
                'attributes': {
                  'align': 'center',
                  'height': 'auto',
                  'src': footerUrl,
                  'target': '_blank',
                  'width': '600px',
                  'padding': '0px 0px 0px 0px'
                },
                'children': []
              }
            ]
          }
        ]
      }
    ]
  };
};
