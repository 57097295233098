/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ShopifyCustomerView { 
    id?: number;
    email?: string;
    acceptsMarketing?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    firstName?: string;
    lastName?: string;
    ordersCount?: number;
    state?: string;
    totalSpent?: string;
    lastOrderId?: number;
    note?: string;
    verifiedEmail?: boolean;
    multipassIdentifier?: any;
    taxExempt?: boolean;
    phone?: any;
    tags?: string;
    lastOrderName?: any;
    currency?: string;
    addresses?: Array<any>;
    acceptsMarketingUpdatedAt?: any;
    marketingOptInLevel?: any;
    adminGraphqlApiId?: string;
}