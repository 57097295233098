import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplexDirective } from './complex.directive';
import { PostcodeDirective } from './postcode.directive';
import { ContainsDirective } from './contains.directive';
import { VatnumberDirective } from './vatnumber.directive';
import { DynamicFieldSharedDirective } from './dynamic-field.directive';
import { EqualToDirective } from './equalTo.directive';
import { UrlDirective } from './url.directive';

@NgModule({
  declarations: [EqualToDirective, ComplexDirective, DynamicFieldSharedDirective, PostcodeDirective, ContainsDirective, VatnumberDirective, UrlDirective],
  imports: [
    CommonModule
  ],
  exports: [EqualToDirective, ComplexDirective, DynamicFieldSharedDirective, PostcodeDirective, ContainsDirective, VatnumberDirective, UrlDirective]
})
export class DirectivesModule { }
