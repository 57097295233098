import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'loyale-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() page = null;
  @Input() itemsPerPage = null;
  @Input() totalPages = null;
  @Output() changePage = new EventEmitter();
  @Output() changeItemsPerPage = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
