/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BasicCustomerModel } from './basicCustomerModel';
import { CouponResultView } from './couponResultView';

export interface CouponsLinkedResultView { 
    coupon?: CouponResultView;
    id?: string;
    customerId?: string;
    usesLeft?: number;
    favorite?: boolean;
    barCode?: string;
    rewardId?: string;
    createdDate?: Date;
    updatedDate?: Date;
    couponId?: string;
    from?: Date;
    to?: Date;
    isUsed?: boolean;
    createdBy?: string;
    updatedBy?: string;
    platform?: string;
    customer?: BasicCustomerModel;
    readonly isValid?: boolean;
}