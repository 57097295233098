import { Pipe, PipeTransform } from '@angular/core';

enum Status {
  'New' = 0,
  'Opened',
  'Dismissed'
}

@Pipe({
  name: 'alertStatus'
})
export class AlertStatusPipe implements PipeTransform {

  transform(value: any): string {
    return Status[value];
  }

}
