/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BasicCustomerModel } from './basicCustomerModel';
import { GiftCard } from './giftCard';

export interface GiftCardLinkedResultView { 
    customer?: BasicCustomerModel;
    purchasingCustomer?: BasicCustomerModel;
    readonly isValid?: boolean;
    id?: string;
    giftCard?: GiftCard;
    giftCardId?: string;
    customerId?: string;
    barCode?: string;
    toName?: string;
    fromName?: string;
    message?: string;
    favorite?: boolean;
    from?: Date;
    to?: Date;
    originalValue?: number;
    currentValue?: number;
    batchId?: string;
    createdBy?: string;
    updatedBy?: string;
    isEnabled?: boolean;
    comments?: string;
    enabledBy?: string;
    enabledDate?: Date;
    purchasingCustomerId?: string;
    createdDate?: Date;
    updatedDate?: Date;
}