/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CouponOutlet } from './couponOutlet';
import { CouponsLinked } from './couponsLinked';
import { CouponsUsage } from './couponsUsage';
import { DiscountType } from './discountType';

export interface Coupon { 
    id?: string;
    name?: string;
    description?: string;
    valid?: boolean;
    initialNumberOfUses?: number;
    schemeId?: string;
    from?: Date;
    to?: Date;
    until?: Date;
    imageUrl?: string;
    couponOutlets?: Array<CouponOutlet>;
    externalReference?: string;
    platform?: string;
    couponsUsage?: Array<CouponsUsage>;
    couponsLinked?: Array<CouponsLinked>;
    createdBy?: string;
    updatedBy?: string;
    isDynamic?: boolean;
    dynamicDays?: number;
    discountType?: DiscountType;
    discountAmount?: number;
    notify?: boolean;
    notificationTime?: string;
    daysBeforeExpire?: number;
    pushNotificationId?: string;
    maxUses?: number;
    barcodePrefix?: string;
    terms?: string;
    validAfter?: number;
    validFor?: number;
    createdDate?: Date;
    updatedDate?: Date;
}