import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../../services/session.service';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(sessionService: SessionService) {
  }

  transform(value: any, pattern: string = 'E'): any {
    return formatDate(value, pattern, 'en');
  }
}
