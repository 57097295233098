/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryResultView } from './categoryResultView';

export interface OutletFranchiseResultView { 
    id?: string;
    name?: string;
    description?: string;
    schemeId?: string;
    imageUrl?: string;
    updatedDate?: Date;
    createdDate?: Date;
    hidden?: boolean;
    isDefault?: boolean;
    createdBy?: string;
    updatedBy?: string;
    categoriesIds?: Array<string>;
    categories?: Array<CategoryResultView>;
}