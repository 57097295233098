/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SchemeResultView } from './schemeResultView';

export interface AdminResultView { 
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    roles?: { [key: string]: string; };
    schemes?: Array<SchemeResultView>;
    updatedDate?: Date;
    createdDate?: Date;
    lastConnectedDate?: Date;
    twoFactorEnabled?: boolean;
    marketingSub?: boolean;
}