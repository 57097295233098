import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../../material/material.module';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { FilteredTransactionsComponent } from './filtered-transactions/filtered-transactions.component';
import { GroupedTransactionsComponent } from './grouped-transactions/grouped-transactions.component';
import { PurchaseOutletComponent } from './purchase-outlet/purchase-outlet.component';
import { AudienceComponent } from './audience/audience.component';
import { AgeingChartComponent } from './ageing-chart/ageing-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  declarations: [FilteredTransactionsComponent, GroupedTransactionsComponent, PurchaseOutletComponent, AudienceComponent, AgeingChartComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    FormsModule,
    NgxChartsModule
  ],
  exports: [
    FilteredTransactionsComponent,
    GroupedTransactionsComponent,
    PurchaseOutletComponent,
    AudienceComponent,
    AgeingChartComponent
  ]
})
export class ChartsModule { }
