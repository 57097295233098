import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'loyale-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
  @Input() label = 'Date Time';
  @Input() fullWidth = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() clearable = false;
  @Input() dateTime = null;
  @Input() returnUtc = true;
  @Input() minDate = null;
  @Input() maxDate = null;
  @Output() onChange = new EventEmitter<any>();

  @Input()
  public set markAsTouched(touched: boolean) {
    if (touched === true) {
      this.dateTimeForm.markAllAsTouched();
    }
  }

  dateTimeForm: UntypedFormGroup = new UntypedFormGroup({
    dateTimeControl: new UntypedFormControl('', [Validators.required])
  });

  constructor() { }

  ngOnInit() {
    if (this.dateTime !== null) {
      this.dateTimeForm.patchValue({
        dateTimeControl: this.dateTime
      });
    }

    this.dateTimeForm.valueChanges.subscribe(res => {

      this.dateTime = res.dateTimeControl;
      this.onDateTimeChange();
    });
  }

  onClear() {
    this.dateTimeForm.patchValue({
      dateTimeControl: null
    });
    this.dateTime = null;

    this.onChange.emit(this.dateTime);
  }

  onDateTimeChange() {
    if (this.dateTime != null) {
      if (this.returnUtc) {
        this.dateTime = moment(this.dateTime).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      } else {
        this.dateTime = moment(this.dateTime).format('YYYY-MM-DDTHH:mm:ssZ');
      }
    }
    this.onChange.emit(this.dateTime);
  }
}
