import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { OutletService, TransactionResultView } from 'src/app/swagger';
import { SessionService } from 'src/app/services/session.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'loyale-mini-transaction',
  templateUrl: './mini-transaction.component.html',
  styleUrls: ['./mini-transaction.component.scss']
})
export class MiniTransactionComponent implements OnInit {
  @Input() transaction: TransactionResultView = <TransactionResultView>{};
  @Input() disabled = false;
  @Output() onRedirect = new EventEmitter<any>();

  constructor(public sessionService: SessionService, public commonService: CommonService, public outletService: OutletService) { }

  ngOnInit() {
    this.getOutlet();
  }

  getOutlet() {
    this.outletService.apiOutletIdGet(this.transaction.outletId).subscribe(res => {
      this.transaction.outlet = res;
    })
  }

}
