/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Variant { 
    id?: number;
    productId?: number;
    title?: string;
    price?: number;
    sku?: string;
    position?: number;
    inventoryPolicy?: string;
    compareAtPrice?: number;
    fulfillmentService?: string;
    inventoryManagement?: string;
    option1?: string;
    option2?: any;
    option3?: any;
    createdAt?: any;
    updatedAt?: any;
    taxable?: boolean;
    barcode?: any;
    grams?: number;
    imageId?: any;
    weight?: number;
    weightUnit?: string;
    inventoryItemId?: any;
    inventoryQuantity?: number;
    oldInventoryQuantity?: number;
    requiresShipping?: boolean;
    adminGraphqlApiId?: string;
}