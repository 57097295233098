import { Component, OnInit } from '@angular/core';
import { FeedbackCreationInsertView, FeedbackQuestionInsertView, FeedbackService } from '../../../swagger';
import { BehaviorSubject } from 'rxjs';
import { ActionConnectorInterface } from '../../stepper/actionConnector.interface';
import { ActionType } from '../../stepper/action.enum';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { SessionService } from '../../../services/session.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'loyale-new-feedback-dialog',
  templateUrl: './new-feedback-dialog.component.html',
  styleUrls: ['./new-feedback-dialog.component.scss']
})
export class NewFeedbackDialogComponent implements OnInit {

  formTitle: string = '';
  formDescription: string = '';
  questionList = [];
  questions: FeedbackQuestionInsertView[] = [];

  feedBackForm: UntypedFormGroup = this.formBuilder.group({
    title: ['', Validators.required],
    description: ['', Validators.required],
  });

  public readonly dataUpdate: BehaviorSubject<ActionConnectorInterface> = new BehaviorSubject<ActionConnectorInterface>({
    actionType: ActionType.FEEDBACK_FORM,
    actionData: {
      name: this.formTitle,
      description: this.formDescription,
      questions: this.questions,
    },
    formValid: false
  });

  constructor(private sessionService: SessionService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              public commonService: CommonService,
              private formBuilder: UntypedFormBuilder,
              private feedbackService: FeedbackService,
              private dialogRef: MatDialogRef<NewFeedbackDialogComponent>) { }

  ngOnInit() {
  }

  // get data directly from parent stepper component
  public getData(): ActionConnectorInterface {
    this.feedBackForm.markAllAsTouched();

    return {
      actionType: ActionType.FEEDBACK_FORM,
      actionData: {
        name: this.formTitle,
        description: this.formDescription,
        questions: this.questions.filter(element => element !== null),
      },
      formValid: (this.feedBackForm.valid && this.questions.length !== 0)
    };
  }

  addQuestion(): void {
    this.questionList.push(this.questionList.length);
    this.questions.push(null);
  }

  updateQuestionData(index: number, event: any) {
    const question: FeedbackQuestionInsertView = { ...event, order: index };
    this.questions[index] = question;
  }

  deleteQuestionForm(index: number) {
    this.questionList.splice(index, 1);
    this.questions.splice(index, 1);
  }

  duplicateQuestionForm(event: any) {
    console.log(event);
    this.questionList.push(this.questionList.length);
    this.questions.push({ ...event, order: this.questions.length, feedbackAnswers: event.answers });
  }

  createNewFeedback() {
    if (this.feedBackForm.valid && this.questions.length !== 0) {
      this.feedbackService.apiFeedbackPost({
        name: this.formTitle,
        description: this.formDescription,
        questions: this.questions.filter(element => element !== null),
      }, this.sessionService.schemeId).pipe(take(1)).subscribe(res => {
        this.snackBar.open(this.translate.instant('The Feedback Form Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.commonService.errorHandler(err);
      });
    }

  }

}
