<div [formGroup]="dateTimeForm">
  <!-- disabled doesnt work/look correct on this custom component -->
  <!-- so instead if disabled property is passed, we apply a class instead -->
  <mat-form-field [ngClass]="{'w-100': fullWidth, 'disabled': disabled}">
    <mat-label>{{label}}</mat-label>
    <input matInput [owlDateTime]="picker" formControlName="dateTimeControl" [required]="required"
      [owlDateTimeTrigger]="picker" [min]="minDate" [max]="maxDate" readonly>
    <button type="button" mat-icon-button matSuffix (click)="onClear()" *ngIf="clearable && dateTime != null">
      <mat-icon>cancel</mat-icon>
    </button>
    <button type="button" mat-icon-button matPrefix [owlDateTimeTrigger]="picker">
      <mat-icon>event</mat-icon>
    </button>
    <mat-error>Required</mat-error>
    <owl-date-time #picker pickerType="both" hour12Timer="true"></owl-date-time>
  </mat-form-field>
</div>
