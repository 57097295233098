import { PushNotificationResultView } from './../../swagger/model/pushNotificationResultView';
import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'loyale-preview-notification',
  templateUrl: './preview-notification.component.html',
  styleUrls: ['./preview-notification.component.scss']
})
export class PreviewNotificationComponent implements OnInit {
  @Input() dialogMode = false;
  @Input() pushNotification: PushNotificationResultView = <PushNotificationResultView>{};

  constructor(public sessionService: SessionService) { }

  ngOnInit() {
  }

}
