/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Scheme } from './scheme';
import { SchemeTerm } from './schemeTerm';

export interface PaymentsLog { 
    id?: string;
    schemeId?: string;
    scheme?: Scheme;
    schemeTermId?: string;
    schemeTerm?: SchemeTerm;
    billingStatus?: string;
    createdDate?: Date;
    updatedDate?: Date;
}