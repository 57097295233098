import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../../../swagger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewAlertDialogComponent } from '../../action-selector-dialog/new-alert-dialog/new-alert-dialog.component';

@Component({
  selector: 'loyale-action-alert-selector',
  templateUrl: './action-alert-selector.component.html',
  styleUrls: ['./action-alert-selector.component.scss']
})
export class ActionAlertSelectorComponent implements OnInit {

  awaitingGet = false;
  alerts = [];
  filteredAlerts = [];
  @Input() selectedAlertId = null;
  @Output() outputAlertId = new EventEmitter<any>();
  @Output() removeAlert = new EventEmitter<any>();

  alertFilter = null;

  constructor(private alertsService: AlertService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getAlerts();
  }

  getAlerts() {
    this.awaitingGet = true;
    this.alertsService.apiAlertGet(null, '-createdDate').subscribe(res => {
      this.awaitingGet = false;
      this.alerts = res;
      this.filteredAlerts = this.alerts;
    });
  }

  onNewAlert() {
    const dialogRef = this.dialog.open(NewAlertDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getAlerts();
        this.selectedAlertId = res.id;
        this.onAlertChange();
      }
    });
  }

  onAlertChange() {
    this.outputAlertId.emit(this.selectedAlertId);
  }

  onFilterAlerts() {
    if (!this.alerts) {
      return;
    }

    let filter = this.alertFilter;
    if (!filter) {
      this.filteredAlerts = this.alerts;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredAlerts = this.alerts.filter(alert => alert.title.toLowerCase().indexOf(filter) > -1);
  }

}
