<span class="micro-item" *ngIf="coupon">
  <button mat-button [matMenuTriggerFor]="previewCustomer" class="toggle">
    {{coupon.name}}
    <mat-icon class="open">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #previewCustomer="matMenu">
    <ng-template matMenuContent>
      <div mat-menu-item class="pointer-events-none" *ngIf="coupon.discountType == 0">
        <mat-icon>monetization_on</mat-icon>
        <span>{{'Open' | translate}}</span>
      </div>
      <div mat-menu-item class="pointer-events-none" *ngIf="coupon.discountType == 1">
        <mat-icon>monetization_on</mat-icon>
        <span>{{coupon.discountAmount | currency:sessionService.schemeCurrency:'symbol':'1.2-2'}}</span>
      </div>
      <div mat-menu-item class="pointer-events-none" *ngIf="coupon.discountType == 2">
        <mat-icon>monetization_on</mat-icon>
        <span>{{coupon.discountAmount}}%</span>
      </div>
      <a mat-menu-item [routerLink]="['/coupons/details', coupon.id]" *ngIf="!disabled">
        <mat-icon>launch</mat-icon>
        <span>Open</span>
      </a>
    </ng-template>
  </mat-menu>
</span>
