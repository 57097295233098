import { AdvancedType, BasicType, components, getPreviewClassName, IBlock, IBlockData, mergeBlock } from 'easy-email-core';

import { CustomBlocksType } from '../constants';
import React from 'react';
import { getContentEditableClassName } from 'easy-email-editor';

const { Column, Section, Wrapper, Spacer, Text, Button, AccordionText, Image, Group, Raw, MjmlBlock } = components;

export type IUnsubscribe = IBlockData<{
  'background-color': string;
  'button-color': string;
  'button-text-color': string;
  'title-color': string;
  'text-color': string;
  'font-family': string;
},
  {
    unsubscribeUrl: string;
    unsubscribeText: string;
    unsubscribeURLText: string;
  }>;

export const Unsubscribe: IBlock = {
  name: 'Unsubscribe',
  type: BasicType.TEXT,
  validParentType: [
    BasicType.COLUMN, AdvancedType.COLUMN],

  create(
    payload
  ) {
    const defaultData: IUnsubscribe = {
      type: CustomBlocksType.UNSUBSCRIBE,
      data: {
        value: {
          unsubscribeUrl: '{{UnsubscribeLink}}',
          unsubscribeText: 'If you don’t want to receive marketing emails in the future, please ' + `<a href="{{UnsubscribeLink}}" target="_blank" style="color: inherit; text-decoration: underline;">click here</a>`,
          unsubscribeURLText: 'Unsubscribe',
        },
      },
      attributes: {
        'background-color': '',
        'button-text-color': '#000000',
        'button-color': 'transparent',
        'title-color': '#000000',
        'text-color': '#000000',
        'font-family': 'Roboto'
      },
      children: [],
    };
    return mergeBlock(defaultData, payload);
  },
  render: ({ data, idx, mode, context, dataSource }) => {
    const { unsubscribeUrl, unsubscribeText, unsubscribeURLText } = data.data.value;
    const attributes = data.attributes;

    const instance = (

        <Section
          padding={'0px 0px 0px 0px '}
          background-color={attributes['background-color']}
          text-align={'center'}
          css-class={mode === 'testing' ? getPreviewClassName(`${idx}`, data.type) : ''}
        >
          <Column>
            <Text text-align={'center'} align={'center'} color={attributes['text-color']}
                  css-class={getContentEditableClassName(
                    BasicType.TEXT,
                    `${idx}.data.value.unsubscribeText`,
                  ).join(' ')}
            >
              {unsubscribeText}

            </Text>
          </Column>
        </Section>
      )
    ;

    return instance;
  },
};

export { Panel } from './Panel';


