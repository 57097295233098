/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Enabled } from '../model/enabled';
import { LightSpeedRetailApplyGiftCardRequestView } from '../model/lightSpeedRetailApplyGiftCardRequestView';
import { LightSpeedRetailUseCouponView } from '../model/lightSpeedRetailUseCouponView';
import { LightspeedRetailRedeemRequestView } from '../model/lightspeedRetailRedeemRequestView';
import { LightspeedRetailSettingsView } from '../model/lightspeedRetailSettingsView';
import { LightspeedRetailVendorLink } from '../model/lightspeedRetailVendorLink';
import { LightspeedSetupModelView } from '../model/lightspeedSetupModelView';
import { TestClass } from '../model/testClass';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LightspeedRetailService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param lsCustomerId 
     * @param scheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailCouponsSchemeIntegrationIdLsCustomerIdGet(schemeIntegrationId: string, lsCustomerId: string, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailCouponsSchemeIntegrationIdLsCustomerIdGet(schemeIntegrationId: string, lsCustomerId: string, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailCouponsSchemeIntegrationIdLsCustomerIdGet(schemeIntegrationId: string, lsCustomerId: string, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailCouponsSchemeIntegrationIdLsCustomerIdGet(schemeIntegrationId: string, lsCustomerId: string, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailCouponsSchemeIntegrationIdLsCustomerIdGet.');
        }

        if (lsCustomerId === null || lsCustomerId === undefined) {
            throw new Error('Required parameter lsCustomerId was null or undefined when calling apiLightspeedRetailCouponsSchemeIntegrationIdLsCustomerIdGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/Coupons/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(lsCustomerId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param lsCustomerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailCustomerSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, lsCustomerId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailCustomerSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, lsCustomerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailCustomerSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, lsCustomerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailCustomerSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, lsCustomerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailCustomerSchemeIntegrationIdGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (lsCustomerId !== undefined && lsCustomerId !== null) {
            queryParameters = queryParameters.set('LsCustomerId', <any>lsCustomerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/Customer/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of lightspeed employees
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailEmployeesSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailEmployeesSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailEmployeesSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailEmployeesSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailEmployeesSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/Employees/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checks if the integration is enabled
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailEnabledSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/Enabled/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checks if the integration is enabled
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailEnabledSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/LightspeedRetail/Enabled/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Exchanges refresh token to access token
     * 
     * @param state 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailGetAccessTokenGet(state?: string, code?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailGetAccessTokenGet(state?: string, code?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailGetAccessTokenGet(state?: string, code?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailGetAccessTokenGet(state?: string, code?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (state !== undefined && state !== null) {
            queryParameters = queryParameters.set('state', <any>state);
        }
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/GetAccessToken`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param lsCustomerId 
     * @param scheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailGiftCardsSchemeIntegrationIdLsCustomerIdGet(schemeIntegrationId: string, lsCustomerId: string, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailGiftCardsSchemeIntegrationIdLsCustomerIdGet(schemeIntegrationId: string, lsCustomerId: string, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailGiftCardsSchemeIntegrationIdLsCustomerIdGet(schemeIntegrationId: string, lsCustomerId: string, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailGiftCardsSchemeIntegrationIdLsCustomerIdGet(schemeIntegrationId: string, lsCustomerId: string, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailGiftCardsSchemeIntegrationIdLsCustomerIdGet.');
        }

        if (lsCustomerId === null || lsCustomerId === undefined) {
            throw new Error('Required parameter lsCustomerId was null or undefined when calling apiLightspeedRetailGiftCardsSchemeIntegrationIdLsCustomerIdGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/GiftCards/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(lsCustomerId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailRedeemSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightspeedRetailRedeemRequestView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailRedeemSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightspeedRetailRedeemRequestView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailRedeemSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightspeedRetailRedeemRequestView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailRedeemSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightspeedRetailRedeemRequestView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailRedeemSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/LightspeedRetail/Redeem/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of Register for the specific shop
     * 
     * @param schemeIntegrationId 
     * @param shopId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailRegistersSchemeIntegrationIdShopIdGet(schemeIntegrationId: string, shopId: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailRegistersSchemeIntegrationIdShopIdGet(schemeIntegrationId: string, shopId: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailRegistersSchemeIntegrationIdShopIdGet(schemeIntegrationId: string, shopId: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailRegistersSchemeIntegrationIdShopIdGet(schemeIntegrationId: string, shopId: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailRegistersSchemeIntegrationIdShopIdGet.');
        }

        if (shopId === null || shopId === undefined) {
            throw new Error('Required parameter shopId was null or undefined when calling apiLightspeedRetailRegistersSchemeIntegrationIdShopIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/Registers/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(shopId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param saleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailSaleSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, saleId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailSaleSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, saleId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailSaleSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, saleId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailSaleSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, saleId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailSaleSchemeIntegrationIdGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (saleId !== undefined && saleId !== null) {
            queryParameters = queryParameters.set('saleId', <any>saleId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/Sale/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Retail setup Settings
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailSettingsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailSettingsSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/Settings/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Retail setup settings
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: LightspeedRetailSettingsView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: LightspeedRetailSettingsView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: LightspeedRetailSettingsView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailSettingsSchemeIntegrationIdPut(schemeIntegrationId: string, body?: LightspeedRetailSettingsView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailSettingsSchemeIntegrationIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/LightspeedRetail/Settings/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates authorization link and Creates a schemeIntegration
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailSetupPost(body?: LightspeedSetupModelView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailSetupPost(body?: LightspeedSetupModelView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailSetupPost(body?: LightspeedSetupModelView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailSetupPost(body?: LightspeedSetupModelView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/LightspeedRetail/Setup`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates authorization link
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailSetupSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailSetupSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailSetupSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailSetupSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailSetupSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/Setup/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds or Sync&#x27;s loyale customer to Lightspeed
     * 
     * @param schemeIntegrationId 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailSyncCustomerSchemeIntegrationIdCustomerIdGet(schemeIntegrationId: string, customerId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailSyncCustomerSchemeIntegrationIdCustomerIdGet(schemeIntegrationId: string, customerId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailSyncCustomerSchemeIntegrationIdCustomerIdGet(schemeIntegrationId: string, customerId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailSyncCustomerSchemeIntegrationIdCustomerIdGet(schemeIntegrationId: string, customerId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailSyncCustomerSchemeIntegrationIdCustomerIdGet.');
        }

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiLightspeedRetailSyncCustomerSchemeIntegrationIdCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/SyncCustomer/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds loyale customers to Lightspeed
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailSyncSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailSyncSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailSyncSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailSyncSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailSyncSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/Sync/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailTestTransactionsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: TestClass, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailTestTransactionsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: TestClass, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailTestTransactionsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: TestClass, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailTestTransactionsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: TestClass, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailTestTransactionsSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/LightspeedRetail/TestTransactions/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync&#x27;s all scheme customers with shireburn
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailUpdateAllCustomersSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailUpdateAllCustomersSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailUpdateAllCustomersSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailUpdateAllCustomersSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailUpdateAllCustomersSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/UpdateAllCustomers/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sync&#x27;s a customer with shireburn
     * 
     * @param customerId 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailUpdateCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailUpdateCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailUpdateCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailUpdateCustomerSchemeIntegrationIdCustomerIdGet(customerId: string, schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiLightspeedRetailUpdateCustomerSchemeIntegrationIdCustomerIdGet.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailUpdateCustomerSchemeIntegrationIdCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/UpdateCustomer/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailUseCouponsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightSpeedRetailUseCouponView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailUseCouponsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightSpeedRetailUseCouponView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailUseCouponsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightSpeedRetailUseCouponView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailUseCouponsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightSpeedRetailUseCouponView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailUseCouponsSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/LightspeedRetail/UseCoupons/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailUseGiftCardSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightSpeedRetailApplyGiftCardRequestView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailUseGiftCardSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightSpeedRetailApplyGiftCardRequestView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailUseGiftCardSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightSpeedRetailApplyGiftCardRequestView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailUseGiftCardSchemeIntegrationIdPost(schemeIntegrationId: string, body?: LightSpeedRetailApplyGiftCardRequestView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailUseGiftCardSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/LightspeedRetail/UseGiftCard/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of lightspeedvendors that needs/or tobe/are linked to loyale outlets
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailVendorsSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/LightspeedRetail/Vendors/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Link a vendor to an outlet
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLightspeedRetailVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<LightspeedRetailVendorLink>, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLightspeedRetailVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<LightspeedRetailVendorLink>, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLightspeedRetailVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<LightspeedRetailVendorLink>, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLightspeedRetailVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<LightspeedRetailVendorLink>, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiLightspeedRetailVendorsSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/LightspeedRetail/Vendors/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
