import { DashboardService } from 'src/app/swagger';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorsComponent } from '../shared/errors/errors.component';
import { SessionService } from './session.service';
import { AdditionalSchemeFieldsService, AdminService, CustomerDevicesInsertView, SchemeService } from '../swagger';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import cronstrue from 'cronstrue';
import * as he from 'he';
import * as moment from 'moment';

import Quill from 'quill';

var AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);
var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
import { htmlEditButton } from 'quill-html-edit-button-fix';
import { Status } from '../pipes/scheme-status.pipe';
import { BehaviorSubject } from 'rxjs';

Quill.register('modules/htmlEditButton', htmlEditButton);

type Access = 'Read' | 'Write';
type Roles = 'Owner' | 'Admin' | 'Editor' | 'Finance' | 'Marketing' | 'Customer Care' | 'Clerk' | 'POS' | 'Viewer';
type Sections =
  'Customers'
  | 'Transactions'
  | 'Reports'
  | 'Brands'
  | 'Automations'
  | 'Reach'
  | 'Rewards'
  | 'Add-ons'
  | 'Settings'
  | 'Subscription';

type AccessMatrix = {
  [key in Roles]: {
    [key in Sections]: {
      [key in Access]: boolean;
    };
  };
};

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  _this = this;

  navigator = navigator.userAgent;
  nameOffset = null;
  verOffset = null;
  ix = null;

  device: CustomerDevicesInsertView = <CustomerDevicesInsertView>{};

  updateCheckInterval = null;
  sessionCheckInterval = null;
  healthCheckInterval = null;
  setupCheckInterval = null;

  public onChangeViewCheckDone: BehaviorSubject<number> = new BehaviorSubject(0);

  public accessMatrix = {
    'Customers': {
      'Admin': { read: true, write: true },
      'Editor': { read: true, write: true },
      'Finance': { read: true, write: false },
      'Marketing': { read: true, write: false },
      'Customer Care': { read: true, write: true },
      'Clerk': { read: true, write: true },
      'POS': { read: false, write: false },
      'Viewer': { read: true, write: false },
      'Owner': { read: true, write: true },
    },
    'Transactions': {
      'Admin': { read: true, write: true },
      'Editor': { read: true, write: true },
      'Finance': { read: true, write: true },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: true, write: true },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: true, write: false },
      'Owner': { read: true, write: true },
    },
    'Reports': {
      'Admin': { read: true, write: true },
      'Editor': { read: true, write: true },
      'Finance': { read: true, write: true },
      'Marketing': { read: true, write: true },
      'Customer Care': { read: true, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: true, write: false },
      'Owner': { read: true, write: true },
    },
    'Brands': {
      'Admin': { read: true, write: true },
      'Editor': { read: true, write: true },
      'Finance': { read: false, write: false },
      'Marketing': { read: true, write: true },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: true, write: false },
      'Owner': { read: true, write: true },
    },
    'Automations': {
      'Admin': { read: true, write: true },
      'Editor': { read: true, write: true },
      'Finance': { read: false, write: false },
      'Marketing': { read: true, write: true },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: true, write: false },
      'Owner': { read: true, write: true },
    },
    'Reach': {
      'Admin': { read: true, write: true },
      'Editor': { read: true, write: true },
      'Finance': { read: false, write: false },
      'Marketing': { read: true, write: true },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: true, write: false },
      'Owner': { read: true, write: true }
    },
    'Rewards': {
      'Admin': { read: true, write: true },
      'Editor': { read: true, write: true },
      'Finance': { read: false, write: false },
      'Marketing': { read: true, write: true },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: true, write: false },
      'Owner': { read: true, write: true }
    },
    'Add-ons': {
      'Admin': { read: true, write: true },
      'Editor': { read: false, write: false },
      'Finance': { read: false, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: true, write: false },
      'Owner': { read: true, write: true }
    },
    'Settings': {
      'Admin': { read: true, write: true },
      'Editor': { read: false, write: false },
      'Finance': { read: true, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    },
    'Subscription': {
      'Admin': { read: false, write: false },
      'Editor': { read: false, write: false },
      'Finance': { read: false, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    },
    'Setup': {
      'Admin': { read: true, write: true },
      'Editor': { read: false, write: false },
      'Finance': { read: false, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    },
    'Scheme': {
      'Admin': { read: true, write: true },
      'Editor': { read: false, write: false },
      'Finance': { read: false, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    },
    'Agents': {
      'Admin': { read: true, write: true },
      'Editor': { read: false, write: false },
      'Finance': { read: false, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    },
    'Policies': {
      'Admin': { read: true, write: true },
      'Editor': { read: false, write: false },
      'Finance': { read: false, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    },
    'API Keys': {
      'Admin': { read: true, write: true },
      'Editor': { read: false, write: false },
      'Finance': { read: false, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    },
    'Payments': {
      'Admin': { read: false, write: false },
      'Editor': { read: false, write: false },
      'Finance': { read: true, write: true },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    },
    'Email': {
      'Admin': { read: true, write: true },
      'Editor': { read: false, write: false },
      'Finance': { read: false, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    },
    'Verification': {
      'Admin': { read: true, write: true },
      'Editor': { read: false, write: false },
      'Finance': { read: false, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    },
    'Configuration': {
      'Admin': { read: false, write: false },
      'Editor': { read: false, write: false },
      'Finance': { read: false, write: false },
      'Marketing': { read: false, write: false },
      'Customer Care': { read: false, write: false },
      'Clerk': { read: false, write: false },
      'POS': { read: false, write: false },
      'Viewer': { read: false, write: false },
      'Owner': { read: true, write: true },
    }
  };

  constructor(private snackBar: MatSnackBar, private dashboardService: DashboardService, private translate: TranslateService, private http: HttpClient, private schemeService: SchemeService, private dialog: MatDialog, private adminService: AdminService, private router: Router, private sessionService: SessionService, private additionalSchemeFieldsService: AdditionalSchemeFieldsService) { }

  getRandomColor(): string {
    let color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  getRandomColorShade(color) {
    let p = 1;
    let result = '';

    while (p < color.length) {
      let temp = parseInt(color.slice(p, p += 2), 16);
      temp += Math.floor((255 - temp) * Math.random());
      result += temp.toString(16).padStart(2, '0');
    }
    return '#' + result;
  }

  getSchemeOriginString(type: number) {
    let origin = '';

    if (type == 0) {
      origin = 'Loyale';
    } else if (type == 1) {
      origin = 'Shopify';
    }

    return origin;
  }

  getPlainText(htmlText) {
    // using he.js to decode html string https://github.com/mathiasbynens/he
    // return he.decode(htmlText.replace(/<[^>]*>/g, ""));

    let a = he.decode(htmlText);
    let b = a.replace(/<\/p>/g, '\n');
    let c = b.replace(/<[^>]*>/g, '');
    return c;
  }

  getPascalCase(value, keepSpaces?) {
    let pascalCase = value.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });

    return keepSpaces ? pascalCase : pascalCase.replace(/\s/g, '');
  }

  getUpperCase(value) {
    return value.toUpperCase().replace(/\s/g, '');
  }

  getFileFromBase64 = (base64, filename?) => {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    if (filename == null) {
      filename = this.getRandomString(10, false, false);
    }

    return new File([u8arr], filename, { type: mime });
  };

  getRandomString(length: number, useNumbers: boolean, useSymbols: boolean) {
    let letters = 'abcdefghijklmnopqrstuvwxyz';
    let numbers = '0123456789';
    let symbols = '!@#$%^&*()_+~|}{[]:;?><-=';
    let cleanString = '';
    let rawString = '';

    while (cleanString.length < length) {
      let r1 = Math.ceil(letters.length * Math.random() * Math.random());
      let r2 = Math.ceil(numbers.length * Math.random() * Math.random());
      let r3 = Math.ceil(symbols.length * Math.random() * Math.random());

      let s = new Date().getSeconds();
      let letter = letters.charAt(r1);
      letter = ((r1 + length + s) % 2 == 0) ? (letter.toUpperCase()) : (letter);
      rawString += letter;

      if (useNumbers) {
        rawString += numbers.charAt(r2);
      }

      if (useSymbols) {
        rawString += symbols.charAt(r3);
      }

      cleanString = rawString;
    }
    cleanString = cleanString.split('').sort(function () { return 0.5 - Math.random(); }).join('');
    return cleanString.substr(0, length);
  }

  getCronString(cron: string) {
    return cronstrue.toString(cron, { use24HourTimeFormat: true });
  }

  getCronSchedule(cron: string) {
    if (cron.includes('undefined')) {
      return '';
    }

    let Cron = require('cron-converter');

    var cronInstance = new Cron({
      timezone: this.sessionService.schemeTimezone ? this.sessionService.schemeTimezone : 'Europe/London'
    });

    cronInstance.fromString(cron);
    var schedule = cronInstance.schedule();
    let nextExecution = schedule.next().format('YYYY-MM-DDTHH:mm:ssZ');
    let prevExecution = schedule.prev().format('YYYY-MM-DDTHH:mm:ssZ');

    return { schedule: schedule, next: nextExecution, prev: prevExecution };
  }

  getTimezone() {
    let timezone = {
      utc: 'UTC ' + moment().format('Z'), //=UTC +02:00 - +/- difference
      offset: moment().utcOffset(), //=120  - offset in minutes
      locale: Intl.DateTimeFormat().resolvedOptions().timeZone //=Europe/Malta - continent/country names
    };

    return timezone;
  }

  getGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getDevice() {
    this.device.appName = environment.projectName;
    this.device.appVersion = environment.version;

    if (this.navigator.indexOf('Win') != -1) {
      this.device.osName = 'Windows';
      if (this.navigator.indexOf('Windows NT 10.0') != -1) {
        this.device.osVersion = '10';
      }
      if (this.navigator.indexOf('Windows NT 6.2') != -1) {
        this.device.osVersion = '8';
      }
      if (this.navigator.indexOf('Windows NT 6.1') != -1) {
        this.device.osVersion = '7';
      }
      if (this.navigator.indexOf('Windows NT 6.0') != -1) {
        this.device.osVersion = 'Vista';
      }
      if (this.navigator.indexOf('Windows NT 5.1') != -1) {
        this.device.osVersion = 'XP';
      }
      if (this.navigator.indexOf('Windows NT 5.0') != -1) {
        this.device.osVersion = '2000';
      }
    }

    if (this.navigator.indexOf('Mac') != -1) {
      this.device.osName = 'Mac';
      if (this.navigator.indexOf('Mac OS X 10_15_7') != -1) {
        this.device.osVersion = 'Big Sur';
      }
      if (this.navigator.indexOf('Mac OS X 10_15_6') != -1) {
        this.device.osVersion = 'Catalina';
      }
      if (this.navigator.indexOf('Mac OS X 10_14') != -1) {
        this.device.osVersion = 'Mojave';
      }
      if (this.navigator.indexOf('Mac OS X 10_13') != -1) {
        this.device.osVersion = 'High Sierra';
      }
      if (this.navigator.indexOf('Mac OS X 10_12') != -1) {
        this.device.osVersion = 'Sierra';
      }
      if (this.navigator.indexOf('Mac OS X 10_11') != -1) {
        this.device.osVersion = 'El Capitan';
      }
      if (this.navigator.indexOf('Mac OS X 10_10') != -1) {
        this.device.osVersion = 'Yosemite';
      }
      if (this.navigator.indexOf('Mac OS X 10_9') != -1) {
        this.device.osVersion = 'Mavericks';
      }
    }

    if (this.navigator.indexOf('X11') != -1) {
      this.device.osName = 'UNIX';
      this.device.osVersion = 'Unknown';
    }

    if (this.navigator.indexOf('Linux') != -1) {
      this.device.osName = 'Linux';
      this.device.osVersion = 'Unknown';
    }

    // In Opera, the true version is after "Opera" or after "Version"
    if ((this.verOffset = this.navigator.indexOf('Opera')) != -1) {
      this.device.deviceName = 'Opera';
      this.device.deviceVersion = this.navigator.substring(this.verOffset + 6);
      if ((this.verOffset = this.navigator.indexOf('Version')) != -1) {
        this.device.deviceVersion = this.navigator.substring(this.verOffset + 8);
      }
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((this.verOffset = this.navigator.indexOf('MSIE')) != -1) {
      this.device.deviceName = 'Microsoft Internet Explorer';
      this.device.deviceVersion = this.navigator.substring(this.verOffset + 5);
    }
    // In Edge, the true version is after "Edge" in userAgent
    else if ((this.verOffset = this.navigator.indexOf('Edge')) != -1) {
      this.device.deviceName = 'Edge';
      this.device.deviceVersion = this.navigator.substring(this.verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((this.verOffset = this.navigator.indexOf('Chrome')) != -1) {
      this.device.deviceName = 'Chrome';
      this.device.deviceVersion = this.navigator.substring(this.verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((this.verOffset = this.navigator.indexOf('Safari')) != -1) {
      this.device.deviceName = 'Safari';
      this.device.deviceVersion = this.navigator.substring(this.verOffset + 7);
      if ((this.verOffset = this.navigator.indexOf('Version')) != -1) {
        this.device.deviceVersion = this.navigator.substring(this.verOffset + 8);
      }
    }
    // In Firefox, the true version is after "Firefox"
    else if ((this.verOffset = this.navigator.indexOf('Firefox')) != -1) {
      this.device.deviceName = 'Firefox';
      this.device.deviceVersion = this.navigator.substring(this.verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ((this.nameOffset = this.navigator.lastIndexOf(' ') + 1) <
      (this.verOffset = this.navigator.lastIndexOf('/'))) {
      this.device.deviceName = this.navigator.substring(this.nameOffset, this.verOffset);
      this.device.deviceVersion = this.navigator.substring(this.verOffset + 1);
      if (this.device.deviceName.toLowerCase() == this.device.deviceName.toUpperCase()) {
        this.device.deviceName = navigator.appName;
      }
    }
    // trim the _version string at semicolon/space if present
    if ((this.ix = this.device.deviceVersion.indexOf(';')) != -1) {
      this.device.deviceVersion = this.device.deviceVersion.substring(0, this.ix);
    }
    if ((this.ix = this.device.deviceVersion.indexOf(' ')) != -1) {
      this.device.deviceVersion = this.device.deviceVersion.substring(0, this.ix);
    }

    return (this.device);
  }

  errorHandler(apiError: any) {
    const errors = apiError.error.errors;

    if (errors.length == 1) {
      this.snackBar.open('Error: ' + errors[0]);
    } else {
      const snackBarRef = this.snackBar.open('Errors: Multiple errors', 'View');

      snackBarRef.onAction().subscribe(() => {
        this.dialog.open(ErrorsComponent, { data: { errors: errors } });
      });
    }
  }

  copyHandler(action?) {
    this.snackBar.open(action + ' copied');
  }

  quillHtmlConfig() {
    let quillModules = {
      htmlEditButton: {
        msg: 'HTML View',
        buttonTitle: 'HTML View',
        okText: 'Save'
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['{{'],
        showDenotationChar: false,
        source: function (searchTerm, renderList, mentionChar) {
          let values = [
            { id: 1, value: '{{Customer.FirstName}}' },
            { id: 2, value: '{{Customer.LastName}}' },
            { id: 3, value: '{{Customer.MobileNumber}}' },
            { id: 4, value: '{{Customer.Email}}' },
            { id: 5, value: '{{Customer.BarCode}}' },
            { id: 6, value: '{{VerifyConfig.CallbackUrl}}' },
            { id: 6, value: '{{ResetLink}}' }
          ];

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++) {
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              ) {
                matches.push(values[i]);
              }
            }
            renderList(matches, searchTerm);
          }
        }
      },
      'emoji-toolbar': true,
      'emoji-shortname': true,
      toolbar: {
        container: [
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
          ['link', 'image', 'emoji'],
          ['clean']
        ],
        handlers: {
          image: this.quillImageHandler
        }
      }
    };

    return quillModules;
  }

  quillImageHandler(this: any) {
    const tooltip = this.quill.theme.tooltip;

    const originalSave = tooltip.save;
    const originalHide = tooltip.hide;

    tooltip.save = function (this: any) {
      const range = this.quill.getSelection(true);
      const value = this.textbox.value;
      if (value) {
        this.quill.insertEmbed(range.index, 'image', value, 'user');
      }
    };

    tooltip.hide = function (this: any) {
      tooltip.save = originalSave;
      tooltip.hide = originalHide;
      tooltip.hide();
    };

    tooltip.edit('image');
  }

  quillPlainConfig() {
    let quillModules = {
      'emoji-toolbar': true,
      'emoji-shortname': true,
      toolbar: {
        container: [
          ['emoji'],
          ['clean']
        ]
      }
    };

    return quillModules;
  }

  setCookie(name: string, value: string, days?: number) {
    const expire = moment().add(days == null ? 7 : days, 'days').hour(0).minute(0).second(0).utc();
    document.cookie = name + '=' + value + '; expires=' + expire.toDate() + '; path=/';
  }

  getCookie(name: string) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length == 2) {
      return parts.pop().split(';').shift();
    }
  }

  deleteCookie(name: string) {
    const expire = moment().subtract(1, 'days');
    document.cookie = name + '=; expires=' + expire.toDate() + '; path=/';
  }

  onUpgrade() {
    this.router.navigate(['/settings/subscription']);
  }

  startUpdateCheck() {
    //check immediatly
    this.checkBackofficeUpdate();

    //every 5 min
    this.updateCheckInterval = setInterval(() => {
      this.checkBackofficeUpdate();
    }, 300000);
  }

  checkBackofficeUpdate() {
    console.info('Check for update');
    const timestamp = moment().unix();

    this.http.get(environment.baseUrl + '/CHANGELOG-PUBLIC.md?v=' + timestamp, { responseType: 'text' }).subscribe(res => {
      let startIndex = res.indexOf('[');
      let endIndex = res.indexOf(']');
      let publicVersion = res.substring(startIndex + 1, endIndex);

      if (publicVersion != undefined && publicVersion > environment.version) {
        //if update found, stop update loop check
        clearInterval(this.updateCheckInterval);
        const snackBarRef = this.snackBar.open(this.translate.instant('Update available'), this.translate.instant('Reload'), {
          duration: 120000,
        });

        snackBarRef.onAction().subscribe(() => {
          location.reload();
        });
      }
    });
  }

  startSessionCheck() {
    //check immediatly
    this.checkAgentSession();

    //every 5 min
    this.sessionCheckInterval = setInterval(() => {
      this.checkAgentSession();
    }, 300000);
  }

  checkAgentSession() {
    console.info('Check for session');
    let token = this.sessionService.token;

    if (token) {
      if (!this.sessionService.tokenValid) {
        this.snackBar.open('Session expired');
        this.sessionService.signOut();
      }
    } else {
      //if there is no token, stop session loop check
      clearInterval(this.sessionCheckInterval);
    }
  }

  startHealthCheck() {
    //check immediatly
    this.checkSchemeHealth();

    //every 5 min
    this.healthCheckInterval = setInterval(() => {
      this.checkSchemeHealth();
    }, 300000);
  }

  checkSchemeHealth() {
    console.info('Check for health');
    this.schemeService.apiSchemeHealthGet(this.sessionService.schemeId).subscribe(res => {
      this.sessionService.schemeHealth = res;
      this.onChangeViewCheckDone.next(this.onChangeViewCheckDone.value + 1);
    }, _err => {
      //if this gives an error, stop health loop check
      clearInterval(this.healthCheckInterval);
      this.onChangeViewCheckDone.next(this.onChangeViewCheckDone.value + 1);
    });
  }

  startSetupCheck() {
    this.checkSchemeSetup();

    this.setupCheckInterval = setInterval(() => {
      this.checkSchemeSetup();
    }, 300000);
  }

  checkSchemeSetup() {
    console.info('Check for setup');
    this.schemeService.apiSchemeSchemeSetupProgressGet(this.sessionService.schemeId).subscribe(res => {
      this.sessionService.schemeSetup = res;
      this.onChangeViewCheckDone.next(this.onChangeViewCheckDone.value + 1);
    }, _err => {
      //if this gives an error, stop setup loop check
      clearInterval(this.setupCheckInterval);
      this.onChangeViewCheckDone.next(this.onChangeViewCheckDone.value + 1);
    });
  }

  loadIntercom() {
    console.info('Load intercom function');
    if (this.sessionService.schemeId && environment.production) {
      this.adminService.apiAdminIdGet(this.sessionService.agentId).subscribe(res => {
        this.dashboardService.apiDashboardSchemeOverviewGet(this.sessionService.schemeId).subscribe(res2 => {
          this.adminService.apiAdminGetSchemeAdminsGet(this.sessionService.schemeId, 'role==Owner', null, 1, 1).subscribe(res3 => {
            this.schemeService.apiSchemeIdGet(this.sessionService.schemeId).subscribe(res4 => {
              //https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects

              (<any>window).Intercom('boot', {
                app_id: environment.intercomAppId,
                name: res.firstName + ' ' + res.lastName,
                email: this.sessionService.agentEmail,
                created_at: moment(res.createdDate).unix(),
                user_id: this.sessionService.agentId,
                company: {
                  size: 0,
                  industry: 'N/A',
                  customers: res2.totalActiveCustomers,
                  owner: res3.length > 0 ? res3[0].email : 'N/A',
                  owner_name: res3.length > 0 ? res3[0].firstName : 'N/A',
                  owner_surname: res3.length > 0 ? res3[0].lastName : 'N/A',
                  created_at: res4.createdDate,
                  company_id: res4.id,
                  name: res4.name,
                  plan: res4.plan.name,
                  monthly_spend: res4.plan.monthlyBasePrice,
                  currency: res4.currency,
                  timezone: res4.timezone,
                  saas: res4.inLoyale,
                  status: Status[res4.status]
                }
              });
            });
          });
        });
      });
    }
  }

  checkForAccess(section, role, access) {
    return this.accessMatrix[section][role][access];
  }

  //to use call, await commonService.delay(2000); between your functions
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getEnumKeyValueArray(enumObj: any): { key: string, value: number | string }[] {
    return Object.keys(enumObj)
      .filter(key => isNaN(Number(key))) // filter out numeric keys
      .map(key => ({ key: enumObj[key], value: key }));
  }

  calculareRedemptionRate(pointValue: number) {
    return +(1 / pointValue);
  }

  calculatePointValue(redemptionRate: number) {
    return +(1 / redemptionRate);
  }

  calculateGainRate(rewardPercentage: number) {
    return +((rewardPercentage / 100) / (1 / this.sessionService.schemeRedemptionRate));
  }

  calculateRewardPercentage(gainRate: number) {
    return +(gainRate * (1 / this.sessionService.schemeRedemptionRate) * 100).toFixed(2);
  }
}

