<mat-card>
  <mat-card-header>

    <mat-card-title>
      <mat-icon color="accent" class="mr-2">email</mat-icon>
      <span>Email</span>
    </mat-card-title>

    <mat-card-subtitle>
      Select an existing email or create a new one
    </mat-card-subtitle>

    <button mat-icon-button class="top-right-action" (click)="removeEmail.emit()" matTooltip="Remove">
      <mat-icon>cancel</mat-icon>
    </button>

  </mat-card-header>

  <mat-card-content>
    <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

    <div class="row align-items-center" *ngIf="!awaitingGet">
      <div class="col-12 col-lg-7">
        <mat-form-field class="w-100">
          <mat-label>Emails</mat-label>
          <mat-select name="email" #email="ngModel" [(ngModel)]="selectedEmailId" (ngModelChange)="onEmailChange()"
            [disabled]="emails.length == 0" required>

            <mat-option>
              <ngx-mat-select-search name="emailSearch" #emailSearch="ngModel" [(ngModel)]="emailFilter"
                (ngModelChange)="onFilterEmails()" placeholderLabel="Filter" noEntriesFoundLabel="No results found">
                <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
              </ngx-mat-select-search>
            </mat-option>

            <mat-option [value]="email.id" *ngFor="let email of filteredEmails">
              {{email.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="email?.errors?.required">Required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-1 text-center">
        <h5 class="mb-4">Or</h5>
      </div>

      <div class="col-12 col-lg-4 text-center text-lg-left">
        <button mat-flat-button color="primary" class="mb-4" (click)="onNewEmail()">Create a new email</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
