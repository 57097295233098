<div class="introduction px-4 d-flex justify-content-between align-items-center scale-up"
  [ngClass]="{'scale-down': sessionService.introCollapse, 'scale-up': !sessionService.introCollapse}"
  tourAnchor="introTourStartButton">

  <div class="left-wrapper">
    <div class="category-wrapper d-flex align-items-center">
      <mat-icon color="accent" class="icon">{{icon}}</mat-icon>
      <h3 class="text-capitalize ml-2 mb-0">{{category}}</h3>
      <mat-chip-list class="pointer-events-none ml-2" *ngIf="item">
        <mat-chip class="pointer-events-none">
          <span class="text-capitalize">{{item}}</span>
        </mat-chip>
      </mat-chip-list>
    </div>

    <div class="message-wrapper" *ngIf="message">
      <p class="text-grey m-0">{{message}}</p>
    </div>
  </div>

  <div class="right-wrapper">
    <button mat-icon-button (click)="startTourTrigger()" matTooltip="Tour" *ngIf="!hideTour">
      <mat-icon>tour</mat-icon>
    </button>
  </div>
</div>

<ng-content></ng-content>

<div class="w-100 mb-4"></div>
