<mat-card class="mini-entity">
  <mat-card-header>
    <mat-card-title>{{ transaction.transactionType | transactionType}}</mat-card-title>
    <mat-card-subtitle>{{transaction?.outlet?.name}}</mat-card-subtitle>

    <div class="top-right-action">
      <a mat-icon-button matTooltip="Open" [routerLink]="['/transactions/details', transaction.id]"
        (click)="onRedirect.emit()" *ngIf="!disabled">
        <mat-icon>launch</mat-icon>
      </a>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="d-flex align-items-center mb-2">
      <mat-icon class="mr-1">fingerprint</mat-icon>
      <span>{{transaction.externalRefId}}</span>
    </div>
    <div class="d-flex align-items-center mb-2">
      <mat-icon class="mr-1">shopping_cart</mat-icon>
      <span>{{transaction.monetaryValue | currency:sessionService.schemeCurrency:'symbol':'1.2-2'}}</span>
    </div>
    <div class="d-flex align-items-center mb-2">
      <mat-icon class="mr-1">event</mat-icon>
      <span>{{transaction.transactionDate | amFromUtc | amLocal | amDateFormat:'DD/MM/YYYY HH:mm'}}</span>
    </div>
  </mat-card-content>
</mat-card>
