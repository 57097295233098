import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TimeTriggerInsertView, TimeTriggerService } from '../../swagger';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../services/session.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';

@Component({
  selector: 'loyale-shared-trigger-time',
  templateUrl: './trigger-time.component.html',
  styleUrls: ['./trigger-time.component.scss']
})
export class TriggerTimeComponent implements OnInit {
  @Input() new = false;
  @Output() outputTriggerId = new EventEmitter<any>();

  @ViewChild('form') form: DynamicFormComponent;

  @Input()
  public set markAsTouched(touched: boolean) {
    if (touched === true) {
      this.timeTriggerForm.markAllAsTouched();
      this.touched = true;
    }
  }

  touched = false;

  timeTriggerForm: UntypedFormGroup = new UntypedFormGroup({
    repeat: new UntypedFormControl(null, []),
    once: new UntypedFormControl(null, []),
    day: new UntypedFormControl(null, []),
    week: new UntypedFormControl(null, []),
    month: new UntypedFormControl(null, []),
  });

  awaitingPost = false;
  trigger: TimeTriggerInsertView = <TimeTriggerInsertView> {};

  selectedRepeat = null;

  validTime = true;

  monthList = [{ name: 'January', value: 1 }, { name: 'February', value: 2 }, { name: 'March', value: 3 }, {
    name: 'April',
    value: 4
  }, { name: 'May', value: 5 }, { name: 'June', value: 6 }, { name: 'July', value: 7 }, {
    name: 'August',
    value: 8
  }, { name: 'September', value: 9 }, { name: 'October', value: 10 }, { name: 'November', value: 11 }, { name: 'December', value: 12 }];
  dayList = [{ name: '1st', value: 1 }, { name: '2nd', value: 2 }, { name: '3rd', value: 3 }, { name: '4th', value: 4 }, {
    name: '5th',
    value: 5
  }, { name: '6th', value: 6 }, { name: '7th', value: 7 }, {
    name: '8th',
    value: 8
  }, { name: '9th', value: 9 }, { name: '10th', value: 10 }, { name: '11th', value: 11 }, { name: '12th', value: 12 }, {
    name: '13th',
    value: 13
  }, { name: '14th', value: 14 }, { name: '15th', value: 15 }, {
    name: '16th',
    value: 16
  }, { name: '17th', value: 17 }, { name: '18th', value: 18 }, { name: '19th', value: 19 }, { name: '20th', value: 20 }, {
    name: '21st',
    value: 21
  }, { name: '22nd', value: 22 }, { name: '23rd', value: 23 }, {
    name: '24th',
    value: 24
  }, { name: '25th', value: 25 }, { name: '26th', value: 26 }, { name: '27th', value: 27 }, { name: '28th', value: 28 }, {
    name: '29th',
    value: 29
  }, { name: '30th', value: 30 }, { name: '31st', value: 31 }];
  dayOfWeekList = [{ name: 'Monday', value: 'MON' }, { name: 'Tuesday', value: 'TUE' }, {
    name: 'Wednesday',
    value: 'WED'
  }, { name: 'Thursday', value: 'THU' }, { name: 'Friday', value: 'FRI' }, {
    name: 'Saturday',
    value: 'SAT'
  }, { name: 'Sunday', value: 'SUN' }];
  frequencyList = [{ name: 'First', value: '#1' }, { name: 'Second', value: '#2' }, { name: 'Third', value: '#3' }, {
    name: 'Fourth',
    value: '#4'
  }, { name: 'Fifth', value: '#5' }, { name: 'Last', value: 'L' }];
  monthNumberList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  dayNumberList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

  once = { date: null, time: null, dateTime: null };
  daily = { dayFrequency: null, time: null };
  weekly = { daysOfWeek: null, time: null };
  monthly = { day: null, monthFrequency: null, time: null };
  monthlyNth = { frequency: null, daysOfWeek: null, monthFrequency: null, time: null };
  yearly = { month: null, day: null, time: null };
  yearlyNth = { frequency: null, daysOfWeek: null, month: null, time: null };

  constructor(private translate: TranslateService, public commonService: CommonService, private snackBar: MatSnackBar, private timeTriggerService: TimeTriggerService, private sessionService: SessionService) { }

  ngOnInit() {
    if (this.new) {
      this.selectedRepeat = 'once';
      this.trigger.isOnce = true;
    }
  }

  onceTimeChangeCheck($event) {
    const currentDateTime = new Date();
    const eventTime = new Date($event);
    if (currentDateTime > eventTime) {
      this.validTime = false;
    } else {
      this.once.dateTime = $event;
      this.validTime = true;
    }
  }

  onRepeatChange(repeat) {
    this.selectedRepeat = repeat;
    this.trigger.cron = null;
    this.resetRules();

    if (repeat == 'once') {
      this.trigger.isOnce = true;
    } else {
      this.trigger.isOnce = false;
    }
    this.timeTriggerForm.clearValidators();

    switch (repeat) {

      case 'once':
        this.timeTriggerForm.get('once').setValidators([Validators.required]);
        break;

      case 'daily':
        this.timeTriggerForm.get('day').setValidators([Validators.required]);
        break;
      case 'weekly':
        this.timeTriggerForm.get('day').setValidators([Validators.required]);
        break;
      case 'monthly':
        this.timeTriggerForm.get('day').setValidators([Validators.required]);
        this.timeTriggerForm.get('month').setValidators([Validators.required]);
        break;
      case 'yearly':
        this.timeTriggerForm.get('day').setValidators([Validators.required]);
        this.timeTriggerForm.get('month').setValidators([Validators.required]);
        break;
    }
  }

  generateCron(event?: any) {
    if (this.selectedRepeat == 'once' && this.once.dateTime) {
      const date = this.once.dateTime.split('T')[0].split('-');
      const day = date[2];
      const month = date[1];

      const time = this.once.dateTime.split('T')[1].replace('T', '').replace('Z', '').split(':');
      let hour = time[0];
      let minute = time[1];

      this.trigger.cron = minute + ' ' + hour + ' ' + day + ' ' + month + ' *';

      // let df = moment(this.once.dateTime).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ssZ');
      // let dt = moment(this.once.dateTime).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ssZ');

      // this.trigger.dateFrom = new Date(df);
      // this.trigger.dateTo = new Date(dt);

    } else if (this.selectedRepeat == 'daily') {
      let hour = 0;
      let minute = 0;

      if (this.daily.time != null) {
        const time = this.daily.time.split('T')[1].replace('T', '').replace('Z', '').split(':');
        hour = time[0];
        minute = time[1];
      }

      let frequency = [];
      if (this.daily.dayFrequency != null) {
        var iterator = this.daily.dayFrequency;
        var day = 1;
        for (day; day <= 31; day + iterator) {
          frequency.push(day);
          day = day + iterator;
        }
      }

      this.trigger.cron = minute + ' ' + hour + ' ' + frequency.toString() + ' * *';
    } else if (this.selectedRepeat == 'weekly') {
      let hour = 0;
      let minute = 0;

      if (this.weekly.time != null) {
        const time = this.weekly.time.split('T')[1].replace('T', '').replace('Z', '').split(':');
        hour = time[0];
        minute = time[1];
      }

      this.trigger.cron = minute + ' ' + hour + ' * * ' + this.weekly.daysOfWeek;
    } else if (this.selectedRepeat == 'monthly') {
      let hour = 0;
      let minute = 0;
      let day = 0;

      if (this.monthly.time != null) {
        const time = this.monthly.time.split('T')[1].replace('T', '').replace('Z', '').split(':');
        hour = time[0];
        minute = time[1];
      }

      let frequency = [];
      if (this.monthly.monthFrequency != null) {
        var iterator = this.monthly.monthFrequency;
        var month = this.monthly.monthFrequency;
        for (month; month <= 12; month + iterator) {
          frequency.push(month);
          month = month + iterator;
        }
      }

      this.trigger.cron = minute + ' ' + hour + ' ' + this.monthly.day + ' ' + frequency.toString() + ' *';
    } else if (this.selectedRepeat == 'yearly') {
      let hour = 0;
      let minute = 0;

      if (this.yearly.time != null) {
        const time = this.yearly.time.split('T')[1].replace('T', '').replace('Z', '').split(':');
        hour = time[0];
        minute = time[1];
      }

      this.trigger.cron = minute + ' ' + hour + ' ' + this.yearly.day + ' ' + this.yearly.month + ' *';
    }
  }

  resetRules() {
    this.trigger.cron = null;

    this.once = { date: null, time: null, dateTime: null };
    this.daily = { dayFrequency: null, time: null };
    this.weekly = { daysOfWeek: null, time: null };
    this.monthly = { day: null, monthFrequency: null, time: null };
    this.monthlyNth = { frequency: null, daysOfWeek: null, monthFrequency: null, time: null };
    this.yearly = { month: null, day: null, time: null };
    this.yearlyNth = { frequency: null, daysOfWeek: null, month: null, time: null };
  }

  onSubmit(form: any) {
    this.touched = true;
    this.timeTriggerForm.markAllAsTouched();
    if (form.valid && this.validTime) {
      this.awaitingPost = true;
      this.timeTriggerService.apiTimeTriggerPost(this.sessionService.schemeId, this.trigger).subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.outputTriggerId.emit(res.id);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });
    } else {
      this.snackBar.open('Fill in the form to continue');
    }
  }
}
