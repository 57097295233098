/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BasicCustomerModel } from './basicCustomerModel';
import { FeedbackResponseFeedbackResultView } from './feedbackResponseFeedbackResultView';
import { FeedbackResponseResponseResultView } from './feedbackResponseResponseResultView';

export interface FeedbackResponseResultView { 
    id?: string;
    submittedDate?: Date;
    submitted?: boolean;
    feedback?: FeedbackResponseFeedbackResultView;
    customer?: BasicCustomerModel;
    responses?: Array<FeedbackResponseResponseResultView>;
    createdDate?: Date;
    createdBy?: string;
    updatedBy?: string;
    updatedDate?: Date;
}