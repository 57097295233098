<div class="d-flex flex-row pr-3 pt-3 pl-3 pb-5 border br-5 cursor-pointer simple-card h-100" *ngIf="!selectedFlag">
    <div class="pr-3">
        <mat-icon color="accent">{{icon}}</mat-icon>
    </div>
    <div class="pr-4">
        <h5>{{title  | translate}}</h5>
        <div>
            {{description | translate}}
        </div>
    </div>
</div>

<mat-card *ngIf="selectedFlag" class="h-100">
    <mat-card-content class="d-flex flex-row pr-4">
        <div class="pr-3">
            <mat-icon color="accent">{{icon}}</mat-icon>
        </div>
        <div class="flex flex-column flex-grow-1">
            <h5>{{title  | translate}}</h5>
            <div class="text-grey">
                {{description | translate}}
            </div>
        </div>
    </mat-card-content>
</mat-card>
