import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { FranchiseResultView } from 'src/app/swagger';

@Component({
  selector: 'loyale-mini-franchise',
  templateUrl: './mini-franchise.component.html',
  styleUrls: ['./mini-franchise.component.scss']
})
export class MiniFranchiseComponent implements OnInit {
  @Input() franchise: FranchiseResultView = <FranchiseResultView>{}
  @Input() disabled = false;

  constructor(public sessionService: SessionService) { }

  ngOnInit() {
  }

}
