import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padding'
})
export class PaddingPipe implements PipeTransform {

  transform(value: any, side: string, character: string, length: number): string {
    if (side == 's') {
      return value.toString().padStart(length, character);
    } else if (side == 'e') {
      return value.toString().padEnd(length, character);
    }
  }

}
