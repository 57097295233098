import { Component, OnInit, ViewChild } from '@angular/core';
import { DemographicsInsertView, DemographicsService } from 'src/app/swagger';
import { SessionService } from 'src/app/services/session.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'src/app/services/common.service';
import { DynamicFormComponent } from '../../../shared/dynamic-form/dynamic-form.component';
import { TranslateService } from '@ngx-translate/core';

class History {
  group: number;
  rule: number;
}

class Rule {
  id: string;
  op: string;
  value: any;
}

@Component({
  selector: 'loyale-new-target-demographics-dialog',
  templateUrl: './new-target-demographics-dialog.component.html',
  styleUrls: ['./new-target-demographics-dialog.scss']
})
export class NewTargetDemographicsDialogComponent implements OnInit {
  loadedFromTask = false;

  awaitingPost = false;
  demographic: DemographicsInsertView = <DemographicsInsertView>{};

  showBuilder = false;

  ruleProperties = [];
  ruleCounter = 0;
  groupCounter = 0;
  history: History[] = [];

  ruleParameters = [];
  ruleErrors = [];

  @ViewChild('dynamicForm') dynamicForm: DynamicFormComponent;

  constructor(private translate: TranslateService, private sessionService: SessionService, private dialogRef: MatDialogRef<NewTargetDemographicsDialogComponent>, private snackBar: MatSnackBar, private dialog: MatDialog, private demographicService: DemographicsService, public commonService: CommonService) { }

  ngOnInit() {
    this.demographicService.apiDemographicsBlueprintsGet(this.sessionService.schemeId).subscribe(res => {
      this.ruleProperties = res.blueprintProperties;
    })
  }

  addAndRule() {
    this.dynamicForm.createSingleControl({ "type": "and", "name": "rule" + this.ruleCounter, "group": this.groupCounter, "properties": this.ruleProperties });
    this.history.push({ group: this.groupCounter, rule: this.ruleCounter });
    this.ruleCounter++;
  }

  addOrRule() {
    this.groupCounter++;
    this.ruleCounter = 0;
    this.dynamicForm.createSingleControl({ "type": "or" });

    this.addAndRule();
  }

  generateRules() {
    const rawRules = this.dynamicForm.value;
    this.ruleParameters = [];
    this.ruleErrors = [];

    for (let h of this.history) {
      let rule: Rule = <Rule>{};
      rule.id = rawRules['group' + h.group + 'rule' + h.rule + 'property'];
      rule.op = rawRules['group' + h.group + 'rule' + h.rule + 'operator'];
      rule.value = rawRules['group' + h.group + 'rule' + h.rule + 'value'];

      if (!rule.id || !rule.op || !rule.value) {
        this.ruleErrors.push('Group ' + h.group + ' Rule ' + h.rule);
      } else {
        if (this.ruleParameters[h.group] == undefined) {
          this.ruleParameters[h.group] = [rule];
        } else {
          this.ruleParameters[h.group].push(rule);
        }
      }

    }

    this.demographic.parameters = this.ruleParameters;
  }

  onFindMatch() {
    this.generateRules();

    this.demographicService.apiDemographicsDemographicsMatchPost(this.sessionService.schemeId, this.demographic).subscribe(res => {
    })
  }

  onSubmit(form: any) {
    console.log('e')
    this.generateRules();

    if (form.valid && this.ruleErrors.length == 0) {
      this.awaitingPost = true;
      this.demographicService.apiDemographicsPost(this.sessionService.schemeId, this.demographic).subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.commonService.errorHandler(err);
        this.awaitingPost = false;
      })
    } else {
      this.snackBar.open('Invalid rule set');
      console.error(this.ruleErrors);
    }
  }
}
