<h1 mat-dialog-title>New Points Multiplier</h1>
<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>Multiplier</mat-label>
          <span matPrefix>x&nbsp;</span>
          <input matInput name="value" #value="ngModel" [(ngModel)]="pointsMultiplier.multiplier" required>
          <mat-error *ngIf="value?.errors?.required">Required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-6">

      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="flex-fill"></div>
    <button mat-flat-button [mat-dialog-close]>Close</button>
    <button mat-flat-button color="primary" [disabled]="awaitingPost">Save</button>
  </div>
</form>
