/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BannerType } from './bannerType';

export interface BannerPutView { 
    order?: number;
    imageUrl?: string;
    videoUrl?: string;
    label?: string;
    type?: BannerType;
    link?: string;
    from?: Date;
    to?: Date;
}