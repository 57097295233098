import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExternalService {

  constructor(private http: HttpClient) { }

  getGeocodingAddress(lat: number, lng: number) {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + environment.googleMapsKey);
  }

  getGeocodingCoordinates(address: string) {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + environment.googleMapsKey);
  }
}
