import { Pipe, PipeTransform } from '@angular/core';

export enum Status {
  'Trial' = 0,
  'Active',
  'Closed',
  'Provisional',
  'Suspended',
  'Demo'
}

@Pipe({
  name: 'schemeStatus'
})
export class SchemeStatusPipe implements PipeTransform {

  transform(value: any): string {
    return Status[value];
  }

}
