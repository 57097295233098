/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DiscountType } from './discountType';
import { OutletResultView } from './outletResultView';

export interface CouponResultView { 
    id?: string;
    schemeId?: string;
    name?: string;
    description?: string;
    readonly valid?: boolean;
    readonly isValid?: boolean;
    initialNumberOfUses?: number;
    from?: Date;
    to?: Date;
    until?: Date;
    imageUrl?: string;
    updatedDate?: Date;
    createdDate?: Date;
    outletId?: Array<string>;
    externalReference?: string;
    platform?: string;
    isDynamic?: boolean;
    dynamicDays?: number;
    maxUses?: number;
    maxUsesLeft?: number;
    discountType?: DiscountType;
    discountAmount?: number;
    createdBy?: string;
    updatedBy?: string;
    terms?: string;
    validAfter?: number;
    validFor?: number;
    outlets?: Array<OutletResultView>;
    notify?: boolean;
    notificationTime?: Date;
    daysBeforeExpire?: number;
    pushNotificationId?: string;
}