/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SaleTransactionResultView { 
    id?: string;
    saleTotal?: string;
    cashRedeemed?: string;
    saleCurrency?: string;
    chitNumber?: string;
    pointsBefore?: number;
    pointsDelta?: number;
    pointsFinal?: number;
    monetaryValueBefore?: number;
    monetaryValueAfter?: number;
    firstAttempt?: boolean;
    receiptNumber?: string;
}