/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GiftCardLinkedUseView } from './giftCardLinkedUseView';
import { LineItemInsertView } from './lineItemInsertView';

export interface SaleTransactionRequest { 
    saleTotal: string;
    cashRedeemed: string;
    saleCurrency: string;
    chitNumber: string;
    schemeId: string;
    lineItems?: Array<LineItemInsertView>;
    couponsUsed?: Array<string>;
    giftCardsUsed?: Array<GiftCardLinkedUseView>;
    receiptNumber?: string;
}