<mat-card>
  <mat-card-header>

    <mat-card-title>
      <mat-icon color="accent" class="mr-2">notifications</mat-icon>
      <span>Push Notification</span>
    </mat-card-title>

    <mat-card-subtitle>
      Select an existing push notifications or create a new one
    </mat-card-subtitle>

    <button class="top-right-action" mat-icon-button (click)="removePushNotification.emit()" matTooltip="Remove">
      <mat-icon>cancel</mat-icon>
    </button>

  </mat-card-header>
  <mat-card-content>
    <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

    <div class="row align-items-center" *ngIf="!awaitingGet">
      <div class="col-12 col-lg-7">
        <mat-form-field class="w-100">
          <mat-label>Push Notifications</mat-label>
          <mat-select name="pushNotification" #pushNotification="ngModel" [(ngModel)]="selectedPushNotificationId"
            (ngModelChange)="onPushNotificationChange()" [disabled]="pushNotifications.length == 0" requried>

            <mat-option>
              <ngx-mat-select-search name="pushSearch" #pushSearch="ngModel" [(ngModel)]="pushNotificationFilter"
                (ngModelChange)="onFilterPushNotifications()" placeholderLabel="Filter"
                noEntriesFoundLabel="No results found">
                <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
              </ngx-mat-select-search>
            </mat-option>

            <mat-option [value]="pushNotification.id" *ngFor="let pushNotification of filteredPushNotifications">
              {{pushNotification.title}} - {{pushNotification.subTitle}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="pushNotification?.errors?.required">Required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-1 text-center">
        <h5 class="mb-4">Or</h5>
      </div>

      <div class="col-12 col-lg-4 text-center text-lg-left">
        <button mat-flat-button color="primary" class="mb-4" (click)="onNewPushNotification()">Create a new push
          notification
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
