import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'loyale-status-cta',
  templateUrl: './status-cta.component.html',
  styleUrls: ['./status-cta.component.scss']
})
export class StatusCtaComponent implements OnInit {

  constructor(public sessionService: SessionService) { }

  ngOnInit(): void {
  }

  onContactUs() {
    (<any>window).Intercom('showNewMessage');
  }
}
