<mat-card class="mini-entity">
  <mat-card-header>
    <mat-card-title>{{collection.name}}</mat-card-title>
    <!-- <mat-card-subtitle></mat-card-subtitle> -->
  </mat-card-header>
  <mat-card-content>
    <div class="d-flex align-items-center">
      <mat-icon class=" mr-1">category</mat-icon>
      <span>{{collection.categories[0].name}}</span>
    </div>
  </mat-card-content>
</mat-card>
