/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FranchiseOnboardView } from './franchiseOnboardView';
import { LevelInsertView } from './levelInsertView';
import { OutletInsertView } from './outletInsertView';
import { SchemeInsertView } from './schemeInsertView';
import { TierInsertView } from './tierInsertView';

export interface SchemeOnboardView { 
    scheme?: SchemeInsertView;
    franchises?: Array<FranchiseOnboardView>;
    outlets?: Array<OutletInsertView>;
    tier?: TierInsertView;
    level?: LevelInsertView;
    discountCode?: string;
}