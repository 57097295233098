<ng-container>
  <div class="row">
    <div class="col-12 col-lg-6">
      <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

      <ng-container *ngIf="!awaitingGet">
        <form [formGroup]="giftCardForm">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Details</mat-card-title>
              <!-- <mat-card-subtitle>Subtitle</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
              <mat-form-field class="w-100">
                <mat-label>Name</mat-label>
                <input matInput name="name" formControlName="name" required>
                <mat-error *ngIf="giftCardForm.get('name')?.errors?.required">Required</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Description</mat-label>
                <input matInput name="description" formControlName="description" required>
                <mat-error *ngIf="giftCardForm.get('name')?.errors?.required">Required</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>External Reference</mat-label>
                <input matInput name="extRef" formControlName="externalRefId">
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Terms & Conditions</mat-label>
                <textarea matInput name="terms" rows="8" formControlName="terms"></textarea>
              </mat-form-field>
            </mat-card-content>
          </mat-card>

          <mat-card>
            <mat-card-header>
              <mat-card-title>Value</mat-card-title>
              <!-- <mat-card-subtitle>Subtitle</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Would you like this gift card to have an open value?</span>
                    <mat-icon class=""
                      matTooltip="With an open value gift card template, the value will be set on issuing a gift card">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="openCheck" formControlName="isOpenValue" (change)="onOpenChange()">
                    <span *ngIf="giftCardForm.get('isOpenValue').value">Yes</span>
                    <span *ngIf="!giftCardForm.get('isOpenValue').value">No</span>
                  </mat-slide-toggle>
                </div>
              </div>

              <mat-form-field class="w-100" *ngIf="!giftCardForm.get('isOpenValue').value">
                <mat-label>Value</mat-label>
                <span matPrefix>{{sessionService.schemeCurrency}}&nbsp;</span>
                <input matInput type="number" name="value" formControlName="value">
                <mat-error *ngIf="giftCardForm.get('value')?.errors?.required">Required</mat-error>
              </mat-form-field>
            </mat-card-content>
          </mat-card>

          <mat-card>
            <mat-card-header>
              <mat-card-title>Usage & Availability</mat-card-title>
              <!-- <mat-card-subtitle>Subtitle</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Allow this gift card to be partially used?</span>
                    <mat-icon class=""
                      matTooltip="With partial use, the gift card value linked to the customer will change based on the usage">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="partialCheck" formControlName="partialUse">
                    <span *ngIf="giftCardForm.get('partialUse').value">Yes</span>
                    <span *ngIf="!giftCardForm.get('partialUse').value">No</span>
                  </mat-slide-toggle>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card>
            <mat-card-header>
              <mat-card-title>Validity</mat-card-title>
              <!-- <mat-card-subtitle>Subtitle</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Would you like this gift card to have a dynamic validity period?</span>
                    <mat-icon class=""
                      matTooltip="Choose if this gift card will have a fixed, or a dynamic validity period based on when it is issued to the customer">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="expiryCheck" formControlName="isDynamic" (change)="onDynamicExpiryChange()">
                    <span *ngIf="giftCardForm.get('isDynamic').value">Yes</span>
                    <span *ngIf="!giftCardForm.get('isDynamic').value">No</span>
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="mat-form-field-errors" *ngIf="!giftCardForm.get('isDynamic').value">
                <loyale-date-range-picker label="Valid From - To"
                  [dateRange]="[giftCardForm.get('from')?.value, giftCardForm.get('to')?.value]"
                  (onChange)="dateRangeOnChange($event)" fullWidth="true"
                  [required]="!giftCardForm.get('isDynamic').value" [markAsTouched]="giftCardForm.touched">
                </loyale-date-range-picker>
              </div>

              <mat-form-field class="w-100" *ngIf="giftCardForm.get('isDynamic').value">
                <mat-label>Valid after</mat-label>
                <input matInput type="number" formControlName="validAfter">
                <span matSuffix>days from issue</span>
                <mat-error *ngIf="giftCardForm.get('validAfter').getError('required')">Required
                </mat-error>
              </mat-form-field>

              <mat-form-field class="w-100" *ngIf="giftCardForm.get('isDynamic').value">
                <mat-label>Valid for</mat-label>
                <input matInput type="number" formControlName="validFor"
                  [required]="giftCardForm.get('isDynamic').value">
                <span matSuffix>days after start</span>
                <mat-error *ngIf="giftCardForm.get('validFor').getError('required')">Required
                </mat-error>
              </mat-form-field>

            </mat-card-content>
          </mat-card>

          <mat-card
            *ngIf="sessionService.schemeFeatures.payments.isAvailable && sessionService.schemeFeatures.payments.isEnabled">
            <mat-card-header>
              <mat-card-title>Purchasing</mat-card-title>
              <mat-card-subtitle>Choose if this gift card can be purchased digitally via the Loyale Payments
                Service</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field class="w-100">
                <mat-label>Purchasable</mat-label>
                <mat-select formControlName="isPurchasable" required>
                  <mat-option [value]="true">Yes</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Sold By</mat-label>
                <mat-select name="outlets" formControlName="purchasingOutletId" [disabled]="nestedOutlets.length == 0"
                  [required]="giftCardForm.get('isPurchasable').value == true">
                  <mat-option>
                    <ngx-mat-select-search [(ngModel)]="outletsFilter" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="outletsSearchChanges()" placeholderLabel="Search"
                      noEntriesFoundLabel="No results found">
                      <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-optgroup *ngFor="let nest of filteredOutlets" [label]="nest.franchiseName">
                    <mat-option *ngFor="let outlet of nest.outlets" [value]="outlet.id">
                      {{outlet.name}}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
                <mat-error *ngIf="giftCardForm.get('purchasingOutletId')?.getError('required')">Required</mat-error>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </form>
      </ng-container>
    </div>

    <div class="col-12 col-lg-6">
      <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

      <loyale-preview-gift-card [giftCard]="giftCard" *ngIf="!awaitingGet"></loyale-preview-gift-card>
    </div>
  </div>
</ng-container>
