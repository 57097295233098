/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FranchiseOnboardView { 
    id?: string;
    name: string;
    description?: string;
    imageUrl?: string;
    imageGallery?: Array<string>;
    categoriesIds?: Array<string>;
    hidden?: boolean;
}