<h1 mat-dialog-title>{{'Transfer' | translate}}</h1>
<div mat-dialog-content class="h-auto">
  <p>Choose an other Level to transfer customer to.</p>

  <mat-form-field class="w-100">
    <mat-label>Levels</mat-label>
    <mat-select name="level" #level="ngModel" [(ngModel)]="selectedLevelId" required
      [disabled]="nestedLevels.length == 0">
      <mat-optgroup *ngFor="let nest of nestedLevels" [label]="nest.tierName">
        <mat-option *ngFor="let level of nest.levels" [value]="level.id"
          [disabled]="level.id == data.currentLevelId">{{level.name}}</mat-option>
      </mat-optgroup>
    </mat-select>
    <mat-error *ngIf="level?.errors?.required">Required</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <div class="flex-fill"></div>
  <button mat-flat-button [mat-dialog-close]>{{'Cancel' | translate}}</button>
  <button mat-flat-button [disabled]="!selectedLevelId" [color]="'primary'" class="ml-1"
    (click)="onTransfer()">{{'Apply' | translate}}
  </button>
</div>
