/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdditionalCustomerFieldsResultView } from './additionalCustomerFieldsResultView';

export interface BasicCustomerModel { 
    email?: string;
    areaCode?: string;
    mobileNumber?: string;
    barCode?: string;
    externalRefId?: string;
    onHold?: boolean;
    additionalCustomerFields?: Array<AdditionalCustomerFieldsResultView>;
    id?: string;
    firstName?: string;
    lastName?: string;
    isProfileGenerated?: boolean;
    emailVerified?: boolean;
}