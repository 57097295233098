import { Pipe, PipeTransform } from '@angular/core';

export enum PaymentStatus {
  'Processing' = 0,
  'Succeeded',
  'Canceled',
  'Requires Payment Method',
  'Requires Confirmation',
  'Requires Action',
  'Requires Capture',
  'Failed',
  'Session Expired',
  'Refund Pending',
  'Partially Refunded',
  'Refunded',
  'Communication Error'
}

@Pipe({
  name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {

  transform(value: any): string {
    return PaymentStatus[value];
  }

}
