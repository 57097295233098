<form [formGroup]="sendForm">
    <loyale-target-demographics-selector
            (outputDemographicId)="onTargetChange($event)"></loyale-target-demographics-selector>

    <loyale-advanced-scheduling [actionType]="actionType"
                                (TaskActionsList)="changeTaskActions($event)"></loyale-advanced-scheduling>
    <div class="row">
        <div class="col-12 ">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Name This Automation</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-form-field class="w-100">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Automation name" formControlName="name"
                               [(ngModel)]="scheduledTask.name">
                        <mat-error *ngIf="sendForm.get('name').errors?.required">Required</mat-error>
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

</form>
