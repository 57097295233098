import { Pipe, PipeTransform } from '@angular/core';

enum Status {
  'Upcoming' = 0,
  'Ongoing',
  'Closed',
  'Drawn'
}

@Pipe({
  name: 'lotteryStatus'
})
export class LotteryStatusPipe implements PipeTransform {

  transform(value: any): string {
    return Status[value];
  }

}
