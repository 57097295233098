import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

export class ShopifyConfig {
  label: string;
  icon: string;
  launcher: number;
  primary: string;
  accent: string;
  position: string;
  welcome: string;
}

@Component({
  selector: 'loyale-preview-shopify',
  templateUrl: './preview-shopify.component.html',
  styleUrls: ['./preview-shopify.component.scss']
})
export class PreviewShopifyComponent implements OnInit {
  @Input() fromDialog = false;
  @Input() config: ShopifyConfig = null;

  constructor(public sessionService: SessionService) { }

  ngOnInit() {
  }
}
