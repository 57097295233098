<h1 mat-dialog-title>{{'Confirm' | translate}}</h1>
<div mat-dialog-content>
  <div class="mat-dialog-warning d-flex py-3 px-4 mb-3 mx-n4" *ngIf="data.warning">
    <mat-icon>info</mat-icon>&nbsp;
    <span>{{data.warning | translate}}</span>
  </div>

  <p>{{data.message | translate}}</p>
</div>
<div mat-dialog-actions>
  <div class="flex-fill"></div>
  <button mat-flat-button [mat-dialog-close]>{{'No' | translate}}</button>
  <button cdkFocusInitial mat-flat-button [mat-dialog-close]="true" color="primary" class="ml-1">{{'Yes' |
    translate}}</button>
</div>
