import { Pipe, PipeTransform } from '@angular/core';

export enum DestinationType {
  'Bank Account' = 0,
  'Card'
}


@Pipe({
  name: 'destinationType'
})
export class DestinationTypePipe implements PipeTransform {

  transform(value: any): string {
    return DestinationType[value];
  }

}
