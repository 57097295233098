<h1 mat-dialog-title class="do-not-print">
  <span *ngIf="data.format == 'qrcode'">QR Code</span>
  <span *ngIf="data.format == 'barcode'">Barcode</span>
</h1>
<div mat-dialog-content>
  <div class="d-flex align-items-center justify-content-center vh-25 vw-25" *ngIf="awaitingGet">
    <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>

  <ng-container *ngIf="!awaitingGet">
    <div class="text-center">
      <h5>{{data.name}}</h5>
      <img class="mb-1" [src]="imageBase64 == null ? 'assets/placeholder.svg' : imageBase64" width="250">
      <p *ngIf="data.barcode">{{data.barcode}}</p>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions class="do-not-print">
  <div class="flex-fill"></div>
  <button mat-flat-button [mat-dialog-close]>Close</button>
  <button mat-flat-button color='primary' (click)="onPrint()">Print</button>
</div>
