import { Pipe, PipeTransform } from '@angular/core';

enum Errors {
  'Missing billing details' = 0,
  'Missing payment details'
}

@Pipe({
  name: 'subscriptionErrors'
})
export class SubscriptionErrorsPipe implements PipeTransform {

  transform(value: any): string {
    return Errors[value];
  }

}
