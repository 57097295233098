/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RefundReason } from './refundReason';
import { StripePaymentStatus } from './stripePaymentStatus';
import { StripeRefundAttemptResultView } from './stripeRefundAttemptResultView';

export interface StripeRefundResultView { 
    id?: string;
    amount?: number;
    loyaleFeeAmount?: number;
    processingFeeAmount?: number;
    refundId?: string;
    lastFailureMessage?: string;
    status?: StripePaymentStatus;
    reason?: RefundReason;
    createdBy?: string;
    updatedBy?: string;
    createdDate?: Date;
    updatedDate?: Date;
    stripePaymentId?: string;
    stripeRefundAttempts?: Array<StripeRefundAttemptResultView>;
}