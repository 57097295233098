/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SkuOverridePutView } from '../model/skuOverridePutView';
import { SkuPromotionInsertView } from '../model/skuPromotionInsertView';
import { SkuPromotionOverrideBatchInsertView } from '../model/skuPromotionOverrideBatchInsertView';
import { SkuPromotionOverrideBatchResultView } from '../model/skuPromotionOverrideBatchResultView';
import { SkuPromotionOverrideInsertView } from '../model/skuPromotionOverrideInsertView';
import { SkuPromotionOverrideResultView } from '../model/skuPromotionOverrideResultView';
import { SkuPromotionPutView } from '../model/skuPromotionPutView';
import { SkuPromotionResultView } from '../model/skuPromotionResultView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SkuPromotionService {

    protected basePath = 'https://api.staging.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Gets a list of SkuPromotions, depending on the filter or sorting
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SkuPromotionResultView>>;
    public apiSkuPromotionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SkuPromotionResultView>>>;
    public apiSkuPromotionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SkuPromotionResultView>>>;
    public apiSkuPromotionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SkuPromotionResultView>>('get',`${this.basePath}/api/SkuPromotion`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import SkuPromotions from a CSV file
     * 
     * @param xScheme 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionImportPostForm(xScheme: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSkuPromotionImportPostForm(xScheme: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSkuPromotionImportPostForm(xScheme: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSkuPromotionImportPostForm(xScheme: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiSkuPromotionImportPost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/SkuPromotion/Import`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new SkuPromotionOverride using a batch of ids
     * 
     * @param xScheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionOverrideBatchPost(xScheme: string, body?: SkuPromotionOverrideBatchInsertView, observe?: 'body', reportProgress?: boolean): Observable<Array<SkuPromotionOverrideBatchResultView>>;
    public apiSkuPromotionOverrideBatchPost(xScheme: string, body?: SkuPromotionOverrideBatchInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SkuPromotionOverrideBatchResultView>>>;
    public apiSkuPromotionOverrideBatchPost(xScheme: string, body?: SkuPromotionOverrideBatchInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SkuPromotionOverrideBatchResultView>>>;
    public apiSkuPromotionOverrideBatchPost(xScheme: string, body?: SkuPromotionOverrideBatchInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiSkuPromotionOverrideBatchPost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<SkuPromotionOverrideBatchResultView>>('post',`${this.basePath}/api/SkuPromotion/Override/Batch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of SkuPromotionOverrides, depending on the filter or sorting
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionOverrideGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SkuPromotionOverrideResultView>>;
    public apiSkuPromotionOverrideGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SkuPromotionOverrideResultView>>>;
    public apiSkuPromotionOverrideGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SkuPromotionOverrideResultView>>>;
    public apiSkuPromotionOverrideGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SkuPromotionOverrideResultView>>('get',`${this.basePath}/api/SkuPromotion/Override`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an SkuPromotionOverride
     * 
     * @param overrideId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionOverrideOverrideIdDelete(overrideId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSkuPromotionOverrideOverrideIdDelete(overrideId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSkuPromotionOverrideOverrideIdDelete(overrideId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSkuPromotionOverrideOverrideIdDelete(overrideId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (overrideId === null || overrideId === undefined) {
            throw new Error('Required parameter overrideId was null or undefined when calling apiSkuPromotionOverrideOverrideIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/SkuPromotion/Override/${encodeURIComponent(String(overrideId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an SkuPromotionOverride by Id
     * 
     * @param overrideId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionOverrideOverrideIdGet(overrideId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SkuPromotionOverrideResultView>;
    public apiSkuPromotionOverrideOverrideIdGet(overrideId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SkuPromotionOverrideResultView>>;
    public apiSkuPromotionOverrideOverrideIdGet(overrideId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SkuPromotionOverrideResultView>>;
    public apiSkuPromotionOverrideOverrideIdGet(overrideId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (overrideId === null || overrideId === undefined) {
            throw new Error('Required parameter overrideId was null or undefined when calling apiSkuPromotionOverrideOverrideIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SkuPromotionOverrideResultView>('get',`${this.basePath}/api/SkuPromotion/Override/${encodeURIComponent(String(overrideId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new SkuPromotionOverride
     * 
     * @param xScheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionOverridePost(xScheme: string, body?: SkuPromotionOverrideInsertView, observe?: 'body', reportProgress?: boolean): Observable<SkuPromotionOverrideResultView>;
    public apiSkuPromotionOverridePost(xScheme: string, body?: SkuPromotionOverrideInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SkuPromotionOverrideResultView>>;
    public apiSkuPromotionOverridePost(xScheme: string, body?: SkuPromotionOverrideInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SkuPromotionOverrideResultView>>;
    public apiSkuPromotionOverridePost(xScheme: string, body?: SkuPromotionOverrideInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiSkuPromotionOverridePost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SkuPromotionOverrideResultView>('post',`${this.basePath}/api/SkuPromotion/Override`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify a SkuPromotionOverride
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionOverridePut(body?: SkuOverridePutView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SkuPromotionOverrideResultView>;
    public apiSkuPromotionOverridePut(body?: SkuOverridePutView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SkuPromotionOverrideResultView>>;
    public apiSkuPromotionOverridePut(body?: SkuOverridePutView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SkuPromotionOverrideResultView>>;
    public apiSkuPromotionOverridePut(body?: SkuOverridePutView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SkuPromotionOverrideResultView>('put',`${this.basePath}/api/SkuPromotion/Override`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new SkuPromotion
     * 
     * @param xScheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionPost(xScheme: string, body?: SkuPromotionInsertView, observe?: 'body', reportProgress?: boolean): Observable<SkuPromotionResultView>;
    public apiSkuPromotionPost(xScheme: string, body?: SkuPromotionInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SkuPromotionResultView>>;
    public apiSkuPromotionPost(xScheme: string, body?: SkuPromotionInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SkuPromotionResultView>>;
    public apiSkuPromotionPost(xScheme: string, body?: SkuPromotionInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiSkuPromotionPost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SkuPromotionResultView>('post',`${this.basePath}/api/SkuPromotion`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modify an SkuPromotion
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionPut(body?: SkuPromotionPutView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SkuPromotionResultView>;
    public apiSkuPromotionPut(body?: SkuPromotionPutView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SkuPromotionResultView>>;
    public apiSkuPromotionPut(body?: SkuPromotionPutView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SkuPromotionResultView>>;
    public apiSkuPromotionPut(body?: SkuPromotionPutView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SkuPromotionResultView>('put',`${this.basePath}/api/SkuPromotion`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an SkuPromotion
     * 
     * @param skuPromotionId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionSkuPromotionIdDelete(skuPromotionId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSkuPromotionSkuPromotionIdDelete(skuPromotionId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSkuPromotionSkuPromotionIdDelete(skuPromotionId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSkuPromotionSkuPromotionIdDelete(skuPromotionId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (skuPromotionId === null || skuPromotionId === undefined) {
            throw new Error('Required parameter skuPromotionId was null or undefined when calling apiSkuPromotionSkuPromotionIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/SkuPromotion/${encodeURIComponent(String(skuPromotionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an SkuPromotion by Id
     * 
     * @param skuPromotionId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSkuPromotionSkuPromotionIdGet(skuPromotionId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SkuPromotionResultView>;
    public apiSkuPromotionSkuPromotionIdGet(skuPromotionId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SkuPromotionResultView>>;
    public apiSkuPromotionSkuPromotionIdGet(skuPromotionId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SkuPromotionResultView>>;
    public apiSkuPromotionSkuPromotionIdGet(skuPromotionId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (skuPromotionId === null || skuPromotionId === undefined) {
            throw new Error('Required parameter skuPromotionId was null or undefined when calling apiSkuPromotionSkuPromotionIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SkuPromotionResultView>('get',`${this.basePath}/api/SkuPromotion/${encodeURIComponent(String(skuPromotionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
