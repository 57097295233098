/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FirebaseCredentialsView { 
    type?: string;
    projectId?: string;
    privateKeyId?: string;
    privateKey?: string;
    clientEmail?: string;
    authUri?: string;
    tokenUri?: string;
    authProviderX509CertUrl?: string;
    clientX509CertUrl?: string;
}