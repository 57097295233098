/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Level } from './level';
import { LevelUpgradeCriteria } from './levelUpgradeCriteria';

export interface Tier { 
    id?: string;
    levels?: Array<Level>;
    schemeId?: string;
    name?: string;
    isDefault?: boolean;
    hidden?: boolean;
    createdBy?: string;
    updatedBy?: string;
    levelsUpgradable?: boolean;
    upgradeCriteria?: LevelUpgradeCriteria;
    expiryPeriod?: number;
    upgradePeriod?: number;
    guaranteedPeriod?: number;
    createdDate?: Date;
    updatedDate?: Date;
}