import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'loyale-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Input() label = 'Date Range';
  @Input() fullWidth = true;
  @Input() required = false;
  @Input() disabled = false;
  @Input() clearable = false;
  @Input() dateRange = [null, null];
  @Input() returnUtc = true;
  @Input() minDate = null;
  @Input() maxDate = null;

  @Output() onChange = new EventEmitter<any>();

  @Input()
  public set markAsTouched(touched: boolean) {
    if (touched === true) {
      this.dateRangeForm.markAllAsTouched();
    }
  }

  dateRangeForm: UntypedFormGroup = new UntypedFormGroup({
    startDate: new UntypedFormControl(null, []),
    endDate: new UntypedFormControl(null, [])
  });

  constructor() { }

  ngOnInit() {
    if (this.required) {
      this.dateRangeForm.get('startDate').setValidators([Validators.required]);
      this.dateRangeForm.get('startDate').updateValueAndValidity();
      this.dateRangeForm.get('endDate').setValidators([Validators.required]);
      this.dateRangeForm.get('endDate').updateValueAndValidity();
    }

    if (this.dateRange[0] !== null && this.dateRange[1] !== null) {

      this.dateRangeForm.patchValue({
        startDate: this.dateRange[0]
      });

      this.dateRangeForm.patchValue({
        endDate: this.dateRange[1]
      });
    }
  }

  onClear() {
    this.dateRange = [null, null];
    this.dateRangeForm.patchValue({ startDate: null, endDate: null });
    this.onChange.emit(this.dateRange);
  }

  onDateRangeChange() {
    if (this.dateRangeForm.get('startDate').value !== null && this.dateRangeForm.get('endDate').value !== null) {
      this.dateRange = [null, null];

      if (this.returnUtc) {
        this.dateRange[0] = moment(this.dateRangeForm.get('startDate').value).hour(0).minute(0).second(0).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        this.dateRange[1] = moment(this.dateRangeForm.get('endDate').value).hour(23).minute(59).second(59).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      } else {
        this.dateRange[0] = moment(this.dateRangeForm.get('startDate').value).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ssZ');
        this.dateRange[1] = moment(this.dateRangeForm.get('endDate').value).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ssZ');
      }
      if (this.dateRange[0] !== null && this.dateRange[1] !== null) {
        this.onChange.emit(this.dateRange);
      }
    }
  }
}
