/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RefundLineItem } from './refundLineItem';

export interface ShopifyRefundView { 
    id?: number;
    orderId?: number;
    createdAt?: Date;
    note?: string;
    userId?: number;
    processedAt?: Date;
    restock?: boolean;
    duties?: Array<any>;
    adminGraphqlApiId?: string;
    refundLineItems?: Array<RefundLineItem>;
    transactions?: Array<any>;
    orderAdjustments?: Array<any>;
}