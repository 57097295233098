<span class="micro-item" *ngIf="outlet">
  <button mat-button [matMenuTriggerFor]="previewCustomer" class="toggle">
    {{outlet.name}}
    <mat-icon class="open">arrow_drop_down</mat-icon></button>
  <mat-menu #previewCustomer="matMenu">
    <ng-template matMenuContent>
      <div mat-menu-item class="pointer-events-none" *ngIf="outlet.type == 0">
        <mat-icon>storefront</mat-icon>
        <span>{{outlet.address.addressLine1}},&nbsp;{{outlet.address.town}}</span>
      </div>
      <div mat-menu-item class="pointer-events-none" *ngIf="outlet.type == 1">
        <mat-icon>language</mat-icon>
        <span>{{outlet.webstoreUrl}}</span>
      </div>
      <div mat-menu-item class="pointer-events-none" *ngIf="outlet.phoneNumber">
        <mat-icon>call</mat-icon>
        <span>{{outlet.phoneNumber}}</span>
      </div>
      <a mat-menu-item [routerLink]="['/outlets/details', outlet.id]" *ngIf="!disabled">
        <mat-icon>launch</mat-icon>
        <span>Open</span>
      </a>
    </ng-template>
  </mat-menu>
</span>
