import { Component, Input, SimpleChanges } from '@angular/core';
import { FilteredTransactionsInsertView, ChartService } from 'src/app/swagger';
import { SessionService } from 'src/app/services/session.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'loyale-filtered-transactions',
  templateUrl: './filtered-transactions.component.html',
  styleUrls: ['./filtered-transactions.component.scss']
})
export class FilteredTransactionsComponent {
  @Input() dateFrom;
  @Input() dateTo;
  @Input() types;
  @Input() outlets;

  chart = null;
  report = { dates: [], purchase: [], redeem: [], positiveAdj: [], negativeAdj: [] };

  params: FilteredTransactionsInsertView = <FilteredTransactionsInsertView>{};

  constructor(private chartService: ChartService, private sessionService: SessionService, public commonService: CommonService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.params.transactionTypes = this.types == null ? [] : this.types;
    this.params.outletIds = this.outlets == null ? [] : this.outlets;
    this.getChart();
  }

  getChart() {
    this.chartService.apiChartFilteredTransactionsTzOffsetPost((this.commonService.getTimezone().offset / 60), this.params, this.sessionService.schemeId, new Date(this.dateFrom), new Date(this.dateTo)).subscribe(res => {
      this.report = res;

      this.chart = {
        labels: this.report.dates,
        datasets: [
          {
            label: 'Purchase',
            data: this.report.purchase,
            fill: false,
            borderColor: '#4739ac',
            pointRadius: 2,
            lineTension: 0,
            hidden: this.report.purchase.length == 0 ? true : false
          },
          {
            label: 'Positive Adjustment',
            data: this.report.positiveAdj,
            fill: false,
            borderColor: '#756bbd',
            pointRadius: 2,
            lineTension: 0,
            hidden: this.report.positiveAdj.length == 0 ? true : false
          },
          {
            label: 'Redeem',
            data: this.report.redeem,
            fill: false,
            borderColor: '#e8bd3c',
            pointRadius: 2,
            lineTension: 0,
            hidden: this.report.redeem.length == 0 ? true : false
          },
          {
            label: 'Negative Adjustment',
            data: this.report.negativeAdj,
            fill: false,
            borderColor: '#ecdca9',
            pointRadius: 2,
            lineTension: 0,
            hidden: this.report.negativeAdj.length == 0 ? true : false
          }
        ],
      }
    }, err => {
      this.commonService.errorHandler(err);
    });
  }
}
