<mat-card>
  <mat-card-header>

    <mat-card-title>
      <mat-icon color="accent" class="mr-2">sticky_note_2</mat-icon>
      <span>Post</span>
    </mat-card-title>

    <mat-card-subtitle>
      Select an existing post or create a new one
    </mat-card-subtitle>

    <button mat-icon-button class="top-right-action" (click)="removeAlert.emit()" matTooltip="Remove">
      <mat-icon>cancel</mat-icon>
    </button>

  </mat-card-header>

  <mat-card-content>
    <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

    <div class="row align-items-center" *ngIf="!awaitingGet">
      <div class="col-12 col-lg-7">
        <mat-form-field class="w-100">
          <mat-label>Posts</mat-label>
          <mat-select name="alert" #alert="ngModel" [(ngModel)]="selectedAlertId" (ngModelChange)="onAlertChange()"
            [disabled]="alerts.length == 0" required>

            <mat-option>
              <ngx-mat-select-search name="filter" #filter="ngModel" [(ngModel)]="alertFilter"
                (ngModelChange)="onFilterAlerts()" placeholderLabel="Filter" noEntriesFoundLabel="No results found">
                <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
              </ngx-mat-select-search>
            </mat-option>

            <mat-option [value]="alert.id" *ngFor="let alert of filteredAlerts">
              {{alert.title}} - {{alert.subTitle}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="alert?.errors?.required">Required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-1 text-center">
        <h5 class="mb-4">Or</h5>
      </div>

      <div class="col-12 col-lg-4 text-center text-lg-left">
        <button mat-flat-button color="primary" class="mb-4" (click)="onNewAlert()">Create a new post</button>
      </div>
    </div>

  </mat-card-content>
</mat-card>
