import { Pipe, PipeTransform } from '@angular/core';

enum Type {
  'Delivery' = 0,
  'Collection',
  'Delivery or Collection'
}

@Pipe({
  name: 'receiveBy'
})
export class ReceiveByPipe implements PipeTransform {

  transform(value: any): string {
    return Type[value];
  }

}
