import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { PromiseService } from 'src/app/services/promise.service';
import { SessionService } from 'src/app/services/session.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService, SchemeService } from 'src/app/swagger';

@Component({
  selector: 'loyale-switch-scheme',
  templateUrl: './switch-scheme.component.html',
  styleUrls: ['./switch-scheme.component.scss']
})
export class SwitchSchemeComponent implements OnInit {
  awaitingGet = false;
  awaitingPost = false;

  filteredSchemes = [];
  allSchemes = [];

  filter = null;
  status = null;
  plan = null;

  displayedColumns: string[] = ['name', 'accountId', 'plan', 'status', 'other'];

  constructor(private router: Router, private snackBar: MatSnackBar, private promiseService: PromiseService, private schemeService: SchemeService, private adminService: AdminService, private dalogRef: MatDialogRef<SwitchSchemeComponent>, public sessionService: SessionService, public commonService: CommonService) { }

  ngOnInit() {
    if (this.sessionService.superAdmin) {
      this.getSchemesSuper();
    } else {
      this.getSchemes();
    }
  }

  getSchemesSuper(page?: number) {
    this.awaitingGet = true;
    this.schemeService.apiSchemeGet(null, 'createdDate', page ? page : 1, 1500, null, 'response').subscribe(res => {
      this.allSchemes = this.allSchemes.concat(res.body);
      this.filteredSchemes = this.allSchemes;
      this.awaitingGet = false;

      // due to paging limitations, if more pages are available, we will just loop on this request
      let totalPage = +res.headers.get('x-total-pages');

      if (page < totalPage) {
        this.getSchemesSuper(page + 1);
      }

    }, err => {
      this.awaitingGet = false;
      this.commonService.errorHandler(err);
    })
  }

  getSchemes() {
    this.awaitingGet = true;
    this.adminService.apiAdminGetSchemesAdminIdGet(this.sessionService.agentId).subscribe(res => {
      for (let schemeAndRole of res) {
        this.allSchemes.push(schemeAndRole.scheme);
        this.filteredSchemes = this.allSchemes;
      }
      this.awaitingGet = false;
    }, err => {
      this.awaitingGet = false;
      this.commonService.errorHandler(err);
    })
  }

  onFiltersChange() {
    let tempFilteredSchemes = this.allSchemes;

    if (this.filter) {
      tempFilteredSchemes = tempFilteredSchemes.filter(s => s.name.toLowerCase().includes(this.filter.toLowerCase()) || s.accountId.toString().includes(this.filter));
    }

    //status 0 also considerd as null, so need to be explicit with condition
    if (this.status != null) {
      tempFilteredSchemes = tempFilteredSchemes.filter(s => s.status == this.status);
    }

    if (this.plan) {
      tempFilteredSchemes = tempFilteredSchemes.filter(s => s.planId == this.plan);
    }

    this.filteredSchemes = tempFilteredSchemes
  }

  onLoadScheme(scheme) {
    this.awaitingPost = true;
    if (this.sessionService.superAdmin) {
      return this.promiseService.loadSuperAdminSchemes(scheme.id).then(_res => {
        this.router.navigate(['/load'], { queryParamsHandling: 'merge' });
        this.dalogRef.close();
      }).catch(err => {
        this.snackBar.dismiss();
        this.commonService.errorHandler(err);
        this.router.navigate(['/signout']);
        this.awaitingPost = false;
      })
    } else {
      return this.promiseService.loadAdminSchemes(scheme.id).then(_res => {
        this.router.navigate(['/load'], { queryParamsHandling: 'merge' });
        this.dalogRef.close();
      }).catch(err => {
        this.snackBar.dismiss();
        this.commonService.errorHandler(err);
        this.router.navigate(['/signout']);
        this.awaitingPost = false;
      })
    }
  }

  onDeleteScheme(scheme) {
    this.awaitingPost = true;
    this.schemeService.apiSchemeIdDelete(scheme.id).subscribe(_res => {
      this.awaitingPost = false;
      if (this.sessionService.superAdmin) {
        this.getSchemesSuper();
      } else {
        this.getSchemes();
      }
    }, err => {
      this.awaitingPost = false;
      this.commonService.errorHandler(err);
    })
  }
}
