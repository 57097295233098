/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerLevel } from './customerLevel';
import { Gender } from './gender';

export interface CustomerPutView { 
    id: string;
    firstName?: string;
    lastName?: string;
    dob?: Date;
    email?: string;
    password?: string;
    areaCode?: string;
    mobileNumber?: string;
    addressLine1?: string;
    addressLine2?: string;
    town?: string;
    state?: string;
    postCode?: string;
    country?: string;
    gender?: Gender;
    barCode?: string;
    marketingSub?: boolean;
    detailedLevels?: Array<CustomerLevel>;
    alerts?: Array<string>;
    profileImageUrl?: string;
    externalRefId?: string;
    parentId?: string;
}