/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerPassbooks } from './customerPassbooks';
import { PassbookRegistrations } from './passbookRegistrations';

export interface PassbookPasses { 
    id?: string;
    passTypeIdentifier?: string;
    serialNumber?: string;
    authToken?: string;
    passbookRegistrations?: Array<PassbookRegistrations>;
    customerPassbooks?: Array<CustomerPassbooks>;
    createdDate?: Date;
    updatedDate?: Date;
}