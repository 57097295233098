import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'loyale-searchable-list',
  templateUrl: './searchable-list.component.html',
  styleUrls: ['./searchable-list.component.scss']
})
export class SearchableListComponent implements OnInit {
  @Input()
  template?: TemplateRef<{ $implicit: string[] }>;

  @Input() label!: string;
  @Input() filterProperty!: string[];
  @Input() selectedProperty: string;
  @Input() selectedValue: any | null;
  @Input() placeholderSearchLabel = 'Search';

  @Input() set initList(listValue) {
    this.list = listValue;
    this.filteredList = listValue;
  }

  @Input() required = false;
  @Input() disabled = false;

  list: any;
  filteredList: any;
  filter: any;

  @Output() selectedValueChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onFilter(): void {

    if (!this.filter) {
      this.filteredList = this.list;
      return;
    }

    let filter = this.filter;
    if (!filter) {
      this.filteredList = this.list;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredList = this.list.filter((element) => {

      var flag = false;
      this.filterProperty.forEach(val => {
        if (element[val].toString().toLowerCase().indexOf(filter) > -1) {
          flag = true;
          return;
        }
      });
      if (flag) {
        return element;
      }
    }
    )
      ;
  }

  onChange(): void {
    this.selectedValueChange.emit(this.selectedValue);
  }

}
