import { Component, OnInit } from '@angular/core';
import { GuideService } from 'src/app/services/guide.service';

@Component({
  selector: 'loyale-demo-welcome-dialog',
  templateUrl: './demo-welcome-dialog.component.html',
  styleUrls: ['./demo-welcome-dialog.component.scss']
})
export class DemoWelcomeDialogComponent implements OnInit {

  constructor(public guideService: GuideService) { }

  ngOnInit(): void {
  }


}
