import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'loyale-setup-cta',
  templateUrl: './setup-cta.component.html',
  styleUrls: ['./setup-cta.component.scss']
})
export class SetupCtaComponent implements OnInit {

  constructor(public sessionService: SessionService) { }

  ngOnInit(): void {
  }

}
