/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Image } from './image';
import { Option } from './option';
import { Variant } from './variant';

export interface ShopifyProductWebHook { 
    id?: number;
    title?: string;
    bodyHtml?: any;
    vendor?: string;
    productType?: string;
    createdAt?: Date;
    handle?: string;
    updatedAt?: Date;
    publishedAt?: Date;
    templateSuffix?: string;
    publishedScope?: string;
    tags?: string;
    adminGraphqlApiId?: string;
    variants?: Array<Variant>;
    options?: Array<Option>;
    images?: Array<Image>;
    image?: any;
}