<div mat-dialog-content>
  <div class="text-center">
    <img src="../../../../assets/logo.svg" alt="loyale" width="100">

    <div class="mt-3">
      <div>Loyale Co Ltd, 2/2 Ferralco Bldgs,<br>Brewery Street, Central Business District,<br>CBD 3040, Malta</div>
    </div>

    <div class="mt-3">
      <div>Version: {{env.version}}<span *ngIf="!env.production">&nbsp;{{env.name}}</span></div>
      <div>Timezone: {{timezone.utc}} {{timezone.locale}}</div>
      <div>Language: {{language}}</div>
    </div>

    <div class="d-flex justify-content-center mt-3">
      <a href="https://www.facebook.com/loyale.io/?ref_src=loyale_backoffice" target="_blank" mat-icon-button
        color="primary">
        <i class="loyale-icon-facebook" style="font-size: 1.3rem;"></i>
      </a>
      <a href="https://www.linkedin.com/company/loyaleio?ref_src=loyale_backoffice" target="_blank" mat-icon-button
        color="primary">
        <i class="loyale-icon-linkedin" style="font-size: 1.3rem;"></i>
      </a>
      <a href="https://twitter.com/loyale_io?ref_src=loyale_backoffice" target="_blank" mat-icon-button color="primary">
        <i class="loyale-icon-twitter" style="font-size: 1.3rem;"></i>
      </a>
      <a href="https://www.instagram.com/loyale.io/?ref_src=loyale_backoffice" target="_blank" mat-icon-button
        color="primary">
        <i class="loyale-icon-instagram" style="font-size: 1.3rem;"></i>
      </a>
    </div>

    <div class="mt-4 small">&copy; loyale - {{year}}</div>
  </div>
</div>
