import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { SchemeResultView, SchemeService } from 'src/app/swagger';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'loyale-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent implements OnInit {
  scheme: SchemeResultView = <SchemeResultView>{};

  constructor(private translate: TranslateService, public sessionService: SessionService, public commonService: CommonService, private snackBar: MatSnackBar, private dialog: MatDialog, private schemeService: SchemeService) { }

  ngOnInit() {

  }

  onPutOffline() {
    let message = 'Are you sure you want to put your scheme offline?';
    let warning = 'This will exclude your scheme from being searchable by new customers.';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, warning: warning }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getScheme(true);
      }
    });
  }

  onPutOnline() {
    let message = 'Are you sure you want to put your scheme online?';
    let warning = 'This will allow your scheme to be found by new customers.';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: message, warning: warning }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getScheme(false);
      }
    });
  }

  getScheme(hidden: boolean) {
    this.schemeService.apiSchemeIdGet(this.sessionService.schemeId).subscribe(res => {
      this.scheme = res;
      this.scheme.hidden = hidden;
      this.updateScheme();
    })
  }

  updateScheme() {
    this.schemeService.apiSchemePut(this.scheme).subscribe(res => {
      this.snackBar.open(this.translate.instant('Updated'));
      this.sessionService.scheme = res;
    }, err => {
      this.commonService.errorHandler(err);
    })
  }
}
