<h1 mat-dialog-title>{{'Confirm' | translate}}</h1>
<div mat-dialog-content>
    <p>{{data?.message | translate}}</p>
</div>
<div mat-dialog-actions>
    <div class="flex-fill"></div>
    <button mat-flat-button [mat-dialog-close]>{{ data?.cancelButtonLabel | translate}}</button>
    <button cdkFocusInitial mat-flat-button [mat-dialog-close]="true" color="primary"
            class="ml-1">{{data?.confirmButtonLabel |
      translate}}</button>
</div>
