/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Plan } from './plan';
import { Scheme } from './scheme';
import { SubscriptionType } from './subscriptionType';

export interface SchemeSubscription { 
    id?: string;
    schemeId?: string;
    scheme?: Scheme;
    subscriptionType?: SubscriptionType;
    planId?: string;
    plan?: Plan;
    createdDate?: Date;
    updatedDate?: Date;
}