import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { AdminRoleResultView, AdminService } from 'src/app/swagger';
import { ImageCropperDialogComponent } from '../dialogs/image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'loyale-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent implements OnInit {
  roles: AdminRoleResultView[] = []

  constructor(public sessionService: SessionService, private router: Router, private adminService: AdminService, private dialog: MatDialog, public commonService: CommonService) { }

  ngOnInit() {
    this.getRoles();
  }

  getRoles() {
    this.adminService.apiAdminRolesListGet().subscribe(res => {
      this.roles = res;
    })
  }

  openImageUploadDialog() {
    this.dialog.open(ImageCropperDialogComponent, { data: { width: 500, ratio: 1 / 1, debug: true }, width: '80vw' });
  }

  onSimulateRole(role) {
    this.router.navigate(['/dashboard'], { queryParamsHandling: 'merge' })
    this.sessionService.agentRole = role;
  }
}
