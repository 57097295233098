<h1 mat-dialog-title>Save filter</h1>
<form #form="ngForm" (ngSubmit)="onSubmit(form)">
    <div mat-dialog-content>
        <!-- <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name</mat-label>
            <input matInput name="name" #name="ngModel" [(ngModel)]="filter.title" required>
            <mat-error *ngIf="name?.errors?.required">Required</mat-error>
        </mat-form-field> -->
    </div>
    <div mat-dialog-actions>
        <div class="flex-fill"></div>
        <button type="button" mat-flat-button [mat-dialog-close]>Cancel</button>
        <button cdkFocusInitial mat-flat-button color="primary" class="ml-1"
            [disabled]="awaitingPost">Save</button>
    </div>
</form>