<div class="d-flex flex-row h-100" tourAnchor="stepnav008">
  <div class="level-one d-flex flex-column justify-content-between">
    <div class="option" [routerLink]="['/load']">
      <img src="../../../assets/identifier.svg" alt="loyale" height="28">
    </div>
    <div class="option" [ngClass]="{'active': sidenav.currentOption == 'general'}" (click)="setCurrentOption('general')"
      tourAnchor="stepnav001" matTooltip="General" matTooltipPosition="right" matTooltipClass="tooltip-custom">
      <mat-icon class="icon-resize">dashboard</mat-icon>
    </div>
    <ng-container
      *ngIf="sessionService.schemeStatus == 0 || sessionService.schemeStatus == 1 || sessionService.schemeStatus == 3  || sessionService.schemeStatus == 5">
      <div class="option" [ngClass]="{'active': sidenav.currentOption == 'customers'}" tourAnchor="stepnav002"
        (click)="setCurrentOption('customers')" matTooltip="Customers" matTooltipPosition="right"
        matTooltipClass="tooltip-custom"
        *ngIf="commonService.checkForAccess('Customers', sessionService.agentRole, 'read')">
        <mat-icon class="icon-resize">supervised_user_circle</mat-icon>
      </div>
      <div class="option" [ngClass]="{'active': sidenav.currentOption == 'reach'}" (click)="setCurrentOption('reach')"
        tourAnchor="stepnav003" matTooltip="Reach" matTooltipPosition="right" matTooltipClass="tooltip-custom"
        *ngIf="commonService.checkForAccess('Reach', sessionService.agentRole, 'read')">
        <mat-icon class="icon-resize">send</mat-icon>
      </div>
      <div class="option" [ngClass]="{'active': sidenav.currentOption == 'rewards'}"
        (click)="setCurrentOption('rewards')" matTooltip="Rewards" matTooltipPosition="right" tourAnchor="stepnav004"
        matTooltipClass="tooltip-custom"
        *ngIf="commonService.checkForAccess('Rewards', sessionService.agentRole, 'read')">
        <mat-icon class="icon-resize">workspace_premium</mat-icon>
      </div>
      <div class="option" [ngClass]="{'active': sidenav.currentOption == 'automations'}"
        (click)="setCurrentOption('automations')" matTooltip="Automations" matTooltipPosition="right"
        matTooltipClass="tooltip-custom" tourAnchor="stepnav005"
        *ngIf="commonService.checkForAccess('Automations', sessionService.agentRole, 'read')">
        <mat-icon class="icon-resize">bolt</mat-icon>
      </div>
      <div class="option" [ngClass]="{'active': sidenav.currentOption == 'addons'}" (click)="setCurrentOption('addons')"
        tourAnchor="stepnav006" matTooltip="Add-ons" matTooltipPosition="right" matTooltipClass="tooltip-custom"
        *ngIf="commonService.checkForAccess('Add-ons', sessionService.agentRole, 'read')">
        <mat-icon class="icon-resize">extension</mat-icon>
      </div>
    </ng-container>

    <div class="flex-fill"></div>

    <loyale-debug *ngIf="sessionService.superAdmin"></loyale-debug>

    <div class="option" (click)="openSchemePicker()" [matTooltip]="sessionService.schemeName" matTooltipPosition="right"
      matTooltipClass="tooltip-custom">
      <img [src]="sessionService.schemeImageUrl" [alt]="sessionService.schemeName" class="br-5" width="30">
    </div>
    <div class="option" [ngClass]="{'active': sidenav.currentOption == 'settings'}"
      (click)="setCurrentOption('settings')" matTooltip="Settings" matTooltipPosition="right"
      matTooltipClass="tooltip-custom" tourAnchor="stepnav007"
      *ngIf="commonService.checkForAccess('Settings', sessionService.agentRole, 'read')">
      <mat-icon class="icon-resize">settings</mat-icon>
    </div>
    <div class="option" [ngClass]="{'active': sidenav.currentOption == 'user'}" (click)="setCurrentOption('user')"
      matTooltip="User" matTooltipPosition="right" matTooltipClass="tooltip-custom">
      <mat-icon class="icon-resize">account_box</mat-icon>
    </div>
  </div>

  <div class="level-two" [ngClass]="{'collapsed': sidenav.isCollapsed}">
    <ng-container *ngIf="sidenav.currentOption == 'general'">
      <div class="items">
        <div class="title d-flex align-items-center justify-content-between mb-2 p-2"
          [ngClass]="{'px-3': !sidenav.isCollapsed}">
          <h5 class="m-0">{{'General' | translate}}</h5>
          <button mat-icon-button (click)="setIsCollapsed(!sidenav.isCollapsed)">
            <mat-icon *ngIf="!sidenav.isCollapsed">menu_open</mat-icon>
            <mat-icon *ngIf="sidenav.isCollapsed">list</mat-icon>
          </button>
        </div>
        <a [routerLink]="['dashboard']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Dashboard': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>analytics</mat-icon>
          <span matLine>{{'Dashboard' | translate}}</span>
        </a>
        <ng-container
          *ngIf="sessionService.schemeStatus == 0 || sessionService.schemeStatus == 1 || sessionService.schemeStatus == 3 || sessionService.schemeStatus == 5">
          <a [routerLink]="['transactions']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Transactions': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Transactions', sessionService.agentRole, 'read')">
            <mat-icon>compare_arrows</mat-icon>
            <span matLine>{{'Transactions' | translate}}</span>
          </a>
          <a [routerLink]="['reports']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Reports': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Reports', sessionService.agentRole, 'read')">
            <mat-icon>lab_profile</mat-icon>
            <span matLine>{{'Reports' | translate}}</span>
          </a>
          <a [routerLink]="['franchises']" class="p-3" routerLinkActive="active"
            [matTooltip]="sidenav.isCollapsed? 'Brands': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Brands', sessionService.agentRole, 'read')">
            <mat-icon>storefront</mat-icon>
            <span matLine>{{'Brands' | translate}}</span>
          </a>
          <!-- <a [routerLink]="['product']" class="p-3 super-item" routerLinkActive="active"
            [matTooltip]="sidenav.isCollapsed? 'Products': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom" *ngIf="sessionService.superAdmin">
            <mat-icon>shopping_bag</mat-icon>
            <span matLine>{{'Products' | translate}}</span>
          </a> -->
          <a [routerLink]="['line-items']" class="p-3" routerLinkActive="active"
            [matTooltip]="sidenav.isCollapsed? 'Products': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom">
            <mat-icon>shopping_bag</mat-icon>
            <span matLine>{{'Products' | translate}}</span>
          </a>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="sidenav.currentOption == 'customers'">
      <div class="items">
        <div class="title d-flex align-items-center justify-content-between mb-2 p-2"
          [ngClass]="{'px-3': !sidenav.isCollapsed}">
          <h5 class="m-0">{{'Customers' | translate}}</h5>
          <button mat-icon-button (click)="setIsCollapsed(!sidenav.isCollapsed)">
            <mat-icon *ngIf="!sidenav.isCollapsed">menu_open</mat-icon>
            <mat-icon *ngIf="sidenav.isCollapsed">list</mat-icon>
          </button>
        </div>
        <a [routerLink]="['customers']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Customers': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>people</mat-icon>
          <span matLine>{{'Customers' | translate}}</span>
        </a>
        <a [routerLink]="['tiers']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Groups': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>folder</mat-icon>
          <span matLine>{{'Groups' | translate}}</span>
        </a>
        <a [routerLink]="['demographics']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Demographics': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>donut_large</mat-icon>
          <span matLine>{{'Demographics' | translate}}</span>
        </a>
        <a [routerLink]="['tags']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Tags': null" matTooltipPosition="right" matTooltipClass="tooltip-custom">
          <mat-icon>label</mat-icon>
          <span matLine>{{'Tags' | translate}}</span>
        </a>
        <a [routerLink]="['messages']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Messages': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>question_answer</mat-icon>
          <span matLine>{{'Messages' | translate}}</span>
        </a>

      </div>
    </ng-container>

    <ng-container *ngIf="sidenav.currentOption == 'reach'">
      <div class="items">
        <div class="title d-flex align-items-center justify-content-between mb-2 p-2"
          [ngClass]="{'px-3': !sidenav.isCollapsed}">
          <h5 class="m-0">{{'Reach' | translate}}</h5>
          <button mat-icon-button (click)="setIsCollapsed(!sidenav.isCollapsed)">
            <mat-icon *ngIf="!sidenav.isCollapsed">menu_open</mat-icon>
            <mat-icon *ngIf="sidenav.isCollapsed">list</mat-icon>
          </button>
        </div>
        <a [routerLink]="['push-notifications']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Push Notifications': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>notifications</mat-icon>
          <span matLine>{{'Push Notifications' | translate}}</span>
        </a>
        <a [routerLink]="['emails']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Emails': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>email</mat-icon>
          <span matLine>{{'Emails' | translate}}</span>
        </a>
        <a [routerLink]="['alerts']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Posts': null" matTooltipPosition="right" matTooltipClass="tooltip-custom">
          <mat-icon>sticky_note_2</mat-icon>
          <span matLine>{{'Posts' | translate}}</span>
        </a>
        <a [routerLink]="['feedbacks']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Feedback Forms': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>sms_failed</mat-icon>
          <span matLine>{{'Feedback Forms' | translate}}</span>
        </a>
        <a [routerLink]="['sms']" routerLinkActive="active" class="p-3" [matTooltip]="sidenav.isCollapsed? 'SMS': null"
          matTooltipPosition="right" matTooltipClass="tooltip-custom">
          <mat-icon>sms</mat-icon>
          <span matLine>{{'SMS' | translate}}</span>
        </a>
        <a [routerLink]="['banners']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Media Banners': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>screenshot_monitor</mat-icon>
          <span matLine>{{'Media Banners' | translate}}</span>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="sidenav.currentOption == 'rewards'">
      <div class="items">
        <div class="title d-flex align-items-center justify-content-between mb-2 p-2"
          [ngClass]="{'px-3': !sidenav.isCollapsed}">
          <h5 class="m-0">{{'Rewards' | translate}}</h5>
          <button mat-icon-button (click)="setIsCollapsed(!sidenav.isCollapsed)">
            <mat-icon *ngIf="!sidenav.isCollapsed">menu_open</mat-icon>
            <mat-icon *ngIf="sidenav.isCollapsed">list</mat-icon>
          </button>
        </div>
        <a [routerLink]="['points']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Points': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>auto_awesome</mat-icon>
          <span matLine>{{'Points' | translate}}</span>
        </a>
        <a [routerLink]="['coupons']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Coupons': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>local_play</mat-icon>
          <span matLine>{{'Coupons' | translate}}</span>
        </a>
        <a [routerLink]="['gift-cards']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Gift Cards': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>card_membership</mat-icon>
          <span matLine>{{'Gift Cards' | translate}}</span>
        </a>
        <a [routerLink]="['referrals']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Referrals': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>social_distance</mat-icon>
          <span matLine>{{'Referrals' | translate}}</span>
        </a>
        <a [routerLink]="['promo-codes']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Promo Codes': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>abc</mat-icon>
          <span matLine>{{'Promo Codes' | translate}}</span>
        </a>
        <a [routerLink]="['welcome']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Welcome': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>waving_hand</mat-icon>
          <span matLine>{{'Welcome' | translate}}</span>
        </a>
        <a [routerLink]="['lotteries']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Lotteries': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>trophy</mat-icon>
          <span matLine>{{'Lotteries' | translate}}</span>
        </a>
        <a [routerLink]="['collections']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Vouchers': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>inventory_2</mat-icon>
          <span matLine>{{'Vouchers' | translate}}</span>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="sidenav.currentOption == 'automations'">
      <div class="items">
        <div class="title d-flex align-items-center justify-content-between mb-2 p-2"
          [ngClass]="{'px-3': !sidenav.isCollapsed}">
          <h5 class="m-0">{{'Automations' | translate}}</h5>
          <button mat-icon-button (click)="setIsCollapsed(!sidenav.isCollapsed)">
            <mat-icon *ngIf="!sidenav.isCollapsed">menu_open</mat-icon>
            <mat-icon *ngIf="sidenav.isCollapsed">list</mat-icon>
          </button>
        </div>
        <a [routerLink]="['automations']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Automations': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>bolt</mat-icon>
          <span matLine>{{'Automations' | translate}}</span>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="sidenav.currentOption == 'addons'">
      <div class="items">
        <div class="title d-flex align-items-center justify-content-between mb-2 p-2"
          [ngClass]="{'px-3': !sidenav.isCollapsed}">
          <h5 class="m-0">{{'Addons' | translate}}</h5>
          <button mat-icon-button (click)="setIsCollapsed(!sidenav.isCollapsed)">
            <mat-icon *ngIf="!sidenav.isCollapsed">menu_open</mat-icon>
            <mat-icon *ngIf="sidenav.isCollapsed">list</mat-icon>
          </button>
        </div>
        <a [routerLink]="['integrations']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Integrations': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>hub</mat-icon>
          <span matLine>{{'Integrations' | translate}}</span>
        </a>
        <a [routerLink]="['webhooks']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Webhooks': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>webhook</mat-icon>
          <span matLine>{{'Webhooks' | translate}}</span>
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="sidenav.currentOption == 'settings'">
      <div class="items">
        <div class="title d-flex align-items-center justify-content-between mb-2 p-2"
          [ngClass]="{'px-3': !sidenav.isCollapsed}">
          <h5 class="m-0">{{'Settings' | translate}}</h5>
          <button mat-icon-button (click)="setIsCollapsed(!sidenav.isCollapsed)">
            <mat-icon *ngIf="!sidenav.isCollapsed">menu_open</mat-icon>
            <mat-icon *ngIf="sidenav.isCollapsed">list</mat-icon>
          </button>
        </div>
        <div class="flex-fill"></div>
        <ng-container
          *ngIf="sessionService.schemeStatus == 0 || sessionService.schemeStatus == 1 || sessionService.schemeStatus == 3 || sessionService.schemeStatus == 5">
          <a [routerLink]="['/settings/setup-guide']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Setup Guide': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Setup', sessionService.agentRole, 'write')">
            <mat-icon>task_alt</mat-icon>
            <span matLine>{{'Setup Guide' | translate}}</span>
          </a>
          <a [routerLink]="['/settings/scheme']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Scheme': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Scheme', sessionService.agentRole, 'write')">
            <mat-icon>work</mat-icon>
            <span matLine>{{'Scheme' | translate}}</span>
          </a>
          <a [routerLink]="['/settings/application']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Application': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Scheme', sessionService.agentRole, 'write')">
            <mat-icon>devices</mat-icon>
            <span matLine>{{'Application' | translate}}</span>
          </a>
          <a [routerLink]="['/settings/agents']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Agents': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Agents', sessionService.agentRole, 'write')">
            <mat-icon>support_agent</mat-icon>
            <span matLine>{{'Agents' | translate}}</span>
          </a>
          <a [routerLink]="['/settings/policies']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Policies': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Policies', sessionService.agentRole, 'write')">
            <mat-icon>policy</mat-icon>
            <span matLine>{{'Policies' | translate}}</span>
          </a>
          <a [routerLink]="['/settings/api-keys']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'API Keys': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('API Keys', sessionService.agentRole, 'write')">
            <mat-icon>vpn_key</mat-icon>
            <span matLine>{{'API Keys' | translate}}</span>
          </a>
        </ng-container>
        <ng-container *ngIf="sessionService.schemePaymentType != 2 || sessionService.superAdmin">
          <a [routerLink]="['/settings/subscription']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Subscription': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Subscription', sessionService.agentRole, 'read')">
            <mat-icon>payment</mat-icon>
            <span matLine>{{'Subscription' | translate}}</span>
          </a>
        </ng-container>
        <ng-container
          *ngIf="sessionService.schemeStatus == 0 || sessionService.schemeStatus == 1 || sessionService.schemeStatus == 3 || sessionService.schemeStatus == 5">
          <a [routerLink]="['/settings/stripe-connect']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Payments': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Payments', sessionService.agentRole, 'write')">
            <mat-icon>monetization_on</mat-icon>
            <span matLine>{{'Payments' | translate}}</span>
          </a>
          <a [routerLink]="['/settings/email']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Email': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Email', sessionService.agentRole, 'write')">
            <mat-icon>alternate_email</mat-icon>
            <span matLine>{{'Email' | translate}}</span>
          </a>
          <a [routerLink]="['/settings/verification']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Verification': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Verification', sessionService.agentRole, 'write')">
            <mat-icon>verified</mat-icon>
            <span matLine>{{'Verification' | translate}}</span>
          </a>
          <a [routerLink]="['/settings/reset']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Reset': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom">
            <mat-icon>lock_reset</mat-icon>
            <span matLine>{{'Reset' | translate}}</span>
          </a>
          <a [routerLink]="['/settings/inactivity']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Inactivity': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom">
            <mat-icon>speed</mat-icon>
            <span matLine>{{'Inactivity' | translate}}</span>
          </a>
          <a [routerLink]="['/settings/configuration']" routerLinkActive="active" class="p-3"
            [matTooltip]="sidenav.isCollapsed? 'Configuration': null" matTooltipPosition="right"
            matTooltipClass="tooltip-custom"
            *ngIf="commonService.checkForAccess('Configuration', sessionService.agentRole, 'write')">
            <mat-icon>tune</mat-icon>
            <span matLine>{{'Configuration' | translate}}</span>
          </a>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="sidenav.currentOption == 'user'">
      <div class="items">
        <div class="title d-flex align-items-center justify-content-between mb-2 p-2"
          [ngClass]="{'px-3': !sidenav.isCollapsed}">
          <h5 class="m-0">{{'User' | translate}}</h5>
          <button mat-icon-button (click)="setIsCollapsed(!sidenav.isCollapsed)">
            <mat-icon *ngIf="!sidenav.isCollapsed">menu_open</mat-icon>
            <mat-icon *ngIf="sidenav.isCollapsed">list</mat-icon>
          </button>
        </div>
        <div class="flex-fill"></div>
        <a [routerLink]="['user/account']" routerLinkActive="active" class="p-3"
          [matTooltip]="sidenav.isCollapsed? 'Account': null" matTooltipPosition="right"
          matTooltipClass="tooltip-custom">
          <mat-icon>account_circle</mat-icon>
          <span matLine>{{'Account' | translate}}</span>
        </a>
        <a (click)="openAppInfo()" class="p-3 cursor-pointer" [matTooltip]="sidenav.isCollapsed? 'About': null"
          matTooltipPosition="right" matTooltipClass="tooltip-custom">
          <mat-icon>info</mat-icon>
          <span matLine>{{'About' | translate}}</span>
        </a>
        <a [routerLink]="['/signout']" class="p-3" [matTooltip]="sidenav.isCollapsed? 'Sign Out': null"
          matTooltipPosition="right" matTooltipClass="tooltip-custom">
          <mat-icon>logout</mat-icon>
          <span matLine>{{'Sign Out' | translate}}</span>
        </a>
      </div>
    </ng-container>
  </div>
</div>
