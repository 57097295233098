/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BasicCustomer } from './basicCustomer';
import { BasicCustomerModel } from './basicCustomerModel';
import { CouponResultView } from './couponResultView';
import { CustomerWithTransactionDetailsResultView } from './customerWithTransactionDetailsResultView';
import { GiftCardLinkedResultSimplifiedView } from './giftCardLinkedResultSimplifiedView';
import { LevelResultView } from './levelResultView';
import { LineItemInsertView } from './lineItemInsertView';
import { LoyaleTransactionType } from './loyaleTransactionType';
import { OutletResultView } from './outletResultView';
import { SchemeResultView } from './schemeResultView';

export interface TransactionResultView { 
    id?: string;
    value: string;
    readonly monetaryValue?: number;
    saleCurrency: string;
    lineItems?: Array<LineItemInsertView>;
    couponsUsed?: Array<CouponResultView>;
    customerId: string;
    customer?: BasicCustomerModel;
    transactionType: LoyaleTransactionType;
    posId?: string;
    posType?: string;
    outletId?: string;
    outlet?: OutletResultView;
    pointValue: number;
    allocatedPointValue: number;
    cachedResultantPointValue: number;
    deleted: boolean;
    externalRefId: string;
    description: string;
    transactionDate: Date;
    createdBy?: string;
    createdDate?: Date;
    updatedBy?: string;
    updatedDate?: Date;
    schemeId?: string;
    scheme?: SchemeResultView;
    isSplit?: boolean;
    splittedBetween?: Array<CustomerWithTransactionDetailsResultView>;
    isSend?: boolean;
    targetTransactionId?: string;
    targetCustomer?: BasicCustomer;
    sourceTransactionId?: string;
    sourceCustomer?: BasicCustomer;
    hidden?: boolean;
    groupId?: string;
    levelId?: string;
    level?: LevelResultView;
    giftCardsUsed?: Array<GiftCardLinkedResultSimplifiedView>;
    splitBetween?: Array<TransactionResultView>;
    receiptNumber?: string;
}