import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CouponService } from '../../../swagger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewCouponDialogComponent } from '../../action-selector-dialog/new-coupon-dialog/new-coupon-dialog.component';

@Component({
  selector: 'loyale-action-coupon-selector',
  templateUrl: './action-coupon-selector.component.html',
  styleUrls: ['./action-coupon-selector.component.scss']
})
export class ActionCouponSelectorComponent implements OnInit {

  awaitingGet = false;
  coupons = [];
  filteredCoupons = [];
  @Input() selectedCouponId = null;
  @Output() outputCouponId = new EventEmitter<any>();
  @Output() removeCoupon = new EventEmitter<any>();

  couponFilter = null;

  constructor(private couponsService: CouponService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getCoupons();
  }

  getCoupons() {
    this.awaitingGet = true;
    this.couponsService.apiCouponGet(null, '-createdDate').subscribe(res => {
      this.awaitingGet = false;
      this.coupons = res;
      this.filteredCoupons = this.coupons;
    });
  }

  onNewCoupon() {
    const dialogRef = this.dialog.open(NewCouponDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getCoupons();
        this.selectedCouponId = res.id;
        this.onCouponChange();
      }
    });
  }

  onCouponChange() {
    this.outputCouponId.emit(this.selectedCouponId);
  }

  onFilterCoupons() {
    if (!this.coupons) {
      return;
    }

    let filter = this.couponFilter;
    if (!filter) {
      this.filteredCoupons = this.coupons;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredCoupons = this.coupons.filter(coupon => coupon.name.toLowerCase().indexOf(filter) > -1);
  }

}
