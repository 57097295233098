import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionTypeIcon'
})
export class TransactionTypeIconPipe implements PipeTransform {

  transform(value: any): string {
    if (value == 0) {
      return 'add_circle';
    } else if (value == 1) {
      return 'add_circle_outline';
    } else if (value == 2) {
      return 'remove_circle_outline';
    } else if (value == 3) {
      return 'remove_circle';
    } else if (value == 4) {
      //refun eventually, maybe?
      return 'arrow_circle_down';
    } else if (value == 5) {
      return 'arrow_circle_up';
    } else if (value == 6) {
      return 'block';
    }
  }

}
