import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { TransactionReportView, ChartService } from 'src/app/swagger';
import { SessionService } from 'src/app/services/session.service';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';

@Component({
  selector: 'loyale-audience-chart',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.scss']
})
export class AudienceComponent {
  @Input() dateFrom;
  @Input() dateTo;

  chart: any[] = [];

  colorScheme = {
    domain: ['#7b61e5', '#b3a1ff', '#f86e36']
  };

  report = { dates: [], joined: [], left: [], total: [] };

  constructor(private chartService: ChartService, private sessionService: SessionService, public commonService: CommonService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.getChart();
  }

  getChart() {
    this.chartService.apiChartAudienceTzOffsetGet((this.commonService.getTimezone().offset / 60), new Date(this.dateFrom), new Date(this.dateTo), this.sessionService.schemeId).subscribe(res => {
      this.chart = [];

      this.report = res;

      this.report.dates.forEach((date, index) => {
        this.chart.push({
          name: date.toString(),
          series: [
            {
              name: 'Joined',
              value: this.report.joined[index]
            },
            {
              name: 'Left',
              value: this.report.left[index]
            },
            {
              name: 'Total',
              value: this.report.total[index]
            }
          ],
        });
      });
    }, err => {
      this.commonService.errorHandler(err);
    });
  }
}
