import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loyale-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss']
})
export class ActionCardComponent implements OnInit {

  @Input() selectedFlag = false;
  @Input() icon = '';
  @Input() title = '';
  @Input() description = '';
  constructor() { }

  ngOnInit() {
  }

}
