import { CommonService } from 'src/app/services/common.service';
import { EmailSettingsService } from './../../swagger/api/emailSettings.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { SchemeResultView, EmailSettingPutView, EmailResultView } from 'src/app/swagger';

class EmailConfig {
  headerUrl: string;
  footerUrl: string;
  signature: string;
}

@Component({
  selector: 'loyale-preview-email',
  templateUrl: './preview-email.component.html',
  styleUrls: ['./preview-email.component.scss']
})
export class PreviewEmailComponent implements OnInit {
  awaitingGet = false;
  @Input() dialogMode = false;
  @Input() message = null;
  @Input() email: EmailResultView;

  scheme: SchemeResultView = <SchemeResultView>{};

  settings: EmailSettingPutView = <EmailSettingPutView>{};

  constructor(public sessionService: SessionService, private emailSettingsService: EmailSettingsService, public commonService: CommonService) { }

  ngOnInit() {
    this.getEmailSettings();

    if (this.message == undefined) {
      this.message = '';
    }
  }

  getEmailSettings() {
    this.awaitingGet = true;
    this.emailSettingsService.apiEmailSettingsGet(this.sessionService.schemeId).subscribe(res => {
      this.awaitingGet = false;
      this.settings = res;
    }, err => {
      this.awaitingGet = false;
      this.commonService.errorHandler(err);
    });
  }
}
