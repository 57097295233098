<span class="label">{{label}}<span *ngIf="required"> *</span></span>

<div class="map">
    <google-map width="full" [zoom]="zoom" [options]="mapOptions" [center]="coordinates" (mapClick)="onMapClick($event)"
                (zoomChanged)="onZoomChange($event)">

        <div *ngFor="let position of markerPositions">
            <map-marker
                        [position]="position" [options]="markerOptions"></map-marker>
        </div>


    </google-map>
</div>
