import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { CollectionResultView } from 'src/app/swagger';

@Component({
  selector: 'loyale-mini-collection',
  templateUrl: './mini-collection.component.html',
  styleUrls: ['./mini-collection.component.scss']
})
export class MiniCollectionComponent implements OnInit {
  @Input() collection: CollectionResultView = <CollectionResultView>{}
  @Input() disabled = false;

  constructor(public sessionService: SessionService) { }


  ngOnInit() {
  }

}
