import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'substring'
})
export class SubstringPipe implements PipeTransform {
  ellipses = '';

  transform(value: any, side: string, count: number, withEllipses?: boolean): string {
    if (value) {

      if (side == 's') {
        let returnValue = value.substring(0, count);

        if (withEllipses && value.length > count) {
          returnValue = returnValue + ' ...';
        }

        return returnValue;
      } else if (side == 'e') {
        let returnValue = value.substring(value.length - count, value.length);

        if (withEllipses && value.length > count) {
          returnValue = '... ' + returnValue;
        }

        return returnValue;
      }
    }

  }

}
