import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from 'src/app/services/session.service';
import { SavedFilterInsertView } from 'src/app/swagger/model/savedFilterInsertView';
import { CommonService } from 'src/app/services/common.service';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  type: any;
  filter: string;
}

@Component({
  selector: 'loyale-save-filter',
  templateUrl: './save-filter.component.html',
  styleUrls: ['./save-filter.component.scss']
})
export class SaveFilterComponent implements OnInit {
  awaitingPost = false;
  filter: SavedFilterInsertView = <SavedFilterInsertView>{}

  constructor(private translate: TranslateService, @Inject(MAT_DIALOG_DATA) public data: DialogData, private dialogRef: MatDialogRef<SaveFilterComponent>, private snackBar: MatSnackBar, public sessionService: SessionService, public commonService: CommonService) { }

  ngOnInit() {
    this.filter.type = this.data.type;
    //this.filter.filter = this.data.filter;
  }

  onSubmit(form: any) {
    //todo
    // if (form.valid) {
    //   this.awaitingPost = true;
    //   this.savedFilterSevice.apiSavedFilterPost(this.sessionService.schemeId, this.filter).subscribe(res => {
    //     this.snackBar.open(this.translate.instant('Saved'));
    //     this.dialogRef.close(true);
    //   }, err => {
    //     this.awaitingPost = false;
    //     this.commonService.errorHandler(err);
    //   })
    // }
  }
}
