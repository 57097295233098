/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AppType } from './appType';
import { BarcodeType } from './barcodeType';
import { CustomLinkInsertView } from './customLinkInsertView';

export interface ApplicationSettingInsertView { 
    color1?: string;
    color2?: string;
    customLinks?: Array<CustomLinkInsertView>;
    barcodeType?: BarcodeType;
    appType?: AppType;
    iosAppUrl?: string;
    androidAppUrl?: string;
    webAppUrl?: string;
    passTypeIdentifier?: string;
    teamIdentifier?: string;
    organizationName?: string;
    bannerUrl?: string;
    appleCertificateUrl?: string;
    appleCertificatePass?: string;
}