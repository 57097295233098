import { SessionService } from 'src/app/services/session.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GiftCardResultView } from 'src/app/swagger';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'loyale-preview-gift-card',
  templateUrl: './preview-gift-card.component.html',
  styleUrls: ['./preview-gift-card.component.scss']
})
export class PreviewGiftCardComponent implements OnInit {
  @Input() giftCard: GiftCardResultView = <GiftCardResultView>{};

  dummyBarCode = moment().unix();
  dummyQrCodeUrl = environment.baseApiUrl + '/api/barcode/qrcode?qrcode=' + this.dummyBarCode;
  dummyDate = moment();

  constructor(public sessionService: SessionService) { }

  ngOnInit() {

  }

}
