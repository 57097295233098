import { Component, OnInit, Input } from '@angular/core';
import { Session } from 'protractor';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'loyale-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss']
})
export class BubbleComponent implements OnInit {
  @Input() value = null;
  @Input() size = 'default';

  constructor(public sessionService: SessionService) { }

  ngOnInit() {
  }

}
