/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SchemeSetupProgressView { 
    readonly tasksTotal?: number;
    schemeDetailsSetupCompleted?: boolean;
    outletsSetupCompleted?: boolean;
    levelsSetupCompleted?: boolean;
    reachSetupCompleted?: boolean;
    rewardsSetupCompleted?: boolean;
    schemePolicySetupCompleted?: boolean;
    readonly tasksCompleted?: number;
}