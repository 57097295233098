<div class="sticky-wrapper">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="text-grey">Preview</div>
  </div>

  <div class="preview-sms">
    <div class="sms">
      <div class="d-flex flex-row">
        <div class="icon-wrapper mr-2">
          <img
            [src]="sessionService.schemeImageUrl ? sessionService.schemeImageUrl : 'assets/preview/notification/loyale-icon.svg'"
            width="40" height="40" class="br-5">
        </div>

        <div class="d-flex flex-column">
          <div class="d-flex align-items-center">
            <div class="text-grey">{{sessionService.schemeName}}</div>
            <div class="time text-grey">&nbsp;-&nbsp;15m</div>
          </div>
          <div class="text" [innerHtml]="sms.message? sms.message : 'Message'"></div>
        </div>
      </div>
    </div>
  </div>
</div>
