<div class="sticky-wrapper">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="text-grey">Preview</div>
  </div>

  <div class="preview-gift-card">
    <div class="list mb-3">
      <div class="d-flex flex-row align-items-center">
        <div class="image-wrapper mr-3">
          <img
            [src]="sessionService.schemeImageUrl ? sessionService.schemeImageUrl : './assets/preview/placeholder.png'"
            class="w-100 br-5">
        </div>
        <div class="text-wrapper d-flex flex-column">
          <div class="title">{{giftCard.name ? giftCard.name : 'Name'}}</div>
          <ng-container *ngIf="giftCard?.isDynamic">
            <div class="valid text-grey">Valid till {{dummyDate | date: 'dd/MM/yyyy'}}</div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="detail">
      <div class="image-wrapper d-flex flex-column align-items-center justify-content-center br-5 mb-3"
        [style.background]="sessionService.schemeColor">
        <div class="value" *ngIf="giftCard.isOpenValue">Open</div>
        <div class="value" *ngIf="!giftCard.isOpenValue">{{giftCard.value |
          currency:sessionService.schemeCurrency:'symbol':'1.2-2'}}</div>
        <div class="text">Gift Card</div>
      </div>

      <div class="qrcode-wrapper text-center mb-3">
        <img [src]="dummyQrCodeUrl" class="w-100">
        <div class="barcode">{{dummyBarCode}}</div>
      </div>

      <div class="text-wrapper">
        <ng-container *ngIf="giftCard?.isDynamic">
          <div class="valid text-grey">Valid till {{dummyDate | date: 'dd/MM/yyyy'}}</div>
        </ng-container>
        <div class="title">{{giftCard.name ? giftCard.name : 'Name'}}</div>
        <div class="description text-grey">{{giftCard.description ? giftCard.description : 'Description'}}</div>
        <hr />
        <div class="terms text-grey" [innerText]="giftCard.terms ? giftCard.terms : 'Terms & Conditions'"></div>
      </div>
    </div>
  </div>
</div>
