import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'loyale-pre-join',
  templateUrl: './pre-join.component.html',
  styleUrls: ['./pre-join.component.scss']
})
export class PreJoinComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar, public sessionService: SessionService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let code = params['code'] ? params['code'] : null;
      let schemeId = params['scheme'] ? params['scheme'] : null;

      if (code && schemeId) {
        if (this.sessionService.tokenValid()) {
          this.router.navigate(['/join'], { queryParamsHandling: 'merge' });
        } else {
          this.router.navigate(['/auth/join'], { queryParamsHandling: 'merge' });
        }
      } else {
        this.snackBar.open('Unable to join scheme, contact administrator');
        this.sessionService.signOut();
      }
    });
  }

}
