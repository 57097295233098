/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CouponItemView } from './couponItemView';
import { GiftCardItemView } from './giftCardItemView';
import { TaskPointsResultView } from './taskPointsResultView';

export interface TransactionSimulationResultView { 
    saleSubTotal?: number;
    saleTotal?: number;
    currentPointBalance?: number;
    readonly currentMonetaryBalance?: number;
    pointsValueGained?: number;
    readonly monetaryValueGained?: number;
    pointsValueRedeemed?: number;
    readonly monetaryValueRedeemed?: number;
    readonly newPointBalance?: number;
    readonly newMonetaryBalance?: number;
    coupons?: Array<CouponItemView>;
    giftCard?: Array<GiftCardItemView>;
    rewardPointsList?: Array<TaskPointsResultView>;
    pointRedemptionPerCurrency?: number;
    rewardPointsTotal?: number;
}