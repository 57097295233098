/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CouponsLinkedResultView } from '../model/couponsLinkedResultView';
import { Enabled } from '../model/enabled';
import { ShopifyApiType } from '../model/shopifyApiType';
import { ShopifyApperanceView } from '../model/shopifyApperanceView';
import { ShopifyCollectionsResultView } from '../model/shopifyCollectionsResultView';
import { ShopifyCreateCouponView } from '../model/shopifyCreateCouponView';
import { ShopifyGdprWebhookType } from '../model/shopifyGdprWebhookType';
import { ShopifyLevelDiscountView } from '../model/shopifyLevelDiscountView';
import { ShopifyLogType } from '../model/shopifyLogType';
import { ShopifyMetaFieldResultView } from '../model/shopifyMetaFieldResultView';
import { ShopifyMetafieldInsertView } from '../model/shopifyMetafieldInsertView';
import { ShopifyOrderWebhookType } from '../model/shopifyOrderWebhookType';
import { ShopifyOrderWebhookView } from '../model/shopifyOrderWebhookView';
import { ShopifyProductWebHook } from '../model/shopifyProductWebHook';
import { ShopifyProductWebHookType } from '../model/shopifyProductWebHookType';
import { ShopifyRedeemPointsView } from '../model/shopifyRedeemPointsView';
import { ShopifyRefundView } from '../model/shopifyRefundView';
import { ShopifySynchronizationProgressView } from '../model/shopifySynchronizationProgressView';
import { ShopifyThemeModifyView } from '../model/shopifyThemeModifyView';
import { ShopifyVendorView } from '../model/shopifyVendorView';
import { Webhook } from '../model/webhook';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ShopifyBasicService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicApperanceSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicApperanceSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicApperanceSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicApperanceSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicApperanceSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/ShopifyBasic/Apperance/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicApperanceSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicApperanceSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicApperanceSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicApperanceSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicApperanceSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/Apperance/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicApperanceSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicApperanceSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicApperanceSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicApperanceSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicApperanceSchemeIntegrationIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/ShopifyBasic/Apperance/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes config by id
     * 
     * @param schemeIntegrationId 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicConfigSchemeIntegrationIdDelete(schemeIntegrationId: string, id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicConfigSchemeIntegrationIdDelete(schemeIntegrationId: string, id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicConfigSchemeIntegrationIdDelete(schemeIntegrationId: string, id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicConfigSchemeIntegrationIdDelete(schemeIntegrationId: string, id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicConfigSchemeIntegrationIdDelete.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiShopifyBasicConfigSchemeIntegrationIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/ShopifyBasic/Config/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get config for shopify
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicConfigSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicConfigSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicConfigSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicConfigSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicConfigSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/ShopifyBasic/Config/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates or updates config for shopify
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicConfigSchemeIntegrationIdPut(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicConfigSchemeIntegrationIdPut(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicConfigSchemeIntegrationIdPut(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicConfigSchemeIntegrationIdPut(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicConfigSchemeIntegrationIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/ShopifyBasic/Config/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Configure webHooks for RIG
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicConfigureRigWebHooksSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Webhook>;
    public apiShopifyBasicConfigureRigWebHooksSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Webhook>>;
    public apiShopifyBasicConfigureRigWebHooksSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Webhook>>;
    public apiShopifyBasicConfigureRigWebHooksSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicConfigureRigWebHooksSchemeIntegrationIdPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Webhook>('post',`${this.basePath}/api/ShopifyBasic/ConfigureRigWebHooks/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Configure webHooks for specified scheme
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicConfigureWebHooksForSchemeSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Webhook>>;
    public apiShopifyBasicConfigureWebHooksForSchemeSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Webhook>>>;
    public apiShopifyBasicConfigureWebHooksForSchemeSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Webhook>>>;
    public apiShopifyBasicConfigureWebHooksForSchemeSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicConfigureWebHooksForSchemeSchemeIntegrationIdPost.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyBasicConfigureWebHooksForSchemeSchemeIntegrationIdPost.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Webhook>>('post',`${this.basePath}/api/ShopifyBasic/ConfigureWebHooksForScheme/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new coupon for shopify with percentage
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicCouponPercentageSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyCreateCouponView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiShopifyBasicCouponPercentageSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyCreateCouponView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiShopifyBasicCouponPercentageSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyCreateCouponView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiShopifyBasicCouponPercentageSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyCreateCouponView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicCouponPercentageSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('post',`${this.basePath}/api/ShopifyBasic/CouponPercentage/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new coupon for shopify
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyRedeemPointsView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiShopifyBasicCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyRedeemPointsView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiShopifyBasicCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyRedeemPointsView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiShopifyBasicCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyRedeemPointsView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicCouponSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('post',`${this.basePath}/api/ShopifyBasic/Coupon/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checks if the integration is enabled
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicEnabledSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/ShopifyBasic/Enabled/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checks if the integration is enabled
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicEnabledSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/Enabled/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns list of shopify collections
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicGetShopifyCollectionsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ShopifyCollectionsResultView>>;
    public apiShopifyBasicGetShopifyCollectionsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShopifyCollectionsResultView>>>;
    public apiShopifyBasicGetShopifyCollectionsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShopifyCollectionsResultView>>>;
    public apiShopifyBasicGetShopifyCollectionsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicGetShopifyCollectionsSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ShopifyCollectionsResultView>>('get',`${this.basePath}/api/ShopifyBasic/GetShopifyCollections/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicGetSyncTransactionsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicGetSyncTransactionsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicGetSyncTransactionsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicGetSyncTransactionsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicGetSyncTransactionsProgressSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/ShopifyBasic/GetSyncTransactionsProgress/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param type 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicGetWebhooksSchemeIntegrationIdTypeGet(schemeIntegrationId: string, type: ShopifyApiType, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicGetWebhooksSchemeIntegrationIdTypeGet(schemeIntegrationId: string, type: ShopifyApiType, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicGetWebhooksSchemeIntegrationIdTypeGet(schemeIntegrationId: string, type: ShopifyApiType, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicGetWebhooksSchemeIntegrationIdTypeGet(schemeIntegrationId: string, type: ShopifyApiType, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicGetWebhooksSchemeIntegrationIdTypeGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyBasicGetWebhooksSchemeIntegrationIdTypeGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/ShopifyBasic/GetWebhooks/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(type))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates binding of outletId with franchise name
     * 
     * @param schemeIntegrationId 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdGet(schemeIntegrationId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdGet(schemeIntegrationId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdGet(schemeIntegrationId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdGet(schemeIntegrationId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicLevelDiscountSchemeIntegrationIdGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/ShopifyBasic/LevelDiscount/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates binding of outletId with franchise name
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicLevelDiscountSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/LevelDiscount/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates binding of outletId with franchise name
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdPut(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdPut(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdPut(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicLevelDiscountSchemeIntegrationIdPut(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicLevelDiscountSchemeIntegrationIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/ShopifyBasic/LevelDiscount/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param type 
     * @param scheme 
     * @param date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicLogsTypeSchemeIntegrationIdPost(schemeIntegrationId: string, type: ShopifyLogType, scheme?: string, date?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicLogsTypeSchemeIntegrationIdPost(schemeIntegrationId: string, type: ShopifyLogType, scheme?: string, date?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicLogsTypeSchemeIntegrationIdPost(schemeIntegrationId: string, type: ShopifyLogType, scheme?: string, date?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicLogsTypeSchemeIntegrationIdPost(schemeIntegrationId: string, type: ShopifyLogType, scheme?: string, date?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicLogsTypeSchemeIntegrationIdPost.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyBasicLogsTypeSchemeIntegrationIdPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined && date !== null) {
            queryParameters = queryParameters.set('date', <any>date.toISOString());
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/Logs/${encodeURIComponent(String(type))}/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates meta field
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicMetaFieldSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyMetafieldInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<ShopifyMetaFieldResultView>;
    public apiShopifyBasicMetaFieldSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyMetafieldInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShopifyMetaFieldResultView>>;
    public apiShopifyBasicMetaFieldSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyMetafieldInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShopifyMetaFieldResultView>>;
    public apiShopifyBasicMetaFieldSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyMetafieldInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicMetaFieldSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ShopifyMetaFieldResultView>('post',`${this.basePath}/api/ShopifyBasic/MetaField/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param type 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicOnGdprTypePost(type: ShopifyGdprWebhookType, body?: { [key: string]: any; }, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicOnGdprTypePost(type: ShopifyGdprWebhookType, body?: { [key: string]: any; }, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicOnGdprTypePost(type: ShopifyGdprWebhookType, body?: { [key: string]: any; }, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicOnGdprTypePost(type: ShopifyGdprWebhookType, body?: { [key: string]: any; }, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyBasicOnGdprTypePost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/OnGdpr/${encodeURIComponent(String(type))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param type 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicOnOrderRigTypeSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, type: ShopifyOrderWebhookType, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicOnOrderRigTypeSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, type: ShopifyOrderWebhookType, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicOnOrderRigTypeSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, type: ShopifyOrderWebhookType, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicOnOrderRigTypeSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, type: ShopifyOrderWebhookType, schemeId: string, body?: ShopifyOrderWebhookView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicOnOrderRigTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyBasicOnOrderRigTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyBasicOnOrderRigTypeSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/OnOrderRig/${encodeURIComponent(String(type))}/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param type 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicOnOrderTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicOnOrderTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicOnOrderTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicOnOrderTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyBasicOnOrderTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicOnOrderTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyBasicOnOrderTypeSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/OnOrder/${encodeURIComponent(String(type))}/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Webhook for customer register event
     * 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyBasicOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/OnShopifyCustomerCreate/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param type 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, type: ShopifyProductWebHookType, schemeId: string, body?: ShopifyProductWebHook, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, type: ShopifyProductWebHookType, schemeId: string, body?: ShopifyProductWebHook, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, type: ShopifyProductWebHookType, schemeId: string, body?: ShopifyProductWebHook, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, type: ShopifyProductWebHookType, schemeId: string, body?: ShopifyProductWebHook, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyBasicOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyBasicOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/OnShopifyProduct/${encodeURIComponent(String(type))}/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyBasicOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/OnShopifyRefundRig/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicOnShopifyRefundSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicOnShopifyRefundSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicOnShopifyRefundSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicOnShopifyRefundSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicOnShopifyRefundSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyBasicOnShopifyRefundSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/OnShopifyRefund/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes webhooks for scheme
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicRemoveWebHooksSchemeIntegrationIdDelete(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Webhook>>;
    public apiShopifyBasicRemoveWebHooksSchemeIntegrationIdDelete(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Webhook>>>;
    public apiShopifyBasicRemoveWebHooksSchemeIntegrationIdDelete(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Webhook>>>;
    public apiShopifyBasicRemoveWebHooksSchemeIntegrationIdDelete(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicRemoveWebHooksSchemeIntegrationIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Webhook>>('delete',`${this.basePath}/api/ShopifyBasic/RemoveWebHooks/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicRestoreSkusSchemeIntegrationIdPut(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicRestoreSkusSchemeIntegrationIdPut(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicRestoreSkusSchemeIntegrationIdPut(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicRestoreSkusSchemeIntegrationIdPut(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicRestoreSkusSchemeIntegrationIdPut.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/ShopifyBasic/RestoreSkus/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicSetUpSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/SetUp/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets synchronization progress
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicSyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<ShopifySynchronizationProgressView>;
    public apiShopifyBasicSyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShopifySynchronizationProgressView>>;
    public apiShopifyBasicSyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShopifySynchronizationProgressView>>;
    public apiShopifyBasicSyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicSyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyBasicSyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShopifySynchronizationProgressView>('get',`${this.basePath}/api/ShopifyBasic/SyncLoyaleCollectionProductsProgress/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets synchronization progress
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicSyncLoyaleCollectionProductsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicSyncLoyaleCollectionProductsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicSyncLoyaleCollectionProductsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicSyncLoyaleCollectionProductsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicSyncLoyaleCollectionProductsSchemeIntegrationIdPost.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyBasicSyncLoyaleCollectionProductsSchemeIntegrationIdPost.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/SyncLoyaleCollectionProducts/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param dateFrom 
     * @param dateTo 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicSyncTransactionsSchemeIntegrationIdDateFromDateToPost(schemeIntegrationId: string, dateFrom: Date, dateTo: Date, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicSyncTransactionsSchemeIntegrationIdDateFromDateToPost(schemeIntegrationId: string, dateFrom: Date, dateTo: Date, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicSyncTransactionsSchemeIntegrationIdDateFromDateToPost(schemeIntegrationId: string, dateFrom: Date, dateTo: Date, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicSyncTransactionsSchemeIntegrationIdDateFromDateToPost(schemeIntegrationId: string, dateFrom: Date, dateTo: Date, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicSyncTransactionsSchemeIntegrationIdDateFromDateToPost.');
        }

        if (dateFrom === null || dateFrom === undefined) {
            throw new Error('Required parameter dateFrom was null or undefined when calling apiShopifyBasicSyncTransactionsSchemeIntegrationIdDateFromDateToPost.');
        }

        if (dateTo === null || dateTo === undefined) {
            throw new Error('Required parameter dateTo was null or undefined when calling apiShopifyBasicSyncTransactionsSchemeIntegrationIdDateFromDateToPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/SyncTransactions/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(dateFrom))}/${encodeURIComponent(String(dateTo))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets synchronization progress
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicSyncVendorsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<ShopifySynchronizationProgressView>;
    public apiShopifyBasicSyncVendorsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShopifySynchronizationProgressView>>;
    public apiShopifyBasicSyncVendorsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShopifySynchronizationProgressView>>;
    public apiShopifyBasicSyncVendorsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicSyncVendorsProgressSchemeIntegrationIdGet.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyBasicSyncVendorsProgressSchemeIntegrationIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShopifySynchronizationProgressView>('get',`${this.basePath}/api/ShopifyBasic/SyncVendorsProgress/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets synchronization progress
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicSyncVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicSyncVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicSyncVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicSyncVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicSyncVendorsSchemeIntegrationIdPost.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyBasicSyncVendorsSchemeIntegrationIdPost.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/SyncVendors/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets up assets for current theme
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicThemeSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyThemeModifyView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicThemeSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyThemeModifyView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicThemeSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyThemeModifyView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicThemeSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyThemeModifyView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicThemeSetUpSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/ThemeSetUp/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Tries to use existing coupon
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicTryUseCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiShopifyBasicTryUseCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiShopifyBasicTryUseCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiShopifyBasicTryUseCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicTryUseCouponSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('post',`${this.basePath}/api/ShopifyBasic/TryUseCoupon/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets list of vendors
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicVendorsSchemeIntegrationIdGet.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyBasicVendorsSchemeIntegrationIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/ShopifyBasic/Vendors/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates new binding of outletId with franchise name
     * 
     * @param scheme 
     * @param schemeIntegrationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicVendorsSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicVendorsSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicVendorsSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicVendorsSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyBasicVendorsSchemeIntegrationIdPost.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicVendorsSchemeIntegrationIdPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/ShopifyBasic/Vendors/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates binding of outletId with franchise name
     * 
     * @param scheme 
     * @param schemeIntegrationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyBasicVendorsSchemeIntegrationIdPut(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyBasicVendorsSchemeIntegrationIdPut(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyBasicVendorsSchemeIntegrationIdPut(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyBasicVendorsSchemeIntegrationIdPut(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyBasicVendorsSchemeIntegrationIdPut.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyBasicVendorsSchemeIntegrationIdPut.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/ShopifyBasic/Vendors/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
