<button mat-icon-button [matMenuTriggerFor]="options" matTooltip="Options" class="ic_top-nav-options">
  <mat-icon>account_circle</mat-icon>
</button>
<mat-menu #options="matMenu">
  <a mat-menu-item [routerLink]="['/profile']">
    <mat-icon>account_circle</mat-icon>
    Profile
  </a>
  <a mat-menu-item href="https://docs.loyale.io/" target="_blank">
    <mat-icon>book</mat-icon>
    Documentation
  </a>
  <a mat-menu-item href="https://loyaleio.statuspage.io/" target="_blank">
    <mat-icon>crisis_alert</mat-icon>
    Status
  </a>
  <button mat-menu-item (click)="onAppInfo()">
    <mat-icon>info</mat-icon>
    About
  </button>
  <button mat-menu-item (click)="sessionService.signOut()">
    <mat-icon>logout</mat-icon>
    Sign out
  </button>
</mat-menu>
