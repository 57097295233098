import { ShireburnService } from './../swagger/api/shireburn.service';
import { DemographicsService, IProService, LightspeedRestoService, ShopifyService } from 'src/app/swagger';
import { Injectable } from '@angular/core';
import { NestedLevels, NestedOutlets, SessionService } from './session.service';
import { AdditionalSchemeFieldsService, AdditionalSchemeFieldsInsertView, SchemeService, AdminService, TierService, LevelService, FranchiseService, OutletService, LightspeedRetailService } from '../swagger';
import { CommonService } from './common.service';
import { ReplaySubject } from 'rxjs';

class EmailConfig {
  headerUrl: string;
  footerUrl: string;
  signature: string;
}

class ReferralsConfig {
  callbackUrl: string;
  message: string;
  sourceCustomerPoints: number;
  targetCustomerPoints: number;
  transactionOutletId: string;
  transactionDescription: string;
  uses: number;
  fulfilOnMobileVerify: boolean;
  fulfilOnEmailVerify: boolean;
  fulfilOnFirstPurchase: boolean;
}

class WelcomeConfig {
  pointsEnabled: boolean;
  points: string;
  description: string;
  emailEnabled: boolean;
  emailTemplate: string;
  emailSubject: string;
  emailMessage: string;
  notificationEnabled: boolean;
  notificationTitle: string;
  notificationMessage: string;
}

class ShopifyConfig {
  label: string;
  icon: string;
  launcher: number;
  primary: string;
  accent: string;
  position: string;
  welcome: string;
  visible: boolean
}

class WoocommerceConfg {
  label: string;
  icon: string;
  launcher: number;
  primary: string;
  accent: string;
  position: string;
  welcome: string;
  visible: boolean
}

@Injectable({
  providedIn: 'root'
})
export class PromiseService {
  emailConfigFields = {
    key: 'Email:Config',
    name: 'Email: Config'
  }

  referralsConfigFields = {
    key: 'Referrals:Config',
    name: 'Referrals: Config'
  }

  welcomeConfigFields = {
    key: 'Welcome:Config',
    name: 'Welcome: Config'
  }

  woocommerceConfigFields = {
    key: 'WoocommerceConfig',
    name: 'Woocommerce Config'
  }

  constructor(private sessionService: SessionService, private demographicsService: DemographicsService, private shopifyService: ShopifyService, private commonService: CommonService, private shireburnService: ShireburnService, private iProService: IProService, private lightspeedRestoService: LightspeedRestoService, private lightspeedRetailService: LightspeedRetailService, private franchiseService: FranchiseService, private outletService: OutletService, private tierService: TierService, private levelService: LevelService, private adminService: AdminService, private schemeService: SchemeService, private additionalSchemeFieldsService: AdditionalSchemeFieldsService) { }

  setDefaultEmailConfig() {
    const defaultConfig: EmailConfig = { headerUrl: 'https://loyale.s3.eu-west-1.amazonaws.com/emails/header_loyale.png', footerUrl: 'https://loyale.s3.eu-west-1.amazonaws.com/emails/footer.jpg', signature: this.sessionService.schemeName };

    let field: AdditionalSchemeFieldsInsertView = <AdditionalSchemeFieldsInsertView>{};
    field.key = this.emailConfigFields.key;
    field.name = this.emailConfigFields.name;
    field.value = JSON.stringify(defaultConfig);
    field.internal = true;
    field.requireAuth = false;

    return new Promise((resolve, reject) => {
      this.additionalSchemeFieldsService.apiAdditionalSchemeFieldsPost(this.sessionService.schemeId, field).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    })

  }

  setDefaultReferralsConfig(): Promise<any> {
    const defaultConfig: ReferralsConfig = {
      callbackUrl: null,
      message: null,
      sourceCustomerPoints: null,
      targetCustomerPoints: null,
      transactionOutletId: null,
      transactionDescription: null,
      uses: null,
      fulfilOnMobileVerify: null,
      fulfilOnEmailVerify: null,
      fulfilOnFirstPurchase: null,
    };

    let field: AdditionalSchemeFieldsInsertView = <AdditionalSchemeFieldsInsertView>{};
    field.key = this.referralsConfigFields.key;
    field.name = this.referralsConfigFields.name;
    field.value = JSON.stringify(defaultConfig);
    field.internal = true;
    field.requireAuth = false;

    return new Promise((resolve, reject) => {
      this.additionalSchemeFieldsService.apiAdditionalSchemeFieldsPost(this.sessionService.schemeId, field).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    })
  }

  setDefaultWelcomeConfig(): Promise<any> {
    const defaultConfig: WelcomeConfig = {
      pointsEnabled: false, points: null, description: null, emailEnabled: false, emailTemplate: 'Welcome', emailSubject: null, emailMessage: null, notificationEnabled: false, notificationTitle: null, notificationMessage: null
    };

    let field: AdditionalSchemeFieldsInsertView = <AdditionalSchemeFieldsInsertView>{};
    field.key = this.welcomeConfigFields.key;
    field.name = this.welcomeConfigFields.name;
    field.value = JSON.stringify(defaultConfig);
    field.internal = true;
    field.requireAuth = false;

    return new Promise((resolve, reject) => {
      this.additionalSchemeFieldsService.apiAdditionalSchemeFieldsPost(this.sessionService.schemeId, field).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    })
  }

  setDefaultWoocommerceConfig(): Promise<any> {
    const defaultConfig: WoocommerceConfg = { label: this.sessionService.schemeName, icon: 'toggle-loyale', launcher: 0, primary: '#8060ed', accent: '#f86e36', position: 'left', welcome: 'Welcome to ' + this.sessionService.schemeName, visible: false };

    let field: AdditionalSchemeFieldsInsertView = <AdditionalSchemeFieldsInsertView>{};
    field.requireAuth = false;
    field.key = this.woocommerceConfigFields.key;
    field.name = this.woocommerceConfigFields.name;
    field.value = JSON.stringify(defaultConfig);
    field.internal = true;
    field.requireAuth = false;

    return new Promise((resolve, reject) => {
      this.additionalSchemeFieldsService.apiAdditionalSchemeFieldsPost(this.sessionService.schemeId, field).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    })
  }

  loadSuperAdminSchemes(preselectedSchemeId?): Promise<any> {
    return new Promise((resolve, reject) => {
      this.schemeService.apiSchemeGet().subscribe(res => {
        let allSchemes = res;

        if (allSchemes.length == 0) {
          const err = { error: { errors: ['This agent is not associated with any scheme.'] } }
          reject(err);
        } else {
          if (preselectedSchemeId) {
            let selectedScheme = allSchemes.find(s => s.id == preselectedSchemeId);
            this.sessionService.agentRole = 'Owner';
            this.sessionService.scheme = selectedScheme;
          } else {
            this.sessionService.agentRole = 'Owner';
            this.sessionService.scheme = allSchemes[4];
          }

          resolve(true);
        }

        resolve(true);
      }, err => {
        reject(err);
      })
    });
  }

  loadAdminSchemes(preselectedSchemeId?): Promise<any> {
    return new Promise((resolve, reject) => {
      this.adminService.apiAdminGetSchemesAdminIdGet(this.sessionService.agentId).subscribe(res => {
        let allSchemesWithRoles = res;

        if (allSchemesWithRoles.length == 0) {
          const err = { error: { errors: ['This agent is not associated with any scheme.'] } }
          reject(err);
        } else {
          if (preselectedSchemeId) {
            let selectedSchemeWithRole = allSchemesWithRoles.find(s => s.schemeId == preselectedSchemeId);
            this.sessionService.agentRole = selectedSchemeWithRole.role;
            this.sessionService.scheme = selectedSchemeWithRole.scheme;
            if (selectedSchemeWithRole.token) {
              this.sessionService.token = selectedSchemeWithRole.token;
            }
          } else {
            this.sessionService.agentRole = allSchemesWithRoles[0].role;
            this.sessionService.scheme = allSchemesWithRoles[0].scheme;
            if (allSchemesWithRoles[0].token) {
              this.sessionService.token = allSchemesWithRoles[0].token;
            }
          }

          resolve(true);
        }
      }, err => {
        reject(err);
      })
    })
  }

  getNestedLevels(): Promise<any> {
    let nestedLevels: NestedLevels[] = [];

    return new Promise((resolve, reject) => {
      this.tierService.apiTierGet(null, 'name').subscribe(tiers => {
        for (let tier of tiers) {
          tier.levels.sort((x, y) => (x.order - y.order));
          nestedLevels.push({ tierName: tier.name, levels: tier.levels });
        }

        resolve(nestedLevels);
      }, err => {
        reject(err);
      })
    })
  }

  getNestedOutlets(): Promise<any> {
    let nestedOutlets: NestedOutlets[] = [];

    return new Promise((resolve, reject) => {
      this.franchiseService.apiFranchiseGet(null, 'name').subscribe(franchises => {
        for (let franchise of franchises) {
          franchise.outlets.sort((a, b) => (a.name < b.name ? -1 : 1));
          nestedOutlets.push({ franchiseName: franchise.name, outlets: franchise.outlets });
        }

        resolve(nestedOutlets);
      }, err => {
        reject(err);
      })
    })
  }

  getDemographicMatch(demographics): Promise<any> {
    return new Promise((resolve, reject) => {
      this.demographicsService.apiDemographicsDemographicsMatchPost(this.sessionService.schemeId, demographics).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    })
  }
}
