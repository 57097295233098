/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Billing } from './billing';
import { Links } from './links';
import { MetaData } from './metaData';
import { Shipping } from './shipping';
import { TaxLine } from './taxLine';
import { WooCommerceCoupon } from './wooCommerceCoupon';
import { WooCommerceLineItem } from './wooCommerceLineItem';
import { WooCommerceShippingLine } from './wooCommerceShippingLine';

export interface WooCommerceTransactionView { 
    id?: number;
    parentId?: number;
    number?: string;
    orderKey?: string;
    createdVia?: string;
    version?: string;
    status?: string;
    currency?: string;
    dateCreated?: Date;
    dateCreatedGmt?: Date;
    dateModified?: Date;
    dateModifiedGmt?: Date;
    discountTotal?: number;
    discountTax?: number;
    shippingTotal?: number;
    shippingTax?: number;
    cartTax?: number;
    total?: number;
    totalTax?: number;
    pricesIncludeTax?: boolean;
    customerId?: number;
    customerIpAddress?: string;
    customerUserAgent?: string;
    customerNote?: string;
    billing?: Billing;
    shipping?: Shipping;
    paymentMethod?: string;
    paymentMethodTitle?: string;
    transactionId?: string;
    datePaid?: Date;
    datePaidGmt?: Date;
    dateCompleted?: any;
    dateCompletedGmt?: any;
    cartHash?: string;
    metaData?: Array<MetaData>;
    lineItems?: Array<WooCommerceLineItem>;
    taxLines?: Array<TaxLine>;
    shippingLines?: Array<WooCommerceShippingLine>;
    feeLines?: Array<any>;
    couponLines?: Array<WooCommerceCoupon>;
    refunds?: Array<any>;
    links?: Links;
}