import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'loyale-temp-token',
  templateUrl: './temp-token.component.html',
  styleUrls: ['./temp-token.component.scss']
})
export class TempTokenComponent implements OnInit {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit() {}
}
