/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StripeAccountSettingsOptions } from './stripeAccountSettingsOptions';
import { StripeBusinessProfileSettings } from './stripeBusinessProfileSettings';
import { StripeBusinessType } from './stripeBusinessType';
import { StripeCompanyOptions } from './stripeCompanyOptions';
import { StripeIndividualProfileSettings } from './stripeIndividualProfileSettings';

export interface StripeOnboardingInsertView { 
    businessType?: StripeBusinessType;
    country?: string;
    email?: string;
    defaultCurrency?: string;
    companySettings?: StripeCompanyOptions;
    accountSettings?: StripeAccountSettingsOptions;
    businessProfileSettings?: StripeBusinessProfileSettings;
    individualProfileSettings?: StripeIndividualProfileSettings;
}