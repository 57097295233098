/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Plan { 
    id?: string;
    name?: string;
    rank?: number;
    monthlyBasePrice?: number;
    monthlyBasePriceUsd?: number;
    yearlyBasePrice?: number;
    yearlyBasePriceUsd?: number;
    agentsLimit?: number;
    customersLimit?: number;
    pushNotificationsLimit?: number;
    emailsLimit?: number;
    integrationsLimit?: number;
    hidden?: boolean;
    createdDate?: Date;
    updatedDate?: Date;
    multipleGroups?: boolean;
    multipleBrands?: boolean;
    multipleOutlets?: boolean;
    multipleLevels?: boolean;
    api?: boolean;
    posApp?: boolean;
    walletApp?: boolean;
    points?: boolean;
    referrals?: boolean;
    coupons?: boolean;
    pointsExpiry?: boolean;
    lotteries?: boolean;
    promoCodes?: boolean;
    messages?: boolean;
    giftCards?: boolean;
    pushNotifications?: boolean;
    emails?: boolean;
    posts?: boolean;
    feedbackForms?: boolean;
    products?: boolean;
    sms?: boolean;
    tags?: boolean;
    split?: boolean;
    send?: boolean;
    payments?: boolean;
}