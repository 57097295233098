/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AlertResultView } from './alertResultView';
import { BasicCustomerModel } from './basicCustomerModel';
import { CustomerAlertStatus } from './customerAlertStatus';

export interface ActivityPostLinkedResultView { 
    postId?: string;
    post?: AlertResultView;
    id?: string;
    alertId?: string;
    alert?: AlertResultView;
    customerId?: string;
    status?: CustomerAlertStatus;
    isDeleted?: boolean;
    createdDate?: Date;
    updatedDate?: Date;
    createdBy?: string;
    updatedBy?: string;
    customer?: BasicCustomerModel;
}