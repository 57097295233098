import { ApplicationRef, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';

export interface Field {
  name?: string;
  properties?: any[];
  value?: any;
  group?: number;
  type: string;
}

@Component({
  selector: 'loyale-shared-dynamic-and-rule',
  templateUrl: './dynamic-and-rule.component.html',
  styleUrls: ['./dynamic-and-rule.component.scss']
})
export class DynamicAndRuleComponent implements OnInit {
  field: Field;
  group: UntypedFormGroup;

  textOperators = [{ 'name': 'Equals', 'symbol': '==' }, { 'name': 'Not Equals', 'symbol': '!=' }];
  numberOperators = [{ 'name': 'Equals', 'symbol': '==' }, { 'name': 'Not Equals', 'symbol': '!=' }, { 'name': 'Greater than', 'symbol': '>' }, { 'name': 'Greater than or Equals', 'symbol': '>=' }, { 'name': 'Less than', 'symbol': '<' }, { 'name': 'Less than or Equals', 'symbol': '<=' }];
  listOperators = [{ 'name': 'Equals', 'symbol': '==' }, { 'name': 'Not Equals', 'symbol': '!=' }];
  dateOperators = [{ 'name': 'Greater than', 'symbol': '>' }, { 'name': 'Greater than or Equals', 'symbol': '>=' }, { 'name': 'Less than', 'symbol': '<' }, { 'name': 'Less than or Equals', 'symbol': '<=' }];
  lineItemOperators = [{ name: 'Contains', symbol: 'Contains' }];
  periodOperators = [{ 'name': 'is After', 'symbol': '>' }, { 'name': 'is Before', 'symbol': '<' }];

  compareType = null;
  selectedProperty = null;

  dateMoment = null;

  constructor(private appRef: ApplicationRef, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

  onDateChange(formControlName) {
    this.group.controls[formControlName].setValue(moment(this.dateMoment).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));
  }

}
