import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ReportService, TransactionReportView, ChartService } from 'src/app/swagger';
import { SessionService } from 'src/app/services/session.service';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';

@Component({
  selector: 'loyale-grouped-transactions',
  templateUrl: './grouped-transactions.component.html',
  styleUrls: ['./grouped-transactions.component.scss']
})
export class GroupedTransactionsComponent {
  @Input() dateFrom;
  @Input() dateTo;
  @Input() types;
  @Input() outlets;
  @Input() group;

  chart: any[] = [];

  colorScheme = {
    domain: ['#7b61e5', '#b3a1ff']
  };

  report = { groupBy: [], monetaryValue: [], pointsValue: [] };

  params: TransactionReportView = <TransactionReportView>{};

  constructor(private chartService: ChartService, private sessionService: SessionService, public commonService: CommonService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.params.transactionTypes = this.types == null ? [] : this.types;
    this.params.outletIds = this.outlets == null ? [] : this.outlets;
    this.params.groupBy = this.group;
    this.getChart();
  }

  getChart() {
    this.chartService.apiChartGroupedTransactionsPost(this.params, this.sessionService.schemeId, new Date(this.dateFrom), new Date(this.dateTo)).subscribe(res => {
      this.report = res;
      this.chart = [];

      this.report.groupBy.forEach((date, index) => {
        if (date) {
          this.chart.push({
            name: date.toString(),
            series: [
              {
                name: 'Monetary Value',
                value: this.report.monetaryValue[index]
              },
              {
                name: 'Points Value',
                value: this.report.pointsValue[index]
              }
            ],
          });
        }

      });

    }, err => {
      this.commonService.errorHandler(err);
    });
  }
}
