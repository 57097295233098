/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SchemeResultView } from './schemeResultView';

export interface PushNotificationResultView { 
    id?: string;
    title?: string;
    subTitle?: string;
    deepLinkId?: string;
    deepLinkType?: string;
    isDeleted?: boolean;
    scheme?: SchemeResultView;
    createdDate?: Date;
    updatedDate?: Date;
    isMarketingMaterial?: boolean;
    sendImage?: boolean;
    imageUrl?: string;
    createdBy?: string;
    updatedBy?: string;
}