/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Price } from './price';

export interface ShippingLine { 
    id?: number;
    title?: string;
    price?: string;
    code?: any;
    source?: string;
    phone?: any;
    requestedFulfillmentServiceId?: any;
    deliveryCategory?: any;
    carrierIdentifier?: any;
    discountedPrice?: string;
    priceSet?: Price;
    discountedPriceSet?: Price;
    discountAllocations?: Array<any>;
    taxLines?: Array<any>;
}