<ng-container>
  <div class="row">
    <div class="col-12 col-lg-6">
      <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

      <ng-container *ngIf="!awaitingGet">
        <mat-card>
          <mat-card-content>
            <form [formGroup]="smsForm">
              <mat-form-field class="w-100">
                <mat-label>Name</mat-label>
                <input matInput name="name" formControlName="name" required>
                <mat-error *ngIf="smsForm.get('name')?.errors?.required">Required</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Message</mat-label>
                <textarea rows="5" matInput formControlName="message"></textarea>
                <mat-error *ngIf="smsForm.get('message').errors?.required">Required</mat-error>
                <mat-hint>{{smsForm.get('message').value.length}}/160</mat-hint>
              </mat-form-field>

              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Contains Marketing Material</span>
                    <mat-icon class=""
                      matTooltip="If this contains markeing material, it will never be distributed to customers that opted out of the marketing communication">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="marketingMaterial" formControlName="isMarketingMaterial">
                    <span *ngIf="smsForm.get('isMarketingMaterial').value">Yes</span>
                    <span *ngIf="!smsForm.get('isMarketingMaterial').value">No</span>
                  </mat-slide-toggle>
                </div>
              </div>
            </form>

          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>

    <div class="col-12 col-lg-6">
      <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

      <loyale-preview-sms [sms]="sms" *ngIf="!awaitingGet"></loyale-preview-sms>
    </div>
  </div>
</ng-container>
