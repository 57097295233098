<ng-container>
  <div class="row">
    <div class="col-12 col-lg-6">
      <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

      <ng-container *ngIf="!awaitingGet">
        <form [formGroup]="couponForm">
          <mat-card>
            <mat-card-header>
              <mat-card-title>Details</mat-card-title>
              <!-- <mat-card-subtitle>Subtitle</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
              <mat-form-field class="w-100">
                <mat-label>Name</mat-label>
                <input matInput name="name" required="true" formControlName="name">
                <mat-error *ngIf="couponForm.get('name')?.getError('required')">Required</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Description</mat-label>
                <input matInput name="description" formControlName="description">
                <mat-error *ngIf="couponForm.get('description')?.getError('required')">Required
                </mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>External Reference</mat-label>
                <input matInput name="extRef" formControlName="externalReference">
              </mat-form-field>

              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <loyale-image label="Image" tooltip="500 x 500 pixels" [required]="true"
                    [inputUrl]="couponForm.get('imageUrl').value"
                    (outputUrl)="couponForm.patchValue({imageUrl: $event})"></loyale-image>
                  <input type="hidden" name="image" formControlName="imageUrl" required>
                  <mat-error *ngIf="couponForm.touched && couponForm.get('imageUrl')?.getError('required')">
                    Required
                  </mat-error>
                </div>
              </div>

              <mat-form-field class="w-100">
                <mat-label>Terms & Conditions</mat-label>
                <textarea matInput rows="8" name="terms" formControlName="terms"></textarea>
              </mat-form-field>
            </mat-card-content>
          </mat-card>

          <mat-card>
            <mat-card-header>
              <mat-card-title>Value</mat-card-title>
              <!-- <mat-card-subtitle>Subtitle</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
              <mat-form-field class="w-100">
                <mat-label>Type</mat-label>
                <mat-select name="type" formControlName="discountType" (selectionChange)="changeDiscountType()">
                  <mat-option [value]="0">Open</mat-option>
                  <mat-option [value]="1">Fixed Amount</mat-option>
                  <mat-option [value]="2">Percentage</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="w-100" *ngIf="couponForm.get('discountType').value == 1">
                <mat-label>Fixed Amount Off</mat-label>
                <span matPrefix>{{sessionService.schemeCurrency}}&nbsp;</span>
                <input matInput type="number" name="discount" formControlName="discountAmount"
                  [required]="couponForm.get('discountType').value == 1">
                <span matSuffix> Off</span>
                <mat-error *ngIf="couponForm.get('discountAmount')?.getError('required')">Required
                </mat-error>
              </mat-form-field>

              <mat-form-field class="w-100" *ngIf="couponForm.get('discountType').value == 2">
                <mat-label>Percentage Off</mat-label>
                <input matInput type="number" min="0" max="100" formControlName="discountAmount"
                  [required]="couponForm.get('discountType').value == 2">
                <span matSuffix *ngIf="couponForm.get('discountType').value == 2">% Off</span>
                <mat-error *ngIf="couponForm.get('discountAmount')?.getError('required')">Required
                </mat-error>
                <mat-error *ngIf="couponForm.get('discountAmount')?.getError('max')">The discount
                  percentage should be in 0-100 range
                </mat-error>
              </mat-form-field>
            </mat-card-content>
          </mat-card>

          <mat-card>
            <mat-card-header>
              <mat-card-title>Usage & Availability</mat-card-title>
              <!-- <mat-card-subtitle>Subtitle</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Would you like this coupon to have infinte number of uses?</span>
                    <mat-icon class=""
                      matTooltip="Choose if this coupon will have a finite or infinite number of uses per customer">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="customerUsesCheck" #customerUsesCheck="ngModel"
                    [(ngModel)]="infiniteCustomerUses" [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onInfiniteCustomerUsesChange()">
                    <span *ngIf="infiniteCustomerUses">Yes</span>
                    <span *ngIf="!infiniteCustomerUses">No</span>
                  </mat-slide-toggle>
                </div>
              </div>

              <mat-form-field class="w-100" *ngIf="!infiniteCustomerUses">
                <mat-label>Number of Uses per Customer</mat-label>
                <input matInput type="number" name="initialNumberOfUses" required="true"
                  formControlName="initialNumberOfUses">
                <mat-error *ngIf="couponForm.get('initialNumberOfUses')?.getError('required')">
                  Required
                </mat-error>
              </mat-form-field>

              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Would you like this coupon to have infinte global number of uses?</span>
                    <mat-icon class=""
                      matTooltip="Choose if the coupon will have a finite or infinite number of uses globally">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="globalUsesCheck" #globalUsesCheck="ngModel" [(ngModel)]="infiniteGlobalUses"
                    [ngModelOptions]="{standalone: true}" (change)="onInfiniteGlobalUsesChange()">
                    <span *ngIf="infiniteGlobalUses">Yes</span>
                    <span *ngIf="!infiniteGlobalUses">No</span>
                  </mat-slide-toggle>
                </div>
              </div>

              <mat-form-field class="w-100" *ngIf="!infiniteGlobalUses">
                <mat-label>Number of Uses Globally</mat-label>
                <input matInput type="number" required="true" name="maxUses" formControlName="maxUses">
                <mat-error *ngIf="couponForm.get('maxUses')?.getError('required')">Required
                </mat-error>
              </mat-form-field>

              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Would you like this coupon to be valid in all your outlets?</span>
                    <mat-icon class=""
                      matTooltip="Choose if this coupon will be vaild at all your outlets or a specific number of outlets">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="outletsCheck" #outletsCheck="ngModel" [(ngModel)]="allOutlets"
                    [ngModelOptions]="{standalone: true}" (change)="onAllOutletsChange()">
                    <span *ngIf="allOutlets">Yes</span>
                    <span *ngIf="!allOutlets">No</span>
                  </mat-slide-toggle>
                </div>
              </div>

              <mat-form-field class="w-100" *ngIf="!allOutlets">
                <mat-label>Available at</mat-label>
                <mat-select name="outlets" formControlName="outletId" multiple [disabled]="nestedOutlets.length == 0"
                  [required]="!allOutlets">
                  <mat-option>
                    <ngx-mat-select-search [(ngModel)]="outletsFilter" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="outletsSearchChanges()" placeholderLabel="Search"
                      noEntriesFoundLabel="No results found">
                      <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-optgroup *ngFor="let nest of filteredOutlets" [label]="nest.franchiseName">
                    <mat-option *ngFor="let outlet of nest.outlets" [value]="outlet.id">
                      {{outlet.name}}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
                <mat-error *ngIf="couponForm.get('outletId')?.getError('required')">Required</mat-error>
              </mat-form-field>

            </mat-card-content>
          </mat-card>

          <mat-card>
            <mat-card-header>
              <mat-card-title>Validity</mat-card-title>
              <!-- <mat-card-subtitle>Subtitle</mat-card-subtitle> -->
            </mat-card-header>
            <mat-card-content>
              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Would you like this coupon to have a dynamic validity period?</span>
                    <mat-icon class=""
                      matTooltip="Choose if this coupon will have a fixed, or a dynamic validity period based on when it is issued to the customer">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="expiryCheck" formControlName="isDynamic" (change)="onDynamicExpiryChange()">
                    <span *ngIf="couponForm.get('isDynamic').value">Yes</span>
                    <span *ngIf="!couponForm.get('isDynamic').value">No</span>
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="mat-form-field-errors" *ngIf="!couponForm.get('isDynamic').value">
                <loyale-date-range-picker label="Valid From - To"
                  [dateRange]="[couponForm.get('from').value, couponForm.get('to').value]"
                  (onChange)="dateRangeOnChange($event)" fullWidth="true"
                  [required]="!couponForm.get('isDynamic').value" [markAsTouched]="couponForm.touched">
                </loyale-date-range-picker>
              </div>

              <mat-form-field class="w-100" *ngIf="couponForm.get('isDynamic').value">
                <mat-label>Valid after</mat-label>
                <input matInput type="number" formControlName="validAfter">
                <span matSuffix>days from issue</span>
                <mat-error *ngIf="couponForm.get('validAfter').getError('required')">Required
                </mat-error>
              </mat-form-field>

              <mat-form-field class="w-100" *ngIf="couponForm.get('isDynamic').value">
                <mat-label>Valid for</mat-label>
                <input matInput type="number" formControlName="validFor" [required]="couponForm.get('isDynamic').value">
                <span matSuffix>days after start</span>
                <mat-error *ngIf="couponForm.get('validFor').getError('required')">Required
                </mat-error>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </form>

        <loyale-expiry-reminder [notify]="couponForm.controls.notify.value"
          [notificationTime]="couponForm.controls.notificationTime.value"
          [daysBeforeExpire]="couponForm.controls.daysBeforeExpire.value"
          [pushNotificationId]="couponForm.controls.pushNotificationId.value"
          (onChange)="setExpiryReminded($event)"></loyale-expiry-reminder>
      </ng-container>
    </div>

    <div class="col-12 col-lg-6">
      <loyale-preview-coupon [coupon]="couponForm.value" *ngIf="!awaitingGet"></loyale-preview-coupon>
    </div>
  </div>
</ng-container>
