<form [formGroup]="form" *ngIf="form">
    <div>
        <div *ngFor="let day of weekdays; index as i" [formGroupName]="i"
             class="d-flex flex-row justify-content-between">
            <div class="left-side">
                <div class="d-flex flex-row justify-content-between ">
                         <span class="day__weekday">
                    <div>{{getDateForWeekDay(i) | localizedDate | titlecase}}</div>
                                 <span>

                            <mat-icon [matTooltip]="'Add break'"
                                      [ngClass]="[form.get([i]).get('doubleTimeRange').value ? 'icon-active' : 'icon-disabled']"
                                      (click)="form.get([i]).get('doubleTimeRange').setValue(!form.get([i]).get('doubleTimeRange').value)">avg_pace</mat-icon>

                    </span>
                </span>
                    <div class="d-flex flex-column justify-content-center">
                           <span class="day__box"
                                 (click)="onChangeOperationState(i)"
                                 [ngClass]="businessHours[i]['open'] ? 'day__box--open' : 'day__box--closed'">
                    <input type="checkbox" class="invisible" formControlName="open"/>
                    </span>
                    </div>

                </div>
                <!--                <div class="">-->
                <!--                    <mat-checkbox [disabled]="!businessHours[i]['open']">-->
                <!--                       -->
                <!--                    </mat-checkbox>-->
                <!--                </div>-->

            </div>
            <div class="right-side ">
                <div class="d-flex flex-row justify-content-between">
                    <div class="d-flex flex-column mr-2">
                        <loyale-time-picker [label]="'From'" [time]="form.get([i]).get('from').value"
                                            [fullWidth]="true"
                                            (onChange)="form.get([i]).get('from').setValue($event)"
                                            [disabled]="!businessHours[i]['open']"></loyale-time-picker>
                        <loyale-time-picker [fullWidth]="true"  [label]="'From'"
                                            [disabled]="!businessHours[i]['open']"
                                            [time]="form.get([i]).get('secondFrom').value"
                                            (onChange)="form.get([i]).get('secondFrom').setValue($event)"
                                            *ngIf="businessHours[i]['doubleTimeRange']"></loyale-time-picker>
                    </div>
                    <mat-divider getOrientation="vertical"></mat-divider>

                    <div class="d-flex flex-column">

                        <loyale-time-picker [label]="'To'" [time]="form.get([i]).get('to').value"
                                            [fullWidth]="true"
                                            (onChange)="form.get([i]).get('to').setValue($event)"
                                            [disabled]="!businessHours[i]['open']"></loyale-time-picker>
                        <loyale-time-picker [fullWidth]="true" [label]="'To'"
                                            [time]="form.get([i]).get('secondTo').value"
                                            (onChange)="form.get([i]).get('secondTo').setValue($event)"
                                            [disabled]="!businessHours[i]['open']"
                                            *ngIf="businessHours[i]['doubleTimeRange']"></loyale-time-picker>

                    </div>
                    <mat-error *ngIf="form.get(i.toString())?.hasError('timeToMustBeGreaterThenTimeFrom')"
                               class="text-small-error">
                        {{validationErrorMessage}}
                        {{validationErrorMessage ? validationErrorMessage : 'The "From" must be earlier than the "To"'}}
                    </mat-error>
                </div>
            </div>
        </div>
    </div>
</form>
