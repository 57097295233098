/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Customer } from './customer';

export interface PushToken { 
    customer?: Customer;
    id?: string;
    customerId?: string;
    token?: string;
    isUnregistered?: boolean;
    unregisteredDate?: Date;
    createdDate?: Date;
    updatedDate?: Date;
}