<form [formGroup]="scheduleForm">
  <mat-card>
    <mat-card-content>
      <h5>Schedule Type and Trigger</h5>
      <mat-tab-group dynamicHeight [selectedIndex]="type" (selectedIndexChange)="type = $event">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="mr-2">calendar_today</mat-icon>
            Event
          </ng-template>
          <div class="mt-4">
            <loyale-shared-trigger-event [new]="true"
              (outputTriggerId)="onTriggerChange($event)"></loyale-shared-trigger-event>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="mr-2">access_time</mat-icon>
            Time
          </ng-template>
          <div class="mt-4">
            <loyale-shared-trigger-time [new]="true"
              (outputTriggerId)="onTriggerChange($event)"></loyale-shared-trigger-time>
          </div>
        </mat-tab>
      </mat-tab-group>

      <div class="row mt-4">
        <div class="col-12 col-lg-6">
          <div class="mat-form-field-custom">
            <div class="mat-form-field-wrapper">
              <div class="d-flex align-items-center justify-content-between">
                <span class="label">Run Indefinitely</span>
                <mat-icon class="icon-resize-small"
                  matTooltip="Choose if this trigger will run indefinitely, or have a fixed period.">
                  info
                </mat-icon>
              </div>
              <mat-slide-toggle formControlName="runCheck" [(ngModel)]="runIndefinitely"
                (change)="changeValidationStatus()">
                <span *ngIf="runIndefinitely">Yes</span>
                <span *ngIf="!runIndefinitely">No</span>
              </mat-slide-toggle>
            </div>
          </div>
        </div>

      </div>

      <ng-container *ngIf="!runIndefinitely">
        <div class="row">
          <div class="col-12 col-lg-6">
            <loyale-date-time-picker label="From" appearance="outline" clearable="true" fullWidth="true"
              (onChange)="scheduledTask.dateFrom = $event" [required]="!runIndefinitely">
            </loyale-date-time-picker>

            <input type="hidden" formControlName="dateFromDummy" [(ngModel)]="scheduledTask.dateFrom"
              [required]="!runIndefinitely">
          </div>

          <div class="col-12 col-lg-6">
            <loyale-date-time-picker label="To" appearance="outline" clearable="true" fullWidth="true"
              (onChange)="scheduledTask.dateTo = $event" [required]="!runIndefinitely">
            </loyale-date-time-picker>

            <input type="hidden" formControlName="dateToDummy" [(ngModel)]="scheduledTask.dateTo"
              [required]="!runIndefinitely">
          </div>
        </div>
      </ng-container>

    </mat-card-content>
  </mat-card>


  <loyale-target-demographics-selector
    (outputDemographicId)="onTargetChange($event)"></loyale-target-demographics-selector>

  <loyale-advanced-scheduling [actionType]="actionType"
    (TaskActionsList)="changeTaskActions($event)"></loyale-advanced-scheduling>
  <div class="row">
    <div class="col-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Name This Automation</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field class="w-100">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Automation name" formControlName="name" [(ngModel)]="scheduledTask.name">
            <mat-error *ngIf="scheduleForm.get('name').errors?.required">Required</mat-error>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
