import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

class Options {
  emoji?: boolean = false;
  referrals?: boolean = false;
}

@Component({
  selector: 'loyale-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent implements OnInit {
  @Input() inputValue = null;
  @Input() label = 'Text';
  @Input() rows = 10;
  @Input() required = false;
  @Input() maxLength = 5000;
  @Input() disabled = false;
  @Input() hint = null;

  @Input() placeHolders = [];

  @Input()
  public set markAsTouched(touched: boolean) {
    if (touched === true) {
      this.text.markAllAsTouched();
    }
  }

  @Input() options: Options = <Options> {};

  @Output() outputValue = new EventEmitter();
  @ViewChild('input', { static: false }) input: ElementRef;

  text = new UntypedFormControl();
  showEmojiPicker = false;
  toggled = true;

  textForm: UntypedFormGroup = this.formBuilder.group({
    text: ['', [Validators.maxLength(this.maxLength - 1)]],
  });

  constructor(public sessionService: SessionService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.textForm.setValue({ text: this.inputValue });

    if (this.disabled) {
      this.textForm.get('text').disable();
    }

    if (this.required) {
      this.textForm.get('text').addValidators(Validators.required);
    }
  }

  onEditorClick() {
    if (this.showEmojiPicker) {
      this.showEmojiPicker = false;
    }
  }

  onInsertEmoji(event) {
    this.showEmojiPicker = false;
    this.outputValue.emit(this.textForm.get('text').value);

    let splitString = [];
    splitString[0] = this.textForm.get('text').value.substring(0, this.input.nativeElement.selectionStart);
    splitString[1] = this.textForm.get('text').value.substring(this.input.nativeElement.selectionEnd, this.textForm.get('text').value.length);

    this.textForm.get('text').setValue(splitString[0] + event.emoji.native + splitString[1]);
  }

  onInsertPlaceholder(placeholder) {
    let splitString = [];
    splitString[0] = this.textForm.get('text').value.substring(0, this.input.nativeElement.selectionStart);
    splitString[1] = this.textForm.get('text').value.substring(this.input.nativeElement.selectionEnd, this.textForm.get('text').value.length);

    this.textForm.get('text').setValue(splitString[0] + '{{' + placeholder + '}}' + splitString[1]);
    this.outputValue.emit(this.textForm.get('text').value);
  }

  addTagToMessage(tag: string) {
    const currentText = this.input.nativeElement.value;
    const caretPosition = this.input.nativeElement.selectionStart;
    const newText = `${currentText.slice(0, caretPosition)} ${'{{' + tag + '}}'} ${currentText.slice(caretPosition)}`;
    this.textForm.get('text').setValue(newText);
    this.outputValue.emit(this.textForm.get('text').value);
  }

  modelChange() {

  }

}
