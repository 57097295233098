import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { BasicCustomer, CustomerResultView, PointBalanceResultView, PointBalanceService } from 'src/app/swagger';

@Component({
  selector: 'loyale-mini-customer',
  templateUrl: './mini-customer.component.html',
  styleUrls: ['./mini-customer.component.scss']
})
export class MiniCustomerComponent implements OnInit {
  @Input() customer: CustomerResultView = <CustomerResultView>{};
  @Input() disabled = false;
  @Input() dummy = false;
  @Input() preTitle = null;
  @Output() onRedirect = new EventEmitter<any>();

  balance: PointBalanceResultView = <PointBalanceResultView>{};

  constructor(private pointBalanceService: PointBalanceService, public sessionService: SessionService) { }

  ngOnInit() {
    if (!this.dummy) {
      if (!this.customer.balance) {
        this.getBalance();
      }
      else this.balance = this.customer.balance;
    }
  }

  getBalance() {
    this.pointBalanceService.apiPointBalanceGet('customerId==' + this.customer.id + ',schemeId==' + this.sessionService.schemeId, null, null, null, this.sessionService.schemeId).subscribe(res => {
      if (res.length > 0) {
        this.balance = res[0];
      }
    });
  }
}
