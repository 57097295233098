/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Demographics } from './demographics';
import { LineItemsMatchesView } from './lineItemsMatchesView';
import { TaskAction } from './taskAction';
import { Trigger } from './trigger';

export interface ScheduledTask { 
    id?: string;
    name?: string;
    schemeId?: string;
    demographics?: Demographics;
    demographicsId?: string;
    lineItemsMatches?: Array<LineItemsMatchesView>;
    createdBy?: string;
    updatedBy?: string;
    trigger?: Trigger;
    triggerId?: string;
    dateFrom?: Date;
    dateTo?: Date;
    isEnabled?: boolean;
    taskActionList?: Array<TaskAction>;
    createdDate?: Date;
    updatedDate?: Date;
}