/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ReferralSettingResultView { 
    id?: string;
    schemeId?: string;
    transactionDescription?: string;
    message?: string;
    callbackUrl?: string;
    sourceCustomerPoints?: number;
    targetCustomerPoints?: number;
    uses?: number;
    transactionOutletId?: string;
    fulfilOnMobileVerify?: boolean;
    fulfilOnEmailVerify?: boolean;
    fulfilOnFirstPurchase?: boolean;
    shortenCallbackUrl?: boolean;
    updatedDate?: Date;
    createdDate?: Date;
    createdBy?: string;
    updatedBy?: string;
}