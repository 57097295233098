/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ActivityType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export const ActivityType = {
    NUMBER_0: 0 as ActivityType,
    NUMBER_1: 1 as ActivityType,
    NUMBER_2: 2 as ActivityType,
    NUMBER_3: 3 as ActivityType,
    NUMBER_4: 4 as ActivityType,
    NUMBER_5: 5 as ActivityType,
    NUMBER_6: 6 as ActivityType,
    NUMBER_7: 7 as ActivityType,
    NUMBER_8: 8 as ActivityType,
    NUMBER_9: 9 as ActivityType
};