/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerResultView } from './customerResultView';
import { SchemeResultView } from './schemeResultView';

export interface ReferralResultView { 
    id?: string;
    referralCode?: string;
    validFrom?: Date;
    validTo?: Date;
    uses?: number;
    sourceCustomerPoints?: number;
    targetCustomerPoints?: number;
    fulfilOnMobileVerify?: boolean;
    fulfilOnEmailVerify?: boolean;
    fulfilOnFirstPurchase?: boolean;
    scheme?: SchemeResultView;
    schemeId?: string;
    isCustomerCode?: boolean;
    sourceCustomer?: CustomerResultView;
    sourceCustomerId?: string;
}