import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { AppInfoDialogComponent } from '../dialogs/app-info-dialog/app-info-dialog.component';
import { SwitchSchemeComponent } from '../switch-scheme/switch-scheme.component';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { getSectionNameByUrl as getSectionName } from './url-accordance.enum';

class SideNav {
  currentOption: string;
  isCollapsed: boolean;
}

@Component({
  selector: 'loyale-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  sidenav: SideNav = <SideNav>{};

  constructor(private router: Router, private route: ActivatedRoute, public sessionService: SessionService, public commonService: CommonService, private dialog: MatDialog) { }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.url.toString().split('/')[1];

        this.sidenav = { currentOption: getSectionName(currentUrl), isCollapsed: false };
        this.sessionService.sidenav = this.sidenav;
      }
    });

    if (!this.sidenav) {
      this.sidenav = { currentOption: 'general', isCollapsed: false };
      this.sessionService.sidenav = this.sidenav;
    }
  }

  setCurrentOption(option: string) {
    this.sidenav.currentOption = option;
    this.sessionService.sidenav = this.sidenav;
  }

  setIsCollapsed(collapsed: boolean) {
    this.sidenav.isCollapsed = collapsed;
    this.sessionService.sidenav = this.sidenav;
  }

  openAppInfo() {
    this.dialog.open(AppInfoDialogComponent);
  }

  openSchemePicker() {
    this.dialog.open(SwitchSchemeComponent, { minWidth: '50vw' });
  }
}
