import { BarCodeService } from 'src/app/swagger';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  type: number;
  name: string;
  identifier: string;
  barcode: string;
  format: string;
}

@Component({
  selector: 'loyale-qrcode-print-dialog',
  templateUrl: './qrcode-print-dialog.component.html',
  styleUrls: ['./qrcode-print-dialog.component.scss']
})
export class QrcodePrintDialogComponent implements OnInit {
  awaitingGet = false;
  imageBase64 = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private barCodeService: BarCodeService) { }

  ngOnInit() {
    this.awaitingGet = true;
    this.barCodeService.apiBarCodeGenerateTypeGet(this.data.type, this.data.identifier).subscribe(res => {
      this.awaitingGet = false;
      this.imageBase64 = 'data:image/png;base64,' + res.qrCode;
    });
  }

  onPrint() {
    window.print();
  }
}
