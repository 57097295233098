import { TransactionResultView } from './../../swagger/model/transactionResultView';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loyale-micro-transaction',
  templateUrl: './micro-transaction.component.html',
  styleUrls: ['./micro-transaction.component.scss']
})
export class MicroTransactionComponent implements OnInit {
  @Input() transaction: TransactionResultView = <TransactionResultView>{};
  @Input() disabled = false;

  constructor() { }

  ngOnInit() {
  }

}
