<mat-card>
  <mat-card-header>
    <mat-card-title>Expiry Reminder</mat-card-title>
    <mat-slide-toggle color="accent" class="top-right-action" (change)="changeExpiryReminder()"
      [checked]="this.expiryReminderObject.notify"></mat-slide-toggle>
    <mat-card-subtitle>Send a notification to the customer a few days before their coupon expires.</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content *ngIf="this.expiryReminderObject.notify" [formGroup]="expiryReminderForm">
    <h6>Use an existing push notification or create a new one.</h6>

    <div class="row align-items-center">
      <div class="col-12 col-lg-7">
        <mat-form-field class="w-100">
          <mat-label>Push Notifications</mat-label>
          <mat-select name="pushNotification" formControlName="pushNotificationId" requried>

            <mat-option>
              <ngx-mat-select-search placeholderLabel="Filter" noEntriesFoundLabel="No results found">
                <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
              </ngx-mat-select-search>
            </mat-option>

            <mat-option [value]="pushNotification.id" *ngFor="let pushNotification of filteredPushNotifications">
              {{pushNotification.title}} - {{pushNotification.subTitle}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="expiryReminderForm.touched && expiryReminderForm.get('pushNotificationId')?.getError('required')">
            Required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-1 text-center">
        <h5 class="mb-4">or</h5>
      </div>

      <div class="col-12 col-lg-3 text-center text-lg-left">
        <button mat-stroked-button color="primary" class="mb-4" (click)="onNewPushNotification()">Create New
        </button>
      </div>
    </div>

    <mat-form-field class="w-100" floatLabel="always">
      <mat-label>Send notification</mat-label>
      <input matInput type="number" class="example-right-align" placeholder="0" formControlName="daysBeforeExpire">
      <span matSuffix>&nbsp;{{'days before' | translate}} </span>
      <mat-error *ngIf="expiryReminderForm.touched && expiryReminderForm.get('daysBeforeExpire')?.getError('required')">
        Required
      </mat-error>
    </mat-form-field>

    <div>
      <loyale-time-picker label="Notification Time" [returnUtc]="true" [fullWidth]="true" [stepMinute]="60"
        [time]="expiryReminderForm.get('notificationTime').value"
        (onChange)="expiryReminderForm.patchValue({notificationTime: $event })">
      </loyale-time-picker>
      <input type="hidden" formControlName="notificationTime" required>
      <mat-error *ngIf="expiryReminderForm.touched && expiryReminderForm.get('notificationTime')?.getError('required')">
        Required
      </mat-error>
    </div>
  </mat-card-content>
</mat-card>
