/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CouponsUsage } from './couponsUsage';
import { Customer } from './customer';
import { LoyaleTransactionType } from './loyaleTransactionType';
import { Outlet } from './outlet';
import { Scheme } from './scheme';
import { TransactionItem } from './transactionItem';

export interface Transaction { 
    id?: string;
    value: number;
    hidden?: boolean;
    saleCurrency: string;
    lineItems?: Array<TransactionItem>;
    coupons?: Array<CouponsUsage>;
    customerId: string;
    customer?: Customer;
    transactionType: LoyaleTransactionType;
    posId?: string;
    posType?: string;
    outletId?: string;
    outlet?: Outlet;
    pointValue: number;
    cachedResultantPointValue: number;
    allocatedPointValue: number;
    expiredPointValue?: number;
    externalRefId: string;
    description: string;
    transactionDate: Date;
    createdBy?: string;
    updatedBy?: string;
    scheme?: Scheme;
    schemeId: string;
    isSplit?: boolean;
    isSend?: boolean;
    targetTransactionId?: string;
    targetTransaction?: Transaction;
    sourceTransactionId?: string;
    sourceTransaction?: Transaction;
    groupId?: string;
    transactionGroupId?: string;
    levelId?: string;
    receiptNumber?: string;
    createdDate?: Date;
    updatedDate?: Date;
}