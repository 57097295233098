import { DialogRef } from '@angular/cdk/dialog';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { Upload2Service } from 'src/app/services/upload2.service';

export interface DialogData {
  width: number;
  ratio: number;
  debug: boolean;
}

@Component({
  selector: 'loyale-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {
  awaitingPost = false;

  resizeToWidth = 500;
  aspectRatio = 1 / 1;
  maintainAspectRatio = true;
  containWithinAspectRatio = true;
  format = 'png';

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('stepper') private stepper: MatStepper;

  imageSource: any = null;
  imageCropped: any = null;
  imageUpload: any = null;

  stepSelecteComplete = false;
  stepCropComplete = false;
  stepSaveComplete = false;

  constructor(private uploadService: Upload2Service, @Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<ImageCropperDialogComponent>, private sessionService: SessionService, public commonService: CommonService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.data.width ? this.resizeToWidth = this.data.width : this.resizeToWidth = 500;
    this.data.ratio ? this.aspectRatio = this.data.ratio : this.aspectRatio = 1 / 1;
  }

  onFileChange(event: any) {
    this.imageSource = event;
  }

  onSelect() {
    this.stepSelecteComplete = true;
    this.onNext();
  }

  onCrop() {
    this.stepCropComplete = true;
    this.onNext();
  }

  async onNext() {
    await this.commonService.delay(200);
    this.stepper.next();
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.imageCropped = event.base64;
  }

  onSave() {
    this.awaitingPost = true;
    this.imageUpload = this.commonService.getFileFromBase64(this.imageCropped);

    this.uploadService.uploadFile(this.imageUpload).subscribe(res => {
      this.awaitingPost = false;

      if (this.data.debug) {
        console.info(res.url);
        this.snackBar.open('Check console for url')
      }

      this.dialogRef.close(res.url);
    }, err => {
      this.awaitingPost = false;
      this.commonService.errorHandler(err);
    });

    //previously we had support for returning base64 instead of url
    // if (this.base64) {
    //   this.imageUpload = true;
    //   console.log(this.imageCropped);
    //   this.dialogRef.close(this.imageCropped);
    // }
  }
}
