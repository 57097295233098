/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type GroupByTransactions = 1 | 2 | 3 | 4 | 5;

export const GroupByTransactions = {
    NUMBER_1: 1 as GroupByTransactions,
    NUMBER_2: 2 as GroupByTransactions,
    NUMBER_3: 3 as GroupByTransactions,
    NUMBER_4: 4 as GroupByTransactions,
    NUMBER_5: 5 as GroupByTransactions
};