<mat-form-field class="w-100">
  <mat-label>{{label}}</mat-label>
  <mat-select name="giftCard" #form="ngModel" [(ngModel)]="selectedValue" [required]="required"
    (ngModelChange)="onChange()" [disabled]="disabled">

    <mat-option>
      <ngx-mat-select-search name="filter" [(ngModel)]="filter" (ngModelChange)="onFilter()"
        [placeholderLabel]="placeholderSearchLabel" noEntriesFoundLabel="No results found">
        <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
      </ngx-mat-select-search>
    </mat-option>

    <mat-option [value]="element[selectedProperty]" *ngFor="let element of filteredList">
      <ng-container *ngTemplateOutlet="template || defaultTemplate; context:{$implicit: element}"></ng-container>
      <ng-template #defaultTemplate>
        <span *ngFor="let property of filterProperty">
          {{element[property]}}
        </span>
      </ng-template>
    </mat-option>
  </mat-select>

  <mat-error *ngIf="form?.errors?.required">Required</mat-error>
</mat-form-field>
