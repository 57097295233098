import { FormGroup, ValidationErrors } from '@angular/forms';
import moment from 'moment';

export function daySettingsValidator(group: FormGroup): ValidationErrors | null {
  const isOpen = group.get('open')?.value;

  if (!isOpen) {
    return null;
  }

  const timeFrom = moment(group.get('from')?.value, ' YYYY-MM-DDThh:mmTZD').valueOf();
  const timeTo = moment(group.get('to')?.value, ' YYYY-MM-DDThh:mmTZD').valueOf();

  if (timeFrom < timeTo) {
    group.get('from')?.setErrors(null);
    group.get('to')?.setErrors(null);

    return null;
  }

  group.get('from')?.setErrors({ timeToMustBeGreaterThenTimeFrom: true });
  group.get('to')?.setErrors({ timeToMustBeGreaterThenTimeFrom: true });

  // if (this.scheduledTaskPut.dateFrom !== null && this.scheduledTaskPut.dateTo !== null) {
  //   if (this.scheduledTaskPut.dateTo > this.scheduledTaskPut.dateFrom) {
  //     this.fromDataToDateValid = true;
  //   } else {
  //     this.fromDataToDateValid = false;
  //   }
  // } else {
  //   this.fromDataToDateValid = true;
  // }

  return { timeToMustBeGreaterThenTimeFrom: true };
}
