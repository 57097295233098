<h1 mat-dialog-title>{{data.action}} {{'Saved' | translate}}!</h1>
<div mat-dialog-content>
    <p>Your {{data.action}} has been saved. Head over to the {{data.action}} section to make changes.</p>
    <p>To send this out to your customers, go to the Scheduled Tasks section.</p>
</div>
<div mat-dialog-actions>
    <div class="flex-fill"></div>
    <button mat-flat-button [mat-dialog-close]>{{'Schedule now' | translate}}</button>
    <button cdkFocusInitial mat-flat-button [mat-dialog-close]="true" color="primary" class="ml-1">{{'Got it' |
        translate}}</button>
</div>