import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class GuideService {

  constructor(public sessionService: SessionService, public commonService: CommonService) { }

  public setItem(item: string, show: boolean) {
    const key = 'guide_' + item + '_' + this.sessionService.schemeId;
    //localStorage.setItem(key, JSON.stringify(show));
    this.commonService.setCookie(key, JSON.stringify(show), 1826);
  }

  public getItem(item: string) {
    const key = 'guide_' + item + '_' + this.sessionService.schemeId;
    //return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
    return this.commonService.getCookie(key) ? JSON.parse(this.commonService.getCookie(key)) : true;
  }

  public set guideDemo(show: boolean) {
    const key = 'guideDemo_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guideDemo() {
    const key = 'guideDemo_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

  public set guideTour(show: boolean) {
    const key = 'guideTour_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guideTour() {
    const key = 'guideTour_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

}
