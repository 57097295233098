import { CommonService } from 'src/app/services/common.service';
import { FranchiseService } from 'src/app/swagger';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NestedOutlets } from 'src/app/services/session.service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'loyale-nested-outlets',
  templateUrl: './nested-outlets.component.html',
  styleUrls: ['./nested-outlets.component.scss']
})
export class NestedOutletsComponent implements OnInit {
  @Input() outlets = null;
  @Input() appearance = 'outline';
  @Input() required = true;
  @Input() multiple = false;
  @Output() onChange = new EventEmitter();

  nestedOutlets: NestedOutlets[] = [];
  filteredNestedOutlets: NestedOutlets[] = [];
  outletFilter = null;

  constructor(private franchiseService: FranchiseService, private commonService: CommonService) { }

  ngOnInit() {
    this.getNestedOutlets();
  }

  getNestedOutlets() {
    this.franchiseService.apiFranchiseGet(null, 'name').subscribe(franchises => {
      for (let franchise of franchises) {
        franchise.outlets.sort((a, b) => (a.name < b.name ? -1 : 1));
        this.nestedOutlets.push({ franchiseName: franchise.name, outlets: franchise.outlets });
      }
      this.filteredNestedOutlets = this.nestedOutlets;
    }, err => {
      this.commonService.errorHandler(err);
    })
  }

  onFilterOutlets() {
    if (!this.nestedOutlets) {
      return;
    }

    let filter = this.outletFilter;
    const nestedOutletsClone = cloneDeep(this.nestedOutlets);
    if (!filter) {
      this.filteredNestedOutlets = nestedOutletsClone;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredNestedOutlets =
      nestedOutletsClone.filter(nest => {
        const showNest = nest.franchiseName.toLowerCase().indexOf(filter) > -1;
        if (!showNest) {
          nest.outlets = nest.outlets.filter(outlet => outlet.name.toLowerCase().indexOf(filter) > -1);
        }
        return nest.outlets.length > 0;
      });

  }

  onOutletChange() {
    this.onChange.emit(this.outlets);
  }
}
