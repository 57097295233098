<div class="row align-items-center">
  <div class="col-12 col-md-5">
    <h3>Welcome to the Loyale Backoffice Demo 👋</h3>
    <p>Click around and explore features such as coupons, push notifications, feedback forms and much more.</p>
    <p>Feel free to create your own and explore all the marketing features Loyale has to offer.</p>
    <button mat-flat-button color="accent" class="mt-4" [mat-dialog-close]
      (click)="guideService.guideDemo = false">Let's Get Started</button>
  </div>

  <div class="col-12 col-md-7 text-center text-md-right">
    <img src="../../../../assets/demo/demo-welcome.png" alt="demo welcome">
  </div>
</div>
