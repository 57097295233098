<mat-card>
    <mat-card-header>
            <mat-card-title>
                <mat-icon color="accent" class="mr-2">card_membership</mat-icon>
                <span>Gift Card</span>
            </mat-card-title>

            <mat-card-subtitle>
                Select an existing gift card or create a new one
            </mat-card-subtitle>


            <button mat-icon-button class="top-right-action" (click)="removeGiftCard.emit()" matTooltip="Remove">
                <mat-icon>cancel</mat-icon>
            </button>
    </mat-card-header>

    <mat-card-content>
        <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

        <div class="row align-items-center" *ngIf="!awaitingGet">
            <div class="col-12 col-lg-7">
                <mat-form-field class="w-100">
                    <mat-label>Gift Cards</mat-label>
                    <mat-select name="giftCard" #giftCard="ngModel" [(ngModel)]="selectedGiftCardId"
                                (ngModelChange)="onGiftCardChange()" [disabled]="giftCards.length == 0" required>

                        <mat-option>
                            <ngx-mat-select-search name="filter" #filter="ngModel" [(ngModel)]="giftCardFilter"
                                                   (ngModelChange)="onFilterGiftCards()" placeholderLabel="Filter"
                                                   noEntriesFoundLabel="No results found">
                                <mat-icon ngxMatSelectSearchClear>cancel</mat-icon>
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option [value]="giftCard.id" *ngFor="let giftCard of filteredGiftCards">
                            {{giftCard.name}} - {{giftCard.description}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="giftCard?.errors?.required">Required</mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 col-lg-1 text-center">
                <h5 class="mb-4">Or</h5>
            </div>

            <div class="col-12 col-lg-4 text-center text-lg-left">
                <button mat-flat-button color="primary" class="mb-4" (click)="onNewGiftCard()">Create a new gift card
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>


