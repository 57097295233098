/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LightspeedRetailSaleLineView } from './lightspeedRetailSaleLineView';
import { LightspeedRetailSalePaymentView } from './lightspeedRetailSalePaymentView';

export interface Sale { 
    saleID?: string;
    customerId?: string;
    shopId?: string;
    employeeID?: string;
    completeTime?: string;
    registerID?: string;
    calcDiscount?: string;
    total?: string;
    balance?: string;
    saleLines?: LightspeedRetailSaleLineView;
    salePayments?: LightspeedRetailSalePaymentView;
}