/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LightspeedCredentialsView { 
    outletId?: string;
    consumerKey?: string;
    consumerSecret?: string;
    codeVerifier?: string;
    accessToken?: string;
    refreshToken?: string;
    expiresIn?: number;
    companyId?: number;
    useDiscounts?: boolean;
}