/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Lottery } from './lottery';

export interface LotteryReward { 
    id?: string;
    name?: string;
    description?: string;
    imageUrl?: string;
    order?: number;
    quantity?: number;
    lottery?: Lottery;
    lotteryId?: string;
    createdBy?: string;
    updatedBy?: string;
    createdDate?: Date;
    updatedDate?: Date;
}