<div *ngIf="data?.message && !data.template">
    {{data.message}}
</div>

<div *ngIf="data?.template">
    <ng-conteiner *ngTemplateOutlet="data.template; context: { data: data.message }"></ng-conteiner>
</div>

<div *ngIf="!data?.template && !data?.message">
    Custom snackbar
</div>
