/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Coupon } from './coupon';
import { Customer } from './customer';

export interface CouponsLinked { 
    id?: string;
    createdBy?: string;
    updatedBy?: string;
    barCode?: string;
    usesLeft?: number;
    favourite?: boolean;
    customer?: Customer;
    customerId?: string;
    coupon?: Coupon;
    couponId?: string;
    from?: Date;
    to?: Date;
    isUsed?: boolean;
    platform?: string;
    createdDate?: Date;
    updatedDate?: Date;
}