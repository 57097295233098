import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductService } from '../../../swagger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'loyale-action-product-selector',
  templateUrl: './action-product-selector.component.html',
  styleUrls: ['./action-product-selector.component.scss']
})
export class ActionProductSelectorComponent implements OnInit {

  awaitingGet = false;
  products = [];
  filteredProducts = [];
  @Input() selectedProductId = null;
  @Output() outputProductId = new EventEmitter<any>();
  @Output() removeProduct = new EventEmitter<any>();

  productFilter = null;

  constructor(private productService: ProductService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getProducts();
  }

  getProducts() {
    this.awaitingGet = true;
    this.productService.apiProductGet(null, '-createdDate').subscribe(res => {
      this.awaitingGet = false;
      this.products = res;
      this.filteredProducts = this.products;
    })
  }

  onNewProduct() {
    // const dialogRef = this.dialog.open(NewDialogComponent, { width: '80vw' });

    // dialogRef.afterClosed().subscribe(res => {
    //   if (res) {
    //     this.getCoupons(res.id);
    //   }
    // })
  }

  onProductChange() {
    this.outputProductId.emit(this.selectedProductId);
  }

  onFilterProduct() {
    if (!this.products) {
      return;
    }

    let filter = this.productFilter;
    if (!filter) {
      this.filteredProducts = this.products;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredProducts = this.products.filter(product => product.name.toLowerCase().indexOf(filter) > -1);
  }

}
