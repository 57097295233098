<div class="br-5 bg-white mb-3 p-3 pending-toolbar d-flex align-items-center">
  <mat-chip-list class="mr-3">
    <mat-chip color="warn" selected>
      <mat-icon class="mr-1">warning</mat-icon>
      Pending
    </mat-chip>
  </mat-chip-list>
  <h5 class="mb-0">{{title}}</h5>
  <div class="flex-fill"></div>
  <button mat-flat-button color="accent" (click)="onAction.emit()">{{button}}</button>
  <button mat-icon-button (click)="onDismiss.emit()" class="dismiss-button" matTooltip="Dismiss" *ngIf="canDismiss">
    <mat-icon>close</mat-icon>
  </button>
</div>
