<h1 mat-dialog-title>{{'Confirm Delete' | translate}}</h1>
<div mat-dialog-content>
    <div class="mat-dialog-warning d-flex py-3 px-4 mb-3 mx-n4">
        <mat-icon>info</mat-icon>&nbsp;
        <span *ngIf="!data.warning">{{'Deleting is permanent. This action cannot be reversed.' | translate}}</span>
        <span *ngIf="data.warning">{{data.warning}}</span>
    </div>
    <p>{{data.message | translate}}</p>
    <p>{{'To confirm deletion, type ' + data.confirmValue + ' in the text field below.' | translate}}</p>

    <mat-form-field class="w-100">
        <mat-label>{{data.confirmValue}}</mat-label>
        <input matInput name="confirm" #confirm="ngModel" [(ngModel)]="confirmInput" required>
        <mat-error *ngIf="confirm?.errors?.required">Required</mat-error>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <div class="flex-fill"></div>
    <button type="button" mat-flat-button [mat-dialog-close]>{{'No' | translate}}</button>
    <button cdkFocusInitial mat-flat-button [mat-dialog-close]="true" color="warn" class="ml-1"
            [disabled]="confirmInput != data.confirmValue">{{'Yes' |
      translate}}</button>
</div>
