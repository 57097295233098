/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { SchemeStripeLink } from './schemeStripeLink';
import { StripePaymentAttempt } from './stripePaymentAttempt';
import { StripePaymentStatus } from './stripePaymentStatus';
import { StripeProductType } from './stripeProductType';
import { StripeRefund } from './stripeRefund';
import { Transaction } from './transaction';

export interface StripePayment { 
    id?: string;
    amount?: number;
    loyaleFeeAmount?: number;
    processingFeeAmount?: number;
    customerId?: string;
    customer?: Customer;
    transactionId?: string;
    transaction?: Transaction;
    lastFailureMessage?: string;
    paymentIntentId?: string;
    currency?: string;
    status?: StripePaymentStatus;
    productType?: StripeProductType;
    createdBy?: string;
    updatedBy?: string;
    schemeStripeLinkId?: string;
    schemeStripeLink?: SchemeStripeLink;
    purchasedProductId?: string;
    orderId?: string;
    guest?: string;
    stripePaymentAttempts?: Array<StripePaymentAttempt>;
    stripeRefunds?: Array<StripeRefund>;
    createdDate?: Date;
    updatedDate?: Date;
}