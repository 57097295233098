<mat-card>
  <mat-card-header>

    <mat-card-title>
      <mat-icon color="accent" class="mr-2">iso</mat-icon>
      <span>Points Modifier</span>
    </mat-card-title>

    <mat-card-subtitle>
      Select an existing points modifier or create a new one
    </mat-card-subtitle>


    <button mat-icon-button class="top-right-action" (click)="removeModifier.emit()" matTooltip="Remove">
      <mat-icon>cancel</mat-icon>
    </button>

  </mat-card-header>

  <mat-card-content>
    <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

    <div class="row align-items-center" *ngIf="!awaitingGet">
      <div class="col-12 col-lg-7">
        <mat-form-field class="w-100">
          <mat-label>Points Modifiers</mat-label>
          <mat-select name="modifier" #modifier="ngModel" [(ngModel)]="selectedModifierId"
            (ngModelChange)="onModifierChange()" [disabled]="modifiers.length == 0" required>
            <mat-option [value]="modifier.id" *ngFor="let modifier of modifiers">
              {{modifier.transactionType | transactionType }} of {{modifier.transactionType |
              transactionPolarity}}{{modifier.value}} -
              Outlet: {{modifier.outlet.name}} - Description: {{modifier.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="modifier?.errors?.required">Required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-1 text-center">
        <h5 class="mb-4">Or</h5>
      </div>

      <div class="col-12 col-lg-4 text-center text-lg-left">
        <button mat-flat-button color="primary" class="mb-4" (click)="onNewPointsMultiplier()">Create a new points
          modifier
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
