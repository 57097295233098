/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TaskActionType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const TaskActionType = {
    NUMBER_0: 0 as TaskActionType,
    NUMBER_1: 1 as TaskActionType,
    NUMBER_2: 2 as TaskActionType,
    NUMBER_3: 3 as TaskActionType,
    NUMBER_4: 4 as TaskActionType,
    NUMBER_5: 5 as TaskActionType,
    NUMBER_6: 6 as TaskActionType,
    NUMBER_7: 7 as TaskActionType,
    NUMBER_8: 8 as TaskActionType,
    NUMBER_9: 9 as TaskActionType,
    NUMBER_10: 10 as TaskActionType,
    NUMBER_11: 11 as TaskActionType,
    NUMBER_12: 12 as TaskActionType
};