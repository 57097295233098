import { Pipe, PipeTransform } from '@angular/core';

enum Type {
  'Base' = 0,
  'Additional',
  'All',
  'Pack'
}

@Pipe({
  name: 'invoiceType'
})
export class InvoiceTypePipe implements PipeTransform {

  transform(value: any): string {
    return Type[value];
  }

}
