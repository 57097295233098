<h1 mat-dialog-title>New Email</h1>

<loyale-main-email #emailComponent mat-dialog-content></loyale-main-email>

<div mat-dialog-actions>
    <div class="flex-fill"></div>
    <button mat-flat-button [mat-dialog-close]>Close</button>
    <button mat-flat-button color="primary" [disabled]="awaitingPost" (click)="onSubmit()">Save</button>
</div>

