/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Webhook { 
    address?: string;
    createdAt?: Date;
    fields?: Array<string>;
    format?: string;
    metafieldNamespaces?: Array<string>;
    topic?: string;
    updatedAt?: Date;
    id?: number;
    adminGraphqlApiId?: string;
}