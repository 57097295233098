/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DiscountApplication } from './discountApplication';
import { LineItem } from './lineItem';
import { Price } from './price';
import { ShippingLine } from './shippingLine';
import { ShopifyAddress } from './shopifyAddress';
import { ShopifyCustomer } from './shopifyCustomer';

export interface ShopifyOrderWebhookView { 
    id?: number;
    email?: string;
    closedAt?: Date;
    createdAt?: Date;
    updatedAt?: Date;
    number?: number;
    note?: any;
    token?: string;
    gateway?: any;
    test?: boolean;
    totalPrice?: string;
    subtotalPrice?: string;
    totalWeight?: number;
    totalTax?: string;
    taxesIncluded?: boolean;
    currency?: string;
    financialStatus?: string;
    confirmed?: boolean;
    totalDiscounts?: string;
    totalLineItemsPrice?: string;
    cartToken?: any;
    buyerAcceptsMarketing?: boolean;
    name?: string;
    referringSite?: any;
    landingSite?: any;
    cancelledAt?: Date;
    cancelReason?: string;
    totalPriceUsd?: any;
    checkoutToken?: any;
    reference?: any;
    userId?: any;
    locationId?: any;
    sourceIdentifier?: any;
    sourceUrl?: any;
    processedAt?: Date;
    deviceId?: any;
    phone?: any;
    customerLocale?: string;
    appId?: any;
    browserIp?: any;
    landingSiteRef?: any;
    orderNumber?: number;
    discountApplications?: Array<DiscountApplication>;
    discountCodes?: Array<any>;
    noteAttributes?: Array<any>;
    paymentGatewayNames?: Array<string>;
    processingMethod?: string;
    checkoutId?: any;
    sourceName?: string;
    fulfillmentStatus?: string;
    taxLines?: Array<any>;
    tags?: string;
    contactEmail?: string;
    orderStatusUrl?: string;
    presentmentCurrency?: string;
    totalLineItemsPriceSet?: Price;
    totalDiscountsSet?: Price;
    totalShippingPriceSet?: Price;
    subtotalPriceSet?: Price;
    totalPriceSet?: Price;
    totalTaxSet?: Price;
    totalTipReceived?: string;
    originalTotalDutiesSet?: any;
    currentTotalDutiesSet?: any;
    adminGraphqlApiId?: string;
    shippingLines?: Array<ShippingLine>;
    billingAddress?: ShopifyAddress;
    shippingAddress?: ShopifyAddress;
    customer?: ShopifyCustomer;
    lineItems?: Array<LineItem>;
    fulfillments?: Array<any>;
    refunds?: Array<any>;
}