/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { IntegrationType } from './integrationType';
import { SchemeIntegrations } from './schemeIntegrations';

export interface Integrations { 
    id?: string;
    name?: string;
    description?: string;
    websiteUrl?: string;
    imageUrl?: string;
    documentationUrl?: string;
    route?: string;
    redirectUrl?: string;
    hidden?: boolean;
    allowMultiple?: boolean;
    type?: IntegrationType;
    schemeIntegrations?: Array<SchemeIntegrations>;
}