/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type WebhookTopic = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;

export const WebhookTopic = {
    NUMBER_0: 0 as WebhookTopic,
    NUMBER_1: 1 as WebhookTopic,
    NUMBER_2: 2 as WebhookTopic,
    NUMBER_3: 3 as WebhookTopic,
    NUMBER_4: 4 as WebhookTopic,
    NUMBER_5: 5 as WebhookTopic,
    NUMBER_6: 6 as WebhookTopic,
    NUMBER_7: 7 as WebhookTopic,
    NUMBER_8: 8 as WebhookTopic,
    NUMBER_9: 9 as WebhookTopic,
    NUMBER_10: 10 as WebhookTopic,
    NUMBER_11: 11 as WebhookTopic,
    NUMBER_12: 12 as WebhookTopic,
    NUMBER_13: 13 as WebhookTopic,
    NUMBER_14: 14 as WebhookTopic,
    NUMBER_15: 15 as WebhookTopic,
    NUMBER_16: 16 as WebhookTopic
};