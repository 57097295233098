import { Component, Input, OnInit } from '@angular/core';
import { OutletResultView } from 'src/app/swagger';

@Component({
  selector: 'loyale-micro-outlet',
  templateUrl: './micro-outlet.component.html',
  styleUrls: ['./micro-outlet.component.scss']
})
export class MicroOutletComponent implements OnInit {
  @Input() outlet: OutletResultView = <OutletResultView>{}
  @Input() disabled = false;

  constructor() { }

  ngOnInit() {
  }

}
