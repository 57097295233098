import React from 'react';
import {
  ColorPickerField,
  TextField,
  TextAreaField,
  TextAlign,
  SwitchField,
  ImageUploaderField,
  FontSize,
  AttributesPanelWrapper,
  FontFamily,
  Padding
} from 'easy-email-extensions';
import { useFocusIdx, Stack, useEditorProps } from 'easy-email-editor';
import { Collapse } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

export function Panel() {
  const { focusIdx } = useFocusIdx();
  const { onUploadImage } = useEditorProps();

  return (
    <AttributesPanelWrapper>

      <Collapse defaultActiveKey={['1']}>
        <Collapse.Panel key="1" header="Images Setting">
          <Stack vertical>


            <ImageUploaderField
              label={t('src')}
              labelHidden
              name={`${focusIdx}.attributes.src`}
              helpText={t(
                'The image suffix should be .jpg, jpeg, png, gif, etc. Otherwise, the picture may not be displayed normally.',
              )}
              uploadHandler={onUploadImage}
            />

            <Padding
              name={`${focusIdx}.attributes.padding`}
            ></Padding>

            <ColorPickerField
              label={t('Background color')}
              name={`${focusIdx}.attributes.bgColor`}
              inline
            />

          </Stack>
        </Collapse.Panel>


      </Collapse>

      <Collapse defaultActiveKey={['2']}>
        <Collapse.Panel key="2" header="Text Setting">


          <ColorPickerField
            label="Text color"
            name={`${focusIdx}.attributes.text-color`}
          />

          <ColorPickerField
            label="Background color"
            name={`${focusIdx}.attributes.background-color`}
          />

          <TextAreaField
            label="Text"
            name={`${focusIdx}.data.value.text`}
          />

          <Padding
            name={`${focusIdx}.data.value.padding`}
          ></Padding>

          <FontFamily name={`${focusIdx}.data.value.fontFamily`}></FontFamily>
          <TextAlign name={`${focusIdx}.data.value.align`}></TextAlign>

        </Collapse.Panel>
      </Collapse>

      <Collapse defaultActiveKey={['3']}>
        <Collapse.Panel key="3" header="Button settings">

          <TextField
            label="Button Url"
            name={`${focusIdx}.attributes.buttonUrl`}
          />

          <ColorPickerField
            label="Button Text color"
            name={`${focusIdx}.attributes.buttonTextColor`}
          />

          <ColorPickerField
            label="Button color"
            name={`${focusIdx}.attributes.buttonColor`}
          />

          <TextField
            label="Button text"
            name={`${focusIdx}.attributes.buttonText`}
          />

          <Padding
            name={`${focusIdx}.attributes.buttonPadding`}
          ></Padding>

          <TextAlign name={`${focusIdx}.attributes.buttonAlign`}></TextAlign>
        </Collapse.Panel>
      </Collapse>

    </AttributesPanelWrapper>

  );
}
