/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BasicCustomerModel } from './basicCustomerModel';
import { FeedbackResultView } from './feedbackResultView';
import { FeedbackStatus } from './feedbackStatus';

export interface FeedbackLinkedResultView { 
    id?: string;
    customerId?: string;
    customer?: BasicCustomerModel;
    feedbackId?: string;
    feedback?: FeedbackResultView;
    submitted?: boolean;
    schemeId?: string;
    createdDate?: Date;
    createdBy?: string;
    updatedBy?: string;
    updatedDate?: Date;
    status?: FeedbackStatus;
}