<mat-card class="mini-entity">
  <mat-card-header>
    <mat-card-title>{{franchise.name}}</mat-card-title>
    <!-- <mat-card-subtitle></mat-card-subtitle> -->
    <div class="top-right-action">
      <a mat-icon-button matTooltip="Open" [routerLink]="['/franchises/details', franchise.id]" *ngIf="!disabled">
        <mat-icon>launch</mat-icon>
      </a>
    </div>
  </mat-card-header>
  <img matCardImage src="Card wide image source">
  <mat-card-content>
    <!-- todo, once the categories are returned properly -->
    <!-- <div class="d-flex align-items-center">
                      <mat-icon class=" mr-1">category</mat-icon>
                      <span>{{franchise.categoriesTemp[0].name}}</span>
                  </div> -->
  </mat-card-content>
</mat-card>
