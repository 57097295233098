/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SmsMessage } from '../model/smsMessage';
import { SmsMessageInsertView } from '../model/smsMessageInsertView';
import { SmsMessagePutView } from '../model/smsMessagePutView';
import { SmsMessageResultView } from '../model/smsMessageResultView';
import { SmsSetting } from '../model/smsSetting';
import { SmsSettingInsertView } from '../model/smsSettingInsertView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SmsService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Removes message by id
     * 
     * @param id 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsIdDelete(id: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSmsIdDelete(id: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSmsIdDelete(id: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSmsIdDelete(id: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSmsIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Sms/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsImportWhatsappRatesPostForm(file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSmsImportWhatsappRatesPostForm(file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSmsImportWhatsappRatesPostForm(file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSmsImportWhatsappRatesPostForm(file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Sms/ImportWhatsappRates`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the SmsSetting for this scheme
     * 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsSettingGet(xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SmsSetting>>;
    public apiSmsSettingGet(xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SmsSetting>>>;
    public apiSmsSettingGet(xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SmsSetting>>>;
    public apiSmsSettingGet(xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiSmsSettingGet.');
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SmsSetting>>('get',`${this.basePath}/api/Sms/Setting`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates new SmsSetting
     * 
     * @param xScheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsSettingPost(xScheme: string, body?: SmsSettingInsertView, observe?: 'body', reportProgress?: boolean): Observable<SmsSetting>;
    public apiSmsSettingPost(xScheme: string, body?: SmsSettingInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmsSetting>>;
    public apiSmsSettingPost(xScheme: string, body?: SmsSettingInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmsSetting>>;
    public apiSmsSettingPost(xScheme: string, body?: SmsSettingInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiSmsSettingPost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmsSetting>('post',`${this.basePath}/api/Sms/Setting`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates SmsSetting
     * 
     * @param xScheme 
     * @param settingId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsSettingSettingIdPut(xScheme: string, settingId: string, body?: SmsSettingInsertView, observe?: 'body', reportProgress?: boolean): Observable<SmsSetting>;
    public apiSmsSettingSettingIdPut(xScheme: string, settingId: string, body?: SmsSettingInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmsSetting>>;
    public apiSmsSettingSettingIdPut(xScheme: string, settingId: string, body?: SmsSettingInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmsSetting>>;
    public apiSmsSettingSettingIdPut(xScheme: string, settingId: string, body?: SmsSettingInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiSmsSettingSettingIdPut.');
        }

        if (settingId === null || settingId === undefined) {
            throw new Error('Required parameter settingId was null or undefined when calling apiSmsSettingSettingIdPut.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmsSetting>('put',`${this.basePath}/api/Sms/Setting/${encodeURIComponent(String(settingId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get scheme sms messages with sieve filter
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsSmsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SmsMessageResultView>>;
    public apiSmsSmsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SmsMessageResultView>>>;
    public apiSmsSmsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SmsMessageResultView>>>;
    public apiSmsSmsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SmsMessageResultView>>('get',`${this.basePath}/api/Sms/Sms`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates new Sms message
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsSmsPost(body?: SmsMessageInsertView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SmsMessageResultView>;
    public apiSmsSmsPost(body?: SmsMessageInsertView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmsMessageResultView>>;
    public apiSmsSmsPost(body?: SmsMessageInsertView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmsMessageResultView>>;
    public apiSmsSmsPost(body?: SmsMessageInsertView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmsMessageResultView>('post',`${this.basePath}/api/Sms/Sms`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates sms message by id
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsSmsPut(body?: SmsMessagePutView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SmsMessageResultView>;
    public apiSmsSmsPut(body?: SmsMessagePutView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmsMessageResultView>>;
    public apiSmsSmsPut(body?: SmsMessagePutView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmsMessageResultView>>;
    public apiSmsSmsPut(body?: SmsMessagePutView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmsMessageResultView>('put',`${this.basePath}/api/Sms/Sms`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get one sms template
     * 
     * @param smsId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsSmsSmsIdGet(smsId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SmsMessage>;
    public apiSmsSmsSmsIdGet(smsId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmsMessage>>;
    public apiSmsSmsSmsIdGet(smsId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmsMessage>>;
    public apiSmsSmsSmsIdGet(smsId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (smsId === null || smsId === undefined) {
            throw new Error('Required parameter smsId was null or undefined when calling apiSmsSmsSmsIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmsMessage>('get',`${this.basePath}/api/Sms/Sms/${encodeURIComponent(String(smsId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends an SMS with the given smsId to the numbers which correspond to the customers with the inputted emails.
     * 
     * @param smsId 
     * @param customerEmails 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsTestPost(smsId?: string, customerEmails?: Array<string>, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSmsTestPost(smsId?: string, customerEmails?: Array<string>, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSmsTestPost(smsId?: string, customerEmails?: Array<string>, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSmsTestPost(smsId?: string, customerEmails?: Array<string>, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (smsId !== undefined && smsId !== null) {
            queryParameters = queryParameters.set('smsId', <any>smsId);
        }
        if (customerEmails) {
            customerEmails.forEach((element) => {
                queryParameters = queryParameters.append('customerEmails', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Sms/Test`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends WhatsApp message with the given smsId to the numbers which correspond to the customers with the inputted emails.
     * 
     * @param whatsappId 
     * @param customerEmails 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsTestWhatsappPost(whatsappId?: string, customerEmails?: Array<string>, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSmsTestWhatsappPost(whatsappId?: string, customerEmails?: Array<string>, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSmsTestWhatsappPost(whatsappId?: string, customerEmails?: Array<string>, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSmsTestWhatsappPost(whatsappId?: string, customerEmails?: Array<string>, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (whatsappId !== undefined && whatsappId !== null) {
            queryParameters = queryParameters.set('whatsappId', <any>whatsappId);
        }
        if (customerEmails) {
            customerEmails.forEach((element) => {
                queryParameters = queryParameters.append('customerEmails', <any>element);
            })
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Sms/Test/Whatsapp`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets status of message by webhook
     * 
     * @param schemeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsWebhookSchemeIdPost(schemeId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSmsWebhookSchemeIdPost(schemeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSmsWebhookSchemeIdPost(schemeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSmsWebhookSchemeIdPost(schemeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiSmsWebhookSchemeIdPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Sms/Webhook/${encodeURIComponent(String(schemeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get scheme Whatsapp messages with sieve filter
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsWhatsappGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SmsMessageResultView>>;
    public apiSmsWhatsappGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SmsMessageResultView>>>;
    public apiSmsWhatsappGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SmsMessageResultView>>>;
    public apiSmsWhatsappGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SmsMessageResultView>>('get',`${this.basePath}/api/Sms/Whatsapp`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates new Whatsapp message
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsWhatsappPost(body?: SmsMessageInsertView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SmsMessageResultView>;
    public apiSmsWhatsappPost(body?: SmsMessageInsertView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmsMessageResultView>>;
    public apiSmsWhatsappPost(body?: SmsMessageInsertView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmsMessageResultView>>;
    public apiSmsWhatsappPost(body?: SmsMessageInsertView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmsMessageResultView>('post',`${this.basePath}/api/Sms/Whatsapp`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates Whatsapp template by id
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsWhatsappPut(body?: SmsMessagePutView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SmsMessageResultView>;
    public apiSmsWhatsappPut(body?: SmsMessagePutView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmsMessageResultView>>;
    public apiSmsWhatsappPut(body?: SmsMessagePutView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmsMessageResultView>>;
    public apiSmsWhatsappPut(body?: SmsMessagePutView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SmsMessageResultView>('put',`${this.basePath}/api/Sms/Whatsapp`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get one whatsapp template
     * 
     * @param whatsappId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSmsWhatsappWhatsappIdGet(whatsappId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SmsMessage>;
    public apiSmsWhatsappWhatsappIdGet(whatsappId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmsMessage>>;
    public apiSmsWhatsappWhatsappIdGet(whatsappId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmsMessage>>;
    public apiSmsWhatsappWhatsappIdGet(whatsappId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (whatsappId === null || whatsappId === undefined) {
            throw new Error('Required parameter whatsappId was null or undefined when calling apiSmsWhatsappWhatsappIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmsMessage>('get',`${this.basePath}/api/Sms/Whatsapp/${encodeURIComponent(String(whatsappId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
