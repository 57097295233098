/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerResultView } from './customerResultView';
import { PunchCardResultView } from './punchCardResultView';
import { PunchCardUsageResultView } from './punchCardUsageResultView';

export interface CustomerPunchCardResultView { 
    id?: string;
    customerId?: string;
    customer?: CustomerResultView;
    punchCardId?: string;
    punchCard?: PunchCardResultView;
    isCompleted?: boolean;
    punchCardUsages?: Array<PunchCardUsageResultView>;
    numberOfPunchesUsed?: number;
    isFavorite?: boolean;
    validFrom?: Date;
    validUntil?: Date;
    createdBy?: string;
    updatedBy?: string;
}