<mat-card [ngClass]="{'alt': fromDashboard}">
  <mat-card-content>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex align-items-center mb-2">
          <mat-icon class="color-green">task_alt</mat-icon>&nbsp;
          <h4 class="mb-0">Setup Guide</h4>
        </div>

        <p>You have pending tasks which are necessary to complete before operating. Head over to our setup guide to get
          your scheme up and running in no time 🙌</p>
      </div>

      <div class="col-12 col-md-6 text-right">
        <ng-container *ngIf="fromDashboard">
          <a [routerLink]="['/settings/setup-guide']" mat-flat-button color="accent">Setup Guide</a>
        </ng-container>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <mat-progress-bar mode="determinate"
          [value]="(sessionService.schemeSetup.tasksCompleted / sessionService.schemeSetup.tasksTotal) * 100"
          class="custom-progress-bar"></mat-progress-bar>
        <label class="mt-1">{{sessionService.schemeSetup.tasksCompleted}}/{{sessionService.schemeSetup.tasksTotal}} tasks
          completed</label>
      </div>
    </div>
  </mat-card-content>
</mat-card>
