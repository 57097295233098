/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DetailedCountryResultView { 
    id?: string;
    name?: string;
    nativeNames?: Array<string>;
    alpha2Code?: string;
    alpha3Code?: string;
    callingCode?: string;
    currencyName?: string;
    currencyCode?: string;
    currencySymbol?: string;
    languageNames?: Array<string>;
    languageCodes?: Array<string>;
    isInEurope?: boolean;
    flag?: string;
}