import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeedbackService, GiftCardService } from '../../../swagger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../services/session.service';
import { NewGiftCardDialogComponent } from '../../action-selector-dialog/new-giftcard-dialog/new-giftcard-dialog.component';
import { NewFeedbackDialogComponent } from '../../action-selector-dialog/new-feedback-dialog/new-feedback-dialog.component';

@Component({
  selector: 'loyale-action-feedback-selector',
  templateUrl: './action-feedback-selector.component.html',
  styleUrls: ['./action-feedback-selector.component.scss']
})
export class ActionFeedbackSelectorComponent implements OnInit {

  awaitingGet = false;
  feedbacks = [];
  filteredFeedbacks = [];
  @Input() selectedFeedbackFormId = null;
  @Output() outputFeedbackFormId = new EventEmitter<any>();
  @Output() removeFeedbackForm = new EventEmitter<any>();

  feedbackFilter = null;

  constructor(private feedbackService: FeedbackService, private dialog: MatDialog, private snackBar: MatSnackBar, public sessionService: SessionService) { }

  ngOnInit() {
    this.getFeedbacks();
  }

  getFeedbacks() {
    this.awaitingGet = true;
    this.feedbackService.apiFeedbackGet('', '-createdDate', null, null, this.sessionService.schemeId).subscribe(res => {
      this.awaitingGet = false;
      this.feedbacks = res;
      this.filteredFeedbacks = this.feedbacks;
    });
  }

  onNewFeedback() {
    const dialogRef = this.dialog.open(NewFeedbackDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getFeedbacks();
        this.selectedFeedbackFormId = res.id;
        this.onFeedbackChange();
      }
    });
  }

  onFeedbackChange() {
    this.outputFeedbackFormId.emit(this.selectedFeedbackFormId);
  }

  onFilterFeedback() {
    if (!this.feedbacks) {
      return;
    }

    let filter = this.feedbackFilter;
    if (!filter) {
      this.filteredFeedbacks = this.feedbacks;
      return;
    } else {
      filter = filter.toLowerCase();
    }

    this.filteredFeedbacks = this.feedbacks.filter(feedback => feedback.name.toLowerCase().indexOf(filter) > -1);
  }

}
