import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { PointMultiplicationService, PointMultiplicationInsertView } from 'src/app/swagger';

@Component({
  selector: 'loyale-new-multiplier-dialog',
  templateUrl: './new-multiplier-dialog.component.html',
  styleUrls: ['./new-multiplier-dialog.component.scss']
})
export class NewMultiplierDialogComponent implements OnInit {
  awaitingPost = false;
  pointsMultiplier: PointMultiplicationInsertView = <PointMultiplicationInsertView>{};

  constructor(private translate: TranslateService, private sessionService: SessionService, private multiplierService: PointMultiplicationService, private dialogRef: MatDialogRef<NewMultiplierDialogComponent>, public commonService: CommonService, private snackBar: MatSnackBar) { }

  ngOnInit() {

  }

  onSubmit(form) {
    if (form.valid) {
      this.awaitingPost = true;
      this.multiplierService.apiPointMultiplicationPost(this.pointsMultiplier).subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      })
    }
  }
}
