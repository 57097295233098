/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StripeProductType } from './stripeProductType';

export interface StripePurchaseView { 
    productType?: StripeProductType;
    unitValue?: number;
    redeemedValue?: number;
    productId?: string;
    quantity?: number;
    orderId?: string;
    customerEmail?: string;
}