/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LotteryReward } from './lotteryReward';
import { LotteryStatus } from './lotteryStatus';
import { Scheme } from './scheme';

export interface Lottery { 
    id?: string;
    name?: string;
    description?: string;
    dateFrom?: Date;
    dateTo?: Date;
    drawDate?: Date;
    schemeId?: string;
    scheme?: Scheme;
    autoDraw?: boolean;
    status?: LotteryStatus;
    purchaseCountPerTicket?: number;
    purchaseValuePerTicket?: number;
    maxTicketsPerCustomer?: number;
    createdBy?: string;
    updatedBy?: string;
    statusCheckJobId?: string;
    rewards?: Array<LotteryReward>;
    createdDate?: Date;
    updatedDate?: Date;
}