import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PointMultiplicationService } from '../../../swagger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewMultiplierDialogComponent } from '../../action-selector-dialog/new-multiplier-dialog/new-multiplier-dialog.component';

@Component({
  selector: 'loyale-action-multiplier-selector',
  templateUrl: './action-multiplier-selector.component.html',
  styleUrls: ['./action-multiplier-selector.component.scss']
})
export class ActionMultiplierSelectorComponent implements OnInit {

  awaitingGet = false;
  multipliers = [];
  @Input() selectedMultiplierId = null;
  @Output() outputMultiplierId = new EventEmitter<any>();
  @Output() removeMultiplier = new EventEmitter<any>();

  constructor(private multipliersService: PointMultiplicationService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getMultipliers();
  }

  getMultipliers() {
    this.awaitingGet = true;
    this.multipliersService.apiPointMultiplicationGet(null, '-createdDate').subscribe(res => {
      this.awaitingGet = false;
      this.multipliers = res;
    });
  }

  onNewPointsMultiplier() {
    const dialogRef = this.dialog.open(NewMultiplierDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getMultipliers();
        this.selectedMultiplierId = res.id;
        this.onMultiplierChange();
      }
    });
  }

  onMultiplierChange() {
    this.outputMultiplierId.emit(this.selectedMultiplierId);
  }

}
