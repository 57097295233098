import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Component, Input, OnInit } from '@angular/core';
import { InfoSheetComponent } from '../info-sheet/info-sheet.component';

@Component({
  selector: 'loyale-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss']
})
export class InfoButtonComponent implements OnInit {
  @Input() entity: any;

  constructor(private bottomSheet: MatBottomSheet) { }

  ngOnInit() {
  }

  onInfoHistory() {
    this.bottomSheet.open(InfoSheetComponent, { data: { entity: this.entity } });
  }
}
