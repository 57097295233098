import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { ExternalService } from 'src/app/services/external.service';
import { Coordinates } from 'src/app/services/session.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'loyale-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnChanges {
  @Input() label = 'Location';
  @Input() required = false;
  @Input() coordinates: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  @Input() address = null;
  @Output() outputCoordinates = new EventEmitter();

  zoom = 1;

  apiLoaded: Observable<boolean>;
  markerOptions = { draggable: false };

  markerPositions: google.maps.LatLngLiteral[] = [];

  mapOptions: google.maps.MapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    panControl: false,
  };

  constructor(private externalService: ExternalService, httpClient: HttpClient) {
    // this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?' + '&key=' + environment.googleMapsKey, 'callback')
    //   .pipe(
    //     map(() => true),
    //     catchError(() => of(false)),
    //   );
  }

  ngOnInit() {
    if (this.coordinates.lat != 0 && this.coordinates.lng != 0) {
      this.markerPositions.push(
        { lat: this.coordinates.lat, lng: this.coordinates.lng }
      );
      this.zoom = 17;
    }
  }

  ngOnChanges() {
    if (this.address) {
      this.onAddressChange();
    }
  }

  onAddressChange() {
    this.externalService.getGeocodingCoordinates(this.address).subscribe(res => {
      let geocoding = res as any;
      if (geocoding.results.length > 0) {
        this.coordinates.lat = geocoding.results[0].geometry.location.lat;
        this.coordinates.lng = geocoding.results[0].geometry.location.lng;
        this.outputCoordinates.emit(this.coordinates);
      }
    });
  }

  onMapClick(event: any) {
    this.coordinates.lat = event.latLng.toJSON().lat;
    this.coordinates.lng = event.latLng.toJSON().lng;

    if (this.markerPositions.length > 0) {
      this.markerPositions[0] = {
        lat: this.coordinates.lat, lng: this.coordinates.lng
      };
    } else {
      this.markerPositions.push(
        { lat: this.coordinates.lat, lng: this.coordinates.lng }
      );
    }

    this.outputCoordinates.emit(this.coordinates);
  }

  onZoomChange(zoom) {
    this.zoom = zoom;
  }
}
