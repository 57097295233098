/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GetGainRateResultView } from '../model/getGainRateResultView';
import { SimulatePointsInsertView } from '../model/simulatePointsInsertView';
import { TransactionInsertView } from '../model/transactionInsertView';
import { TransactionPutView } from '../model/transactionPutView';
import { TransactionResultView } from '../model/transactionResultView';
import { TransactionSplitView } from '../model/transactionSplitView';
import { TransactionSplitViewV2 } from '../model/transactionSplitViewV2';
import { TransferPointsView } from '../model/transferPointsView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TransactionService {

    protected basePath = 'https://api.staging.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get Transactions
     * This endpoint returns a list of transactions. These can be filtered, sorted and paged by the query parameters passed.
     * @param xApiKey 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosTransactionGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TransactionResultView>>;
    public apiPosTransactionGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TransactionResultView>>>;
    public apiPosTransactionGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TransactionResultView>>>;
    public apiPosTransactionGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosTransactionGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosTransactionGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TransactionResultView>>('get',`${this.basePath}/api/Pos/Transaction`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Transaction by ID
     * This endpoint returns one transaction by the id.
     * @param transactionId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosTransactionTransactionIdGet(transactionId: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<TransactionResultView>;
    public apiPosTransactionTransactionIdGet(transactionId: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionResultView>>;
    public apiPosTransactionTransactionIdGet(transactionId: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionResultView>>;
    public apiPosTransactionTransactionIdGet(transactionId: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (transactionId === null || transactionId === undefined) {
            throw new Error('Required parameter transactionId was null or undefined when calling apiPosTransactionTransactionIdGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosTransactionTransactionIdGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosTransactionTransactionIdGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TransactionResultView>('get',`${this.basePath}/api/Pos/Transaction/${encodeURIComponent(String(transactionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionAllocateSchemeTransactionsGet(scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionAllocateSchemeTransactionsGet(scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionAllocateSchemeTransactionsGet(scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionAllocateSchemeTransactionsGet(scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Transaction/AllocateSchemeTransactions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a List of Transactions, depending on the Filter or Sorting
     * 
     * @param profileId 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TransactionResultView>>;
    public apiTransactionByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TransactionResultView>>>;
    public apiTransactionByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TransactionResultView>>>;
    public apiTransactionByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiTransactionByProfileIdProfileIdGet.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TransactionResultView>>('get',`${this.basePath}/api/Transaction/byProfileId/${encodeURIComponent(String(profileId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionFillPastTransactionsLevelIdAllPost(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionFillPastTransactionsLevelIdAllPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionFillPastTransactionsLevelIdAllPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionFillPastTransactionsLevelIdAllPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Transaction/FillPastTransactionsLevelIdAll`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionFillPastTransactionsLevelIdPost(scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionFillPastTransactionsLevelIdPost(scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionFillPastTransactionsLevelIdPost(scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionFillPastTransactionsLevelIdPost(scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Transaction/FillPastTransactionsLevelId`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param customerId 
     * @param scheme 
     * @param outletId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionGainRateCustomerIdGet(customerId: string, scheme?: string, outletId?: string, observe?: 'body', reportProgress?: boolean): Observable<GetGainRateResultView>;
    public apiTransactionGainRateCustomerIdGet(customerId: string, scheme?: string, outletId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGainRateResultView>>;
    public apiTransactionGainRateCustomerIdGet(customerId: string, scheme?: string, outletId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGainRateResultView>>;
    public apiTransactionGainRateCustomerIdGet(customerId: string, scheme?: string, outletId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling apiTransactionGainRateCustomerIdGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (outletId !== undefined && outletId !== null) {
            queryParameters = queryParameters.set('outletId', <any>outletId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetGainRateResultView>('get',`${this.basePath}/api/Transaction/GainRate/${encodeURIComponent(String(customerId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheme 
     * @param outletId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionGainRateGet(scheme?: string, outletId?: string, observe?: 'body', reportProgress?: boolean): Observable<GetGainRateResultView>;
    public apiTransactionGainRateGet(scheme?: string, outletId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGainRateResultView>>;
    public apiTransactionGainRateGet(scheme?: string, outletId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGainRateResultView>>;
    public apiTransactionGainRateGet(scheme?: string, outletId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (outletId !== undefined && outletId !== null) {
            queryParameters = queryParameters.set('outletId', <any>outletId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetGainRateResultView>('get',`${this.basePath}/api/Transaction/GainRate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a List of Transaction, depending on the Filter or Sorting
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TransactionResultView>>;
    public apiTransactionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TransactionResultView>>>;
    public apiTransactionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TransactionResultView>>>;
    public apiTransactionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TransactionResultView>>('get',`${this.basePath}/api/Transaction`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a List of Transaction, depending on the Filter or Sorting
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionGroupedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TransactionResultView>>;
    public apiTransactionGroupedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TransactionResultView>>>;
    public apiTransactionGroupedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TransactionResultView>>>;
    public apiTransactionGroupedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TransactionResultView>>('get',`${this.basePath}/api/Transaction/Grouped`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a Transaction
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionIdDelete(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionIdDelete(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionIdDelete(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionIdDelete(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Transaction/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Transaction
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionIdGet(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<TransactionResultView>;
    public apiTransactionIdGet(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionResultView>>;
    public apiTransactionIdGet(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionResultView>>;
    public apiTransactionIdGet(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TransactionResultView>('get',`${this.basePath}/api/Transaction/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionIdPut(id: string, body?: TransactionPutView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionIdPut(id: string, body?: TransactionPutView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionIdPut(id: string, body?: TransactionPutView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionIdPut(id: string, body?: TransactionPutView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Transaction/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionOnHoldIdDelete(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionOnHoldIdDelete(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionOnHoldIdDelete(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionOnHoldIdDelete(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionOnHoldIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Transaction/OnHold/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates the OnHold transaction
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionOnHoldPost(body?: TransactionInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionOnHoldPost(body?: TransactionInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionOnHoldPost(body?: TransactionInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionOnHoldPost(body?: TransactionInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Transaction/OnHold`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a new Transaction
     * 
     * @param scheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionPost(scheme: string, body?: TransactionInsertView, observe?: 'body', reportProgress?: boolean): Observable<TransactionResultView>;
    public apiTransactionPost(scheme: string, body?: TransactionInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionResultView>>;
    public apiTransactionPost(scheme: string, body?: TransactionInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionResultView>>;
    public apiTransactionPost(scheme: string, body?: TransactionInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiTransactionPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TransactionResultView>('post',`${this.basePath}/api/Transaction`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * RecalculatePointsForCustomer
     * 
     * @param customerId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionRecalculatePointsForCustomerGet(customerId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionRecalculatePointsForCustomerGet(customerId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionRecalculatePointsForCustomerGet(customerId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionRecalculatePointsForCustomerGet(customerId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerId !== undefined && customerId !== null) {
            queryParameters = queryParameters.set('customerId', <any>customerId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Transaction/RecalculatePointsForCustomer`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionRedeemOnHoldIdPost(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionRedeemOnHoldIdPost(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionRedeemOnHoldIdPost(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionRedeemOnHoldIdPost(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTransactionRedeemOnHoldIdPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Transaction/RedeemOnHold/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param transactionId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionReplicateWebhookTransactionIdGet(transactionId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionReplicateWebhookTransactionIdGet(transactionId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionReplicateWebhookTransactionIdGet(transactionId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionReplicateWebhookTransactionIdGet(transactionId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (transactionId === null || transactionId === undefined) {
            throw new Error('Required parameter transactionId was null or undefined when calling apiTransactionReplicateWebhookTransactionIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Transaction/ReplicateWebhook/${encodeURIComponent(String(transactionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionRunPastPointMovementsPost(body?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionRunPastPointMovementsPost(body?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionRunPastPointMovementsPost(body?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionRunPastPointMovementsPost(body?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Transaction/RunPastPointMovements`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionSimulatePointsPost(body?: SimulatePointsInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionSimulatePointsPost(body?: SimulatePointsInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionSimulatePointsPost(body?: SimulatePointsInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionSimulatePointsPost(body?: SimulatePointsInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Transaction/SimulatePoints`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * V2 of split, uses new views
     * 
     * @param xScheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionSplitPointsPost(xScheme: string, body?: TransactionSplitViewV2, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionSplitPointsPost(xScheme: string, body?: TransactionSplitViewV2, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionSplitPointsPost(xScheme: string, body?: TransactionSplitViewV2, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionSplitPointsPost(xScheme: string, body?: TransactionSplitViewV2, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiTransactionSplitPointsPost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Transaction/splitPoints`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionSplitPost(scheme: string, body?: TransactionSplitView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionSplitPost(scheme: string, body?: TransactionSplitView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionSplitPost(scheme: string, body?: TransactionSplitView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionSplitPost(scheme: string, body?: TransactionSplitView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiTransactionSplitPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Transaction/split`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Point transfer from one customer to anther
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionTransferPointsPut(body?: TransferPointsView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionTransferPointsPut(body?: TransferPointsView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionTransferPointsPut(body?: TransferPointsView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionTransferPointsPut(body?: TransferPointsView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Transaction/TransferPoints`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * RecalculatePointsForCustomer
     * 
     * @param transactionId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTransactionUpdateCachedResultantPointsGet(transactionId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTransactionUpdateCachedResultantPointsGet(transactionId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTransactionUpdateCachedResultantPointsGet(transactionId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTransactionUpdateCachedResultantPointsGet(transactionId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (transactionId !== undefined && transactionId !== null) {
            queryParameters = queryParameters.set('transactionId', <any>transactionId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Transaction/UpdateCachedResultantPoints`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Gain Rate
     * This endpoint returns the gain rate based on the outlet id passed.
     * @param xScheme 
     * @param outletId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2TransactionGainRateGet(xScheme?: string, outletId?: string, observe?: 'body', reportProgress?: boolean): Observable<GetGainRateResultView>;
    public apiV2TransactionGainRateGet(xScheme?: string, outletId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGainRateResultView>>;
    public apiV2TransactionGainRateGet(xScheme?: string, outletId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGainRateResultView>>;
    public apiV2TransactionGainRateGet(xScheme?: string, outletId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (outletId !== undefined && outletId !== null) {
            queryParameters = queryParameters.set('outletId', <any>outletId);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetGainRateResultView>('get',`${this.basePath}/api/v2/Transaction/GainRate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Transactions
     * This endpoint returns a list of transactions. These can be filtered, sorted and paged by the query parameters passed.
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2TransactionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TransactionResultView>>;
    public apiV2TransactionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TransactionResultView>>>;
    public apiV2TransactionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TransactionResultView>>>;
    public apiV2TransactionGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TransactionResultView>>('get',`${this.basePath}/api/v2/Transaction`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Transactions Grouped
     * This endpoint returns a list of transactions grouped. These can be filtered, sorted and paged by the query parameters passed.
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2TransactionGroupedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiV2TransactionGroupedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiV2TransactionGroupedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiV2TransactionGroupedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v2/Transaction/Grouped`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Transaction by ID
     * This endpoint returns one transaction by the id.
     * @param transactionId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2TransactionTransactionIdGet(transactionId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<TransactionResultView>;
    public apiV2TransactionTransactionIdGet(transactionId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransactionResultView>>;
    public apiV2TransactionTransactionIdGet(transactionId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransactionResultView>>;
    public apiV2TransactionTransactionIdGet(transactionId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (transactionId === null || transactionId === undefined) {
            throw new Error('Required parameter transactionId was null or undefined when calling apiV2TransactionTransactionIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TransactionResultView>('get',`${this.basePath}/api/v2/Transaction/${encodeURIComponent(String(transactionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
