import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import * as ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import { Liquid } from 'liquidjs';
import { AdvancedType, BasicType, JsonToMjml, ImageManager } from 'easy-email-core';
import { EmailEditor, EmailEditorProvider, EmailEditorProviderProps, IEmailTemplate } from 'easy-email-editor';
import { ExtensionProps, MjmlToJson, StandardLayout } from 'easy-email-extensions';
import { templateData } from './template';
// import '@arco-themes/react-think-purple/css/arco.css';
import mjml from 'mjml-browser';
import { saveAs } from 'file-saver';

import { CustomBlocksType } from './CustomBlocks/constants';
import { Upload2Service } from '../../services/upload2.service';
import { CommonService } from '../../services/common.service';

import './CustomBlocks';
import { EmailSettingsService } from '../../swagger';
import { SessionService } from '../../services/session.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Button,

} from '@arco-design/web-react';

const containerElementName = 'customReactComponentContainer';

ImageManager.add({
  IMAGE_59:
    'assets/email/add-image.svg',
});

const fontList = [
  'Arial',
  'Tahoma',
  'Verdana',
  'Times New Roman',
  'Courier New',
  'Georgia',
  'Lato',
  'Roboto',
  'Montserrat',
].map(item => ({ value: item, label: item }));

const categories: ExtensionProps['categories'] = [
  {
    label: 'Content',
    active: true,
    blocks: [
      {
        type: AdvancedType.TEXT,
        payload: {
          data: {
            value: {
              fontFamily: 'Arial',
              content: 'Replace this text'
            },
            attributes: {
              align: 'left',
              padding: '0px 25px 10px 25px',
            },
          },
        }
      },
      {
        type: AdvancedType.IMAGE,
      },
      {
        type: AdvancedType.BUTTON,
      },
      {
        type: AdvancedType.SOCIAL,
        payload: {
          data: {
            value: {
              elements: [
                {
                  href: '#',
                  target: '_blank',
                  src: 'https://loyale.s3-eu-west-1.amazonaws.com/1a3a39bb-cc72-4967-84f5-8598e28a0466%2fc3263591-27f8-4af6-b5f4-638f4d5cf510.png',
                  content: 'Facebook'
                },
                {
                  href: '#',
                  target: '_blank',
                  src: 'https://loyale.s3-eu-west-1.amazonaws.com/1a3a39bb-cc72-4967-84f5-8598e28a0466%2fee59198d-c184-4aef-8794-ebad653bded2.png',
                  content: 'Linkedin'
                },
                {
                  href: '',
                  target: '_blank',
                  src: 'https://loyale.s3-eu-west-1.amazonaws.com/1a3a39bb-cc72-4967-84f5-8598e28a0466%2f0502bba3-cbff-453c-8437-34a7ae3ef557.png',
                  content: 'Twitter'
                },
                {
                  href: '',
                  target: '_blank',
                  src: 'https://loyale.s3-eu-west-1.amazonaws.com/1a3a39bb-cc72-4967-84f5-8598e28a0466%2f82e801db-ba60-498e-8c3a-84765da1e861.png',
                  content: 'Instagram'
                }
              ]
            },
          },
          attributes: {
            align: 'center',
            color: '#0a0707',
            mode: 'horizontal',
            fontFamily: 'Arial',
            padding: '5px 0px 5px 0px',
            width: '600px',
            'max-width': '600px'
          },
        },
      },
      {
        type: AdvancedType.DIVIDER,
      },
      {
        type: AdvancedType.SPACER,
      },
      {
        type: AdvancedType.HERO,
      },
      {
        type: AdvancedType.WRAPPER,
      },
      {
        type: CustomBlocksType.UNSUBSCRIBE,
        title: 'Unsubscribe',
        payload: { style: 'material-icon', class: 'material-icon' },

      },
      // {
      //   type: CustomBlocksType.IMAGEANDTEXT,
      //   title: 'Article',
      //   payload: { style: 'material-icon', class: 'material-icon' },
      // }
    ],
  },
  {
    label: 'Layout',
    active: true,
    displayType: 'column',
    blocks: [
      {
        title: '1 column',
        payload: [
          ['100%'],
        ],
      },
      {
        title: '2 columns',
        payload: [
          ['50%', '50%'],
          ['33%', '67%'],
          ['67%', '33%'],
          ['25%', '75%'],
          ['75%', '25%'],
        ],
      },
      {
        title: '3 columns',
        payload: [
          ['33.33%', '33.33%', '33.33%'],
          ['25%', '25%', '50%'],
          ['50%', '25%', '25%'],
        ],
      },
      {
        title: '4 columns',
        payload: [['25%', '25%', '25%', '25%']],
      },
    ],
  },

];

const onBeforePreview: EmailEditorProviderProps['onBeforePreview'] = (html: string, mergeTags) => {
  const engine = new Liquid();
  const tpl = engine.parse(html);
  return engine.renderSync(tpl, mergeTags);
};

@Component({
  selector: 'loyale-email-editor',
  template: `
      <div class="emailEditor" #${containerElementName}></div>`,
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})

export class NewEmailEditorComponent implements OnChanges, OnDestroy, OnInit {
  @ViewChild(containerElementName, { static: true }) containerRef!: ElementRef;

  @Input() emailMjmlTemplate;
  @Input() emailMjmlPresent;

  @Output() public emailTemplateOutput = new EventEmitter<any>();
  @Output() uploadPending = new EventEmitter();

  awaitingPost = false;

  initialValues = {
    subject: '1',
    subTitle: '1',
    content: templateData('', '')
  };

  mergeTags: any = [
    { id: 1, value: '{{Customer.FirstName}}' },
    { id: 2, value: '{{Customer.LastName}}' },
    { id: 3, value: '{{Customer.MobileNumber}}' },
    { id: 4, value: '{{Customer.Email}}' },
    { id: 5, value: '{{Customer.BarCode}}' },
    { id: 6, value: '{{VerifyConfig.CallbackUrl}}' },
    { id: 7, value: '{{ResetLink}}' },
    { id: 8, value: '{{UnsubscribeLink}}' }
  ];

  mergeTags2: any =
    {
      EmailImg: 'https://loyale.s3-eu-west-1.amazonaws.com/1a3a39bb-cc72-4967-84f5-8598e28a0466%2f2d494da5-9c6d-4778-bcbd-50c6bd865f3d.png',
      Customer: {
        FirstName: 'John',
        LastName: 'Borg',
        MobileNumber: '11336699',
        BarCode: '8759648215396',
      },
      VerifyConfig: {
        CallbackUrl: '//you-callBackUrl'
      },
      ResetLink: '//you-resetLink',
      UnsubscribeLink: '//you-UnsubscribeLink',
    };

  constructor(public sessionService: SessionService,
              private uploadService: Upload2Service,
              private snackBar: MatSnackBar,
              public commonService: CommonService,
              private emailSettingsService: EmailSettingsService) {

  }

  ngOnInit() {
    if (!this.emailMjmlPresent) {
      this.getEmailSettings();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.emailMjmlPresent && this.emailMjmlTemplate) {
      this.initialValues = {
        subject: '',
        subTitle: '',
        content: JSON.parse(this.emailMjmlTemplate),
      };
      this.render(this.initialValues);
    }
  }

  ngOnDestroy(): void {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  onUploadImage: any = (blob: Blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onloadend = () => {

        this.awaitingPost = true;
        this.uploadPending.emit(true);
        const imageUpload = this.commonService.getFileFromBase64(reader.result);

        if (imageUpload.size > 1000000) {
          this.snackBar.open('You have exceeded the image size limit. Please select another image');
          resolve('');
        } else {
          this.uploadService.uploadFile(imageUpload).subscribe(res => {
            this.uploadPending.emit(false);
            resolve(res.url);
            this.awaitingPost = false;
          }, err => {
            this.uploadPending.emit(false);
            this.commonService.errorHandler(err);
            resolve('');
            this.awaitingPost = false;
          });
        }

      };

    });

  };

  onExportHTML: any = (values: IEmailTemplate) => {
    const mjmlString = JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content,
      dataSource: this.mergeTags,
    });

    const html = mjml(mjmlString, {}).html;

    // pushEvent({ event: 'HTMLExport', payload: { values, mergeTags } });
    navigator.clipboard.writeText(html);
    saveAs(new Blob([html], { type: 'text/html' }), 'easy-email.html');
    return null;
  };

  onSubmit: any = (value, form) => {
    const mjmlString = JsonToMjml({
      data: value.content,
      mode: 'production',
      dataSource: this.mergeTags,
    });

    const html = mjml(mjmlString, {}).html;

    this.emailTemplateOutput.emit(html);

    return value;
  };

  getEmailSettings() {
    this.emailSettingsService.apiEmailSettingsGet(this.sessionService.schemeId).subscribe(res => {
      const emailSettings = res;

      const mjmlTemplate = JsonToMjml({
        data: templateData(res.headerImageUrl, res.footerImageUrl),
        mode: 'production',
      });

      this.initialValues = {
        subject: '',
        subTitle: '',
        content: MjmlToJson(mjmlTemplate)
      };
      this.render(this.initialValues);

    }, err => {
      this.commonService.errorHandler(err);
    });
  }

  private render(initialValues: any): void {
    ReactDOM.render(
      <div>
        <React.StrictMode>
          <EmailEditorProvider

            dashed={false}
            data={initialValues}
            height={'calc(100vh - 85px)'}
            mergeTags={this.mergeTags2}
            autoComplete
            fontList={fontList}
            onUploadImage={this.onUploadImage}
            onBeforePreview={onBeforePreview}
            onSubmit={this.onSubmit}
          >

            {({ values }, { subscribe }) => {
              useEffect(() => {
                const mjmlString = JsonToMjml({
                  data: values.content,
                  mode: 'production',
                  context: values.content,
                  dataSource: this.mergeTags,
                });

                this.emailTemplateOutput.emit({
                  json: JSON.stringify(values.content), html: mjml(mjmlString, {
                    beautify: true,
                    validationLevel: 'soft'
                  }).html
                });
              });

              return (
                <div>
                  {/*<Button onClick={() => this.onExportHTML(values)}>*/}
                  {/*  <strong>Export</strong>*/}
                  {/*</Button>*/}
                  <StandardLayout
                    compact={!true}
                    categories={categories}
                    showSourceCode={true}
                    mjmlReadOnly={false}
                  >
                    <EmailEditor/>
                  </StandardLayout>
                </div>
              );
            }}
          </EmailEditorProvider>

        </React.StrictMode>
      </div>
      , this.containerRef.nativeElement);
  }
}
