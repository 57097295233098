import {
  IBlockData,
  BasicType,
  components,
  createCustomBlock,
  getPreviewClassName,
  AdvancedType,
  mergeBlock, IBlock,
} from 'easy-email-core';

import { CustomBlocksType } from '../constants';
import React from 'react';
import { getContentEditableClassName } from 'easy-email-editor';

const { Column, Section, Wrapper, Text, Button, Image, Group } = components;

export type IProductRecommendation = IBlockData<{
  'background-color': string;
  'button-color': string;
  'button-text-color': string;
  'product-name-color': string;
  'product-price-color': string;
  'title-color': string;
  'text-color': string;
},
  {
    title: string;
    buttonText: string;
    buttonUrl: string;
  }>;

export const ProductRecommendation: IBlock = {
  name: 'Button redirect',
  type: CustomBlocksType.PRODUCT_RECOMMENDATION,
  validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER],
  create(
    payload
  ) {
    const defaultData: IProductRecommendation = {
      type: CustomBlocksType.PRODUCT_RECOMMENDATION,
      data: {
        value: {
          title: 'You might also like',
          buttonText: 'Buy now',
          buttonUrl: '',
        },
      },
      attributes: {
        'background-color': '#ffffff',
        'button-text-color': '#0a0707',
        'button-color': '#414141',
        'product-name-color': '#414141',
        'product-price-color': '#414141',
        'title-color': '#222222',
        'text-color': '#ffffff',
      },
      children: [],
    };
    return mergeBlock(defaultData, payload);
  },
  render: ({ data, idx, mode, context, dataSource }) => {
    const { title, buttonText, buttonUrl } = data.data.value;
    const attributes = data.attributes;

    const instance = (
      <Wrapper
        padding="20px 0px 20px 0px"
        border="none"
        direction="ltr"
        text-align="center"
        background-color={attributes['background-color']}
        css-class={mode === 'testing' ? getPreviewClassName(idx, data.type) : ''}
      >
        <Section padding="20px" >
          <Column>

            <Image padding="0px 0px 0px 0px" width="100px" src={'https://www.transparentpng.com/download/submit-button/1u2PSi-submit-button-free-download.png'}
                   href={buttonUrl} target="_blank"/>

            <Button background-color={attributes['button-color']} color={attributes['text-color']} href={buttonUrl}>
              {buttonText}
            </Button>
          </Column>
        </Section>
      </Wrapper>
    );

    return instance;
  },
};

export { Panel } from './Panel';

