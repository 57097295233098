<ng-container>
  <div class="row">
    <div class="col-12 col-lg-6 ">
      <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

      <ng-container *ngIf="!awaitingGet">
        <mat-card>
          <mat-card-content>
            <form [formGroup]="emailForm">
              <mat-form-field class="w-100">
                <mat-label>Name</mat-label>
                <input matInput name="name" formControlName="name" required="true">
                <mat-error *ngIf="emailForm.get('name')?.errors?.required">Required</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Subject</mat-label>
                <input matInput formControlName="subject">
                <mat-error *ngIf="emailForm.get('subject').errors?.required">Required</mat-error>
              </mat-form-field>

              <div class="mat-form-field-custom">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Contains Marketing Material</span>
                    <mat-icon class=""
                      matTooltip="If this contains markeing material, it will never be distributed to customers that opted out of the marketing communication">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="marketingMaterial" formControlName="isMarketingMaterial">
                    <span *ngIf="emailForm.get('isMarketingMaterial').value">Yes</span>
                    <span *ngIf="!emailForm.get('isMarketingMaterial').value">No</span>
                  </mat-slide-toggle>
                </div>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
  </div>
  <div class="mb-3">
    <loyale-email-editor [emailMjmlTemplate]="emailForm.get('json').value" [emailMjmlPresent]="jsonTemplatePresent"
      (emailTemplateOutput)="updateHTML($event)"></loyale-email-editor>
  </div>

</ng-container>
