<h1 mat-dialog-title>New Demographic</h1>
<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>Name</mat-label>
          <input matInput name="name" #name="ngModel" [(ngModel)]="demographic.name" required>
          <mat-error *ngIf="form.submitted && name?.errors?.required">Required</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="rules">
      <h5>Rules</h5>
      <loyale-shared-dynamic-form #dynamicForm></loyale-shared-dynamic-form>

      <ng-container *ngIf="!showBuilder; else ruleBuilder">
        <div class="d-flex justify-content-center">
          <button type="button" mat-flat-button color="accent" (click)="addAndRule(); showBuilder = true"
            [disabled]="ruleProperties.length == 0">Add Rules</button>
        </div>
      </ng-container>
      <ng-template #ruleBuilder>
        <div class="d-flex justify-content-center">
          <button type="button" mat-flat-button color="accent" (click)="addAndRule()"
            [disabled]="ruleProperties.length == 0">AND</button>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <button type="button" mat-flat-button color="accent" (click)="addOrRule()"
            [disabled]="ruleProperties.length == 0">OR</button>
        </div>
      </ng-template>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="flex-fill"></div>
    <button mat-flat-button [mat-dialog-close]>Close</button>
    <button mat-flat-button color="primary" [disabled]="awaitingPost">Save</button>
  </div>
</form>
