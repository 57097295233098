import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loyale-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss']
})
export class ChatButtonComponent implements OnInit {
  @Input() label = "Let's Talk";
  @Input() message = null;
  @Input() disabled = false;
  @Input() color = 'accent';

  constructor() { }

  ngOnInit() {

  }

  onShowIntercom() {
    if (this.message) {
      (<any>window).Intercom('showNewMessage', this.message);
    } else {
      (<any>window).Intercom('showNewMessage');
    }
  }
}
