/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddressOptions } from './addressOptions';
import { StripeGender } from './stripeGender';
import { StripePoliticalExposure } from './stripePoliticalExposure';

export interface StripeIndividualProfileSettings { 
    address?: AddressOptions;
    registeredAddress?: AddressOptions;
    email?: string;
    firstName?: string;
    lastName?: string;
    gender?: StripeGender;
    politicalExposure?: StripePoliticalExposure;
}