import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: any, stringA: string, stringB: string): string {
    return value.replace(stringA, stringB);
  }

}
