import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[equalTo]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EqualToDirective, multi: true }]
})
export class EqualToDirective implements Validator {
  @Input('equalTo') equalTo!: string;

  validate(control: AbstractControl): ValidationErrors | null {
    return this.equalTo === control.value ? null : { equalTo: true };
  }

}
