/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdditionalIntegrationFields } from './additionalIntegrationFields';
import { Integrations } from './integrations';

export interface SchemeIntegrationResultView {
  id?: string;
  integrationId?: string;
  integration?: Integrations;
  name?: string;
  description?: string;
  schemeId?: string;
  enabled?: boolean;
  setupCompleted?: boolean;
  additionalIntegrationFields?: Array<AdditionalIntegrationFields>;
  createdDate?: Date;
  updatedDate?: Date;
}
