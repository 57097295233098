/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LineItemInsertView } from './lineItemInsertView';
import { LoyaleTransactionType } from './loyaleTransactionType';

export interface SimulatePointsInsertView { 
    transactionDate?: Date;
    outletId?: string;
    type?: LoyaleTransactionType;
    customerId?: string;
    transactionValue?: number;
    transactionPointValue?: number;
    lineItems?: Array<LineItemInsertView>;
}