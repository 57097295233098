import { Pipe, PipeTransform } from '@angular/core';

enum Type {
  'Purchase' = 0,
  'Positive Adjustment',
  'Negative Adjustment',
  'Redeem',
  'Refund',
  'Top Up',
  'On Hold'
}

@Pipe({
  name: 'transactionType'
})
export class TransactionTypePipe implements PipeTransform {

  transform(value: any): string {
    return Type[value];
  }

}
