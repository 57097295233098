import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemeService } from './../../swagger/api/scheme.service';
import { SessionService } from './../../services/session.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadDialogComponent } from '../load-dialog/load-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'loyale-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnInit, OnDestroy {
  dialogRef = null;

  private destroy$ = new Subject<void>();

  constructor(public sessionService: SessionService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router, private schemeService: SchemeService, private commonService: CommonService) { }

  ngOnInit() {
    console.info('Load ' + this.sessionService.schemeId);
    this.getScheme();
    this.dialogRef = this.dialog.open(LoadDialogComponent, { disableClose: true });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getScheme() {
    this.schemeService.apiSchemeIdGet(this.sessionService.schemeId).subscribe(res => {
      this.sessionService.scheme = res;

      this.commonService.loadIntercom();
      this.commonService.startUpdateCheck();
      this.commonService.startSessionCheck();
      this.commonService.startHealthCheck();
      this.commonService.startSetupCheck();
      this.redirectApplication();
    });
  }

  redirectApplication() {
    let destination = sessionStorage.getItem('destination');

    //redirect application only after health and status checks have finished running
    const checkingSub = this.commonService.onChangeViewCheckDone.pipe(takeUntil(this.destroy$)).subscribe((checksNumber: number) => {
      this.dialogRef.close();
      if (checksNumber >= 2) {
        if (destination) {
          sessionStorage.removeItem('destination');
          this.router.navigateByUrl(destination);
          this.destroy$.next();
          this.destroy$.complete();
        } else {
          this.router.navigate(['/dashboard'], { queryParamsHandling: 'merge' });
          this.destroy$.next();
          this.destroy$.complete();
        }

      }
    });
  }
}
