import { Component, Input, OnInit } from '@angular/core';
import { SmsMessageResultView } from '../../swagger/model/smsMessageResultView';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'loyale-preview-sms',
  templateUrl: './preview-sms.component.html',
  styleUrls: ['./preview-sms.component.scss']
})
export class PreviewSmsComponent implements OnInit {
  @Input() dialogMode = false;
  @Input() sms: SmsMessageResultView = <SmsMessageResultView> {};

  constructor(public sessionService: SessionService) { }

  ngOnInit() {
  }

}
