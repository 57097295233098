<ng-container>
  <div class="row">
    <div class="col-12 col-lg-6">
      <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

      <ng-container *ngIf="!awaitingGet">
        <mat-card>
          <mat-card-content>
            <form [formGroup]="pushNotificationForm">
              <mat-form-field class="w-100">
                <mat-label>Title</mat-label>
                <input matInput formControlName="title">
                <mat-error *ngIf="pushNotificationForm.get('title').errors?.required">Required</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100">
                <mat-label>Message</mat-label>
                <textarea rows="1" matInput formControlName="subTitle"></textarea>
                <mat-error *ngIf="pushNotificationForm.get('subTitle').errors?.required">Required</mat-error>
              </mat-form-field>

              <mat-form-field class="w-100" tourAnchor="pushNotificationsStep005">
                <mat-label>Deep Link Type</mat-label>
                <mat-select formControlName="deepLinkType" (selectionChange)="onDeeplinkTypeChange()"
                  (selectionChange)="changeEvent()">
                  <mat-option>None</mat-option>
                  <mat-option value="alerts">Post</mat-option>
                  <mat-option value="coupons">Coupon</mat-option>
                  <mat-option value="feedback">Feedback Form</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="w-100" tourAnchor="pushNotificationsStep006">
                <mat-label>Deep Link To</mat-label>
                <mat-select name="deepLinkId" formControlName="deepLinkId"
                  [disabled]="this.pushNotificationForm.get('deepLinkType').value == null || this.pushNotificationForm.get('deepLinkType').value ==''"
                  [required]="this.pushNotificationForm.get('deepLinkType').value !== null && this.pushNotificationForm.get('deepLinkType').value !==''"
                  (selectionChange)="changeEvent()">
                  <ng-container *ngIf="this.pushNotificationForm.get('deepLinkType').value == 'alerts'">
                    <mat-option *ngFor="let item of alerts" [value]="item.id">{{item.title}}</mat-option>
                  </ng-container>
                  <ng-container *ngIf="this.pushNotificationForm.get('deepLinkType').value == 'coupons'">
                    <mat-option *ngFor="let item of coupons" [value]="item.id">{{item.name}}</mat-option>
                  </ng-container>
                  <ng-container *ngIf="this.pushNotificationForm.get('deepLinkType').value == 'feedback'">
                    <mat-option *ngFor="let item of feedbacks" [value]="item.id">{{item.name}}</mat-option>
                  </ng-container>
                </mat-select>
                <mat-error *ngIf="pushNotificationForm.get('deepLinkId').getError('required')">Required!
                </mat-error>
              </mat-form-field>

              <div class="mat-form-field-custom" tourAnchor="pushNotificationsStep007">
                <div class="mat-form-field-wrapper">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="label">Contains Marketing Material</span>
                    <mat-icon class="icon-resize-small"
                      matTooltip="If this contains markeing material, it will never be distributed to customers that opted out of the marketing communication">
                      info
                    </mat-icon>
                  </div>
                  <mat-slide-toggle name="marketingMaterial" formControlName="isMarketingMaterial">
                    <span *ngIf="pushNotificationForm.get('isMarketingMaterial').value">Yes</span>
                    <span *ngIf="!pushNotificationForm.get('isMarketingMaterial').value">No</span>
                  </mat-slide-toggle>
                </div>
              </div>
            </form>
          </mat-card-content>
        </mat-card>

      </ng-container>
    </div>

    <div class="col-12 col-lg-6">
      <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

      <loyale-preview-notification [pushNotification]="pushNotificationForm.value"
        *ngIf="!awaitingGet"></loyale-preview-notification>
    </div>
  </div>
</ng-container>
