<mat-form-field appearance="" [ngClass]="{'w-100': fullWidth}">
  <mat-label>{{label}}<span *ngIf="required">&nbsp;*</span></mat-label>
  <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="dateRangePicker" [min]="minDate" [max]="maxDate"
    [disabled]="disabled">
    <input matStartDate readonly placeholder="Start date" formControlName="startDate">
    <input matEndDate readonly placeholder="End date" formControlName="endDate" (dateChange)="onDateRangeChange()">
  </mat-date-range-input>
  <button type="button" mat-icon-button matSuffix (click)="onClear()" *ngIf="clearable && dateRange != [null,null]">
    <mat-icon>cancel</mat-icon>
  </button>
  <mat-datepicker-toggle matPrefix [for]="dateRangePicker"></mat-datepicker-toggle>
  <mat-date-range-picker #dateRangePicker>
  </mat-date-range-picker>
  <mat-error
    *ngIf="dateRangeForm.get('startDate').hasError('required') || dateRangeForm.get('endDate').hasError('required')">Invalid
    date range</mat-error>
</mat-form-field>
