import { Component, OnInit } from '@angular/core';
import { FeedbackQuestionInsertView, FeedbackService, TagInsertView, TagService } from '../../../swagger';
import { BehaviorSubject } from 'rxjs';
import { ActionConnectorInterface } from '../../stepper/actionConnector.interface';
import { ActionType } from '../../stepper/action.enum';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionService } from '../../../services/session.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'loyale-new-tag-dialog',
  templateUrl: './new-tag-dialog.component.html',
  styleUrls: ['./new-tag-dialog.component.scss']
})
export class NewTagDialogComponent implements OnInit {
  awaitingPost = false;

  awaitingGet = false;
  tag: TagInsertView = <TagInsertView> {};

  constructor(
    private translate: TranslateService,
    private tagService: TagService,
    private snackBar: MatSnackBar,
    public commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<NewTagDialogComponent>,
    public sessionService: SessionService) { }

  ngOnInit() {
  }

  onSubmit(form: any) {
    if (form.valid) {
      this.awaitingPost = true;
      this.tagService.apiTagPost(this.sessionService.schemeId, this.tag).subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });
    }
  }
}
