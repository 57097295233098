import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'loyale-pending-toolbar',
  templateUrl: './pending-toolbar.component.html',
  styleUrls: ['./pending-toolbar.component.scss']
})
export class PendingToolbarComponent implements OnInit {
  @Input() title = '';
  @Input() button = '';
  @Input() canDismiss = false;
  @Output() onAction = new EventEmitter<any>();
  @Output() onDismiss = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
