import { Pipe, PipeTransform } from '@angular/core';

export enum MessageStatus {
  'Active' = 0,
  'Waiting On Customer',
  'Solved',
  'Closed'
}

@Pipe({
  name: 'messageStatus'
})
export class MessageStatusPipe implements PipeTransform {

  transform(value: any): string {
    return MessageStatus[value];
  }

}
