import { BarCodeService, CouponsLinkedResultView } from 'src/app/swagger';
import { CouponResultView } from './../../swagger/model/couponResultView';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'loyale-preview-coupon',
  templateUrl: './preview-coupon.component.html',
  styleUrls: ['./preview-coupon.component.scss']
})
export class PreviewCouponComponent implements OnInit {
  @Input() coupon: CouponResultView = <CouponResultView>{};

  dummyBarCode = moment().unix();
  dummyQrCodeUrl = environment.baseApiUrl + '/api/barcode/qrcode?qrcode=' + this.dummyBarCode;
  dummyDate = moment();

  constructor(private barCodeService: BarCodeService) { }

  ngOnInit() {
    // if (this.couponLinked) {
    //   this.coupon = this.couponLinked.coupon;

    //   this.barCodeService.apiBarCodeGenerateTypeGet(1, this.couponLinked.id).subscribe(res => {
    //     this.imageBase64 = 'data:image/png;base64,' + res.qrCode;
    //   })
    // }
  }
}
