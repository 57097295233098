import { Component, OnInit, ViewChild } from '@angular/core';
import { CouponInsertView, CouponService, FranchiseService, OutletService } from 'src/app/swagger';
import { NestedOutlets, SessionService } from 'src/app/services/session.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PromiseService } from 'src/app/services/promise.service';

@Component({
  selector: 'loyale-new-coupon-dialog',
  templateUrl: './new-coupon-dialog.component.html',
  styleUrls: ['./new-coupon-dialog.component.scss']
})
export class NewCouponDialogComponent implements OnInit {
  @ViewChild('couponComponent') couponComponent;

  awaitingPost = false;

  constructor(private translate: TranslateService, private promiseService: PromiseService, public sessionService: SessionService, private dialogRef: MatDialogRef<NewCouponDialogComponent>, private franchiseService: FranchiseService, private route: ActivatedRoute, public commonService: CommonService, private snackBar: MatSnackBar, private couponService: CouponService, private outletService: OutletService) { }

  ngOnInit() {

  }

  onSubmit() {
    if (this.couponComponent.couponForm.valid) {
      this.awaitingPost = true;

      this.couponComponent.createNew().subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });
    }
  }
}
