import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService, CouponService, FeedbackService, PushNotificationInsertView, PushNotificationResultView, PushNotificationService } from '../../../swagger';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../../services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../services/common.service';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActionConnectorInterface } from '../../stepper/actionConnector.interface';
import { ActionType } from '../../stepper/action.enum';
import { MainActionInterface } from '../main-action.interface';

@Component({
  selector: 'loyale-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit, MainActionInterface {
  awaitingPost = false;
  awaitingGet = false;

  deeplinkList = [];

  pushNotificationForm: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl('', Validators.required),
    subTitle: new UntypedFormControl('', Validators.required),
    deepLinkType: new UntypedFormControl(null, []),
    deepLinkId: new UntypedFormControl(null, []),
    isMarketingMaterial: new UntypedFormControl(false, [])
  });

  pushNotificationCopy: PushNotificationResultView = <PushNotificationResultView> {};

  editorOptions = {
    emoji: true
  };

  alerts = [];
  coupons = [];
  feedbacks = [];
  @Input() pushNotificationId: string = null;

  @Output() pushNotificationData: EventEmitter<any> = new EventEmitter(this.pushNotificationForm.value);
  @Output() entityChanges: EventEmitter<boolean> = new EventEmitter(false);

  @Input()
  pushNotification: PushNotificationResultView;

  public readonly dataUpdate: BehaviorSubject<ActionConnectorInterface> = new BehaviorSubject<ActionConnectorInterface>({
    actionType: ActionType.PUSH_NOTIFICATION,
    actionData: null,
    formValid: null
  });

  constructor(
    private translate: TranslateService,
    private feedbackService: FeedbackService,
    private couponService: CouponService,
    private pushNotificationService: PushNotificationService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private alertService: AlertService,
    public commonService: CommonService,
    public sessionService: SessionService) { }

  ngOnInit() {
    this.getCoupons();
    this.getAlerts();
    this.getFeedbacks();

    // if we have pushNotificationId then upload pushNotification data
    if (this.pushNotificationId) {
      this.getPushNotification();
    }

    this.pushNotificationForm.valueChanges.subscribe(changes => {
      this.dataUpdate.next(
        {
          actionType: ActionType.PUSH_NOTIFICATION,
          actionData: this.pushNotificationForm.value,
          formValid: this.pushNotificationForm.valid
        }
      );

      if (this.pushNotificationForm.touched) {
        this.pushNotificationData.next(changes);
      }

      this.pushNotification = { ...this.pushNotification, ...this.pushNotificationForm.value };

      this.entityChanges.next(JSON.stringify(this.pushNotificationCopy) !== JSON.stringify(this.pushNotification));

    });

  }

  getPushNotification() {
    this.awaitingGet = true;
    this.pushNotificationService.apiPushNotificationIdGet(this.pushNotificationId).subscribe(res => {
      this.awaitingGet = false;
      this.pushNotification = res;
      this.pushNotificationCopy = res;
      this.pushNotificationForm.patchValue({ ...res });
    });
  }

  getAlerts() {
    this.alertService.apiAlertGet('', '-createdDate').subscribe(res => {
      this.alerts = res;
    });
  }

  getCoupons() {
    this.couponService.apiCouponGet('', '-createdDate').subscribe(res => {
      this.coupons = res;
    });
  }

  getFeedbacks() {
    this.feedbackService.apiFeedbackGet('', '-createdDate').subscribe(res => {
      this.feedbacks = res;
    });
  }

  onDeeplinkTypeChange() {

    if (this.pushNotificationForm.get('deepLinkType').value === 'alerts') {
      this.pushNotificationForm.get('deepLinkId').setValidators([Validators.required]);
      this.pushNotificationForm.get('deepLinkId').updateValueAndValidity();
      this.deeplinkList = this.alerts;
    } else if (this.pushNotificationForm.get('deepLinkType').value === 'coupons') {
      this.pushNotificationForm.get('deepLinkId').setValidators([Validators.required]);
      this.pushNotificationForm.get('deepLinkId').updateValueAndValidity();
      this.deeplinkList = this.coupons;
    } else if (this.pushNotificationForm.get('deepLinkType').value === 'feedback') {
      this.pushNotificationForm.get('deepLinkId').setValidators([Validators.required]);
      this.pushNotificationForm.get('deepLinkId').updateValueAndValidity();
      this.deeplinkList = this.feedbacks;
    } else {
      this.pushNotificationForm.get('deepLinkId').clearValidators();
      this.pushNotificationForm.get('deepLinkId').updateValueAndValidity();
      this.pushNotificationForm.get('deepLinkType').setValue(null);
      this.pushNotificationForm.get('deepLinkId').setValue(null);
    }
  }

  changeEvent() {
    this.pushNotificationForm.markAllAsTouched();
    this.pushNotificationForm.updateValueAndValidity();
  }

  setAndTriggerTouch(formValue?: any) {
    if (formValue) {
      this.pushNotificationForm.markAllAsTouched();
      this.pushNotificationForm.patchValue(formValue);
    }
  }

  // get action data manual direct from component
  public getData(): ActionConnectorInterface {
    this.pushNotificationForm.markAllAsTouched();

    return {
      actionType: ActionType.PUSH_NOTIFICATION,
      actionData: this.pushNotificationForm.value as PushNotificationInsertView,
      formValid: this.pushNotificationForm.valid
    };
  }

  public createNew(): Observable<any> {
    if (this.pushNotificationForm.valid) {
      this.awaitingPost = true;

      return this.pushNotificationService.apiPushNotificationPost(this.pushNotificationForm.value, this.sessionService.schemeId).pipe(
        tap(res => {
          this.awaitingPost = false;

          this.dataUpdate.next(
            {
              actionType: ActionType.PUSH_NOTIFICATION,
              actionData: res,
              formValid: this.pushNotificationForm.valid
            }
          );
        })
      );
    } else {
      return null;
    }
  }

  public update(): Observable<any> {

    if (this.pushNotificationForm.valid) {
      this.awaitingPost = true;
      return this.pushNotificationService.apiPushNotificationPut(this.pushNotification, this.sessionService.schemeId).pipe(
        tap(res => {
          this.awaitingPost = false;

          this.dataUpdate.next(
            {
              actionType: ActionType.PUSH_NOTIFICATION,
              actionData: res,
              formValid: this.pushNotificationForm.valid
            }
          );

          this.pushNotification = res;
          this.pushNotificationCopy = res;

          this.pushNotificationForm.patchValue({ ...res });
        })
      );
    } else {
      return null;
    }
  }

}
