import { BlockManager } from 'easy-email-core';
import { CustomBlocksType } from './constants';
import { Panel as ProductRecommendationPanel, ProductRecommendation } from './ProductRecommendation';
import { BlockAttributeConfigurationManager } from 'easy-email-extensions';
import React from 'react';
import { Panel as UnsubscribePanel, Unsubscribe } from './Unsubscribe';
import { ImageAndText, Panel as ImageAndTextPanel } from './ImagesAndText';

BlockManager.registerBlocks({ [CustomBlocksType.PRODUCT_RECOMMENDATION]: ProductRecommendation });
BlockManager.registerBlocks({ [CustomBlocksType.UNSUBSCRIBE]: Unsubscribe });
BlockManager.registerBlocks({ [CustomBlocksType.IMAGEANDTEXT]: ImageAndText });

BlockAttributeConfigurationManager.add({
  [CustomBlocksType.PRODUCT_RECOMMENDATION]: ProductRecommendationPanel,
  [CustomBlocksType.UNSUBSCRIBE]: UnsubscribePanel,
  [CustomBlocksType.IMAGEANDTEXT]: ImageAndTextPanel,

});
