/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CustomerResultView } from '../model/customerResultView';
import { LotteryByCustomerResultView } from '../model/lotteryByCustomerResultView';
import { LotteryInsertView } from '../model/lotteryInsertView';
import { LotteryPickWinnerInsertView } from '../model/lotteryPickWinnerInsertView';
import { LotteryResultView } from '../model/lotteryResultView';
import { ManualLotteryWinnerResultView } from '../model/manualLotteryWinnerResultView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LotteryService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves list of lotteries for customer
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotteryByCustomerGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<LotteryByCustomerResultView>>;
    public apiLotteryByCustomerGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LotteryByCustomerResultView>>>;
    public apiLotteryByCustomerGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LotteryByCustomerResultView>>>;
    public apiLotteryByCustomerGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LotteryByCustomerResultView>>('get',`${this.basePath}/api/Lottery/ByCustomer`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves one lottery for customer
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotteryByCustomerIdGet(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<LotteryByCustomerResultView>;
    public apiLotteryByCustomerIdGet(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LotteryByCustomerResultView>>;
    public apiLotteryByCustomerIdGet(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LotteryByCustomerResultView>>;
    public apiLotteryByCustomerIdGet(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiLotteryByCustomerIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LotteryByCustomerResultView>('get',`${this.basePath}/api/Lottery/ByCustomer/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of lotteries
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotteryGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<LotteryResultView>>;
    public apiLotteryGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LotteryResultView>>>;
    public apiLotteryGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LotteryResultView>>>;
    public apiLotteryGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LotteryResultView>>('get',`${this.basePath}/api/Lottery`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove Lottery
     * 
     * @param lotteryId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotteryLotteryIdDelete(lotteryId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLotteryLotteryIdDelete(lotteryId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLotteryLotteryIdDelete(lotteryId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLotteryLotteryIdDelete(lotteryId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (lotteryId === null || lotteryId === undefined) {
            throw new Error('Required parameter lotteryId was null or undefined when calling apiLotteryLotteryIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Lottery/${encodeURIComponent(String(lotteryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Lottery
     * 
     * @param lotteryId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotteryLotteryIdGet(lotteryId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<LotteryResultView>;
    public apiLotteryLotteryIdGet(lotteryId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LotteryResultView>>;
    public apiLotteryLotteryIdGet(lotteryId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LotteryResultView>>;
    public apiLotteryLotteryIdGet(lotteryId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (lotteryId === null || lotteryId === undefined) {
            throw new Error('Required parameter lotteryId was null or undefined when calling apiLotteryLotteryIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LotteryResultView>('get',`${this.basePath}/api/Lottery/${encodeURIComponent(String(lotteryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add reward
     * 
     * @param lotteryId 
     * @param ticketId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotteryLotteryIdPickWinnerTicketIdPost(lotteryId: string, ticketId: string, body?: LotteryPickWinnerInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<ManualLotteryWinnerResultView>;
    public apiLotteryLotteryIdPickWinnerTicketIdPost(lotteryId: string, ticketId: string, body?: LotteryPickWinnerInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ManualLotteryWinnerResultView>>;
    public apiLotteryLotteryIdPickWinnerTicketIdPost(lotteryId: string, ticketId: string, body?: LotteryPickWinnerInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ManualLotteryWinnerResultView>>;
    public apiLotteryLotteryIdPickWinnerTicketIdPost(lotteryId: string, ticketId: string, body?: LotteryPickWinnerInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (lotteryId === null || lotteryId === undefined) {
            throw new Error('Required parameter lotteryId was null or undefined when calling apiLotteryLotteryIdPickWinnerTicketIdPost.');
        }

        if (ticketId === null || ticketId === undefined) {
            throw new Error('Required parameter ticketId was null or undefined when calling apiLotteryLotteryIdPickWinnerTicketIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ManualLotteryWinnerResultView>('post',`${this.basePath}/api/Lottery/${encodeURIComponent(String(lotteryId))}/PickWinner/${encodeURIComponent(String(ticketId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove the reward from a ticket.
     * 
     * @param lotteryId 
     * @param ticketId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotteryLotteryIdRemoveWinnerTicketIdPost(lotteryId: string, ticketId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLotteryLotteryIdRemoveWinnerTicketIdPost(lotteryId: string, ticketId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLotteryLotteryIdRemoveWinnerTicketIdPost(lotteryId: string, ticketId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLotteryLotteryIdRemoveWinnerTicketIdPost(lotteryId: string, ticketId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (lotteryId === null || lotteryId === undefined) {
            throw new Error('Required parameter lotteryId was null or undefined when calling apiLotteryLotteryIdRemoveWinnerTicketIdPost.');
        }

        if (ticketId === null || ticketId === undefined) {
            throw new Error('Required parameter ticketId was null or undefined when calling apiLotteryLotteryIdRemoveWinnerTicketIdPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Lottery/${encodeURIComponent(String(lotteryId))}/RemoveWinner/${encodeURIComponent(String(ticketId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a Lottery
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotteryPost(body?: LotteryInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<LotteryResultView>;
    public apiLotteryPost(body?: LotteryInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LotteryResultView>>;
    public apiLotteryPost(body?: LotteryInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LotteryResultView>>;
    public apiLotteryPost(body?: LotteryInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LotteryResultView>('post',`${this.basePath}/api/Lottery`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Lottery by id
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotteryPut(body?: LotteryResultView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<LotteryResultView>;
    public apiLotteryPut(body?: LotteryResultView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LotteryResultView>>;
    public apiLotteryPut(body?: LotteryResultView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LotteryResultView>>;
    public apiLotteryPut(body?: LotteryResultView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LotteryResultView>('put',`${this.basePath}/api/Lottery`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Syncs missing tickets for given lotteryId
     * 
     * @param lotteryId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotterySyncMissingLotteryTicketsLotteryIdPost(lotteryId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiLotterySyncMissingLotteryTicketsLotteryIdPost(lotteryId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiLotterySyncMissingLotteryTicketsLotteryIdPost(lotteryId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiLotterySyncMissingLotteryTicketsLotteryIdPost(lotteryId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (lotteryId === null || lotteryId === undefined) {
            throw new Error('Required parameter lotteryId was null or undefined when calling apiLotterySyncMissingLotteryTicketsLotteryIdPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Lottery/SyncMissingLotteryTickets/${encodeURIComponent(String(lotteryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of lottery winners
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiLotteryWinnersGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerResultView>>;
    public apiLotteryWinnersGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerResultView>>>;
    public apiLotteryWinnersGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerResultView>>>;
    public apiLotteryWinnersGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerResultView>>('get',`${this.basePath}/api/Lottery/winners`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
