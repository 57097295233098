import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PointModifierService } from '../../../swagger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewModifierDialogComponent } from '../../action-selector-dialog/new-modifier-dialog/new-modifier-dialog.component';

@Component({
  selector: 'loyale-action-modifier-selector',
  templateUrl: './action-modifier-selector.component.html',
  styleUrls: ['./action-modifier-selector.component.scss']
})
export class ActionModifierSelectorComponent implements OnInit {
  awaitingGet = false;
  modifiers = [];
  @Input() selectedModifierId = null;
  @Output() outputModifierId = new EventEmitter<any>();
  @Output() removeModifier = new EventEmitter<any>();

  constructor(private modifiersService: PointModifierService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getModifiers();
  }

  getModifiers() {
    this.awaitingGet = true;
    this.modifiersService.apiPointModifierGet(null, '-createdDate').subscribe(res => {
      this.awaitingGet = false;
      this.modifiers = res;
    });
  }

  onNewPointsMultiplier() {
    const dialogRef = this.dialog.open(NewModifierDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getModifiers();
        this.selectedModifierId = res.id;
        this.onModifierChange();
      }
    });
  }

  onModifierChange() {
    this.outputModifierId.emit(this.selectedModifierId);
  }
}
