import { Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { HelpersService } from '../swagger';
import { Observable, of } from 'rxjs';
import { debounceTime, take, switchMap, map } from 'rxjs/operators';

@Directive({
  selector: '[vatnumber]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: VatnumberDirective, multi: true }]

})
export class VatnumberDirective implements AsyncValidator {
  @Input('vatnumber') vatnumber;

  constructor(private helpersService: HelpersService) {
  }

  validate(c: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
    if (c.value == null || c.value == '' || c.value.length < 5) {
      return of({ vatnumber: true });
    } else {
      let countryCode = c.value == null ? '' : c.value.substring(0, 2);
      let vatNumber = c.value == null ? '' : c.value.substring(2, c.value.length);

      return c.valueChanges.pipe(debounceTime(500), take(1), switchMap(_switch =>
        this.helpersService.apiHelpersVatNumberGet(countryCode, vatNumber).pipe(
          map(res =>
            res.isValid == false ? { vatnumber: true } : null
          )
        )
      ))
    }
  }
}
