<mat-card>
  <mat-card-content>
    <form [formGroup]="questionFormGroup">
      <div class="row">
        <mat-form-field class="col-lg-8 col-12">
          <input matInput type="text" required="true" placeholder="Question" formControlName="question">
          <mat-error *ngIf="questionFormGroup.get('question').hasError('required')">
            {{'Question text is required!'|translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class=" col-lg-4 col-12 ">
          <mat-select [(ngModel)]="selectorValue" [disabled]="editViewMode" formControlName="typeSelector">

            <mat-select-trigger class="d-flex flex-row align-items-md-center">
              <mat-icon class="mr-2">{{selectorIcon}}</mat-icon>
              {{selectorValue}}
            </mat-select-trigger>

            <mat-option value="Multiple Choice" (click)="selectorIcon='radio_button_unchecked'">
              <mat-icon>radio_button_unchecked</mat-icon>
              {{'Multiple Choice'|translate}}
            </mat-option>
            <mat-option value="Emoji Rating" (click)="selectorIcon='sentiment_very_satisfied'">
              <mat-icon>sentiment_very_satisfied</mat-icon>
              {{'Emoji Rating'|translate}}
            </mat-option>
            <mat-option value="Scale Rating" (click)="selectorIcon='star_border'">
              <mat-icon>star_border</mat-icon>
              {{'Scale Rating'|translate}}
            </mat-option>
            <mat-option value="Free Text" (click)="selectorIcon='notes'">
              <mat-icon>notes</mat-icon>
              {{'Free Text'|translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div *ngIf="selectorValue==='Multiple Choice'" class="w-100">
        <div *ngFor="let option of optionList; let i = index" class="w-100">
          <div class="mb-3 w-100">
            <mat-form-field class="w-100">
              <textarea matInput type="text" placeholder="Answer" required="true" class="align-items-center w-100"
                [maxLength]="100" [ngModelOptions]="{standalone: true}" [(ngModel)]="optionValue[i]"
                (change)="changeAnswer(optionValue[i], i)" rows="1">
              </textarea>
              <mat-hint>{{optionValue[i].length}}/100</mat-hint>
              <mat-icon *ngIf="!editViewMode && optionList.length>1" class="cursor-pointer" matSuffix
                (click)="deleteOption(i)">
                clear
              </mat-icon>
            </mat-form-field>
          </div>
        </div>
        <mat-error *ngIf="optionList.length===0">
          {{'Answer is required!'|translate}}
        </mat-error>
      </div>

      <div *ngIf="selectorValue==='Emoji Rating'" class="w-100">
        <div class="d-flex mb-3 align-items-center">
          <mat-checkbox [disabled]="editViewMode" [(ngModel)]="labelEnable" [ngModelOptions]="{standalone: true}"
            class="mr-3">
            {{"Add label"|translate}}
          </mat-checkbox>

          <button mat-flat-button color="accent" (click)="fillEmojis()" [disabled]="editViewMode">
            {{'Prefill Emoji'|translate}}
          </button>
        </div>


        <div *ngFor="let emoji of emojiList; let i = index" class="d-flex flex-row w-100 align-items-md-center">
          <!--                    <mat-icon class="d-flex flex-column mr-2">radio_button_unchecked</mat-icon>-->
          <div class="d-flex insert-emoji ">

            <button mat-icon-button type="button" matTooltip="Insert emoji" *ngIf="emoji.value"
              (click)="openEmojiMart(i)">
              <p class="font-20">{{emoji.value}}</p>
            </button>
            <button mat-icon-button type="button" matTooltip="Insert emoji" *ngIf="!emoji.value "
              (click)="openEmojiMart(i)">
              <mat-icon>add_reaction</mat-icon>
            </button>
            <emoji-mart [autoFocus]="true" [isNative]="true" [showPreview]="false" sheetSize="24.0"
              (emojiClick)="onInsertEmojiToList($event,i);" *ngIf="emoji.openEmoji"></emoji-mart>
          </div>
          <button mat-icon-button type="button" matTooltip="Delete emoji option"
            *ngIf="!editViewMode && emojiList.length>1 && !labelEnable" (click)="deleteEmojiRatingOption(i)">
            <mat-icon>clear</mat-icon>
          </button>

          <mat-form-field class="w-100" *ngIf="labelEnable">
            <input matInput type="text" placeholder="Label" class="align-items-center w-100" maxlength="10"
              [(ngModel)]="emoji.label" [ngModelOptions]="{standalone: true}" (change)="triggerAnswerChange()">
            <mat-hint>{{emoji.label.length}}/10</mat-hint>
            <mat-icon *ngIf="!editViewMode && emojiList.length>1" class="cursor-pointer" matSuffix
              (click)="deleteEmojiRatingOption(i)">
              clear
            </mat-icon>
          </mat-form-field>

        </div>
        <mat-error *ngIf="emojiList.length===0 || !emojiList.values()">
          {{"Emoji is required!"|translate}}
        </mat-error>

      </div>
      <div *ngIf="selectorValue==='Scale Rating'" class="w-100">

        <div class="d-flex flex-row w-100">
          <div class="d-flex flex-column mr-3">
            {{"Scale"|translate}}
            <mat-form-field appearance="outline" class="scale-selector-w ">
              <mat-select [disabled]="editViewMode" [(value)]="scaleEmojiNumber" (valueChange)="changeScaleRating()">
                <mat-option [value]="0">
                  1
                </mat-option>
                <mat-option [value]="1">
                  2
                </mat-option>
                <mat-option [value]="2">
                  3
                </mat-option>
                <mat-option [value]="3">
                  4
                </mat-option>
                <mat-option [value]="4">
                  5
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="d-flex flex-column">
            {{"Emoji"|translate}}
            <button mat-icon-button type="button" matTooltip="Insert emoji" *ngIf="emojiValue"
              (click)="showEmojiPicker = !showEmojiPicker">
              <div class="font-20">{{emojiValue}}</div>
            </button>
            <button mat-icon-button type="button" matTooltip="Insert emoji" *ngIf="!emojiValue"
              (click)="showEmojiPicker = !showEmojiPicker">
              <mat-icon>add_reaction</mat-icon>
            </button>
          </div>

          <emoji-mart [autoFocus]="true" [isNative]="true" [showPreview]="false" sheetSize="32.0"
            (emojiClick)="onInsertEmoji($event); " *ngIf="showEmojiPicker"></emoji-mart>
        </div>

        <mat-error class="mb-3" *ngIf="!emojiValue">
          {{"Emoji is required!"|translate}}
        </mat-error>

        <div class="mb-3">
          <mat-checkbox [disabled]="editViewMode" [(ngModel)]="labelEnable" [ngModelOptions]="{standalone: true}">
            {{"Add label"|translate}}
          </mat-checkbox>
        </div>

        <div *ngIf="labelEnable">
          <div class="d-flex flex-row mb-3 align-items-center"
            *ngFor="let labelElement of scaleEmojiList;let index=index">
            <div class="mr-3 no-space-wrap">
              {{index + 1}} {{'Rating'|translate}}
            </div>
            <mat-form-field class="w-100">
              <input matInput type="text" placeholder="Label" [maxLength]="10" (change)="triggerAnswerChange()"
                class="align-items-center w-100" [(ngModel)]="labelElement.label" [ngModelOptions]="{standalone: true}">
              <mat-hint>{{labelElement.label.length}}/10</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="selectorValue==='Free Text'" class="d-flex flex-row color-gray">
        <mat-icon class="mr-2">notes</mat-icon>
        {{'Free Text answer'|translate}}
      </div>

      <hr>

      <div class=" d-flex flex-row w-100 justify-content-end">
        <div *ngIf="!editViewMode" class="d-flex w-100">
          <button mat-flat-button color="accent" class="mr-2"
            *ngIf=" selectorValue==='Emoji Rating' || selectorValue==='Multiple Choice'" (click)="addOption()">
            <div class="d-flex flex-row align-items-center">
              <mat-icon>add</mat-icon>
              {{'Add Answer'|translate}}
            </div>
          </button>

          <div class="flex-fill">

          </div>

          <mat-icon class="mr-2 cursor-pointer" [color]="copyIconColor" (mouseover)="copyIconColor = 'accent'"
            (mouseout)="copyIconColor = ''" (click)="emitDuplicateQuestionForm()"
            matTooltip="Duplicate question">filter_none
          </mat-icon>
          <mat-icon class="mr-2 cursor-pointer" [color]="deleteIconColor" (mouseover)="deleteIconColor = 'accent'"
            (click)="emitDeleteQuestionForm()" (mouseout)="deleteIconColor = ''" matTooltip="Delete question">delete
          </mat-icon>
        </div>

        <mat-divider [vertical]="true" class="mr-2"></mat-divider>
        <div matTooltip="Set question as required">
          <mat-slide-toggle formControlName="required" [disabled]="editViewMode" [labelPosition]="'before'"
            color="accent">{{'Required'|translate}}
          </mat-slide-toggle>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
