<mat-nav-list>
    <div mat-list-item class="d-flex align-items-center justify-content-between">
        <div>
            <small mat-line>Email</small>
            <span mat-line class="d-block mb-3 fs-1-2">{{data.email}}</span>
        </div>
        <button type="button" mat-icon-button matTooltip="Copy" ngxClipboard [cbContent]="data.email">
            <mat-icon>file_copy</mat-icon>
        </button>
    </div>
    <div mat-list-item class="d-flex align-items-center justify-content-between">
        <div>
            <small mat-line>Password</small>
            <code mat-line class="d-block mb-3 fs-1-2">{{data.password}}</code>
        </div>
        <button type="button" mat-icon-button matTooltip="Copy" ngxClipboard [cbContent]="data.password">
            <mat-icon>file_copy</mat-icon>
        </button>
    </div>
    <div mat-list-item class="d-flex align-items-center justify-content-between">
        <div>
            <small mat-line>Token</small>
            <code mat-line class="d-block mb-3 fs-1-2 token">{{data.token}}</code>
        </div>
        <button type="button" mat-icon-button matTooltip="Copy" ngxClipboard [cbContent]="data.token">
            <mat-icon>file_copy</mat-icon>
        </button>
    </div>
</mat-nav-list>

<div class="d-flex justify-content-center p-3">
    <small>This token is valid for 24 hours from creation</small>
</div>