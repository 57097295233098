<div class="d-flex align-items-center">
  <small class="page-index">Page {{page}} / {{totalPages}}</small>
  <div class="ml-3">
    <button type="button" mat-icon-button matTooltip="Prev" (click)="changePage.emit(page - 1)"
      [disabled]="page <= 1 || page == null">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button type="button" mat-icon-button matTooltip="Next" (click)="changePage.emit(page + 1)"
      [disabled]="page >= totalPages || totalPages == null">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>