import { PointModifierInsertView } from './../../../swagger/model/pointModifierInsertView';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { PromiseService } from 'src/app/services/promise.service';
import { NestedOutlets } from 'src/app/services/session.service';
import { FranchiseService, PointModifierService } from 'src/app/swagger';

@Component({
  selector: 'loyale-new-modifier-dialog',
  templateUrl: './new-modifier-dialog.component.html',
  styleUrls: ['./new-modifier-dialog.component.scss']
})
export class NewModifierDialogComponent implements OnInit {
  awaitingPost = false;
  pointsModifier: PointModifierInsertView = <PointModifierInsertView>{};
  allOutlets = false;

  nestedOutlets: NestedOutlets[] = [];

  constructor(private translate: TranslateService, private promiseService: PromiseService, private modifiersService: PointModifierService, private dialogRef: MatDialogRef<NewModifierDialogComponent>, private franchiseService: FranchiseService, private route: ActivatedRoute, public commonService: CommonService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.promiseService.getNestedOutlets().then(res => {
      this.nestedOutlets = res;
    });
  }

  onSubmit(form) {
    if (form.valid) {
      this.awaitingPost = true;
      this.modifiersService.apiPointModifierPost(this.pointsModifier).subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      })
    }
  }
}
