/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface KlaviyoCustomerSyncProperties { 
    firstName?: boolean;
    lastName?: boolean;
    profileImageUrl?: boolean;
    address?: boolean;
    loyalePointBalance?: boolean;
    loyaleMonetaryBalance?: boolean;
    birthday?: boolean;
    additionalCustomerFields?: boolean;
    levels?: boolean;
    tags?: boolean;
    mobileNumber?: boolean;
    lastTransactionDate?: boolean;
    barCode?: boolean;
    lastConnectedDate?: boolean;
    onHold?: boolean;
    gender?: boolean;
    externalReferenceId?: boolean;
}