/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MetaData } from './metaData';
import { Tax } from './tax';

export interface WooCommerceLineItem { 
    id?: number;
    name?: string;
    productId?: number;
    variationId?: number;
    quantity?: number;
    taxClass?: string;
    subtotal?: string;
    subtotalTax?: string;
    total?: string;
    totalTax?: string;
    taxes?: Array<Tax>;
    metaData?: Array<MetaData>;
    sku?: string;
    price?: number;
    vendor?: string;
}