/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ActiveTax } from './activeTax';
import { LSKCategory } from './lSKCategory';

export interface TransactionLine { 
    id?: number;
    currencyCode?: string;
    amount?: number;
    unitAmount?: number;
    quantity?: number;
    staffName?: string;
    nameOverride?: string;
    itemDescription?: string;
    accountingGroupId?: string;
    accountingGroupName?: string;
    itemId?: number;
    itemSku?: string;
    date?: number;
    active?: boolean;
    deviceId?: number;
    deviceName?: string;
    activeTax?: ActiveTax;
    tags?: string;
    phase?: number;
    categories?: Array<LSKCategory>;
    amountWithTax?: number;
    amountLessTax?: number;
    discountedAmount?: number;
    grossUnitAmount?: number;
}