import { Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private overlayContainer: OverlayContainer) { }

  public set theme(value: string) {
    localStorage.setItem('theme', value);
    this.setOverlay(value);
  }

  public get theme() {
    return localStorage.getItem('theme');
  }

  setOverlay(theme) {
    const classList = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(classList).filter((item: string) =>
      item.includes('-theme')
    );
    if (themeClassesToRemove.length) {
      classList.remove(...themeClassesToRemove);
    }
    classList.add(theme);
  }

  refresh() {
    this.theme = this.theme;
  }
}
