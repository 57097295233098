import { Directive, Input } from '@angular/core';
import { ValidatorFn, FormControl, NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[postcode]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PostcodeDirective, multi: true }]
})

export class PostcodeDirective {
  @Input('postcode') postcode;

  constructor() {
  }

  validate(c: AbstractControl) {
    let isValid = /([a-z]{2,3}[0-9]{4})$/.test(c.value);

    const validationState = !isValid ? { postcode: true } : null;
    return validationState
  }
}
