import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private sessionService: SessionService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const tokenValid = this.sessionService.tokenValid();

    if (!tokenValid) {
      return true;
    } else {
      this.router.navigate(['/load'], { queryParamsHandling: 'merge' });
    }
  }

}
