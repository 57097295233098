import { CouponResultView } from './../../swagger/model/couponResultView';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loyale-micro-coupon',
  templateUrl: './micro-coupon.component.html',
  styleUrls: ['./micro-coupon.component.scss']
})
export class MicroCouponComponent implements OnInit {
  @Input() coupon: CouponResultView = <CouponResultView>{}
  @Input() disabled = false;

  constructor() { }

  ngOnInit() {
  }

}
