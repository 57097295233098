/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GroupByTransactions } from './groupByTransactions';
import { LoyaleTransactionType } from './loyaleTransactionType';

export interface TransactionReportView { 
    groupBy?: GroupByTransactions;
    outletIds?: Array<string>;
    transactionTypes?: Array<LoyaleTransactionType>;
}