<form [formGroup]="timeTriggerForm" (ngSubmit)="onSubmit(timeTriggerForm)">
    <div class="row">
        <div class="col-12">
            <p>Select the repeat frequency, and build rules on top of it.</p>
        </div>

        <div class="col-12 col-lg-6">
            <mat-form-field class="w-100">
                <mat-label>Repeat</mat-label>
                <mat-select name="repeat" #repeat="ngModel" [(ngModel)]="selectedRepeat" required
                            (ngModelChange)="onRepeatChange($event)" [ngModelOptions]="{standalone: true}">
                    <mat-option value='once'>Once</mat-option>
                    <mat-option value='daily'>Daily</mat-option>
                    <mat-option value='weekly'>Weekly</mat-option>
                    <mat-option value='monthly'>Monthly</mat-option>
                    <mat-option value='yearly'>Yearly</mat-option>
                </mat-select>
                <mat-error *ngIf="repeat?.errors?.required">Required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <ng-container *ngIf="selectedRepeat == 'once'">
        <div class="interval mt-3">
            <h5>Rules</h5>
            <div class="interval-option mt-3">
                <loyale-date-time-picker label="On" appearance="outline" [returnUtc]="false"
                                         [required]="selectedRepeat == 'once'" [markAsTouched]="touched"
                                         (onChange)="onceTimeChangeCheck($event); generateCron()">
                </loyale-date-time-picker>
                <mat-error *ngIf="!validTime">The chosen time is already in the past</mat-error>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedRepeat == 'daily'">
        <div class="interval">
            <h5>Rules</h5>
            <div class="interval-option mt-3">
                Every&nbsp;

                <mat-form-field class="narrow">
                    <mat-label>Day/s</mat-label>
                    <mat-select [(ngModel)]="daily.dayFrequency"
                                (ngModelChange)="generateCron($event)"
                                [required]="selectedRepeat == 'daily'" formControlName="day">
                        <mat-option *ngFor="let dayNumber of dayNumberList" [value]="dayNumber">{{dayNumber}}
                        </mat-option>
                    </mat-select>

                    <mat-error *ngIf="timeTriggerForm.get('day').errors?.required">Required</mat-error>
                </mat-form-field>&nbsp;


                <loyale-time-picker label="At" [returnUtc]="false" [markAsTouched]="touched"
                                    (onChange)="daily.time = $event; generateCron()"
                                    [required]="selectedRepeat == 'daily'">
                </loyale-time-picker>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedRepeat == 'weekly'">
        <div class="interval">
            <h5>Rules</h5>
            <div class="interval-option mt-3">
                On&nbsp;

                <mat-form-field>
                    <mat-label>Day/s</mat-label>
                    <mat-select multiple [(ngModel)]="weekly.daysOfWeek"
                                (ngModelChange)="generateCron($event)" [required]="selectedRepeat == 'weekly'"
                                formControlName="week">
                        <mat-option *ngFor="let day of dayOfWeekList" [value]="day.value">{{day.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="timeTriggerForm.get('week').errors?.required">Required</mat-error>
                </mat-form-field>&nbsp;

                <loyale-time-picker label="At" [returnUtc]="false" [markAsTouched]="touched"
                                    (onChange)="weekly.time = $event; generateCron()"
                                    [required]="selectedRepeat == 'weekly'">
                </loyale-time-picker>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedRepeat == 'monthly'">
        <div class="interval">
            <h5>Rules</h5>
            <div class="interval-options mt-3">
                On the&nbsp;

                <mat-form-field class="narrow">
                    <mat-label>Day/s</mat-label>
                    <mat-select [(ngModel)]="monthly.day" (ngModelChange)="generateCron($event)"
                                [required]="selectedRepeat == 'monthly'" formControlName="day">
                        <mat-option *ngFor="let day of dayList" [value]="day.value">
                            {{day.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="timeTriggerForm.get('day')?.errors?.required">Required</mat-error>
                </mat-form-field>&nbsp;

                of every&nbsp;

                <mat-form-field>
                    <mat-label>Month/s</mat-label>
                    <mat-select [(ngModel)]="monthly.monthFrequency"
                                (ngModelChange)="generateCron($event)" [required]="selectedRepeat == 'monthly'"
                                formControlName="month">
                        <mat-option *ngFor="let monthNumber of monthNumberList" [value]="monthNumber">
                            {{monthNumber}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="timeTriggerForm.get('month')?.errors?.required">Required</mat-error>
                </mat-form-field>&nbsp;

                <loyale-time-picker label="At" [returnUtc]="false" [markAsTouched]="touched"
                                    (onChange)="monthly.time = $event; generateCron()"
                                    [required]="selectedRepeat == 'monthly'">
                </loyale-time-picker>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedRepeat == 'yearly'">
        <div class="interval">
            <h5>Rules</h5>
            <div class="interval-option mt-3">
                Every&nbsp;

                <mat-form-field>
                    <mat-label>Month</mat-label>
                    <mat-select [(ngModel)]="yearly.month"
                                (ngModelChange)="generateCron($event)"
                                [required]="selectedRepeat == 'yearly'" formControlName="month">
                        <mat-option *ngFor="let month of monthList" [value]="month.value">
                            {{month.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="timeTriggerForm.get('month')?.errors?.required">Required</mat-error>
                </mat-form-field>&nbsp;

                on the&nbsp;

                <mat-form-field class="narrow">
                    <mat-label>Day</mat-label>
                    <mat-select [(ngModel)]="yearly.day" (ngModelChange)="generateCron($event)"
                                [required]="selectedRepeat == 'yearly'" formControlName="day">
                        <mat-option *ngFor="let day of dayList" [value]="day.value">
                            {{day.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="timeTriggerForm.get('day')?.errors?.required">Required</mat-error>
                </mat-form-field>&nbsp;

                <loyale-time-picker label="At" [returnUtc]="false" [markAsTouched]="touched"
                                    (onChange)="yearly.time = $event; generateCron()"
                                    [required]="selectedRepeat == 'yearly'">
                </loyale-time-picker>
            </div>
        </div>
    </ng-container>

    <!--    <input type="hidden" [(ngModel)]="trigger.cron" required [ngModelOptions]="{standalone: true}">-->

    <div class="d-flex">
        <div class="flex-fill"></div>
        <button mat-flat-button color="primary" [disabled]="awaitingPost || selectedRepeat == null">Set Trigger</button>
    </div>
</form>
