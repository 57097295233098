<h1 mat-dialog-title>New Tag</h1>
<form #form="ngForm" (ngSubmit)="onSubmit(form)">
    <div class="row">
        <div class="col-12 col-lg-6">
            <mat-form-field class="w-100">
                <mat-label>Name</mat-label>
                <input matInput name="name" #name="ngModel" [(ngModel)]="tag.name" required>
                <mat-error *ngIf="name?.errors?.required">Required</mat-error>
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>Color</mat-label>
                <span class="picker-v2 prefix" matPrefix [style.background]="tag.color"
                      [cpPosition]="'bottom'"
                      [cpPositionOffset]="'50%'" [cpPositionRelativeToArrow]="true"
                      [(colorPicker)]="tag.color"></span>
                <input matInput name="c" #c="ngModel" [(ngModel)]="tag.color" required>
                <mat-icon matSuffix (click)="tag.color = commonService.getRandomColor()"
                          matTooltip="Random color"
                          class="cursor-pointer">palette
                </mat-icon>
                <mat-error *ngIf="c?.errors?.required">Required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions>
        <div class="flex-fill"></div>
        <button mat-flat-button [mat-dialog-close]>Close</button>
        <button mat-flat-button color="primary" [disabled]="awaitingPost">Save</button>
    </div>
</form>

