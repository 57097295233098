/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { PassbookPasses } from './passbookPasses';

export interface CustomerPassbooks { 
    id?: string;
    customer?: Customer;
    customerId?: string;
    schemeId?: string;
    pass?: PassbookPasses;
    passId?: string;
    key?: string;
    additionalData?: string;
    createdDate?: Date;
    updatedDate?: Date;
}