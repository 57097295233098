<h1 mat-dialog-title>New Feedback Form</h1>
<form [formGroup]="feedBackForm" mat-dialog-content>
    <mat-card>
        <mat-card-content>

            <mat-form-field class="w-100 mr-2">
                <input matInput placeholder="Title" [(ngModel)]="formTitle" formControlName="title">
                <mat-error *ngIf="feedBackForm.get('title').hasError('required')">
                    {{'Title is required!'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-100 mr-2">
          <textarea matInput placeholder="Description" [(ngModel)]="formDescription"
                    formControlName="description"></textarea>
                <mat-error *ngIf="feedBackForm.get('description').hasError('required')">
                    {{'Description is required!'|translate}}
                </mat-error>
            </mat-form-field>

        </mat-card-content>
    </mat-card>
    <div class="w-100 my-4" *ngFor="let question of questionList;let i= index">
        <loyale-question-selector class="w-100" [questionInputData]="questions[i]"
                                  (questionFormData)="updateQuestionData(i, $event)"
                                  (deleteQuestion)="deleteQuestionForm(i)"
                                  (duplicateQuestion)="duplicateQuestionForm($event)"></loyale-question-selector>
    </div>
</form>

<div class="d-flex justify-content-between" mat-dialog-actions>

    <button mat-flat-button color="accent" (click)="addQuestion()">
        <mat-icon>add</mat-icon>
        {{'Add Question'|translate}}
    </button>


    <button mat-flat-button color="accent" (click)="createNewFeedback()">
        {{'Create Feedback Form'|translate}}
    </button>

</div>
