import { CommonService } from 'src/app/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/services/session.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GiftCardService } from './../../../swagger/api/giftCard.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GiftCardInsertView } from 'src/app/swagger';

@Component({
  selector: 'loyale-new-giftcard-dialog',
  templateUrl: './new-giftcard-dialog.component.html',
  styleUrls: ['./new-giftcard-dialog.component.scss']
})
export class NewGiftCardDialogComponent implements OnInit {
  @ViewChild('giftCardComponent') giftCardComponent;

  awaitingPost = false;

  constructor(private giftCardService: GiftCardService, public commonService: CommonService, private snackBar: MatSnackBar, public sessionService: SessionService, private translate: TranslateService, private dialogRef: MatDialogRef<NewGiftCardDialogComponent>) { }

  ngOnInit() {

  }

  onSubmit() {

    if (this.giftCardComponent.giftCardForm.valid) {
      this.awaitingPost = true;

      this.giftCardComponent.createNew().subscribe(res => {
        this.snackBar.open(this.translate.instant('Saved'));
        this.dialogRef.close(res);
      }, err => {
        this.awaitingPost = false;
        this.commonService.errorHandler(err);
      });
    }
  }

}
