import { SignoutComponent } from './standalone/signout/signout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewsGuard } from './views/views.guard';
import { AuthGuard } from './auth/auth.guard';
import { LoadComponent } from './standalone/load/load.component';
import { PreJoinComponent } from './standalone/pre-join/pre-join.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [ViewsGuard],
    loadChildren: () => import('./views/views.module').then(m => m.ViewsModule)
  },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'signout',
    component: SignoutComponent,
  },
  {
    path: 'load',
    component: LoadComponent,
  },
  {
    //temp, pre-join superseeded this route
    path: 'join-check',
    component: PreJoinComponent,
  },
  {
    path: 'pre-join',
    component: PreJoinComponent,
  },
  {
    path: '**', redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
