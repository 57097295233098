/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PunchCardActionType } from './punchCardActionType';

export interface PunchCardInsertView { 
    name: string;
    description: string;
    numberOfRewards: number;
    totalNumberOfPunches: number;
    action: PunchCardActionType;
    redeemItem: string;
    spendItem: string;
    validFrom?: Date;
    validUntil?: Date;
    isDynamic?: boolean;
    dynamicDays?: number;
    isAutoRenew?: boolean;
    platform?: string;
    outletIds?: Array<string>;
    externalRef?: string;
}