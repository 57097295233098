import { Stack, useFocusIdx } from 'easy-email-editor';
import { AttributesPanelWrapper, ColorPickerField, TextField } from 'easy-email-extensions';
import React from 'react';

export function Panel() {
  const { focusIdx } = useFocusIdx();
  return (
    <AttributesPanelWrapper>
      <Stack vertical>
        <TextField
          label="Button text"
          name={`${focusIdx}.data.value.buttonText`}
          inline
        />
        <TextField
          label="Button url"
          name={`${focusIdx}.data.value.buttonUrl`}
          inline
        />
        <ColorPickerField
          label="Button color"
          name={`${focusIdx}.attributes.background-color`}
          inline
        />
        <ColorPickerField
          label="Text color"
          name={`${focusIdx}.attributes.text-color`}
          inline
        />
        <ColorPickerField
          label="Background color"
          name={`${focusIdx}.attributes.background-color`}
          inline
        />

      </Stack>
    </AttributesPanelWrapper>
  );

}
