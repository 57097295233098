import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ReportService, ChartService } from 'src/app/swagger';
import { SessionService } from 'src/app/services/session.service';
import * as moment from 'moment';

@Component({
  selector: 'loyale-purchase-outlet',
  templateUrl: './purchase-outlet.component.html',
  styleUrls: ['./purchase-outlet.component.scss']
})
export class PurchaseOutletComponent {
  @Input() dateFrom;
  @Input() dateTo;

  chart = null;
  report: { keys: [], values: [] };
  colorList = [];

  constructor(public commonService: CommonService, private chartService: ChartService, private sessionService: SessionService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.getReport();
  }

  getReport() {
    this.chartService.apiChartOutletPurchaseGet(new Date(this.dateFrom), new Date(this.dateTo), this.sessionService.schemeId).subscribe(res => {
      this.report = res;

      for (let item of this.report.values) {
        this.colorList.push(this.commonService.getRandomColorShade('#f8bf2e'));
      }

      this.chart = {
        labels: this.report.keys,
        datasets: [
          {
            data: this.report.values,
            backgroundColor: this.colorList,
          }
        ],
      }
    }, err => {
      this.commonService.errorHandler(err);
    })
  }
}
