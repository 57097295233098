import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionPolarity'
})
export class TransactionPolarityPipe implements PipeTransform {

  transform(value: any): string {
    if (value == 0 || value == 1 || value == 5) {
      return '+';
    } else if (value == 2 || value == 3 || value == 4 || value == 6) {
      return '-';
    }
  }

}
