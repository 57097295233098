import { AdvancedType, BasicType, components, getPreviewClassName, IBlock, IBlockData, IText, mergeBlock, } from 'easy-email-core';

import { CustomBlocksType } from '../constants';
import React, { CSSProperties } from 'react';

const { Column, Section, Wrapper, Spacer, Text, Button, Image, Group } = components;

export type IImagesText = IBlockData<{

  alt?: string;
  src?: string;
  title?: string;
  href?: string;
  target?: string;
  border?: string;
  height?: string;
  'text-decoration'?: string;
  'text-transform'?: CSSProperties['textTransform'];
  align?: CSSProperties['textAlign'];
  'container-background-color'?: string;
  width?: string;
  padding?: string;

}>;

export const ImageAndText: IBlock = {
  name: 'Article',
  type: CustomBlocksType.IMAGEANDTEXT,
  validParentType: [
    BasicType.PAGE,
    BasicType.WRAPPER,
    BasicType.COLUMN,
    BasicType.SECTION,
    BasicType.TEMPLATE,
    AdvancedType.COLUMN],
  create(
    payload
  ) {
    const defaultData: any = {
      type: CustomBlocksType.IMAGEANDTEXT,
      data: {
        value: {
          text: 'lorem ipsum',
          imageUrl: '',
          padding: '0px 0px 0px 0px',
          align: 'center',
          fontFamily: 'Roboto'
        },
      },
      attributes: {
        'background-color': '',
        'text-color': '#0a0707',
        src: '',
        bgColor: 'transparent',
        buttonText: 'button',
        buttonColor: '',
        buttonTextColor: '',
        buttonUrl: '',
        buttonAlign: 'center',
        buttonPadding: '0px 0px 0px 0px',
        padding: '10px 25px 10px 25px',
      },
      children: [],
    };
    return mergeBlock(defaultData, payload);
  },
  render: ({ data, idx, mode, context, dataSource }) => {
    const { imageUrl, text } = data.data.value;
    const attributes = data.attributes;

    const instance = (

      <Section background-color={data.attributes.bgColor} css-class={mode === 'testing' ? getPreviewClassName(idx, data.type) : ''}>
        <Column>
          <Image padding={data.attributes.padding} width="full" src={data.attributes.src}/>
          <Text container-background-color={data.attributes['background-color']} color={data.attributes['text-color']} font-family={data.data.value.fontFamily} align={data.data.value.align} padding={data.data.value.padding}>{text}</Text>
          <Spacer></Spacer>
          <Button
            color={attributes['buttonTextColor']}
            background-color={attributes['buttonColor']}
            href={attributes['buttonUrl']}
            align={attributes['buttonAlign']}
            font-weight={'400'}
            border-radius={'3px'}
            line-heiht={'100%'}
            font-family={data.data.value.fontFamily}
            font-size={'16px'}
            padding={attributes['buttonPadding']}
          >
            {attributes['buttonText']}
          </Button>
          <Spacer></Spacer>
        </Column>
      </Section>

    );

    return instance;
  },
};

export { Panel } from './Panel';

