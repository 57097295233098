import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ruleOperator'
})
export class RuleOperatorPipe implements PipeTransform {
  operatorsMap = [
    { symbol: '==', name: "Equals" },
    { symbol: '!=', name: "Not Equals" },
    { symbol: '>', name: "Greater than" },
    { symbol: '>=', name: "Greater than or Equals" },
    { symbol: '<', name: "Less than" },
    { symbol: '<=', name: "Less than or Equals" },
    { symbol: 'Contains', name: "Contains" }
  ]

  transform(value: any): string {
    return this.operatorsMap.find(x => x.symbol == value).name;
  }
}
