<!-- <h1 mat-dialog-title>Upload an image</h1> -->

<div mat-dialog-content>
  <mat-stepper [linear]="true" #stepper>
    <mat-step label="Select" [editable]="false" [completed]="stepSelecteComplete">
      <!-- (click)="fileInput.click()" -->
      <div
        class="image-select d-flex flex-column align-items-center justify-content-center cursor-pointer position-relative">

        <ng-container *ngIf="!imageCropped">
          <mat-icon class="icon">add_photo_alternate</mat-icon>
          <span class="mt-2">Click or Drag and Drop an image</span>
        </ng-container>

        <ng-container *ngIf="imageCropped">
          <img [src]="imageCropped">
        </ng-container>

        <input #fileInput type="file" (change)="onFileChange($event)" class="position-absolute w-100 h-100"
          style="opacity: 0;" />
      </div>

      <div class="d-flex">
        <button type="button" mat-button [mat-dialog-close]>
          {{'Cancel' | translate}}</button>
        <div class="flex-fill"></div>
        <button type="button" mat-button color="primary" (click)="onSelect()" [disabled]="!imageSource">
          {{'Next' | translate}}</button>
      </div>
    </mat-step>

    <mat-step label="Crop" [editable]="false" [completed]="stepCropComplete">
      <div class="image-crop">
        <image-cropper [imageChangedEvent]="imageSource" [maintainAspectRatio]="maintainAspectRatio"
          [aspectRatio]="aspectRatio" [containWithinAspectRatio]="containWithinAspectRatio"
          [resizeToWidth]="resizeToWidth" [format]="format" (imageCropped)="onImageCropped($event)">
        </image-cropper>
      </div>

      <div *ngIf="data.debug">
        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>Image Width</mat-label>
          <input matInput type="number" name="width" #width="ngModel" [(ngModel)]="resizeToWidth">
        </mat-form-field>

        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>Aspect Ratio</mat-label>
          <mat-select name="ratio" #ratio="ngModel" [(ngModel)]="aspectRatio">
            <mat-option [value]="1 / 1">1:1</mat-option>
            <mat-option [value]="4 / 3">4:3</mat-option>
            <mat-option [value]="16 / 9">16:9</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>Mantain Aspect Ratio</mat-label>
          <mat-select name="ratio" #ratio="ngModel" [(ngModel)]="maintainAspectRatio">
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="d-flex">
        <button type="button" mat-button [mat-dialog-close]>{{'Cancel' | translate}}</button>
        <div class="flex-fill"></div>
        <button type="button" mat-button color="primary" (click)="onCrop()" [disabled]="!imageCropped">
          {{'Next' | translate}}</button>
      </div>
    </mat-step>

    <mat-step label="Save" [editable]="false" [completed]="stepSaveComplete">
      <div class="image-save d-flex align-items-center justify-content-center">
        <img [src]="imageCropped" />
      </div>

      <div class="d-flex">
        <button type="button" mat-button [mat-dialog-close]>{{'Cancel' | translate}}</button>
        <div class="flex-fill"></div>
        <button type="button" mat-button color="primary" (click)="onSave()" [disabled]="awaitingPost">
          {{'Save' | translate}}</button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
