import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperDialogComponent } from '../dialogs/image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'loyale-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() label = "Image";
  @Input() required = false;
  @Input() tooltip = null;
  @Input() inputUrl = null;
  @Input() width = 500;
  @Input() ratio = 1 / 1;
  @Output() outputUrl = new EventEmitter<any>();

  imageUrl = null;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.inputUrl) {
      this.imageUrl = this.inputUrl;
    } else {
      this.imageUrl = "../../../assets/placeholder.svg";
    }
  }

  onImageCropper() {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, { data: { width: this.width, ratio: this.ratio }, width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.imageUrl = res;
        this.outputUrl.emit(res);
      }
    });
  }
}
