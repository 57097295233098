/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BalanceTransactionResultView } from '../model/balanceTransactionResultView';
import { ExportResultView } from '../model/exportResultView';
import { PurchaseProductInsertView } from '../model/purchaseProductInsertView';
import { PurchaseProductPutView } from '../model/purchaseProductPutView';
import { PurchaseProductResultView } from '../model/purchaseProductResultView';
import { RedirectUrlResultView } from '../model/redirectUrlResultView';
import { SchemeStripeLinkResultView } from '../model/schemeStripeLinkResultView';
import { StripeCancelInsertView } from '../model/stripeCancelInsertView';
import { StripeCustomerPaymentMethodResultView } from '../model/stripeCustomerPaymentMethodResultView';
import { StripeOnboardingInsertView } from '../model/stripeOnboardingInsertView';
import { StripePaymentAndSetupIntentView } from '../model/stripePaymentAndSetupIntentView';
import { StripePaymentResultView } from '../model/stripePaymentResultView';
import { StripePaymentResultViewById } from '../model/stripePaymentResultViewById';
import { StripePayout } from '../model/stripePayout';
import { StripePurchaseView } from '../model/stripePurchaseView';
import { StripeRefund } from '../model/stripeRefund';
import { StripeRefundInsertView } from '../model/stripeRefundInsertView';
import { StripeRefundResultView } from '../model/stripeRefundResultView';
import { StripeWebPurchaseView } from '../model/stripeWebPurchaseView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PaymentsService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Returns the linked account from Stripe
     * 
     * @param accId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsAccountAccIdGet(accId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsAccountAccIdGet(accId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsAccountAccIdGet(accId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsAccountAccIdGet(accId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accId === null || accId === undefined) {
            throw new Error('Required parameter accId was null or undefined when calling apiPaymentsAccountAccIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Payments/Account/${encodeURIComponent(String(accId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Used for mobile apps to add a PaymentMethod to a Stripe customer
     * 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsAddPaymentMethodPost(xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<StripePaymentAndSetupIntentView>;
    public apiPaymentsAddPaymentMethodPost(xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripePaymentAndSetupIntentView>>;
    public apiPaymentsAddPaymentMethodPost(xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripePaymentAndSetupIntentView>>;
    public apiPaymentsAddPaymentMethodPost(xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StripePaymentAndSetupIntentView>('post',`${this.basePath}/api/Payments/AddPaymentMethod`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a refund the payment with the given Id
     * 
     * @param paymentId 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsCancelPaymentPaymentIdPost(paymentId: string, body?: StripeCancelInsertView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<StripeRefund>;
    public apiPaymentsCancelPaymentPaymentIdPost(paymentId: string, body?: StripeCancelInsertView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripeRefund>>;
    public apiPaymentsCancelPaymentPaymentIdPost(paymentId: string, body?: StripeCancelInsertView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripeRefund>>;
    public apiPaymentsCancelPaymentPaymentIdPost(paymentId: string, body?: StripeCancelInsertView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentId === null || paymentId === undefined) {
            throw new Error('Required parameter paymentId was null or undefined when calling apiPaymentsCancelPaymentPaymentIdPost.');
        }



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StripeRefund>('post',`${this.basePath}/api/Payments/CancelPayment/${encodeURIComponent(String(paymentId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns the editable data for the linked Stripe account
     * 
     * @param accId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsConnectAccIdGet(accId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsConnectAccIdGet(accId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsConnectAccIdGet(accId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsConnectAccIdGet(accId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accId === null || accId === undefined) {
            throw new Error('Required parameter accId was null or undefined when calling apiPaymentsConnectAccIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Payments/Connect/${encodeURIComponent(String(accId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the account on Stripe and the link to Loyale
     * 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsConnectDelete(xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsConnectDelete(xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsConnectDelete(xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsConnectDelete(xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Payments/Connect`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Links an already existing account on stripe to the Loyale platform
     * 
     * @param accountId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsConnectOnboardingLinkAccountIdPost(accountId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<RedirectUrlResultView>;
    public apiPaymentsConnectOnboardingLinkAccountIdPost(accountId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RedirectUrlResultView>>;
    public apiPaymentsConnectOnboardingLinkAccountIdPost(accountId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RedirectUrlResultView>>;
    public apiPaymentsConnectOnboardingLinkAccountIdPost(accountId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling apiPaymentsConnectOnboardingLinkAccountIdPost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RedirectUrlResultView>('post',`${this.basePath}/api/Payments/ConnectOnboardingLink/${encodeURIComponent(String(accountId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates an account on stripe and links it to the Loyale platform
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsConnectPost(body?: StripeOnboardingInsertView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<RedirectUrlResultView>;
    public apiPaymentsConnectPost(body?: StripeOnboardingInsertView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RedirectUrlResultView>>;
    public apiPaymentsConnectPost(body?: StripeOnboardingInsertView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RedirectUrlResultView>>;
    public apiPaymentsConnectPost(body?: StripeOnboardingInsertView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RedirectUrlResultView>('post',`${this.basePath}/api/Payments/Connect`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the account using stripe&#x27;s onboarding system
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsConnectPut(body?: StripeOnboardingInsertView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsConnectPut(body?: StripeOnboardingInsertView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsConnectPut(body?: StripeOnboardingInsertView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsConnectPut(body?: StripeOnboardingInsertView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Payments/Connect`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Links an already existing account on stripe to the Loyale platform
     * 
     * @param accountId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsConnectRequirementsLinkAccountIdPost(accountId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<RedirectUrlResultView>;
    public apiPaymentsConnectRequirementsLinkAccountIdPost(accountId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RedirectUrlResultView>>;
    public apiPaymentsConnectRequirementsLinkAccountIdPost(accountId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RedirectUrlResultView>>;
    public apiPaymentsConnectRequirementsLinkAccountIdPost(accountId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling apiPaymentsConnectRequirementsLinkAccountIdPost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RedirectUrlResultView>('post',`${this.basePath}/api/Payments/ConnectRequirementsLink/${encodeURIComponent(String(accountId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a connection token to be used to connect a POS to Stripe
     * 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsConnectionTokenGet(xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsConnectionTokenGet(xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsConnectionTokenGet(xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsConnectionTokenGet(xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPaymentsConnectionTokenGet.');
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Payments/ConnectionToken`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates a PDF for the given linked GiftCard
     * 
     * @param giftCLId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsGeneratePdfGiftCLIdGet(giftCLId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiPaymentsGeneratePdfGiftCLIdGet(giftCLId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiPaymentsGeneratePdfGiftCLIdGet(giftCLId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiPaymentsGeneratePdfGiftCLIdGet(giftCLId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftCLId === null || giftCLId === undefined) {
            throw new Error('Required parameter giftCLId was null or undefined when calling apiPaymentsGeneratePdfGiftCLIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/Payments/GeneratePdf/${encodeURIComponent(String(giftCLId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of PaymentMethods from a Stripe customer
     * 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsListCustomerPaymentMethodsGet(xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<StripeCustomerPaymentMethodResultView>;
    public apiPaymentsListCustomerPaymentMethodsGet(xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripeCustomerPaymentMethodResultView>>;
    public apiPaymentsListCustomerPaymentMethodsGet(xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripeCustomerPaymentMethodResultView>>;
    public apiPaymentsListCustomerPaymentMethodsGet(xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StripeCustomerPaymentMethodResultView>('get',`${this.basePath}/api/Payments/ListCustomerPaymentMethods`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export the payments for the given account and schemeId to the given emails
     * 
     * @param body 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPaymentsExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<ExportResultView>;
    public apiPaymentsPaymentsExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExportResultView>>;
    public apiPaymentsPaymentsExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExportResultView>>;
    public apiPaymentsPaymentsExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ExportResultView>('post',`${this.basePath}/api/Payments/Payments/Export`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the payments for the given account and schemeId
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPaymentsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<StripePaymentResultView>>;
    public apiPaymentsPaymentsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StripePaymentResultView>>>;
    public apiPaymentsPaymentsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StripePaymentResultView>>>;
    public apiPaymentsPaymentsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StripePaymentResultView>>('get',`${this.basePath}/api/Payments/Payments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the receipt url of the payment/refund
     * 
     * @param paymentIntentId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPaymentsGetReceiptPaymentIntentIdGet(paymentIntentId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsPaymentsGetReceiptPaymentIntentIdGet(paymentIntentId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsPaymentsGetReceiptPaymentIntentIdGet(paymentIntentId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsPaymentsGetReceiptPaymentIntentIdGet(paymentIntentId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentIntentId === null || paymentIntentId === undefined) {
            throw new Error('Required parameter paymentIntentId was null or undefined when calling apiPaymentsPaymentsGetReceiptPaymentIntentIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Payments/Payments/GetReceipt/${encodeURIComponent(String(paymentIntentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the payment with the given Id and schemeId
     * 
     * @param paymentId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPaymentsPaymentIdGet(paymentId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<StripePaymentResultViewById>;
    public apiPaymentsPaymentsPaymentIdGet(paymentId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripePaymentResultViewById>>;
    public apiPaymentsPaymentsPaymentIdGet(paymentId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripePaymentResultViewById>>;
    public apiPaymentsPaymentsPaymentIdGet(paymentId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentId === null || paymentId === undefined) {
            throw new Error('Required parameter paymentId was null or undefined when calling apiPaymentsPaymentsPaymentIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StripePaymentResultViewById>('get',`${this.basePath}/api/Payments/Payments/${encodeURIComponent(String(paymentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the payout with the given Id
     * 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPayoutsEstimatesGet(xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<StripePayout>>;
    public apiPaymentsPayoutsEstimatesGet(xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StripePayout>>>;
    public apiPaymentsPayoutsEstimatesGet(xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StripePayout>>>;
    public apiPaymentsPayoutsEstimatesGet(xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StripePayout>>('get',`${this.basePath}/api/Payments/Payouts/Estimates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export the payouts for the given account and schemeId to the given emails
     * 
     * @param body 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPayoutsExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<ExportResultView>;
    public apiPaymentsPayoutsExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExportResultView>>;
    public apiPaymentsPayoutsExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExportResultView>>;
    public apiPaymentsPayoutsExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ExportResultView>('post',`${this.basePath}/api/Payments/Payouts/Export`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the payouts for the account linked to the given schemeId
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPayoutsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<StripePayout>>;
    public apiPaymentsPayoutsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StripePayout>>>;
    public apiPaymentsPayoutsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StripePayout>>>;
    public apiPaymentsPayoutsGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StripePayout>>('get',`${this.basePath}/api/Payments/Payouts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Exports the transactions which created the payout with the given Id (id is the id of the payout in stripe)
     * 
     * @param stripePayoutId 
     * @param body 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPayoutsHistoryExportStripePayoutIdPost(stripePayoutId: string, body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ExportResultView>>;
    public apiPaymentsPayoutsHistoryExportStripePayoutIdPost(stripePayoutId: string, body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ExportResultView>>>;
    public apiPaymentsPayoutsHistoryExportStripePayoutIdPost(stripePayoutId: string, body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ExportResultView>>>;
    public apiPaymentsPayoutsHistoryExportStripePayoutIdPost(stripePayoutId: string, body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stripePayoutId === null || stripePayoutId === undefined) {
            throw new Error('Required parameter stripePayoutId was null or undefined when calling apiPaymentsPayoutsHistoryExportStripePayoutIdPost.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ExportResultView>>('post',`${this.basePath}/api/Payments/PayoutsHistory/Export/${encodeURIComponent(String(stripePayoutId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the transactions which created the payout with the given Id (id is the id of the payout in stripe)
     * 
     * @param stripePayoutId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPayoutsHistoryStripePayoutIdGet(stripePayoutId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BalanceTransactionResultView>>;
    public apiPaymentsPayoutsHistoryStripePayoutIdGet(stripePayoutId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BalanceTransactionResultView>>>;
    public apiPaymentsPayoutsHistoryStripePayoutIdGet(stripePayoutId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BalanceTransactionResultView>>>;
    public apiPaymentsPayoutsHistoryStripePayoutIdGet(stripePayoutId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (stripePayoutId === null || stripePayoutId === undefined) {
            throw new Error('Required parameter stripePayoutId was null or undefined when calling apiPaymentsPayoutsHistoryStripePayoutIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BalanceTransactionResultView>>('get',`${this.basePath}/api/Payments/PayoutsHistory/${encodeURIComponent(String(stripePayoutId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the payout with the given Id
     * 
     * @param payoutId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPayoutsPayoutIdGet(payoutId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<StripePayout>;
    public apiPaymentsPayoutsPayoutIdGet(payoutId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripePayout>>;
    public apiPaymentsPayoutsPayoutIdGet(payoutId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripePayout>>;
    public apiPaymentsPayoutsPayoutIdGet(payoutId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (payoutId === null || payoutId === undefined) {
            throw new Error('Required parameter payoutId was null or undefined when calling apiPaymentsPayoutsPayoutIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StripePayout>('get',`${this.basePath}/api/Payments/Payouts/${encodeURIComponent(String(payoutId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of all of the products
     * 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsProductGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PurchaseProductResultView>>;
    public apiPaymentsProductGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PurchaseProductResultView>>>;
    public apiPaymentsProductGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PurchaseProductResultView>>>;
    public apiPaymentsProductGet(xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPaymentsProductGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PurchaseProductResultView>>('get',`${this.basePath}/api/Payments/Product`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a product that can be purchased by customers
     * 
     * @param xScheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsProductPost(xScheme: string, body?: PurchaseProductInsertView, observe?: 'body', reportProgress?: boolean): Observable<PurchaseProductResultView>;
    public apiPaymentsProductPost(xScheme: string, body?: PurchaseProductInsertView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PurchaseProductResultView>>;
    public apiPaymentsProductPost(xScheme: string, body?: PurchaseProductInsertView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PurchaseProductResultView>>;
    public apiPaymentsProductPost(xScheme: string, body?: PurchaseProductInsertView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPaymentsProductPost.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PurchaseProductResultView>('post',`${this.basePath}/api/Payments/Product`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a product by Id
     * 
     * @param productId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsProductProductIdDelete(productId: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsProductProductIdDelete(productId: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsProductProductIdDelete(productId: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsProductProductIdDelete(productId: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling apiPaymentsProductProductIdDelete.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPaymentsProductProductIdDelete.');
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Payments/Product/${encodeURIComponent(String(productId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a product by Id
     * 
     * @param productId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsProductProductIdGet(productId: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<PurchaseProductResultView>;
    public apiPaymentsProductProductIdGet(productId: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PurchaseProductResultView>>;
    public apiPaymentsProductProductIdGet(productId: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PurchaseProductResultView>>;
    public apiPaymentsProductProductIdGet(productId: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling apiPaymentsProductProductIdGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPaymentsProductProductIdGet.');
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PurchaseProductResultView>('get',`${this.basePath}/api/Payments/Product/${encodeURIComponent(String(productId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the product whose id was passed in the route
     * 
     * @param xScheme 
     * @param productId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsProductProductIdPut(xScheme: string, productId: string, body?: PurchaseProductPutView, observe?: 'body', reportProgress?: boolean): Observable<PurchaseProductResultView>;
    public apiPaymentsProductProductIdPut(xScheme: string, productId: string, body?: PurchaseProductPutView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PurchaseProductResultView>>;
    public apiPaymentsProductProductIdPut(xScheme: string, productId: string, body?: PurchaseProductPutView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PurchaseProductResultView>>;
    public apiPaymentsProductProductIdPut(xScheme: string, productId: string, body?: PurchaseProductPutView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPaymentsProductProductIdPut.');
        }

        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling apiPaymentsProductProductIdPut.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PurchaseProductResultView>('put',`${this.basePath}/api/Payments/Product/${encodeURIComponent(String(productId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Used for mobile purchases
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsPurchasePost(body?: StripePurchaseView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<StripePaymentAndSetupIntentView>;
    public apiPaymentsPurchasePost(body?: StripePurchaseView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripePaymentAndSetupIntentView>>;
    public apiPaymentsPurchasePost(body?: StripePurchaseView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripePaymentAndSetupIntentView>>;
    public apiPaymentsPurchasePost(body?: StripePurchaseView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StripePaymentAndSetupIntentView>('post',`${this.basePath}/api/Payments/Purchase`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a refund the payment with the given Id
     * 
     * @param paymentId 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsRefundPaymentPaymentIdPost(paymentId: string, body?: StripeRefundInsertView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<StripeRefundResultView>;
    public apiPaymentsRefundPaymentPaymentIdPost(paymentId: string, body?: StripeRefundInsertView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripeRefundResultView>>;
    public apiPaymentsRefundPaymentPaymentIdPost(paymentId: string, body?: StripeRefundInsertView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripeRefundResultView>>;
    public apiPaymentsRefundPaymentPaymentIdPost(paymentId: string, body?: StripeRefundInsertView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentId === null || paymentId === undefined) {
            throw new Error('Required parameter paymentId was null or undefined when calling apiPaymentsRefundPaymentPaymentIdPost.');
        }



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StripeRefundResultView>('post',`${this.basePath}/api/Payments/RefundPayment/${encodeURIComponent(String(paymentId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the refunds linked with the given PaymentId
     * 
     * @param paymentId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsRefundsByPaymentIdPaymentIdGet(paymentId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<StripeRefundResultView>>;
    public apiPaymentsRefundsByPaymentIdPaymentIdGet(paymentId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StripeRefundResultView>>>;
    public apiPaymentsRefundsByPaymentIdPaymentIdGet(paymentId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StripeRefundResultView>>>;
    public apiPaymentsRefundsByPaymentIdPaymentIdGet(paymentId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentId === null || paymentId === undefined) {
            throw new Error('Required parameter paymentId was null or undefined when calling apiPaymentsRefundsByPaymentIdPaymentIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StripeRefundResultView>>('get',`${this.basePath}/api/Payments/Refunds/ByPaymentId/${encodeURIComponent(String(paymentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the refund with the given Id
     * 
     * @param refundId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsRefundsRefundIdGet(refundId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<StripeRefundResultView>;
    public apiPaymentsRefundsRefundIdGet(refundId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripeRefundResultView>>;
    public apiPaymentsRefundsRefundIdGet(refundId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripeRefundResultView>>;
    public apiPaymentsRefundsRefundIdGet(refundId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (refundId === null || refundId === undefined) {
            throw new Error('Required parameter refundId was null or undefined when calling apiPaymentsRefundsRefundIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StripeRefundResultView>('get',`${this.basePath}/api/Payments/Refunds/${encodeURIComponent(String(refundId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Schedules an email to send the linked GiftCard with the passed id
     * 
     * @param giftCLId 
     * @param recipientEmail 
     * @param enqueueTime 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsScheduleEmailGiftCLIdPost(giftCLId: string, recipientEmail?: string, enqueueTime?: Date, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsScheduleEmailGiftCLIdPost(giftCLId: string, recipientEmail?: string, enqueueTime?: Date, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsScheduleEmailGiftCLIdPost(giftCLId: string, recipientEmail?: string, enqueueTime?: Date, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsScheduleEmailGiftCLIdPost(giftCLId: string, recipientEmail?: string, enqueueTime?: Date, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (giftCLId === null || giftCLId === undefined) {
            throw new Error('Required parameter giftCLId was null or undefined when calling apiPaymentsScheduleEmailGiftCLIdPost.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recipientEmail !== undefined && recipientEmail !== null) {
            queryParameters = queryParameters.set('recipientEmail', <any>recipientEmail);
        }
        if (enqueueTime !== undefined && enqueueTime !== null) {
            queryParameters = queryParameters.set('enqueueTime', <any>enqueueTime.toISOString());
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Payments/ScheduleEmail/${encodeURIComponent(String(giftCLId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the SchemeStripeLink associated with the given schemeId
     * 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsSchemeStripeLinkGet(xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<SchemeStripeLinkResultView>;
    public apiPaymentsSchemeStripeLinkGet(xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SchemeStripeLinkResultView>>;
    public apiPaymentsSchemeStripeLinkGet(xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SchemeStripeLinkResultView>>;
    public apiPaymentsSchemeStripeLinkGet(xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SchemeStripeLinkResultView>('get',`${this.basePath}/api/Payments/SchemeStripeLink`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Used to unlink a PaymentMethod from a Stripe customer
     * 
     * @param paymentMethodId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsUnlinkPaymentMethodPaymentMethodIdDelete(paymentMethodId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsUnlinkPaymentMethodPaymentMethodIdDelete(paymentMethodId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsUnlinkPaymentMethodPaymentMethodIdDelete(paymentMethodId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsUnlinkPaymentMethodPaymentMethodIdDelete(paymentMethodId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentMethodId === null || paymentMethodId === undefined) {
            throw new Error('Required parameter paymentMethodId was null or undefined when calling apiPaymentsUnlinkPaymentMethodPaymentMethodIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Payments/UnlinkPaymentMethod/${encodeURIComponent(String(paymentMethodId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Used for mobile apps to add a PaymentMethod to a Stripe customer
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsWalletAddPaymentMethodPost(observe?: 'body', reportProgress?: boolean): Observable<StripePaymentAndSetupIntentView>;
    public apiPaymentsWalletAddPaymentMethodPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripePaymentAndSetupIntentView>>;
    public apiPaymentsWalletAddPaymentMethodPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripePaymentAndSetupIntentView>>;
    public apiPaymentsWalletAddPaymentMethodPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StripePaymentAndSetupIntentView>('post',`${this.basePath}/api/Payments/Wallet/AddPaymentMethod`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves a list of PaymentMethods from a Stripe customer
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsWalletListCustomerPaymentMethodsGet(observe?: 'body', reportProgress?: boolean): Observable<StripeCustomerPaymentMethodResultView>;
    public apiPaymentsWalletListCustomerPaymentMethodsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StripeCustomerPaymentMethodResultView>>;
    public apiPaymentsWalletListCustomerPaymentMethodsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StripeCustomerPaymentMethodResultView>>;
    public apiPaymentsWalletListCustomerPaymentMethodsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StripeCustomerPaymentMethodResultView>('get',`${this.basePath}/api/Payments/Wallet/ListCustomerPaymentMethods`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Used to unlink a PaymentMethod from a Stripe customer
     * 
     * @param paymentMethodId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsWalletUnlinkPaymentMethodPaymentMethodIdDelete(paymentMethodId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsWalletUnlinkPaymentMethodPaymentMethodIdDelete(paymentMethodId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsWalletUnlinkPaymentMethodPaymentMethodIdDelete(paymentMethodId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsWalletUnlinkPaymentMethodPaymentMethodIdDelete(paymentMethodId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (paymentMethodId === null || paymentMethodId === undefined) {
            throw new Error('Required parameter paymentMethodId was null or undefined when calling apiPaymentsWalletUnlinkPaymentMethodPaymentMethodIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Payments/Wallet/UnlinkPaymentMethod/${encodeURIComponent(String(paymentMethodId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Used for web purchases
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsWebPurchasePost(body?: StripeWebPurchaseView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<RedirectUrlResultView>;
    public apiPaymentsWebPurchasePost(body?: StripeWebPurchaseView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RedirectUrlResultView>>;
    public apiPaymentsWebPurchasePost(body?: StripeWebPurchaseView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RedirectUrlResultView>>;
    public apiPaymentsWebPurchasePost(body?: StripeWebPurchaseView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RedirectUrlResultView>('post',`${this.basePath}/api/Payments/WebPurchase`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPaymentsWebhookPost(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPaymentsWebhookPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPaymentsWebhookPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPaymentsWebhookPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Payments/Webhook`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
