<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon color="accent" class="mr-2">emergency</mat-icon>
            <span>Points Multiplier</span>
        </mat-card-title>

        <mat-card-subtitle>
            Select an existing points multiplier or create a new one
        </mat-card-subtitle>


        <button mat-icon-button class="top-right-action" (click)="removeMultiplier.emit()" matTooltip="Remove">
            <mat-icon>cancel</mat-icon>
        </button>

    </mat-card-header>

    <mat-card-content>
        <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

        <div class="row align-items-center" *ngIf="!awaitingGet">
            <div class="col-12 col-lg-7">
                <mat-form-field class="w-100">
                    <mat-label>Points Multipliers</mat-label>
                    <mat-select name="multiplier" #multiplier="ngModel" [(ngModel)]="selectedMultiplierId"
                                (ngModelChange)="onMultiplierChange()" [disabled]="multipliers.length == 0" required>
                        <mat-option [value]="multiplier.id" *ngFor="let multiplier of multipliers">
                            x {{multiplier.multiplier}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="multiplier?.errors?.required">Required</mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 col-lg-1 text-center">
                <h5 class="mb-4">Or</h5>
            </div>

            <div class="col-12 col-lg-4 text-center text-lg-left">
                <button mat-flat-button color="primary" class="mb-4" (click)="onNewPointsMultiplier()">Create a new
                    points
                    multiplier
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>



