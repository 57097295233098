/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ShopifyAddress } from './shopifyAddress';

export interface ShopifyCustomer { 
    id?: number;
    email?: string;
    acceptsMarketing?: boolean;
    createdAt?: any;
    updatedAt?: any;
    firstName?: string;
    lastName?: string;
    ordersCount?: number;
    state?: string;
    totalSpent?: string;
    lastOrderId?: any;
    note?: any;
    verifiedEmail?: boolean;
    multipassIdentifier?: any;
    taxExempt?: boolean;
    phone?: any;
    tags?: string;
    lastOrderName?: any;
    currency?: string;
    acceptsMarketingUpdatedAt?: any;
    marketingOptInLevel?: any;
    adminGraphqlApiId?: string;
    defaultAddress?: ShopifyAddress;
}