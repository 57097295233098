<div class="option super-item" matTooltip="Debug" matTooltipPosition="right" matTooltipClass="tooltip-custom"
  [matMenuTriggerFor]="debug">
  <mat-icon class="icon-resize">bug_report</mat-icon>
</div>
<mat-menu #debug="matMenu">
  <button mat-menu-item ngxClipboard [cbContent]="sessionService.agentId"
    (click)="commonService.copyHandler('Agent ID')">
    <mat-icon>face</mat-icon>
    Admin ID
  </button>
  <a mat-menu-item [routerLink]="['/categories']">
    <mat-icon>category</mat-icon>
    Categories
  </a>
  <a mat-menu-item [routerLink]="['/translate']">
    <mat-icon>translate</mat-icon>
    Translate
  </a>
  <button mat-menu-item (click)="openImageUploadDialog()">
    <mat-icon>add_photo_alternate</mat-icon>
    Upload Image
  </button>
  <button mat-menu-item ngxClipboard [cbContent]="sessionService.schemeId"
    (click)="commonService.copyHandler('Scheme ID')">
    <mat-icon>fingerprint</mat-icon>
    Scheme ID
  </button>
  <button mat-menu-item ngxClipboard [cbContent]="'Bearer '+sessionService.token"
    (click)="commonService.copyHandler('Auth token')">
    <mat-icon>lock</mat-icon>
    Auth token
  </button>
  <button mat-menu-item ngxClipboard [matMenuTriggerFor]="simRole">
    <mat-icon>admin_panel_settings</mat-icon>
    Simulate role
  </button>
</mat-menu>

<mat-menu #simRole="matMenu">
  <button mat-menu-item (click)="this.sessionService.agentRole = 'Owner'">Owner</button>
  <ng-container *ngFor="let role of roles">
    <button mat-menu-item (click)="onSimulateRole(role.name)">{{role.name}}</button>
  </ng-container>
</mat-menu>
