/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LSPaymentType } from './lSPaymentType';

export interface SalePayment { 
    salePaymentID?: string;
    amount?: string;
    createTime?: string;
    saleID?: string;
    paymentTypeID?: string;
    creditAccountId?: string;
    remoteReference?: string;
    paymentType?: LSPaymentType;
}