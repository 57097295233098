import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainActionInterface } from '../main-action.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActionConnectorInterface } from '../../stepper/actionConnector.interface';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActionType } from '../../stepper/action.enum';
import { EmailInsertView, EmailResultView, EmailService, PushNotificationInsertView, PushNotificationResultView } from '../../../swagger';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../services/common.service';
import { SessionService } from '../../../services/session.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'loyale-main-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit, MainActionInterface {
  awaitingPost = false;
  awaitingGet = false;

  email: EmailInsertView | EmailResultView = {};
  jsonTemplatePresent = false;

  emailForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    subject: new UntypedFormControl('', [Validators.required]),
    message: new UntypedFormControl('', []),
    json: new UntypedFormControl('', []),
    html: new UntypedFormControl('', []),
    isMarketingMaterial: new UntypedFormControl(false, [])
  });

  emailCopy: any = null;

  @Input() emailId: string = null;

  public readonly dataUpdate: BehaviorSubject<ActionConnectorInterface> = new BehaviorSubject<ActionConnectorInterface>({
    actionType: ActionType.EMAIL,
    actionData: null,
    formValid: null
  });

  @Output() emailData: EventEmitter<any> = new EventEmitter(this.emailForm.value);
  @Output() entityChanges: EventEmitter<boolean> = new EventEmitter(false);

  constructor(private translate: TranslateService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private router: Router,
              private emailService: EmailService,
              public commonService: CommonService,
              public sessionService: SessionService) { }

  ngOnInit() {
    if (this.emailId) {
      this.getEmail();
      this.jsonTemplatePresent = true;
    }

    this.emailForm.valueChanges.subscribe(changes => {
      this.dataUpdate.next(
        {
          actionType: ActionType.EMAIL,
          actionData: this.emailForm.value,
          formValid: this.emailForm.valid
        }
      );

      if (this.emailForm.touched) {
        this.emailData.next(changes);
      }

      this.email = { ...this.email, ...this.emailForm.value };
      this.entityChanges.next(JSON.stringify(this.emailCopy) !== JSON.stringify(this.email));

    });
  }

  getEmail() {
    this.awaitingGet = true;
    this.emailService.apiEmailIdGet(this.emailId).subscribe(res => {
      this.awaitingGet = false;

      this.email = res;

      this.emailCopy = res;
      this.emailForm.patchValue({ ...res });

    }, err => {
      this.awaitingGet = false;
      this.commonService.errorHandler(err);
    });
  }

  updateHTML(emailTemplate: any) {
    this.emailForm.patchValue({ html: emailTemplate.html, json: emailTemplate.json });
  }

  public createNew(): Observable<any> {
    if (this.emailForm.valid) {
      this.awaitingPost = true;
      return this.emailService.apiEmailPost(this.emailForm.value as EmailInsertView, this.sessionService.schemeId).pipe(tap(res => {
        this.awaitingPost = false;

        this.dataUpdate.next(
          {
            actionType: ActionType.EMAIL,
            actionData: res,
            formValid: this.emailForm.valid
          }
        );
      }));
    } else {
      return null;
    }

  }

  public update(): Observable<any> {
    if (this.emailForm.valid) {
      this.awaitingPost = true;

      return this.emailService.apiEmailPut(this.email, this.sessionService.schemeId).pipe(
        tap(res => {
          this.awaitingPost = false;

          this.dataUpdate.next(
            {
              actionType: ActionType.EMAIL,
              actionData: res,
              formValid: this.emailForm.valid
            }
          );

          this.email = res;
          this.emailCopy = res;

          this.emailForm.patchValue({ ...res });

        })
      );
    } else {
      return null;
    }
  }

  public getData(): ActionConnectorInterface {
    this.emailForm.markAllAsTouched();

    return {
      actionType: ActionType.EMAIL,
      actionData: this.emailForm.value as EmailInsertView,
      formValid: this.emailForm.valid
    };
  }
}
