<mat-vertical-stepper [linear]="isLinear" #stepper [selectedIndex]="stepperSelectedIndex"
                      (selectionChange)="selectionChange($event)">
    <mat-step [label]="firstStepTitle" [stepControl]="firstFormGroup">
        <ng-content></ng-content>
        <div class="d-flex flex-row button-gap" tourAnchor="stepperNextStepAnchor">
            <button mat-flat-button color="accent" (click)="nextStep()">{{nextStepButtonTitle}}
            </button>
        </div>
    </mat-step>

    <mat-step [label]="secondStepTitle" [completed]="stepSecondStatus!==''">
        <ng-container *ngIf="choiceButtonEnable">
            <div class="row">
                <loyale-action-card [selectedFlag]="stepSecondStatus ==='scheduling'"
                                    class="col-12 col-lg-4 h-auto mb-3"
                                    [icon]="'calendar_today'"
                                    [title]="'Schedule'"
                                    [description]="'Pick an event or time to send.'"
                                    (click)="stepSecondStatus = 'scheduling'">
                </loyale-action-card>

                <loyale-action-card [selectedFlag]="stepSecondStatus ==='send_now'" class="col-12 col-lg-4 h-auto mb-3"
                                    [icon]="'call_made'"
                                    [title]="'Send now'"
                                    [description]="'Deliver instantly.'"
                                    (click)="stepSecondStatus = 'send_now'">
                </loyale-action-card>

                <loyale-action-card [selectedFlag]="stepSecondStatus ==='save'" class="col-12 col-lg-4 h-auto mb-3"
                                    [icon]="'save'"
                                    [title]="'Save'"
                                    [description]="'Keep you changes for later.'"
                                    (click)="stepSecondStatus = 'save'">
                </loyale-action-card>
            </div>
        </ng-container>

        <ng-container *ngIf="stepSecondStatus==='send_now' && choiceButtonEnable">
            <loyale-send-now [actionType]="actionType" [actionDataObject]="actionDataObject" #sendNow
                             (scheduledTaskEvent)="sendScheduleTaskPost($event)"></loyale-send-now>
            <button mat-flat-button color="accent" [disabled]="awaitingPost" (click)="send()">Send</button>
        </ng-container>

        <ng-container *ngIf="stepSecondStatus==='scheduling' && choiceButtonEnable">
            <loyale-schedule [actionType]="actionType" [actionDataObject]="actionDataObject"
                             (scheduledTaskEvent)="sendScheduleTaskPost($event)"
                             #schedule></loyale-schedule>
            <button mat-flat-button color="accent" [disabled]="awaitingPost" (click)="scheduleAction()">Schedule
            </button>
        </ng-container>

        <ng-container *ngIf="stepSecondStatus==='save' && choiceButtonEnable">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Save Draft
                    </mat-card-title>
                    <mat-card-subtitle>
                        Your action will be saved in its respective section. You can choose to schedule it at a later
                        stage.
                    </mat-card-subtitle>
                </mat-card-header>
            </mat-card>
            <button mat-flat-button color="accent" (click)="save()" [disabled]="awaitingPost">Save</button>
        </ng-container>
    </mat-step>
</mat-vertical-stepper>
