/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerResultView } from './customerResultView';
import { MessageStatus } from './messageStatus';

export interface MessageResultView { 
    id?: string;
    customerId?: string;
    customer?: CustomerResultView;
    status?: MessageStatus;
    text?: string;
    attachments?: Array<string>;
    createdDate?: Date;
    updatedDate?: Date;
    messageNumber?: string;
    createdBy?: string;
    updatedBy?: string;
}