import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'loyale-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() label = 'Date';
  @Input() fullWidth = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() clearable = false;
  @Input() date = null;
  @Input() dateOnly = false;
  @Input() returnUtc = true;

  @Input()
  public set markAsTouched(touched: boolean) {
    if (touched === true) {
      this.dateForm.markAllAsTouched();
    }
  }

  @Output() onChange = new EventEmitter<any>();

  dateForm: UntypedFormGroup = new UntypedFormGroup({
    dataPickerControl: new UntypedFormControl(null, [])
  });

  constructor() { }

  ngOnInit() {
    if (this.date !== null) {
      this.dateForm.patchValue({ dataPickerControl: this.date });
    }

    if (this.required) {
      this.dateForm.get('dataPickerControl').setValidators([Validators.required]);
      this.dateForm.get('dataPickerControl').updateValueAndValidity();
    }

    this.dateForm.valueChanges.subscribe(res => {
      this.onDateChange();
    });
  }

  onClear() {
    this.date = null;
    this.dateForm.patchValue({ dataPickerControl: null });
    this.onChange.emit(null);
  }

  onDateChange() {
    if (this.dateForm.get('dataPickerControl').value != null) {
      if (this.dateOnly) {
        this.onChange.emit(this.dateForm.get('dataPickerControl').value.format('YYYY-MM-DD'));
      } else {
        if (this.returnUtc) {
          this.onChange.emit(this.dateForm.get('dataPickerControl').value.hour(0).minute(0).second(0).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));
        } else {
          this.onChange.emit(this.dateForm.get('dataPickerControl').value.hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ssZ'));
        }
      }
    }
  }
}
