/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LoyaleTransactionType } from './loyaleTransactionType';
import { OutletResultView } from './outletResultView';

export interface PointModifierResultView { 
    id?: string;
    transactionType?: LoyaleTransactionType;
    outletId?: string;
    outlet?: OutletResultView;
    value?: string;
    schemeId?: string;
    createdDate?: Date;
    updatedDate?: Date;
    description?: string;
}