/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ShopifyAddress { 
    firstName?: string;
    address1?: string;
    phone?: string;
    city?: string;
    zip?: string;
    province?: string;
    country?: string;
    lastName?: string;
    address2?: any;
    company?: string;
    latitude?: any;
    longitude?: any;
    name?: string;
    countryCode?: string;
    provinceCode?: string;
    id?: number;
    customerId?: number;
    countryName?: string;
    _default?: boolean;
}