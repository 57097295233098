<h1 mat-dialog-title>Schedule Type and Trigger</h1>

<div mat-dialog-content>
    <div class="row mb-3">
        <loyale-action-card [selectedFlag]="scheduleStatus ==='scheduling'"
                            class="col-12 col-lg-6 h-auto"
                            [icon]="'calendar_today'"
                            [title]="'Schedule'"
                            [description]="'Pick an event or time to send.'"
                            (click)="scheduleStatus = 'scheduling'">
        </loyale-action-card>

        <loyale-action-card [selectedFlag]="scheduleStatus ==='send_now'" class="col-12 col-lg-6 h-auto"
                            [icon]="'call_made'"
                            [title]="'Send now'"
                            [description]="'Deliver instantly.'"
                            (click)="scheduleStatus = 'send_now'">
        </loyale-action-card>

    </div>

    <loyale-schedule *ngIf="scheduleStatus ==='scheduling'" [actionType]="actionType"
                     [actionDataObject]="actionDataObject"
                     (scheduledTaskEvent)="sendScheduleTaskPost($event)" #schedule></loyale-schedule>

    <loyale-send-now *ngIf="scheduleStatus ==='send_now'" [actionType]="actionType"
                     [actionDataObject]="actionDataObject"
                     (scheduledTaskEvent)="sendScheduleTaskPost($event)" #sendNow></loyale-send-now>
</div>


<div mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]>Cancel</button>
    <button *ngIf="scheduleStatus ==='scheduling'" mat-flat-button color="accent" (click)="scheduleAction()">
        Continue
    </button>
    <button *ngIf="scheduleStatus ==='send_now'" mat-flat-button color="accent" (click)="send()">
        Continue
    </button>
    <div class="flex-fill"></div>
</div>
