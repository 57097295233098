import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
export interface Field {
  name?: string;
  properties?: any[];
  value?: any;
  group?: number;
  type: string;
}

@Component({
  selector: 'loyale-shared-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {

  @Input() fields: Field[] = [];
  @Output() submit = new EventEmitter<any>();

  formGroup: UntypedFormGroup;

  get value() {
    return this.formGroup.value;
  }

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.formGroup = this.fb.group({});
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.formGroup.valid) {
      this.submit.emit(this.formGroup.value);
    }
  }

  reset() {
    this.formGroup.reset();
  }

  createSingleControl(field) {
    this.submit.emit(this.formGroup.value);
    this.fields.push(field);

    if (field.type == 'or') return;

    const controlProperty = this.fb.control(field.value);
    const controlOperator = this.fb.control(field.value);
    const controlValue = this.fb.control(field.value);

    this.formGroup.addControl('group' + field.group + field.name + 'property', controlProperty);
    this.formGroup.addControl('group' + field.group + field.name + 'operator', controlOperator);
    this.formGroup.addControl('group' + field.group + field.name + 'value', controlValue);
  }

}
