import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'loyale-app-info-dialog',
  templateUrl: './app-info-dialog.component.html',
  styleUrls: ['./app-info-dialog.component.scss']
})
export class AppInfoDialogComponent implements OnInit {
  env = environment;
  year = new Date().getFullYear();
  language = navigator.language;
  timezone = null;

  constructor(public commonService: CommonService, public sessionService: SessionService) { }

  ngOnInit() {
    this.timezone = this.commonService.getTimezone();
  }
}
