import { Pipe, PipeTransform } from '@angular/core';

enum Gender {
  'Male' = 0,
  'Femaile',
  'Non-Binary',
  'Unspecified'
}

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(value: any): any {
    return Gender[value];
  }

}