/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ActivityPostLinkedResultView } from './activityPostLinkedResultView';
import { ActivityType } from './activityType';
import { CouponsLinkedResultView } from './couponsLinkedResultView';
import { CouponsUsageResultView } from './couponsUsageResultView';
import { CustomerPunchCardResultView } from './customerPunchCardResultView';
import { FeedbackLinkedResultView } from './feedbackLinkedResultView';
import { GiftCardLinkedResultView } from './giftCardLinkedResultView';
import { GiftCardsUsageResultView } from './giftCardsUsageResultView';
import { LotteryTicketResultView } from './lotteryTicketResultView';
import { PunchCardUsageResultView } from './punchCardUsageResultView';
import { TransactionGroupResultView } from './transactionGroupResultView';

export interface ActivityResultView { 
    customerId?: string;
    activityType?: ActivityType;
    schemeId?: string;
    alertLinked?: ActivityPostLinkedResultView;
    couponLinked?: CouponsLinkedResultView;
    couponsUsage?: CouponsUsageResultView;
    transactionGroup?: TransactionGroupResultView;
    customerPunchCard?: CustomerPunchCardResultView;
    lotteryTicket?: LotteryTicketResultView;
    punchCardUsage?: PunchCardUsageResultView;
    giftCardLinked?: GiftCardLinkedResultView;
    giftCardUsage?: GiftCardsUsageResultView;
    feedbackLinked?: FeedbackLinkedResultView;
}