import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionType } from '../stepper/action.enum';
import { ScheduledTaskActions, TimeTriggerService } from '../../swagger';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutomationActionPipe } from '../../pipes/automation-action.pipe';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'loyale-advanced-scheduling',
  templateUrl: './advanced-scheduling.component.html',
  styleUrls: ['./advanced-scheduling.component.scss']
})
export class AdvancedSchedulingComponent implements OnInit {
  @Input() actionType: ActionType;
  actionTypeEnum = ActionType;

  taskActions?: Array<ScheduledTaskActions> = [];

  showChips = false;

  hasPost = false;
  hasEmail = false;
  hasCoupon = false;
  hasGiftCard = false;
  hasProduct = false;
  hasPointsModifier = false;
  hasPointsMultiplier = false;
  hasPushNotification = false;
  hasFeedbackForm = false;
  hasTag = false;
  hasSMS = false;

  @Output() TaskActionsList: EventEmitter<any> = new EventEmitter<any>();

  constructor(private snackBar: MatSnackBar, private automationActionPipe: AutomationActionPipe, public sessionService: SessionService) { }

  ngOnInit() {
  }

  onToggleAction(type) {
    switch (type) {
      case 0:
        this.hasPushNotification = !this.hasPushNotification;
        !this.hasPushNotification ? this.onActionRemove(type) : null;
        break;
      case 1:
        this.hasPost = !this.hasPost;
        !this.hasPost ? this.onActionRemove(type) : null;
        break;
      case 2:
        this.hasEmail = !this.hasEmail;
        !this.hasEmail ? this.onActionRemove(type) : null;
        break;
      case 3:
        this.hasCoupon = !this.hasCoupon;
        !this.hasCoupon ? this.onActionRemove(type) : null;
        break;
      case 4:
        this.hasProduct = !this.hasProduct;
        !this.hasProduct ? this.onActionRemove(type) : null;
        break;
      case 5:
        this.hasPointsModifier = !this.hasPointsModifier;
        !this.hasPointsModifier ? this.onActionRemove(type) : null;
        break;
      case 6:
        this.hasPointsMultiplier = !this.hasPointsMultiplier;
        !this.hasPointsMultiplier ? this.onActionRemove(type) : null;
        break;
      case 8:
        this.hasGiftCard = !this.hasGiftCard;
        !this.hasGiftCard ? this.onActionRemove(type) : null;
        break;
      case 9:
        this.hasFeedbackForm = !this.hasFeedbackForm;
        !this.hasFeedbackForm ? this.onActionRemove(type) : null;
        break;
      case 10:
        this.hasTag = !this.hasTag;
        !this.hasTag ? this.onActionRemove(type) : null;
        break;
      case 11:
        this.hasSMS = !this.hasSMS;
        !this.hasSMS ? this.onActionRemove(type) : null;
        break;
      default:
    }
  }

  onActionRemove(type) {
    const index = this.taskActions.findIndex(a => a.type === type);

    if (index !== -1) {
      this.taskActions.splice(index, 1);
      this.snackBar.open(this.automationActionPipe.transform(type) + ' Action removed');
    }
    this.emitTaskActions();
  }

  onActionChange(actionType, actionId) {
    const action: ScheduledTaskActions = { id: actionId, type: actionType };

    const index = this.taskActions.findIndex(a => a.type === actionType);

    if (index === -1) {
      this.taskActions.push(action);
    } else {
      this.taskActions[index] = action;
    }

    this.emitTaskActions();
    this.snackBar.open(this.automationActionPipe.transform(actionType) + ' Action set');
  }

  changeActionAvailability() {
    this.showChips = !this.showChips;
    if (!this.showChips) {

      this.hasPost = false;
      this.hasEmail = false;
      this.hasCoupon = false;
      this.hasGiftCard = false;
      this.hasProduct = false;
      this.hasPointsModifier = false;
      this.hasPointsMultiplier = false;
      this.hasPushNotification = false;
      this.hasFeedbackForm = false;
      this.hasTag = false;
      this.hasSMS = false;

      this.taskActions = [];
      this.emitTaskActions();
    }
  }

  emitTaskActions() {
    this.TaskActionsList.emit(this.taskActions);
  }

}
