/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GiftCardLinkedUseView } from './giftCardLinkedUseView';
import { LineItemInsertView } from './lineItemInsertView';
import { LoyaleTransactionType } from './loyaleTransactionType';
import { LoyaleValueType } from './loyaleValueType';

export interface TransactionInsertView { 
    value: string;
    subTotal?: string;
    saleCurrency: string;
    lineItems?: Array<LineItemInsertView>;
    couponsUsed?: Array<string>;
    couponsCheckUsed?: Array<string>;
    giftCardsUsed?: Array<GiftCardLinkedUseView>;
    punchCardLinkedUsed?: Array<string>;
    customerId: string;
    valueType?: LoyaleValueType;
    transactionType: LoyaleTransactionType;
    posId?: string;
    posType?: string;
    outletId?: string;
    externalRefId: string;
    description?: string;
    transactionDate: Date;
    isSendPointsAction?: boolean;
    sourceTransactionId?: string;
    targetTransactionId?: string;
    isSplitted?: boolean;
    groupId?: string;
    preventRecurrence?: boolean;
    receiptNumber?: string;
}