import { Pipe, PipeTransform } from '@angular/core';

enum Type {
  'Abandoned' = 0,
  'Duplicate',
  'Fraudulent',
  'Requested By Customer'
}

@Pipe({
  name: 'stripeRefundReason'
})
export class StripeRefundReasonPipe implements PipeTransform {

  transform(value: any): string {
    return Type[value];
  }

}
