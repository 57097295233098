/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExternalReference } from './externalReference';
import { PaymentLine } from './paymentLine';
import { TransactionLine } from './transactionLine';

export interface Account { 
    id?: number;
    businessLocationId?: number;
    name?: string;
    accountNumber?: string;
    currencyCode?: string;
    deviceId?: number;
    deviceName?: string;
    staffId?: number;
    staffName?: string;
    paidAmount?: number;
    totalAmount?: number;
    coverCount?: number;
    gratuityAmount?: number;
    receiptNumber?: string;
    cancelled?: boolean;
    transactionLines?: Array<TransactionLine>;
    paymentLines?: Array<PaymentLine>;
    externalReferences?: Array<ExternalReference>;
    paymentInProgress?: boolean;
    taxAmount?: number;
    preTaxAmount?: number;
    serviceCharge?: number;
    orderMode?: string;
    type?: string;
    updateDate?: number;
    offset?: number;
    totalDiscount?: number;
    status?: string;
}