/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdditionalAdminFieldsResultView } from './additionalAdminFieldsResultView';
import { SchemeResultView } from './schemeResultView';

export interface AdminResultViewWithAdditionalFields { 
    additionalAdminFields?: Array<AdditionalAdminFieldsResultView>;
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    emailConfirmed?: boolean;
    role?: string;
    scheme?: SchemeResultView;
    updatedDate?: Date;
    createdDate?: Date;
    lastConnectedDate?: Date;
    lastPasswordChangeDate?: Date;
    twoFactorEnabled?: boolean;
    marketingSub?: boolean;
}